import { useCallback, useEffect, useMemo, useRef } from 'react'
import * as d3 from 'd3'
import { useDimensions } from '../../hooks/useDimensions'
import Utils from '../../services/utils'
import { BarChartProps } from './BarChart'

export const BarChartVertical = (props: BarChartProps) => {
    const cxRef = useRef<any>()
    const svgRef = useRef<any>()
    const { margin, width, height } = useDimensions({ cxRef, ...props })

    const barSize = useMemo(() => props.barSize || 27, [props.barSize])
    const minBarHeight = useMemo(() => props.minBarSize || barSize, [props.minBarSize, barSize])
    const textOffset = useMemo(() => 10, [])
    const halfBar = useMemo(() => barSize / 2, [barSize])

    const data = useMemo(() => props.data || [], [props.data])

    const hasComparison = useMemo(() => data.some((d) => d.keyLabel === 'compare'), [data])

    const svg = useMemo(
        () =>
            d3
                .select(svgRef.current)
                .attr('width', width + margin.left + margin.right)
                .attr('height', height + margin.top + margin.bottom),
        [svgRef.current, width, height, margin, hasComparison],
    )

    // thanks: https://observablehq.com/@toff/bar-chart-with-negative-values
    const xScale = useMemo(
        () =>
            d3
                .scaleBand()
                .domain(data.map((d) => d.key) || [])
                .range([margin.left, width - margin.right])
                .padding(props.bandPadding || 0),
        [data, width, margin, props.bandPadding],
    )

    const yScale = useMemo(() => {
        const maxValue = d3.max(data, (d) => d.amount) || 0
        const minValue = d3.min(data, (d) => d.amount) || 0
        return d3
            .scaleLinear()
            .domain([Math.min(minValue, 0), maxValue])
            .range([height - margin.bottom - minBarHeight, margin.top + textOffset])
            .nice()
    }, [data, height, margin, textOffset])

    const xAxis = useMemo(() => svg.append('g'), [svg])
    // const yAxis = useMemo(() => svg.append('g'), [svg])

    const getBarTopLeft = useCallback((d: any) => yScale(d.amount >= 0 ? d.amount : 0), [yScale, minBarHeight])

    const getTextLeft = useCallback(
        (d: any, offset: number = 0) => {
            const xLeft = (xScale(d?.key) || 0) + offset
            if (d?.keyLabel === 'compare') {
                return xLeft + xScale.bandwidth() / 3
            } else if (hasComparison) {
                return xLeft + xScale.bandwidth() / 1.4
            }
            return xLeft
        },
        [xScale, hasComparison],
    )

    const getBarHeight = useCallback(
        (d: any) => yScale(0) - yScale(Math.abs(d.amount || 0)) + minBarHeight,
        [yScale, minBarHeight],
    )

    useEffect(() => {
        // xAxis.selectAll('*').remove()
        xAxis.call(d3.axisBottom(xScale).tickSize(0).tickPadding(10))
        xAxis
            .attr('transform', `translate(0,${height})`)
            .classed('text-base-font', true)
            .classed('fs-base', true)
            .classed('text-primary', true)
        xAxis
            .selectAll('.tick text')
            .text((d: any) => {
                const _d = data?.find((dd) => dd.key === d)
                return _d?.name || d
            })
            .classed('x-axis-label', true)
            .transition()
        xAxis.select('.domain').remove()
        // yAxis.call(d3.axisLeft(y))

        const bars = svg.selectAll('rect.bar').data(data)
        bars.join('rect')
            .classed('bar', true)
            .attr('x', (d) => xScale(d.key) || 0)
            .attr('y', (d) => getBarTopLeft(d))
            .attr('rx', (d) => {
                if (hasComparison) return 10
                if (props.barRadius) return props.barRadius
                const bh = getBarHeight(d)
                if (bh <= minBarHeight) return `${bh / 2}px`
                return `${halfBar}px`
            })
            .attr('width', () => {
                if (hasComparison) return `${xScale.bandwidth() / 4}px`
                return `${props.barSize === null ? xScale.bandwidth() : barSize}px`
            })
            .attr('style', (d) => {
                let styles: string[] = []
                if (d.keyLabel === 'compare') {
                    styles.push(`transform: translateX(${xScale.bandwidth() / 3 - halfBar}px);`)
                } else if (hasComparison) {
                    styles.push(`transform: translateX(${xScale.bandwidth() / 1.4 - halfBar}px);`)
                } else {
                    styles.push(`transform: translateX(${xScale.bandwidth() / 2 - halfBar}px);`)
                }
                if (d.keyLabel === 'compare') {
                    styles.push('opacity: 0.25;')
                }
                return styles.join(' ')
            })
            .attr('height', (d) => getBarHeight(d))
            .attr('fill', (d) => (d.amount !== undefined ? d.color! : Utils.gray0))
            .transition()

        const labels = svg.selectAll('text.co2e-label').data(data)
        labels.selectAll('*').remove()
        labels
            .join('text')
            .classed('co2e-label', true)
            .classed('text-alt-font', true)
            .classed('very-small', true)
            .text((d) => d.valueLabel)
            // .text((d) => `${getBarTopLeft(d)}: ${getBarHeight(d)}`)
            .attr('style', 'text-anchor: middle')
            .attr('transform', (d) => {
                if (d.keyLabel === 'compare') {
                    const xPosition = getTextLeft(d)
                    const yPosition = getBarTopLeft(d) - textOffset
                    return `rotate(-90, ${xPosition}, ${yPosition})`
                }
                if (hasComparison) {
                    const xPosition = getTextLeft(d)
                    const yPosition = getBarTopLeft(d) - textOffset
                    return `rotate(-90, ${xPosition}, ${yPosition})`
                }
                return 'rotate(0)'
            })
            .attr('width', xScale.bandwidth())
            .attr('fill', Utils.getPrimaryColor)
            .attr('x', (d) => {
                if (d.keyLabel === 'compare') {
                    return getTextLeft(d, textOffset * 2)
                }
                if (hasComparison) {
                    return getTextLeft(d, -halfBar / 3 + textOffset)
                }
                return getTextLeft(d, xScale.bandwidth() / 2)
            })
            .attr('y', (d) => getBarTopLeft(d) - textOffset)
            .transition()
        if (props.debug) console.log(props.id, svg, data)
    }, [svg, data])

    return (
        <div
            ref={cxRef}
            className={`fs-base ${props.className || ''}`}
            style={{ minWidth: '300px', maxHeight: '100%', minHeight: '280px', ...props.style }}
        >
            <svg id={props.id} ref={svgRef} />
        </div>
    )
}
