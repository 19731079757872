import { GasPump, IconProps, Lightning } from '@phosphor-icons/react'
import { Inventory } from '../../services/inventory'

export const EnergyIcon = (props: IconProps & { inv?: Inventory; isFuel?: boolean }) => {
    if (props.isFuel || props.inv?.taxonomy?.path?.startsWith('energy/fuel')) {
        return <GasPump {...props} />
    }

    return <Lightning {...props} />
}
