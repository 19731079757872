import { HTMLAttributeAnchorTarget, useEffect, useState } from 'react'
import QRCode from 'qrcode'
import { Img } from './Img'
import { Modal } from './Modal'
import Utils from '../services/utils'

export const QrCode = (props: {
    data: string
    target?: HTMLAttributeAnchorTarget
    size: number
    className?: string
}) => {
    const [showLarge, setShowLarge] = useState<boolean>(false)
    const [qr, setQr] = useState<string>()

    useEffect(() => {
        if (props.data) {
            QRCode.toDataURL(props.data, { width: 1000, margin: 1 }).then(setQr)
        }
    }, [props.data])

    if (!qr) {
        return <span className='spinner-border spinner-border-sm' />
    }

    return (
        <>
            <a
                href={props.data}
                target={props.target || '_self'}
                className={props.className}
                onClick={(e) => {
                    if (!Utils.isModifierKey(e)) {
                        e.preventDefault()
                        setShowLarge(true)
                    }
                }}
            >
                <Img data={qr} size={`${props.size}px`} alt='Passport' />
            </a>
            {showLarge && (
                <Modal
                    hidden={false}
                    contentStyle={{ width: 'auto', margin: 'auto', maxWidth: '100%' }}
                    content={<Img data={qr} size='400px' alt='Passport' style={{ maxWidth: '100%' }} />}
                    onVisibilityChange={setShowLarge}
                />
            )}
        </>
    )
}
