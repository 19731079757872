import { User } from './user-context'
import { Company } from './company'
import { ActionMap } from '../context'
import VariableService from './service'
import { KeyValuePair, ND, VariableBaseNode } from '../types'
import { ProductWithInputs } from './product'
import { ExchangeStatus } from './exchange'
import { FileData } from './file'

export interface Token {
    uuid: string
    value: string
    enabled: boolean
    created?: number
    ttl?: number
    userAgent?: string
}

export interface SharingToken extends VariableBaseNode {
    value: string
    enabled: boolean
    claimedAt?: number
}

export interface ExchangeToken extends VariableBaseNode {
    node: ProductWithInputs
    nodeId?: string
    status?: ExchangeStatus
    labels: ND[]
    documents?: FileData[]
}

export interface TokenResponse {
    type: ND
    target: string
    createdBy?: User
    createdIn?: Company
    claimedByCompanyId?: string
    node?: any
    guestUser?: User
    guestCompany?: Company
}

export enum TokenValueActionType {
    Set = 'SetTokenValues',
    Reset = 'ResetTokenValues',
}

type TokenValueActionPayload = {
    [TokenValueActionType.Set]: string | undefined
    [TokenValueActionType.Reset]: undefined
}

export type TokenValueActions = ActionMap<TokenValueActionPayload>[keyof ActionMap<TokenValueActionPayload>]

export const TokenValueReducer = (state: string | undefined, action: TokenValueActions): string | undefined => {
    switch (action.type) {
        case TokenValueActionType.Set:
            return action.payload
        case TokenValueActionType.Reset:
            return undefined
        default:
            return state
    }
}

export default class TokenService extends VariableService {
    private basePath: string = '/token'
    public static webRoot: string = '/t'

    public static isTokenPath(path: string | null): boolean {
        if (!path) return false
        return path.startsWith(`${this.webRoot}/`)
    }

    public static getTokenUrl(sharingToken?: SharingToken, fullUrl: boolean = true): string {
        let url = `${fullUrl ? document.location.origin : ''}${this.webRoot}`
        if (!sharingToken) {
            return url
        }
        return `${url}/${sharingToken.value}`
    }

    public static getSharingUrl(token?: string, fullUrl: boolean = true): string | undefined {
        if (!token) return undefined
        let url = `${fullUrl ? document.location.origin : ''}${this.webRoot}`
        if (!token) return ''
        return `${url}/${token}`
    }

    public getSharingOptions(): KeyValuePair<boolean>[] {
        return [
            {
                name: 'Shareable link',
                value: true,
                description: (
                    <>
                        Anyone with the link can <strong>view</strong>
                    </>
                ),
            },
            { name: 'Private', value: false, description: <>Private, not shared</> },
        ]
    }

    public get(token: string): Promise<TokenResponse> {
        return this.httpService.get<TokenResponse>(`${this.basePath}/${token}`)
    }

    public enableToken(token: string): Promise<Token> {
        return this.httpService.patch<Token>(this.basePath, {
            body: JSON.stringify({ token: token, enabled: true }),
        })
    }

    public disableToken(token: string): Promise<Token> {
        return this.httpService.patch<Token>(this.basePath, {
            body: JSON.stringify({ token: token, enabled: false }),
        })
    }

    public claimToken(token: string) {
        this.httpService.post<void>(this.basePath, { body: JSON.stringify({ claim: token }) }).then()
    }
}
