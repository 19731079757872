import { useContext, useState } from 'react'
import { UnitString } from './CO2e'
import { UnitActionType, UnitSize } from '../services/unit'
import Utils from '../services/utils'
import Button from './Input/Button'
import { ApplicationContext } from '../context'

export const UnitHeader = (props: {
    className?: string
    extraClassName?: string
    unitSize: UnitSize
    plainNumber?: boolean
}) => {
    const context = useContext(ApplicationContext)
    const [unitStrings] = useState<UnitString[]>(props.unitSize === 'small' ? ['kg', 'g'] : ['t', 'kg'])

    return (
        <Button
            element='span'
            className={
                props.className || `border-0 bg-transparent p-0 shadow-none text-inherit ${props.extraClassName}`
            }
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                const idx = unitStrings.findIndex((us) => {
                    if (props.unitSize === 'large') {
                        return us === context.stores.unitLarge
                    }
                    return us === context.stores.unitSmall
                })
                let nextUnit = unitStrings[idx + 1]
                if (!nextUnit) {
                    nextUnit = unitStrings[0]
                }
                if (props.unitSize === 'large') {
                    context.dispatch({ type: UnitActionType.SetLarge, payload: nextUnit })
                } else {
                    context.dispatch({ type: UnitActionType.SetSmall, payload: nextUnit })
                }
            }}
        >
            {props.unitSize === 'large' && context.stores.unitLarge}
            {props.unitSize === 'small' && context.stores.unitSmall}
            {!props.plainNumber && Utils.co2e}
        </Button>
    )
}
