import { ND, StandardAttributes } from '../../types'
import { MutableRefObject, ReactNode, useContext, useMemo } from 'react'
import DataImportService, { DataImportResponse } from '../../services/dataImport'
import { VariableServicesContext } from '../../services'
import DromoReactUploader from 'dromo-uploader-react'
import { DownloadSimple } from '@phosphor-icons/react'
import Utils from '../../services/utils'
import { PrettyNumber } from '../PrettyNumber'

export const SupplierImport = (
    props: StandardAttributes & {
        children?: ReactNode
        showButton?: boolean
        dromoRef?: MutableRefObject<any>
        onDone?: (result: DataImportResponse) => void
        onCancel?: () => void
        onError?: (e: any) => void
    },
) => {
    const { companyService, dataImportService, analyticsService } = useContext(VariableServicesContext)

    const button = useMemo(() => {
        if (props.children) return props.children
        if (props.showButton === false) return null
        return (
            <>
                <DownloadSimple /> Import
            </>
        )
    }, [props.children, props.showButton])

    return (
        <DromoReactUploader
            schemaName='Import'
            schemaId='supplier-import'
            ref={props.dromoRef}
            className={props.className || 'btn btn-primary'}
            licenseKey={DataImportService.dromoLicenseKey}
            stepHooks={[
                {
                    // type: EStepHook.UPLOAD_STEP,
                    type: 'UPLOAD_STEP',
                    callback: (_, data) => {
                        analyticsService.track('Supplier Import: Uploaded CSV', {
                            rows: (data as any)?.dataPreview?.length,
                        })
                    },
                },
                {
                    // type: EStepHook.REVIEW_STEP,
                    type: 'REVIEW_STEP',
                    callback: (_, data) => {
                        analyticsService.track('Supplier Import: Review Data', {
                            rows: (data as any)?.dataPreview?.length,
                        })
                    },
                },
            ]}
            fields={[
                {
                    label: 'Name',
                    key: 'name',
                    alternateMatches: ['supplier', 'title'],
                    validators: [{ validate: 'required' }],
                },
                { label: 'Org Number', key: 'orgNumber' },
                { label: 'ID', key: 'internalId', alternateMatches: ['number', 'id'] },
                { label: 'Contact Name', key: 'contactName' },
                { label: 'Contact Email', key: 'contactEmail' },
            ]}
            columnHooks={[dataImportService.emailColumnHook('contactEmail')]}
            settings={dataImportService.dromoSettings({
                importIdentifier: 'Suppliers',
                templateDownloadFilename: 'Supplier_Import.csv',
            })}
            user={dataImportService.dromoUser()}
            onResults={(response) => {
                // console.log(response, metadata)
                analyticsService.track('Supplier Import: Submitted Data', { rows: response.length })
                const inProgressToast = Utils.infoToast(
                    <>
                        <span className='spinner-border spinner-border-sm' /> Importing Suppliers
                    </>,
                    { position: 'bottom-right', autoClose: 1500 },
                )
                companyService
                    .importSuppliers(response)
                    .then((result) => {
                        analyticsService.track('Supplier Import: Success', {
                            nodesCreated: result?.imported,
                            nodeTypes: result?.dataImport?.types,
                        })
                        const supplierCount = result.dataImport.nodes?.reduce((acc, node) => {
                            if (node?.type === ND.Company) acc += node.count || 0
                            return acc
                        }, 0)
                        const contactCount = result.dataImport.nodes?.reduce((acc, node) => {
                            if (node?.type === ND.Contact) acc += node.count || 0
                            return acc
                        }, 0)
                        Utils.dismissToast(inProgressToast)
                        Utils.successToast(
                            <>
                                Imported <PrettyNumber num={supplierCount} suffix=' supplier' pluralizeSuffix={true} />{' '}
                                and <PrettyNumber num={contactCount} suffix=' contact' pluralizeSuffix={true} />
                            </>,
                            { autoClose: 3000 },
                        )
                        companyService.getSuppliers().then()
                        dataImportService.get().then()
                        props.onDone?.(result)
                    })
                    .catch((errorMessage) => {
                        analyticsService.track('Supplier Import: Error', { errorMessage })
                        props.onError?.(errorMessage)
                    })
            }}
            onCancel={props.onCancel}
        >
            {button}
        </DromoReactUploader>
    )
}
