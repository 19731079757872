import { Handle, NodeProps, Position } from 'reactflow'
import { FootprintFlowActionType, INPUT_NODE_WIDTH } from '../../services/flow'
import { useContext } from 'react'
import { ApplicationContext } from '../../context'
import { InputAddButtons } from '../Product/InputAddButtons'
import InputService, { InputActionType } from '../../services/input'
import { UseStageCategoryType } from '../../services/useStage'
import { useProduct } from '../../hooks/useProduct'

export const DownstreamNode = ({ id, data, zIndex }: NodeProps) => {
    const context = useContext(ApplicationContext)
    const product = useProduct({ product: data?.product })
    const _height = data?.height ? `${data.height}px` : 'auto'
    return (
        <div
            className='rounded-2 p-2 border rounded-2 text-center overflow-hidden'
            style={{ width: `${INPUT_NODE_WIDTH}px`, height: _height, zIndex: zIndex }}
        >
            <div className='position-absolute start-0 end-0 bottom-0 mb-3 d-flex align-items-center justify-content-center'>
                {product && (
                    <InputAddButtons
                        className='d-flex flex-column align-items-start gap-2'
                        btnClassName='btn btn-sm btn-light border'
                        inputTypes={['input', 'use-stage']}
                        useStageType={UseStageCategoryType.DOWNSTREAM}
                        addText='Downstream'
                        product={product}
                        onChange={(newInput) => {
                            context.dispatch({ type: FootprintFlowActionType.Refresh })
                            if (!Array.isArray(newInput) && InputService.getInputType(newInput) === 'input') {
                                context.dispatch({ type: InputActionType.SetInputId, payload: newInput?.uuid })
                            }
                        }}
                    />
                )}
            </div>
            <Handle type='target' position={Position.Left} id={id} />
        </div>
    )
}
