import { useEffect } from 'react'

export const useSupportWidget = () => {
    useEffect(() => {
        const id = 'statuspage-js'
        const exists = document.getElementById(id)
        if (exists) return
        const script = document.createElement('script')
        script.setAttribute('id', id)
        script.setAttribute('type', 'text/javascript')
        script.setAttribute('async', 'true')
        script.setAttribute('src', 'https://variable-co.statuspage.io/embed/script.js')
        // console.log('append js')
        document.head.append(script)
    }, [])
}
