import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import UnitService, { Unit, UnitType } from '../../services/unit'
import { Selector, SelectorProps } from './Selector'
import { Warning } from '@phosphor-icons/react'
import Utils from '../../services/utils'
import { ApplicationContext } from '../../context'

export type UnitSelectorProps = Omit<SelectorProps, 'onSelect'> & {
    quantity?: number
    unit?: Unit
    unitType?: UnitType
    valueSuffix?: ReactNode
    hardCodeUnit?: Unit
    comparisonUnit?: Unit
    onChange: (newValue?: Unit) => void
}

export const UnitSelector = (props: UnitSelectorProps) => {
    const context = useContext(ApplicationContext)
    const [searchText, setSearchText] = useState<string>()
    const [units, setUnits] = useState<Unit[]>(UnitService.units)

    useEffect(() => {
        if (UnitService.units) {
            const _units = UnitService.units.filter((o) => {
                if (props.comparisonUnit?.type && o.type !== props.comparisonUnit.type) {
                    return false
                }
                if (props.unitType && o.type !== props.unitType) {
                    return false
                }
                return !(props.hardCodeUnit && o.code !== props.hardCodeUnit.code)
            })
            if (props.unitType && ![UnitType.CURRENCY, UnitType.UNKNOWN].includes(props.unitType)) {
                _units.sort((a, b) => (a.fromBaseUnit || 1) - (b.fromBaseUnit || 1))
            } else {
                _units.sort((a, b) => {
                    const _name = a.name.localeCompare(b.name)
                    if (b?.code?.substring(0, a?.code?.length) === a?.code) {
                        return a.code.length - b.code.length
                    }
                    return _name
                })
            }
            setUnits(_units)
        }
    }, [context.stores.ui?.unitsReady, searchText, props.comparisonUnit, props.unitType])

    const _label = useMemo(() => {
        if (!props.unit?.code) return undefined
        let label: ReactNode[] = [props.unit?.code]
        if (props.unit?.type === UnitType.UNKNOWN && props.quantity !== undefined) {
            label = [Utils.pluralize(props.unit?.code, props.quantity)]
        }
        if (props.valueSuffix) {
            label.push(props.valueSuffix)
        }
        return <>{label}</>
    }, [units, props.unit?.code, props.quantity, props.valueSuffix])

    const _footer = useMemo(() => {
        if ([props.unit?.type, props.comparisonUnit?.type].includes(UnitType.CURRENCY)) {
            return (
                <div className='bg-info bg-opacity-10 border-top border-primary-subtle p-2'>
                    <Warning className='nt--1 me-1' />
                    Note that currencies are not converted.
                </div>
            )
        }
        return undefined
    }, [props.unit?.type, props.comparisonUnit?.type])

    const _renderItemValue = useCallback(
        (u: Unit) => (
            <span className='d-flex align-items-center justify-content-between gap-3 text-nowrap'>
                <span>{u.code}</span>
                <span className='text-end'>{u.name}</span>
            </span>
        ),
        [],
    )

    if (!units) return null

    if (props.hardCodeUnit?.code) {
        return (
            <span className={`${props.className || 'small p-1 cursor-default'} ${props.extraClassName}`}>
                {props.hardCodeUnit?.code}
            </span>
        )
    }

    return (
        <Selector
            id={`unit-selector-${Math.random()}`}
            ariaLabel='Unit Selector'
            onSelect={props.onChange}
            options={units}
            option={props.unit}
            footer={_footer}
            placeholder='(unit)'
            positioning='fixed'
            labelKey='code'
            label={_label}
            tooltipStyle={{ minWidth: '10rem' }}
            onSearchText={setSearchText}
            renderItemValue={_renderItemValue}
            {...props}
        />
    )
}
