import { ArrowBendDownRight, ArrowBendLeftUp, Pulse } from '@phosphor-icons/react'
import Utils from '../../services/utils'
import ActivityService from '../../services/activity'
import { PrettyNumber } from '../PrettyNumber'
import { InventoryService, ProductUsageProps } from '../../services/inventory'
import { useContext, useMemo } from 'react'
import { ApplicationContext } from '../../context'
import Tooltip from '../Tooltip'
import { UsedInItem } from '../UsedInItem'
import { Link } from 'react-router-dom'
import CO2e from '../CO2e'

export const ProductUsage = (props: ProductUsageProps) => {
    const context = useContext(ApplicationContext)
    const inventoryStats = useMemo(() => InventoryService.getInventoryStats(props), [props])

    const activityLink = useMemo(() => {
        if (inventoryStats.inventory?.originalTransportType?.uuid) {
            return `${ActivityService.webRootActivities}?transportType=${inventoryStats.inventory?.originalTransportType?.uuid}&when=all`
        }
        return `${ActivityService.webRootActivities}?inventory=${inventoryStats.inventory?.uuid}&when=all`
    }, [inventoryStats])

    if (!context.stores.user?.uuid) return null

    return (
        <table className={`table table-sm table-striped m-0 ${props.extraClassName}`}>
            <tbody>
                <tr>
                    <td className='text-nowrap'>
                        <ArrowBendDownRight size={Utils.verySmallIconSize} /> Used in:
                    </td>
                    <td>
                        <span className='d-flex flex-column align-items-start'>
                            {!inventoryStats.partOfCount && <span className='text-muted'>None</span>}
                            {inventoryStats.partOf?.map((ui) => <UsedInItem usedIn={ui} key={`pu-${ui.uuid}`} />)}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td className='text-nowrap'>
                        <Pulse size={Utils.verySmallIconSize} /> {ActivityService.webTitle(true)}:
                    </td>
                    <td>
                        {!inventoryStats.activityCount && <span className='text-muted'>None</span>}
                        {!!inventoryStats.activityCount && (
                            <Link className='underline-on-hover' to={activityLink}>
                                {props.activityCo2e !== undefined && props.activityCo2e > 0 && (
                                    <>
                                        <CO2e co2e={props.activityCo2e} unitSize='large' /> in{' '}
                                    </>
                                )}
                                <PrettyNumber num={inventoryStats.activityCount} />{' '}
                                {ActivityService.webTitle(inventoryStats.activityCount !== 1).toLowerCase()}
                            </Link>
                        )}
                    </td>
                </tr>
                <tr>
                    <td className='text-nowrap'>
                        <ArrowBendLeftUp size={Utils.verySmallIconSize} /> Source for:
                    </td>
                    <td>
                        <span className='d-flex flex-column align-items-start'>
                            {!inventoryStats.sourceForCount && <span className='text-muted'>None</span>}
                            {inventoryStats.sourceFor?.map((ui) => <UsedInItem usedIn={ui} key={`pu-${ui.uuid}`} />)}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export const MiniProductUsage = (props: ProductUsageProps) => {
    const inventoryStats = useMemo(() => InventoryService.getInventoryStats(props), [props])

    const icons = useMemo(
        () => (
            <div className={`ms-auto d-flex gap-2 ${props.extraClassName}`}>
                {!!inventoryStats.partOfCount && (
                    <div className='d-flex align-items-center flex-nowrap'>
                        <ArrowBendDownRight size={Utils.verySmallIconSize} {...props.iconProps} />
                        <PrettyNumber num={inventoryStats.partOfCount} />
                    </div>
                )}
                {!!inventoryStats.activityCount && (
                    <div className='d-flex align-items-center flex-nowrap'>
                        <Pulse size={Utils.verySmallIconSize} {...props.iconProps} />
                        <PrettyNumber num={inventoryStats.activityCount || 0} />
                    </div>
                )}
                {!!inventoryStats.sourceForCount && (
                    <div className='d-flex align-items-center flex-nowrap'>
                        <ArrowBendLeftUp size={Utils.verySmallIconSize} {...props.iconProps} />
                        <PrettyNumber num={inventoryStats.sourceForCount} />
                    </div>
                )}
            </div>
        ),
        [inventoryStats],
    )

    if (props.showTooltip) {
        return (
            <Tooltip
                trigger='hover'
                placement='top'
                interactive={true}
                closeOnInteraction={true}
                tooltipContent={<ProductUsage {...props} extraClassName='bg-body small' />}
                className='d-flex align-items-center justify-content-center'
            >
                {icons}
            </Tooltip>
        )
    }
    return icons
}
