import { Input } from '../../services/input'
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import Utils from '../../services/utils'
import Button from '../Input/Button'
import { VariableNode } from '../../types'
import { TransportIcons } from '../Icons/TransportIcons'
import { ApplicationContext } from '../../context'
import { PrettyNumber } from '../PrettyNumber'
import { useInputType } from '../../hooks/useInputType'
import TransportService, { TransportInstance } from '../../services/transport'
import { VariableServicesContext } from '../../services'
import { useTransportInstance } from '../../hooks/useTransportInstance'
import { useTransportType } from '../../hooks/useTransportType'

export const TransportCalculator = (props: {
    transportInstance?: TransportInstance
    transportFor?: Input
    node?: VariableNode
    startOpen?: boolean
    disabled?: boolean
    className?: string
    extraClassName?: string
    btnClassName?: string
    prefix?: ReactNode
    suffix?: ReactNode
    onChange: (transportInstance: TransportInstance) => void
    onClear?: () => void
}) => {
    const context = useContext(ApplicationContext)
    const { transportService } = useContext(VariableServicesContext)
    const [ready, setReady] = useState<boolean>(false)
    const { setInstanceId, editTransportInstance } = useInputType({
        instanceId: props.node?.uuid || '',
        onDeleteTransportInstance: props.onClear,
    })

    const transportInstance = useTransportInstance({
        transportInstance: props.transportInstance || TransportService.getEmptyTransportInstance(props.transportFor),
    })
    const transportType = useTransportType({ transportType: transportInstance?.transportType })

    const co2e = useMemo(() => Utils.Decimal(transportInstance?.co2e || 0).toNumber(), [transportInstance?.co2e])

    useEffect(() => {
        if (transportInstance && ready) props.onChange(transportInstance)
        setTimeout(() => setReady(true), 100)
    }, [co2e])

    useEffect(() => {
        props.node?.uuid && setInstanceId(props.node?.uuid)
    }, [props.node?.uuid])

    useEffect(() => {
        if (props.node?.uuid && !transportInstance?.uuid && props.startOpen) {
            editTransportInstance({ ...(props.node as Input), transportInstance: transportInstance }).then()
        }
    }, [transportInstance?.uuid])

    const active: boolean = useMemo(
        () => !!transportInstance?.uuid && context.stores.transport?.instanceId === transportInstance?.uuid,
        [transportInstance?.uuid, context.stores.transport?.instanceId],
    )

    return (
        <Button
            disabled={props.disabled}
            className={[
                props.btnClassName || 'input-element w-auto',
                !co2e && !active ? 'btn-light border border-primary border-dashed' : '',
                active ? 'active' : '',
            ].join(' ')}
            onClick={async () => {
                if (props.disabled) return
                if (transportInstance && props.node && !context.stores.transport?.instanceId) {
                    if (!transportInstance?.uuid) transportInstance.uuid = TransportService.transportNewId
                    await editTransportInstance({ ...(props.node as Input), transportInstance })
                } else {
                    transportService.unsetTransportContext()
                }
            }}
        >
            {!transportInstance && <span className='spinner-border spinner-border-sm opacity-25' />}
            {transportInstance && (
                <>
                    <span hidden={!!co2e}>Configure</span>
                    {props.prefix}
                    <span
                        className={[
                            co2e ? 'd-flex' : 'd-none',
                            props.className || 'align-items-center justify-content-center gap-1',
                            props.extraClassName || '',
                        ].join(' ')}
                    >
                        <span>
                            <PrettyNumber
                                extraClassName='text-nowrap'
                                num={transportInstance?.weight?.quantity}
                                suffix={` ${transportInstance.weight?.unit?.code}`}
                            />
                            <PrettyNumber
                                extraClassName='text-nowrap'
                                prefix=', '
                                num={transportType?.segments?.length}
                                suffix=' leg'
                                pluralizeSuffix={true}
                            />
                        </span>
                        <span>
                            <TransportIcons node={props.node} transportType={transportType} />
                        </span>
                        {props.suffix}
                    </span>
                </>
            )}
        </Button>
    )
}
