import { CSSProperties, RefObject, useCallback, useEffect, useState } from 'react'

export const useDrag = (props: { draggable?: 'x' | 'y' | 'xy'; style?: CSSProperties; passedRef?: RefObject<any> }) => {
    const [offsetLeft, setOffsetLeft] = useState<number | undefined>(
        props.style?.left ? parseInt(props.style.left.toString()) : undefined,
    )
    const [offsetTop, setOffsetTop] = useState<number | undefined>(
        props.style?.top ? parseInt(props.style.top.toString()) : undefined,
    )
    const [startDragX, setStartDragX] = useState<number>()
    const [startDragY, setStartDragY] = useState<number>()
    const [dragging, setDragging] = useState<boolean>(false)

    useEffect(() => {
        if (props.passedRef?.current) {
            if (!props.style?.top) setStartDragY(props.passedRef.current.offsetTop)
            if (!props.style?.left) setStartDragX(props.passedRef.current.offsetLeft)
        }
    }, [props.passedRef])

    const onMouseMove = useCallback(
        (e: MouseEvent | any) => {
            if (!dragging) return
            if (props.draggable?.includes('y')) {
                const _diff = e.pageY - (startDragY || 0)
                setOffsetTop(Math.max(10, (offsetTop || 0) + _diff))
                setStartDragY(e.pageY)
            }
            if (props.draggable?.includes('x')) {
                const _diff = e.pageX - (startDragX || 0)
                setOffsetLeft((offsetLeft || 0) + _diff)
                setStartDragX(e.pageX)
            }
        },
        [dragging, offsetTop, offsetLeft, startDragY, startDragX],
    )

    const onMouseUp = useCallback(() => {
        setDragging(false)
        document.removeEventListener('mousemove', onMouseMove)
        document.removeEventListener('mouseup', onMouseUp)
    }, [dragging])

    useEffect(() => {
        if (!dragging) return
        document.addEventListener('mouseup', onMouseUp)
        document.addEventListener('mousemove', onMouseMove)
    }, [dragging])

    const onMouseDown = useCallback(
        (e: MouseEvent | any) => {
            if (!props.draggable) return
            setStartDragX(e.pageX)
            setStartDragY(e.pageY)
            setDragging(true)
        },
        [props.draggable],
    )

    return { offsetLeft, offsetTop, startDragX, startDragY, dragging, setDragging, onMouseDown, onMouseMove }
}
