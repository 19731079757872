import { AriaRole, RefObject, useEffect, useRef } from 'react'
import { StandardAttributes } from '../../types'

export const Checkbox = (
    props: StandardAttributes & {
        passedRef?: RefObject<any>
        id?: string
        name?: string
        role?: AriaRole
        checked?: boolean
        indeterminate?: boolean
        type?: 'checkbox' | 'radio'
        onChange?: (isChecked: boolean) => void
    },
) => {
    const internalRef = useRef<HTMLInputElement>(null)
    const ref = props.passedRef || internalRef

    useEffect(() => {
        if (ref.current) ref.current.indeterminate = props.indeterminate || false
    }, [props.indeterminate])

    const checkbox = (
        <input
            ref={ref}
            id={props.id}
            name={props.name}
            role={props.role}
            type={props.type || 'checkbox'}
            hidden={props.hidden}
            disabled={props.disabled}
            className={[props.className || 'form-check-input', props.extraClassName].join(' ')}
            style={props.style}
            checked={props.checked}
            onChange={(e) => props.onChange && props.onChange(e.target.checked)}
        />
    )

    if (props.role === 'switch') {
        return <div className='form-switch p-0'>{checkbox}</div>
    }

    return checkbox
}
