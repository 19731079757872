import DatePicker, { DatePickerProps } from 'react-datepicker'
import { DateNavigation } from './DateNavigation'
import { useContext } from 'react'
import { VariableServicesContext } from '../../services'
import Utils from '../../services/utils'

export type DateFieldProps = DatePickerProps & { endYear?: number }

export const DateField = (props: DateFieldProps) => {
    const { userService } = useContext(VariableServicesContext)

    // const onChange = useCallback(
    //     (month?: number, year?: number) => {
    //         const newDate = new Date(props.selected || new Date())
    //         if (month !== undefined) {
    //             newDate.setMonth(month)
    //             props.onMonthChange?.(newDate)
    //         }
    //         if (year !== undefined) {
    //             newDate.setFullYear(year)
    //             props.onYearChange?.(newDate)
    //         }
    //         props.onChange(newDate, undefined)
    //     },
    //     [props.selected],
    // )

    return (
        <DatePicker
            dateFormat={Utils.getLocaleDateString()}
            calendarStartDay={userService.getFirstDayOfWeek()}
            renderCustomHeader={(p) => (
                <DateNavigation
                    {...p}
                    endYear={props.endYear}
                    // changeMonth={(month) => onChange(month)}
                    // changeYear={(year) => onChange(undefined, year)}
                />
            )}
            popperPlacement='bottom'
            popperClassName='position-fixed'
            className='variable-form-control bg-light w-100'
            {...props}
        >
            {props.children}
        </DatePicker>
    )
}
