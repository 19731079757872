import { ApplicationContextInterface } from '../context'
import HttpService from './http'

class VariableService {
    public context: ApplicationContextInterface
    public httpService: HttpService

    constructor(context: ApplicationContextInterface) {
        this.context = context
        this.httpService = new HttpService(context)
    }

    public setContext(context: ApplicationContextInterface) {
        this.context = context
        this.httpService = new HttpService(context)
    }
}

export default VariableService
