import { Unit } from './unit'
import { Product } from './product'
import reactStringReplace from 'react-string-replace'
import { renderToStaticMarkup } from 'react-dom/server'
import { evaluate } from 'mathjs'
import VariableService from './service'
import Utils from './utils'
import { VariableBaseNode } from '../types'

export enum CalculatorInputType {
    VALUE = 'value',
    PERCENT = 'percent',
    UNIT = 'unit',
    FACTOR = 'factor',
    ENUM = 'enum',
}

export const getCalculatorInputTypeEnum = (x: string): CalculatorInputType => {
    switch (x) {
        case CalculatorInputType.FACTOR:
            return CalculatorInputType.FACTOR
        case CalculatorInputType.ENUM:
            return CalculatorInputType.ENUM
        case CalculatorInputType.UNIT:
            return CalculatorInputType.UNIT
        case CalculatorInputType.PERCENT:
            return CalculatorInputType.PERCENT
        default:
            return CalculatorInputType.VALUE
    }
}

export interface CalculatorInput extends VariableBaseNode {
    name: string
    order: number
    baseUnit?: Unit
    type?: CalculatorInputType
    options?: string[] | number[]
    products?: Product[]
    example?: string | number
    examples?: string[] | number[]

    value?: string
    amount?: number
    product?: Product
    unit?: Unit
}

export interface CalculationValue extends VariableBaseNode {
    input: CalculatorInput
    value?: string
    product?: Product
    unit?: Unit
}

export interface Calculation extends VariableBaseNode {
    name: string
    calculator: Calculator
    data: CalculationValue[]
    co2e?: string
}

export interface Calculator extends VariableBaseNode {
    name: string
    formula: string
    inputs: CalculatorInput[]
}

export const EmptyCalculatorInput: CalculatorInput = {
    name: '',
    order: 0,
    type: CalculatorInputType.VALUE,
    example: 1,
    products: [],
}

export const EmptyCalculationValue: CalculationValue = {
    input: EmptyCalculatorInput,
}

export const EmptyCalculator: Calculator = {
    name: '',
    formula: '',
    inputs: [],
}

export const EmptyCalculation: Calculation = {
    name: '',
    calculator: EmptyCalculator,
    data: [],
}

export const ExampleCalculator: Calculator = {
    name: '🚚 Transport Calculator',
    formula: '[How Many] * ([Weight] * [Utilization]%) * [Fuel Amount]',
    inputs: [
        {
            order: 0,
            name: 'How Many',
            type: CalculatorInputType.ENUM,
            options: [1, 2, 3],
        },
        {
            order: 1,
            name: 'Weight',
            type: CalculatorInputType.UNIT,
            example: 10,
        },
        {
            order: 2,
            name: 'Utilization',
            type: CalculatorInputType.VALUE,
            example: 80,
        },
        {
            order: 3,
            name: 'Quantity of Fuel',
            type: CalculatorInputType.FACTOR,
        },
    ],
}

export default class CalculatorService extends VariableService {
    private basePath: string = '/calculator'
    public static webRoot: string = '/calculator'

    public static getCalculatorInputsSortedByFormula = (calculator: Calculator): CalculatorInput[] => {
        const { inputs } = calculator
        inputs.map((input) => {
            input.order = calculator.formula.indexOf(`[${input.name}]`)
            return input
        })
        inputs.sort((a, b) => a.order - b.order)
        return inputs
    }

    public static getFormula(
        calculator: Calculator,
        data?: CalculationValue[],
        useExamples: boolean = false,
        formula?: string,
    ): string {
        let actualFormula = formula || calculator.formula
        calculator.inputs?.forEach((input) => {
            const _data = this.getValueForInput(input, data)
            let value = Utils.Decimal('0')

            if (input.products?.length) {
                if (_data) {
                    if (!_data?.product) {
                        _data.product = input.products[0]
                    }
                    value = Utils.Decimal(_data.product?.co2e || '0')
                } else if (useExamples) {
                    value = Utils.Decimal(input.products[0]?.co2e || '0')
                }
                // const amount = Utils.Decimal(_data?.value || '0' || (useExamples ? 1 : 0))
                const multiplier = Utils.Decimal(_data?.unit?.fromBaseUnit || 1)
                const co2e = Utils.Decimal(value)
                // value = amount.times(multiplier).times(co2e)
                value = multiplier.times(co2e)
            } else if (input.type === CalculatorInputType.FACTOR) {
                // for when no value has been selected, use the first option
                value = Utils.Decimal(input.products?.[0]?.co2e || '0')
            } else {
                // default to example data if there is any (and useExamples is true)
                if (input.type === CalculatorInputType.ENUM) {
                    let [, _value] = CalculatorService.getEnumValue(_data?.value || input.options?.[0])
                    if (isNaN(_value)) {
                        _value = 0
                    }
                    value = Utils.Decimal(_value || 0)
                } else {
                    value = Utils.Decimal(
                        _data?.value || (useExamples && input.example ? input.example.toString() : '0'),
                    )
                }

                if (input.type === CalculatorInputType.UNIT && _data?.unit?.fromBaseUnit) {
                    value = value.times(Utils.Decimal(_data?.unit?.fromBaseUnit))
                } else if (input.type === CalculatorInputType.PERCENT) {
                    value = Utils.Decimal(value).dividedBy(Utils.Decimal(100))
                }
            }

            try {
                const regex = new RegExp(`\\[${input.name}\\]`, 'gi')
                actualFormula = actualFormula.replace(regex, value.toString())
            } catch (e) {
                console.warn(e)
            }
        })
        return actualFormula
    }

    public static getEnumValue(item: any) {
        let display = item
        let value = item
        if (typeof item === 'string') {
            const kv = item.split('=')
            if (kv.length === 2) {
                display = kv[0]
                value = kv[1]
            }
        }
        return [display, value]
    }

    public static getValueForInput(
        calculatorInput: CalculatorInput,
        calculationData?: CalculationValue[],
    ): CalculationValue {
        return (
            calculationData?.find((d) => d?.input?.name === calculatorInput.name) || {
                ...EmptyCalculationValue,
                input: calculatorInput,
            }
        )
    }

    public static getHtmlString(calculator: Calculator, formula?: string): string {
        const editableFormula = this.getHtml(calculator, formula)
        return renderToStaticMarkup(<div>{editableFormula} </div>).toString()
    }

    public static getHtml(calculator: Calculator, formula?: string): string {
        let editableFormula: any = formula || calculator.formula
        calculator.inputs?.forEach((input) => {
            const calcVar = `[${input.name}]`
            editableFormula = reactStringReplace(editableFormula, calcVar, (match, i) => (
                <span
                    key={`editableFormula-${input.uuid}-${i}`}
                    contentEditable={false}
                    className='bg-primary bg-opacity-10 p-1'
                >
                    {match}
                </span>
            ))
        })
        return editableFormula
    }

    public static getResult(
        calculator: Calculator,
        data?: CalculationValue[],
        useExamples: boolean = false,
        formula?: string,
    ): number {
        const mathFormula = this.getFormula(calculator, data, useExamples, formula)
        if (!mathFormula) {
            return 0
        }
        try {
            return evaluate(mathFormula)
        } catch (e) {
            console.warn(e)
            return 0
        }
    }

    public get(): Promise<Calculator[]> {
        return this.httpService.get<Calculator[]>(this.basePath)
    }

    public getCalculator(calculatorId?: string): Promise<Calculator> {
        return this.httpService.get<Calculator>(`${this.basePath}/${calculatorId}`)
    }

    public createOrUpdate(calculator: Calculator): Promise<Calculator> {
        const method = calculator.uuid ? 'put' : 'post'
        return this.httpService.fetch<Calculator>(method, this.basePath, {
            body: JSON.stringify({
                calculator: calculator,
            }),
        })
    }

    public getInstance(calculationId?: string): Promise<Calculation> {
        return this.httpService.get<Calculation>(`${this.basePath}/instance/${calculationId}`)
    }

    public updateInstance(calculation?: Calculation): Promise<Calculation> {
        return this.httpService.put<Calculation>(`${this.basePath}/${calculation?.calculator?.uuid}/instance`, {
            body: JSON.stringify({
                calculation: calculation,
            }),
        })
    }
}
