import { useContext, useMemo } from 'react'
import { ApplicationContext } from '../context'
import InputService, { Input } from '../services/input'

export const useInput = (props: { input?: Input | null; inputId?: string; condition?: boolean }) => {
    const context = useContext(ApplicationContext)
    return useMemo(() => {
        if (props.condition === false) return undefined
        return InputService.byId.get(props.inputId || props.input?.uuid || '') || props.input || undefined
    }, [props.inputId, props.input?.uuid, props.condition, context.stores.input.updates, context.stores.parts.updates])
}
