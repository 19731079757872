import Utils from '../../services/utils'
import { Selector, SelectorProps } from './Selector'
import { StandardAttributes } from '../../types'

export const YearSelector = (
    props: StandardAttributes &
        Omit<SelectorProps, 'onSelect'> & {
            startYear?: number
            endYear?: number
            onChange: (year: number) => void
        },
) => {
    return (
        <Selector
            options={Utils.yearsKeyValueList(props.startYear || 1970, props.endYear).reverse()}
            tooltipStyle={{ maxWidth: '320px' }}
            className='variable-select-arrow text-height-1'
            extraClassName={props.extraClassName}
            itemExtraClassName='font-monospace'
            {...props}
            onSelect={(r) => props.onChange(parseInt(r.value, 10))}
        />
    )
}
