import { Product } from './product'
import { Contact, NewUser, User, UserWithoutID } from './user-context'
import { ActivityCalculationMethod, ActivityItem } from './activity'
import { KeyValuePair, ListResponse, VariableBaseNode } from '../types'
import { SharingToken } from './token'
import { Unit } from './unit'
import { ActionMap } from '../context'
import { IndustryActionType, IndustrySector } from './industries'
import { Subscription } from './subscription'
import { ReactNode } from 'react'
import { DataSource, PremiumFactors } from './dataSource'
import Utils from './utils'
import VariableService from './service'
import { ManipulateType } from 'dayjs'
import { DataImportResponse } from './dataImport'
import { UIOptionActionType } from './ui'

export type CompanyPerspective = 'supplier' | 'customer' | 'both'

export type CompanyTableColumnKey = 'name' | 'connectionStatus' | 'priority' | 'spendValue' | 'totalCo2e' | 'etc'

export type CompanyPageTab = 'products' | 'activities' | 'requests' | 'contacts'

export type CompanyAdminTab = 'settings' | 'users' | 'imports'

export type CompanySize = 'very-small' | 'small' | 'medium' | 'large' | 'enterprise'

export enum CompanyImportance {
    High = 100,
    Medium = 50,
    Low = 10,
    Unknown = 0,
}

export const companyImportanceOptions: KeyValuePair<CompanyImportance>[] = [
    { name: 'High', value: CompanyImportance.High },
    { name: 'Medium', value: CompanyImportance.Medium },
    { name: 'Low', value: CompanyImportance.Low },
]

export interface CompanyRelationshipConfig {
    internalId?: string
    nationalId?: string
    state?: CompanyState
    importance?: CompanyImportance
}

export interface CompanySpend extends VariableBaseNode {
    year?: number
    value?: number
}

export enum CompanySubdomainVisibility {
    PRIVATE = 'private',
    CONNECTED = 'connected',
    PUBLIC = 'public',
}

export enum CompanyState {
    ACTIVE = 'active',
    ARCHIVED = 'archived',
}

export interface Company extends VariableBaseNode {
    labelKey?: string
    claimed?: boolean
    editable?: boolean
    name: string
    aka?: string[]
    slug?: string
    type?: 'company' | 'database'
    subdomainVisibility?: CompanySubdomainVisibility
    companySize?: CompanySize
    industry?: IndustrySector
    logoUrl?: string
    nationalId?: string
    currency?: Unit
    methodologyDescription?: string
    carbonAmbition?: number
    internalCarbonPrice?: number
    downstreamActivityConfiguration?: ActivityCalculationMethod
    domains?: CompanyDomain[]
    contacts?: Contact[]
    contactCount?: number
    token?: SharingToken
    subscription?: Subscription
    replacedBy?: Company
    replacedCompany?: Company
    rlConfig?: CompanyRelationshipConfig
    spend?: CompanySpend
    yearlySpend?: CompanySpend[]
    dataSources?: DataSource[]

    memberCount?: number
    userCount?: number
    partCount?: number
    productCount?: number
    activityCount?: number
    premiumCount?: number
    co2e?: string
}

export interface VariableCustomer extends VariableBaseNode {
    stripeId: string
}

export interface CompanyDomain extends VariableBaseNode {
    url: string
    txt?: string
    verified?: boolean
    verifiedDate?: number
    adminVerified?: boolean
    autoJoin?: boolean
}

export interface CompanyMembership {
    created: number
    updated?: number
}

export interface CompanyMember extends VariableBaseNode {
    user: User
    membership?: CompanyMembership
}

export enum UserRoleSlug {
    OWNER = 'owner',
    ADMIN = 'admin',
    CONTRIBUTOR = 'contributor',
    READONLY = 'readonly',
}

export interface UserRole extends VariableBaseNode {
    slug: UserRoleSlug
    level: number
    isDefault?: boolean
}

export interface SupplierCustomerResponse {
    company: Company
    activities?: ActivityItem[]
    products?: Product[]
    co2e?: string
}

export const EmptyCompany = {
    name: '',
    contacts: [],
}

export enum CompanyActionType {
    Set = 'SetCompany',
    Reset = 'ResetCompany',
}

type CompanyActionPayload = {
    [CompanyActionType.Set]: Company | undefined
    [CompanyActionType.Reset]: undefined
}

export type CompanyActions = ActionMap<CompanyActionPayload>[keyof ActionMap<CompanyActionPayload>]

export const CompanyReducer = (state: Company | undefined, action: CompanyActions): Company | undefined => {
    switch (action.type) {
        case CompanyActionType.Set:
            return action.payload
        case CompanyActionType.Reset:
            return undefined
        default:
            return state
    }
}

export default class CompanyService extends VariableService {
    private basePath: string = '/company'
    public static webRootSupplierList: string = '/suppliers'
    public static webRootSupplier: string = '/supplier'
    public static webRootCustomerList: string = '/customers'
    public static webRootCustomer: string = '/customer'
    public static webRootAdmin: string = '/company'
    public static webRootUsers: string = '/users'
    public static webTitle = (plural: boolean = false): string => (plural ? 'Companies' : 'Company')
    public static webTitleSupplier: string = 'Suppliers'
    public static webTitleCustomer: string = 'Customers'

    public static userRoles: UserRole[] = []
    public static membersById: Map<string, CompanyMember> = new Map()
    public static membersByEmail: Map<string, CompanyMember> = new Map()

    public static byId: Map<string, Company> = new Map()
    public static suppliersById: Map<string, Company> = new Map()
    public static customersById: Map<string, Company> = new Map()

    private setSupplierContext(suppliers: Company[]) {
        suppliers.forEach((s) => {
            if (s.uuid) {
                const existing = CompanyService.suppliersById.get(s.uuid)
                const updated = { ...existing, ...s }
                CompanyService.suppliersById.set(s.uuid, updated)
                CompanyService.byId.set(s.uuid, updated)
                if (s.replacedCompany?.uuid) CompanyService.byId.set(s.replacedCompany.uuid, updated)
            }
        })
        this.context.dispatch({ type: UIOptionActionType.SetCompaniesUpdated })
    }

    private setCustomerContext(customers: Company[]) {
        customers.forEach((c) => {
            if (c.uuid) {
                const existing = CompanyService.customersById.get(c.uuid)
                const updated = { ...existing, ...c }
                CompanyService.customersById.set(c.uuid, updated)
                CompanyService.byId.set(c.uuid, updated)
                if (c.replacedCompany?.uuid) CompanyService.byId.set(c.replacedCompany.uuid, updated)
            }
        })
        this.context.dispatch({ type: UIOptionActionType.SetCompaniesUpdated })
    }

    public isEditable(company?: Company | null): boolean {
        if (!company?.uuid) return false
        if (this.context.stores.user?.role?.slug === UserRoleSlug.READONLY) return false
        if (this.isMyCompany(company)) return true
        return !company?.claimed && company?.editable === true
    }

    public getCompanyUrl(
        company?: Company | null,
        perspective: CompanyPerspective = 'supplier',
        fullUrl: boolean = false,
        debug: boolean = false,
    ): string {
        let url = `${fullUrl ? document.location.origin : ''}`
        const isSupplier = CompanyService.suppliersById.get(company?.uuid || '')
        const isCustomer = CompanyService.customersById.get(company?.uuid || '')
        if (perspective === 'both') {
            if (isSupplier) perspective = 'supplier'
            if (isCustomer) perspective = 'customer'
        }
        if (debug) console.log(perspective, isSupplier?.name, isCustomer?.name)
        if (perspective === 'supplier' && isSupplier) {
            url += `${CompanyService.webRootSupplier}/${company?.uuid}`
        } else if (perspective === 'customer' && isCustomer) {
            url += `${CompanyService.webRootCustomer}/${company?.uuid}`
        }
        return url
    }

    public static getCompanyImportanceString(value?: number): string {
        if (value === CompanyImportance.High) {
            return 'High'
        }
        if (value === CompanyImportance.Medium) {
            return 'Medium'
        }
        if (value === CompanyImportance.Low) {
            return 'Low'
        }
        return ''
    }

    public static getCompanyImportanceIcon(importance?: CompanyImportance): ReactNode {
        return (
            <span
                style={{ width: '.5rem', height: '.5rem' }}
                className={[
                    'rounded-circle me-1 d-inline-block nt--1',
                    importance === CompanyImportance.Low ? 'bg-dark bg-opacity-50' : '',
                    importance === CompanyImportance.Medium ? 'bg-warning' : '',
                    importance === CompanyImportance.High ? 'bg-danger' : '',
                ].join(' ')}
            />
        )
    }

    public static get companyId(): string | null {
        return localStorage.getItem('companyId')
    }

    public static set companyId(companyId: string | null) {
        const currentCompanyId = this.companyId
        if (companyId === null) {
            localStorage.removeItem('companyId')
        } else if (companyId !== currentCompanyId) {
            localStorage.setItem('companyId', companyId)
        }
    }

    private subdomainRegex = /^[a-z0-9-]+$/

    public isValidSubdomain(subdomain: string): boolean {
        return this.subdomainRegex.test(subdomain)
    }

    public isMyCompany(company?: Partial<Company> | null): boolean {
        if (!company) return false
        return company.uuid === this.context.stores.company?.uuid
    }

    public isNewCompany(company?: Company | null, timeQuantity?: number, timeUnit?: ManipulateType): boolean {
        if (!company) company = this.context.stores.company
        return (
            company?.created !== undefined &&
            Utils._dayjs(company?.created).isAfter(Utils._dayjs().subtract(timeQuantity || 1, timeUnit || 'week'))
        )
    }

    public setCompany(uuid?: string, companies?: Company[]) {
        const userCompanies = companies || this.context.stores.user?.companies
        const qs = new URLSearchParams(document.location.search.substring(1))
        const qsUuid = qs.get('u')
        if (uuid) {
            CompanyService.companyId = userCompanies?.find((c) => c.uuid === uuid)?.uuid || null
        } else if (qsUuid) {
            CompanyService.companyId = userCompanies?.find((c) => c.uuid === qsUuid)?.uuid || null
        } else {
            const _previousCompany = userCompanies?.find((c) => c.uuid === CompanyService.companyId)
            if (_previousCompany) {
                return
            } else {
                CompanyService.companyId = userCompanies?.[0]?.uuid || null
            }
        }
    }

    public static companySizes = [
        { name: '1 - 10', value: 'very-small' },
        { name: '10 - 50', value: 'small' },
        { name: '50 - 500', value: 'medium' },
        { name: '500 - 1000', value: 'large' },
        { name: '1000+', value: 'enterprise' },
    ]

    public subdomainVisibilityOptions: KeyValuePair<CompanySubdomainVisibility>[] = [
        { name: 'Private', value: CompanySubdomainVisibility.PRIVATE, description: 'Only you can see the page' },
        // { name: 'Connected', value: CompanySubdomainVisibility.CONNECTED },
        { name: 'Public', value: CompanySubdomainVisibility.PUBLIC, description: 'Anyone can see the page' },
    ]

    public getCompany(companyId: string): Promise<Company> {
        if (!companyId) {
            return Promise.reject()
        }
        return this.httpService.get<Company>(`${this.basePath}/${companyId}`)
    }

    public getCompanyBySlug(): Promise<Company> {
        return this.httpService.get<Company>(this.basePath)
    }

    public checkCompanyDomain(): Promise<Company> {
        return this.httpService.get<Company>(`${this.basePath}/${CompanyService.companyId}?d=1`)
    }

    public getSlimCompanies() {
        const slimParams = [
            'uuid',
            'name',
            'logoUrl',
            'type',
            'productCount',
            'activityCount',
            'co2e',
            'claimed',
            'editable',
            'spend',
            'contacts',
            'dataImportId',
            'replacedCompany',
            'rlConfig',
        ]
        const qs = new URLSearchParams(`return=${slimParams.join(',')}&limit=-1`)
        this.getSuppliers(qs.toString()).then()
        this.getCustomers(qs.toString()).then()
    }

    public async getMyCompany(): Promise<Company | undefined> {
        if (CompanyService.companyId) {
            return this.getCompany(CompanyService.companyId)
                .then((fullCompany) => {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    this.context.dispatch({ type: CompanyActionType.Set, payload: fullCompany })
                    return fullCompany
                })
                .catch(() => {
                    CompanyService.companyId = null
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    this.context.dispatch({ type: CompanyActionType.Reset })
                    return undefined
                })
        }
        return Promise.resolve(undefined)
    }

    public getPremiumFactors(isAdmin: boolean = false): Promise<PremiumFactors[]> {
        if (!this.context.stores.company?.uuid) {
            return Promise.reject('No company')
        }
        let url = `${this.basePath}/${this.context.stores.company?.uuid}/premium`
        if (isAdmin) {
            url += '?a=1'
        }
        return this.httpService.get<PremiumFactors[]>(url)
    }

    public async toggleArchived(company?: Company, perspective?: CompanyPerspective): Promise<Company | undefined> {
        if (!company?.uuid) return
        const state = company.rlConfig?.state === CompanyState.ARCHIVED ? CompanyState.ACTIVE : CompanyState.ARCHIVED
        return this.updateCompany({ uuid: company.uuid, rlConfig: { state } }, perspective).then((c) => {
            if (state === CompanyState.ARCHIVED) Utils.successToast(`${company.name} archived`)
            if (state === CompanyState.ACTIVE) Utils.successToast(`${company.name} unarchived`)
            return c
        })
    }

    public async updateCompany(company: Partial<Company>, perspective?: CompanyPerspective): Promise<Company> {
        return this.httpService
            .put<Company>(`${this.basePath}/${company.uuid}`, { body: JSON.stringify({ company, perspective }) })
            .then((updatedCompany) => {
                if (updatedCompany.uuid === this.context.stores.company?.uuid) {
                    this.context.dispatch({
                        type: CompanyActionType.Set,
                        payload: { ...this.context.stores.company!, ...updatedCompany },
                    })
                } else {
                    const isCustomer = CompanyService.customersById.get(updatedCompany.uuid || '')
                    if (isCustomer) {
                        this.setCustomerContext([updatedCompany])
                    } else {
                        this.setSupplierContext([updatedCompany])
                    }
                }
                return updatedCompany
            })
    }

    public getSubscriptions(subscriptionId?: string): Promise<Subscription[]> {
        let url = `${this.basePath}/subscription`
        if (subscriptionId) url += `/${subscriptionId}`
        return this.httpService.get<Subscription[]>(url)
    }

    public updateSubscription(companyId: string, subscriptionId: string): Promise<Company[]> {
        return this.httpService.put<Company[]>(this.basePath, { body: JSON.stringify({ companyId, subscriptionId }) })
    }

    public updateDomain(company: Company, domain: CompanyDomain, isAdmin: boolean = false): Promise<CompanyDomain> {
        return this.httpService.put<CompanyDomain>(`${this.basePath}/${company.uuid}`, {
            body: JSON.stringify({ domain, isAdmin }),
        })
    }

    public removeDomain(company: Company, domain: CompanyDomain): Promise<CompanyDomain> {
        return this.httpService.delete<CompanyDomain>(`${this.basePath}/${company.uuid}/domain/${domain.uuid}`)
    }

    public async createSupplier(supplier: Company): Promise<Company> {
        return this.httpService.post<Company>(this.basePath, { body: JSON.stringify({ supplier }) }).then((c) => {
            this.setSupplierContext([c])
            this.getSuppliers()
            return c
        })
    }

    public connectVia(user: UserWithoutID, company: Company, customMessage: string): Promise<Company[]> {
        return this.httpService.post<Company[]>(`${this.basePath}/${company.uuid}`, {
            body: JSON.stringify({
                connectVia: user,
                customMessage: customMessage,
            }),
        })
    }

    public async deleteSupplier(supplier: Company): Promise<void> {
        return this.httpService.delete<void>(`${this.basePath}/supplier/${supplier.uuid}`).then(() => {
            if (!supplier.uuid) return
            CompanyService.suppliersById.delete(supplier.uuid)
            CompanyService.byId.delete(supplier.uuid)
            this.context.dispatch({ type: UIOptionActionType.SetCompaniesUpdated })
        })
    }

    public async createCustomer(customer: Company): Promise<Company> {
        return this.httpService.post<Company>(this.basePath, { body: JSON.stringify({ customer }) }).then((c) => {
            this.setCustomerContext([c])
            this.getCustomers()
            return c
        })
    }

    public async deleteCustomer(customer: Company): Promise<void> {
        return this.httpService.delete<void>(`${this.basePath}/customer/${customer.uuid}`).then(() => {
            if (!customer.uuid) return
            CompanyService.customersById.delete(customer.uuid)
            CompanyService.byId.delete(customer.uuid)
            this.context.dispatch({ type: UIOptionActionType.SetCompaniesUpdated })
        })
    }

    public async inviteViaEmail(emailList: string[]): Promise<void> {
        return this.httpService
            .post<void>(`${this.basePath}/member`, { body: JSON.stringify({ emailList }) })
            .then(() => {
                this.getMyCompany().then()
                this.getMembers().then()
            })
    }

    public async inviteNewUsers(newUsers: NewUser[]): Promise<void> {
        return this.httpService
            .post<void>(`${this.basePath}/member`, { body: JSON.stringify({ newUsers }) })
            .then(() => {
                this.getMyCompany().then()
                this.getMembers().then()
            })
    }

    public getConnectedSupplierCount(): number {
        return Array.from(CompanyService.suppliersById.values()).filter((s) => s?.claimed)?.length || 0
    }

    public hasMoreSeats(seatsBeingAdded: number = 0): boolean {
        if (!this.context.stores.company?.subscription?.uuid) {
            return false
        }
        return this.getMemberCount() + Math.max(seatsBeingAdded, 0) < this.context.stores.company?.subscription?.users
    }

    public getMemberCount(): number {
        return Array.from(CompanyService.membersById.values()).filter((m) => !Utils.isVariableEmail(m.user.email))
            .length
    }

    public async getMembers(cacheOk: boolean = false): Promise<CompanyMember[]> {
        const cached = Array.from(CompanyService.membersById.values())
        if (cached?.length && cacheOk) {
            return Promise.resolve(cached)
        }
        return this.httpService.get<CompanyMember[]>(`${this.basePath}/member`).then((members) => {
            members.forEach((m) => {
                CompanyService.membersById.set(m.user.uuid, m)
                CompanyService.membersByEmail.set(m.user.email, m)
            })
            this.context.dispatch({ type: UIOptionActionType.SetMembersUpdated })
            return members
        })
    }

    public async removeMember(user: User): Promise<void> {
        return this.httpService.delete<void>(`${this.basePath}/member/${user.uuid}`).then(() => {
            CompanyService.membersById.delete(user.uuid)
            CompanyService.membersByEmail.delete(user.email)
            this.context.dispatch({ type: UIOptionActionType.SetMembersUpdated })
        })
    }

    public toggleCompanyMembership(company: Company): Promise<boolean> {
        const isMember = this.context.stores.user?.companies?.find((c) => c.uuid === company.uuid)
        if (isMember) {
            return this.leaveCompany(company)
        }
        return this.joinCompany(company)
    }

    public async joinCompany(company: Company): Promise<boolean> {
        return this.httpService.post<boolean>(`${this.basePath}/member`, {
            body: JSON.stringify({ joinCompany: company.uuid }),
        })
    }

    public async leaveCompany(company: Company): Promise<boolean> {
        return this.httpService.post<boolean>(`${this.basePath}/member`, {
            body: JSON.stringify({ leaveCompany: company.uuid }),
        })
    }

    public async getSuppliers(queryString?: string): Promise<ListResponse<Company>> {
        return this.httpService
            .get<ListResponse<Company>>(`${this.basePath}/supplier?${queryString || ''}`)
            .then((slr) => {
                this.setSupplierContext(slr.data)
                return slr
            })
    }

    public async getSupplier(supplierId: string): Promise<SupplierCustomerResponse> {
        return this.httpService.get<SupplierCustomerResponse>(`${this.basePath}/supplier/${supplierId}`).then((scr) => {
            this.setSupplierContext([scr.company])
            return scr
        })
    }

    public async getCustomers(queryString?: string): Promise<ListResponse<Company>> {
        return this.httpService
            .get<ListResponse<Company>>(`${this.basePath}/customer?${queryString || ''}`)
            .then((clr) => {
                this.setCustomerContext(clr.data)
                return clr
            })
    }

    public async getCustomer(customerId: string): Promise<SupplierCustomerResponse> {
        return this.httpService.get<SupplierCustomerResponse>(`${this.basePath}/customer/${customerId}`).then((scr) => {
            this.setCustomerContext([scr.company])
            return scr
        })
    }

    private updateContactsCache(companyId: string, contacts: Contact[]) {
        const supplier = CompanyService.suppliersById.get(companyId)
        if (supplier) {
            const updatedSupplier = { ...supplier, contacts }
            CompanyService.suppliersById.set(companyId, updatedSupplier)
            CompanyService.byId.set(companyId, updatedSupplier)
        }
        const customer = CompanyService.customersById.get(companyId)
        if (customer) {
            const updatedCustomer = { ...customer, contacts }
            CompanyService.customersById.set(companyId, updatedCustomer)
            CompanyService.byId.set(companyId, updatedCustomer)
        }
        this.context.dispatch({ type: UIOptionActionType.SetCompaniesUpdated })
    }

    public async getContacts(companyId: string, cacheOk: boolean = false): Promise<Contact[]> {
        if (cacheOk) {
            const cached = CompanyService.byId.get(companyId)?.contacts || []
            if (cached.length) return Promise.resolve(cached)
        }
        return this.httpService.get<Contact[]>(`${this.basePath}/${companyId}/contacts`).then((contacts) => {
            this.updateContactsCache(companyId, contacts)
            return contacts
        })
    }

    public async updateContacts(companyId: string, contacts: Contact[]): Promise<Contact[]> {
        return this.httpService
            .put<Contact[]>(`${this.basePath}/${companyId}/contacts`, { body: JSON.stringify({ contacts }) })
            .then((contacts) => {
                this.updateContactsCache(companyId, contacts)
                return contacts
            })
    }

    public async deleteContact(companyId: string, contact: Contact): Promise<Contact[]> {
        return this.httpService
            .delete<Contact[]>(`${this.basePath}/${companyId}/contact/${contact.uuid}`)
            .then((contacts) => {
                this.updateContactsCache(companyId, contacts)
                return contacts
            })
    }

    public getSupplierToken(supplier?: Company): Promise<SharingToken> {
        if (!supplier) return Promise.reject()
        if (supplier.token) return Promise.resolve(supplier.token)
        return this.httpService.put<SharingToken>(`${this.basePath}/supplier/${supplier.uuid}`, {
            body: JSON.stringify({ share: supplier.uuid }),
        })
    }

    public claimSupplierConnection(token: string): Promise<Company> {
        return this.httpService.put<Company>(`${this.basePath}/supplier`, {
            body: JSON.stringify({ claim: token }),
        })
    }

    public saveCompanySpend(
        perspective: CompanyPerspective,
        company: Company,
        spend: CompanySpend[],
    ): Promise<CompanySpend[]> {
        return this.httpService.put<CompanySpend[]>(`${this.basePath}/${perspective}/${company.uuid}`, {
            body: JSON.stringify({ spend: spend }),
        })
    }

    public disconnectSupplierConnection(token: string): Promise<Company> {
        return this.httpService.put<Company>(`${this.basePath}/supplier`, {
            body: JSON.stringify({ disconnect: token }),
        })
    }

    public async mergeSuppliers(supplier: Company, mergeIds: string[]): Promise<Company[]> {
        return this.httpService
            .post<Company[]>(`${this.basePath}/supplier/${supplier.uuid}`, {
                body: JSON.stringify({ mergeIds: mergeIds }),
            })
            .then((c) => {
                this.getSlimCompanies()
                return c
            })
    }

    public async getIndustries(): Promise<IndustrySector[]> {
        return this.httpService
            .get<IndustrySector[]>(`${this.basePath}/industry`, { ttlInSeconds: 60 * 60 * 24 })
            .then((industries) => {
                this.context.dispatch({ type: IndustryActionType.Set, payload: industries })
                return industries
            })
    }

    public async getRoles(): Promise<UserRole[]> {
        return this.httpService.get<UserRole[]>(`${this.basePath}/role`).then((roles) => {
            CompanyService.userRoles = roles
            this.context.dispatch({ type: UIOptionActionType.SetUserRolesUpdated })
            return roles
        })
    }

    public async importSuppliers(suppliers: Company[]): Promise<DataImportResponse> {
        return this.httpService.post<DataImportResponse>(this.basePath, {
            body: JSON.stringify({ importSuppliers: suppliers }),
        })
    }
}
