import { Info } from '@phosphor-icons/react'
import Utils from '../../services/utils'
import ActivityService from '../../services/activity'
import ProductService from '../../services/product'

export const CannotBeDeletedTooltip = (props: { className?: string }) => {
    return (
        <div className={props.className} style={{ minWidth: '200px' }}>
            <Info color={Utils.warningColor} className='nt--1 me-1' />
            {ProductService.webTitle(true)} that are used in other {ProductService.webTitle(true)} or{' '}
            {ActivityService.webTitle(true)} cannot be deleted.
        </div>
    )
}
