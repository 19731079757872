import { Selector, SelectorProps } from '../Input/Selector'
import LocationService, { Location } from '../../services/location'
import GeoLocationService, { GeoLocation } from '../../services/geoLocation'
import { useCallback, useContext, useMemo, useState } from 'react'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'
import Utils from '../../services/utils'

export const LocationSelector = (
    props: Omit<SelectorProps, 'onSelect' | 'option' | 'options'> & {
        location?: Location | null
        geoLocation?: GeoLocation | null
        onLocation?: (geoLocation: GeoLocation, location?: Location | null) => void
    },
) => {
    const context = useContext(ApplicationContext)
    const { locationService, geoLocationService } = useContext(VariableServicesContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [searchText, setSearchText] = useState<string>()
    const [searchResults, setSearchResults] = useState<Location[]>([])

    const search = useCallback((searchText: string) => {
        setLoading(true)
        geoLocationService
            .get(searchText)
            .then((sr) => setSearchResults(sr.map(LocationService.geoToLocation)))
            .finally(() => setLoading(false))
    }, [])

    const onSearchText = useCallback(
        (searchText?: string) => {
            setSearchText(searchText)
            if (!searchText) return
            search(searchText)
        },
        [search],
    )

    const onSelect = useCallback(
        (newValue?: Location) => {
            if (!newValue?.geoLocation) return
            const l = LocationService.locationById.get(newValue?.uuid || '')
            props.onLocation?.(newValue?.geoLocation, l || null)
            if (newValue?.geoLocation.uuid && !GeoLocationService.byId.has(newValue?.geoLocation.uuid)) {
                geoLocationService.updateContext([newValue?.geoLocation])
            }
        },
        [props.onLocation],
    )

    const allLocations = useMemo(
        () => locationService.getAllLocations(),
        [context.stores.ui?.locationsUpdated, context.stores.ui?.geoLocationsUpdated],
    )

    const locationById: Map<string, Location> = useMemo(
        () => new Map(allLocations.filter((l) => l.uuid).map((l) => [l.uuid!, l])),
        [allLocations],
    )

    const options = useMemo(() => {
        if (!searchText) return allLocations
        const filteredLocations = allLocations.filter((l) => LocationService.locationFilter(l, searchText))
        if (searchResults.length) {
            return [...filteredLocations, ...searchResults.filter((sr) => !locationById.get(sr.uuid || ''))]
        }
        return filteredLocations
    }, [
        allLocations,
        searchResults,
        searchText,
        context.stores.ui?.locationsUpdated,
        context.stores.ui?.geoLocationsUpdated,
    ])

    const label = useMemo(() => {
        if (props.location) {
            const l = LocationService.locationById.get(props.location.uuid || '') || props.location
            return (
                <>
                    {LocationService.getLocationTypeIcon(l)} {LocationService.getLocationName(l)}
                </>
            )
        }
        if (props.geoLocation) {
            return (
                <>
                    {LocationService.getLocationTypeIcon({ type: 'geo', geoLocation: props.geoLocation })}{' '}
                    {GeoLocationService.getName(props.geoLocation)}
                </>
            )
        }
        return props.placeholder
    }, [props.geoLocation, props.location, props.placeholder])

    const returnItemValue = useCallback(
        (o: Location) => {
            return (
                <span className='d-flex align-items-center justify-content-between gap-2'>
                    <span>{LocationService.getLocationName(o)}</span>
                    <span
                        hidden={!locationById.get(o.uuid || '')}
                        className='small bg-primary bg-opacity-10 rounded-1 px-1 font-monospace text-nowrap'
                    >
                        {LocationService.getLocationTypeIcon(o, { size: Utils.verySmallIconSize })}{' '}
                        {LocationService.getLocationType(o)}
                    </span>
                </span>
            )
        },
        [locationById],
    )

    return (
        <Selector
            ariaLabel='Location'
            label={label}
            options={options}
            loading={loading}
            option={props.location?.uuid || props.geoLocation?.uuid || props.location?.geoLocation?.uuid}
            onSelect={onSelect}
            onSearchText={onSearchText}
            renderItemValue={returnItemValue}
            {...props}
        />
    )
}
