import { CSSProperties, ReactNode } from 'react'

export const Form = (props: {
    className?: string
    style?: CSSProperties
    children: ReactNode
    onSubmit: () => void
}) => {
    return (
        <form
            className={props.className}
            style={props.style}
            onSubmit={(e) => {
                e.preventDefault()
                props.onSubmit()
            }}
        >
            {props.children}
        </form>
    )
}
