import Button from './Input/Button'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import AuthenticationService from '../services/authentication'
import { ApplicationContext } from '../context'
import InputField from './Input/InputField'
import Utils from '../services/utils'
import { VariableServicesContext } from '../services'
import { ChatDots, DiscordLogo } from '@phosphor-icons/react'

export const ChatDialog = () => {
    const context = useContext(ApplicationContext)
    const { supportService } = useContext(VariableServicesContext)
    const [view, setView] = useState<'choose' | 'message'>('choose')
    const [isError, setIsError] = useState<boolean>(false)
    const [showChat, setShowChat] = useState<boolean>(false)
    const [chatting, setChatting] = useState<boolean>(false)
    const [sending, setSending] = useState<boolean>(false)
    const [sent, setSent] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const chatRef = useRef<any>()

    useEffect(() => {
        if (
            AuthenticationService.isOnboarded() &&
            context.stores.user?.emailVerified === true &&
            context.stores.ui?.showChat !== false
        ) {
            setShowChat(true)
        } else {
            setShowChat(false)
        }
    }, [context.stores.user?.emailVerified, context.stores.company?.uuid, context.stores.ui?.showChat])

    useEffect(() => {
        setChatting(false)
    }, [context.stores.ui?.escapeKey])

    useEffect(() => {
        if (chatting && view === 'message' && chatRef.current) {
            setTimeout(() => chatRef.current.focus(), 100)
        }
    }, [chatting, view, chatRef])

    const closeChat = useCallback(async () => {
        setChatting(false)
        await Utils.sleep(300)
        setView('choose')
        setIsError(false)
        setSending(false)
        setSent(false)
        chatRef.current && (chatRef.current.value = '')
    }, [])

    const content = useMemo(() => {
        if (view === 'choose') {
            return (
                <div className='px-2 py-3 d-flex flex-column align-items-start gap-1'>
                    <Button className='btn btn-sm bg-light-hover' onClick={() => setView('message')}>
                        <ChatDots /> Send us a message
                    </Button>
                    <a
                        href='https://discord.gg/TrGYZW7zPG'
                        target='_blank'
                        rel='noreferrer'
                        className='btn btn-sm bg-light-hover'
                    >
                        <DiscordLogo /> Join us on Discord
                    </a>
                </div>
            )
        }

        return (
            <>
                {isError && (
                    <div className='bg-white fill-parent z-index-tooltip p-3'>
                        Sorry, there was an error sending your message. Please try again later, or email us at{' '}
                        <a href='mailto:hello@variable.co'>hello@variable.co</a>
                    </div>
                )}

                {sent && (
                    <div className='bg-white fill-parent z-index-tooltip d-flex align-items-center justify-content-center p-3'>
                        Thanks! We'll respond as soon as we can.
                    </div>
                )}

                <form
                    onSubmit={async (e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setSending(true)
                        try {
                            await supportService.sendMessage(message)
                            setSent(true)
                            setSending(false)
                            await Utils.sleep(2500)
                            await closeChat()
                        } catch (e) {
                            setIsError(true)
                            console.error(e)
                        }
                    }}
                >
                    <fieldset className='d-block p-3'>
                        <label htmlFor='contact-form-message' className='fw-bold small'>
                            Send us a message
                        </label>
                        <InputField
                            id='contact-form-message'
                            passedRef={chatRef}
                            disabled={sending}
                            multiLine={true}
                            rows={4}
                            className='variable-form-control bg-light w-100'
                            onChange={(newValue) => setMessage(newValue)}
                            onEscapeKey={() => !message && setView('choose')}
                        />

                        <Button
                            type='submit'
                            saving={sending}
                            disabled={!message}
                            className='d-block btn btn-sm btn-secondary w-100 mt-2'
                        >
                            Send
                        </Button>
                    </fieldset>
                </form>
            </>
        )
    }, [sent, sending, message, chatRef, view, isError])

    if (!showChat) return null

    return (
        <div className='position-relative m-4 rounded-1 z-index-sticky'>
            <div
                id='plain-chat-container'
                role='button'
                tabIndex={0}
                className={[
                    'position-absolute start-0 bg-white shadow transition-all-300ms rounded-1',
                    chatting ? 'opacity-100' : 'visibility-hidden not-clickable opacity-0',
                ].join(' ')}
                style={{ width: '300px', bottom: chatting ? '4rem' : '1rem' }}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={() => {}}
            >
                {content}
            </div>
            <Button
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (chatting) {
                        closeChat().then()
                    } else {
                        setChatting(true)
                    }
                }}
                className='btn btn-sm btn-secondary shadow position-relative rounded-circle'
                style={{ width: '3rem', height: '3rem' }}
            >
                <svg xmlns='http://www.w3.org/2000/svg' height='100%' width='80%' viewBox='0 0 48 48'>
                    {!chatting && (
                        <path
                            className='position-relative'
                            style={{ top: '1px' }}
                            d='M4 44V7q0-1.15.9-2.075Q5.8 4 7 4h34q1.15 0 2.075.925Q44 5.85 44 7v26q0 1.15-.925 2.075Q42.15 36 41 36H12Zm3-7.25L10.75 33H41V7H7ZM7 7v29.75Z'
                        />
                    )}
                    {chatting && (
                        <path
                            className='nt--1'
                            d='m12.45 37.65-2.1-2.1L21.9 24 10.35 12.45l2.1-2.1L24 21.9l11.55-11.55 2.1 2.1L26.1 24l11.55 11.55-2.1 2.1L24 26.1Z'
                        />
                    )}
                </svg>
            </Button>
        </div>
    )
}
