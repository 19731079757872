import { useContext, useEffect, useState } from 'react'
import { VariableServicesContext } from '../../services'
import NotFound from '../NotFound'
import { FileData } from '../../services/file'

export const FileViewer = (props: {
    url?: string
    documentPath?: string
    onSuccess?: (fileData: FileData, url: string) => void
}) => {
    const { fileService } = useContext(VariableServicesContext)
    const [notFound, setNotFound] = useState<boolean>(false)
    const [iframeSrc, setIframeSrc] = useState<string>()

    useEffect(() => {
        if (props.url?.startsWith('http')) {
            setIframeSrc(props.url)
            props.onSuccess?.({} as FileData, props.url)
        } else if (props.documentPath) {
            fileService
                .getUrl(props.documentPath)
                .then((r) => {
                    setIframeSrc(r.url)
                    props.onSuccess?.(r.fileData, r.url)
                })
                .catch(() => setNotFound(true))
        }
        return () => {
            setIframeSrc(undefined)
            setNotFound(false)
        }
    }, [props.url, props.documentPath])

    if (!props.documentPath) return null

    if (notFound) return <NotFound />

    if (!iframeSrc) {
        return (
            <>
                Loading {fileService.friendlyPath(props.documentPath)}{' '}
                <span className='spinner-border spinner-border-sm' />
            </>
        )
    }

    return (
        <iframe
            title={props.documentPath}
            src={iframeSrc}
            className='fill-parent z-index-off-canvas'
            width='100%'
            height='100%'
        />
    )
}
