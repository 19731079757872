import { createContext, ReactNode, useContext } from 'react'
import { ApplicationContext } from './context'
import ProductService from './services/product'
import PartService from './services/part'
import { AnalyticsService } from './services/analytics'
import ActivityService from './services/activity'
import InputService from './services/input'
import AiService from './services/ai'
import AuthenticationService from './services/authentication'
import BatchService from './services/batch'
import CalculatorService from './services/calculator'
import CompanyService from './services/company'
import DataImportService from './services/dataImport'
import DataRequestService from './services/dataRequest'
import DataSourceService from './services/dataSource'
import FileService from './services/file'
import { FlowService } from './services/flow'
import GeoLocationService from './services/geoLocation'
import OrgService from './services/org'
import PlanService from './services/plan'
import ScheduleService from './services/schedule'
import GHGService from './services/ghg'
import { SubscriptionService } from './services/subscription'
import SyncService from './services/sync'
import TagService from './services/tag'
import TaxonomyService from './services/taxonomy'
import TokenService from './services/token'
import UnitService from './services/unit'
import UserService from './services/user'
import CategoryModelService from './services/category-model'
import FlagService from './services/flag'
import HttpService from './services/http'
import StatusService from './services/status'
import UiService from './services/ui'
import { InventoryService } from './services/inventory'
import FilterService from './services/filter'
import PactService from './services/pact'
import SupportService from './services/support'
import CpcService from './services/cpc'
import ProcessingService from './services/processing'
import TransportService from './services/transport'
import UseStageService from './services/useStage'
import LocationService from './services/location'
import ElectricityService from './services/electricity'
import ExchangeService from './services/exchange'

export interface VariableServices {
    ready?: boolean
    httpService: HttpService
    authenticationService: AuthenticationService
    analyticsService: AnalyticsService
    batchService: BatchService
    calculatorService: CalculatorService
    categoryModelService: CategoryModelService
    companyService: CompanyService
    dataImportService: DataImportService
    dataRequestService: DataRequestService
    dataSourceService: DataSourceService
    electricityService: ElectricityService
    flagService: FlagService
    fileService: FileService
    filterService: FilterService
    flowService: FlowService
    geoLocationService: GeoLocationService
    exchangeService: ExchangeService
    orgService: OrgService
    planService: PlanService
    statusService: StatusService
    scheduleService: ScheduleService
    ghgService: GHGService
    cpcService: CpcService
    subscriptionService: SubscriptionService
    syncService: SyncService
    tagService: TagService
    taxonomyService: TaxonomyService
    tokenService: TokenService
    unitService: UnitService
    userService: UserService
    productService: ProductService
    inputService: InputService
    processingService: ProcessingService
    transportService: TransportService
    useStageService: UseStageService
    partService: PartService
    inventoryService: InventoryService
    activityService: ActivityService
    aiService: AiService
    uiService: UiService
    pactService: PactService
    supportService: SupportService
    locationService: LocationService
}

export const VariableServicesContext = createContext<VariableServices>({} as VariableServices)

let httpService: HttpService
let authenticationService: AuthenticationService
let analyticsService: AnalyticsService
let batchService: BatchService
let calculatorService: CalculatorService
let categoryModelService: CategoryModelService
let companyService: CompanyService
let dataImportService: DataImportService
let dataRequestService: DataRequestService
let dataSourceService: DataSourceService
let electricityService: ElectricityService
let flagService: FlagService
let fileService: FileService
let filterService: FilterService
let flowService: FlowService
let geoLocationService: GeoLocationService
let orgService: OrgService
let planService: PlanService
let statusService: StatusService
let scheduleService: ScheduleService
let ghgService: GHGService
let cpcService: CpcService
let subscriptionService: SubscriptionService
let syncService: SyncService
let tagService: TagService
let taxonomyService: TaxonomyService
let tokenService: TokenService
let unitService: UnitService
let userService: UserService
let productService: ProductService
let inputService: InputService
let processingService: ProcessingService
let transportService: TransportService
let useStageService: UseStageService
let partService: PartService
let inventoryService: InventoryService
let activityService: ActivityService
let aiService: AiService
let uiService: UiService
let pactService: PactService
let supportService: SupportService
let locationService: LocationService
let exchangeService: ExchangeService

export const ServicesProvider = ({ children }: { children: ReactNode }) => {
    const context = useContext(ApplicationContext)

    httpService = httpService || new HttpService(context)
    httpService.setContext(context)
    authenticationService = authenticationService || new AuthenticationService(context)
    authenticationService.setContext(context)
    analyticsService = analyticsService || new AnalyticsService(context)
    analyticsService.setContext(context)
    batchService = batchService || new BatchService(context)
    batchService.setContext(context)
    calculatorService = calculatorService || new CalculatorService(context)
    calculatorService.setContext(context)
    categoryModelService = categoryModelService || new CategoryModelService(context)
    categoryModelService.setContext(context)
    companyService = companyService || new CompanyService(context)
    companyService.setContext(context)
    dataImportService = dataImportService || new DataImportService(context)
    dataImportService.setContext(context)
    dataRequestService = dataRequestService || new DataRequestService(context)
    dataRequestService.setContext(context)
    dataSourceService = dataSourceService || new DataSourceService(context)
    dataSourceService.setContext(context)
    electricityService = electricityService || new ElectricityService(context)
    electricityService.setContext(context)
    flagService = flagService || new FlagService(context)
    flagService.setContext(context)
    fileService = fileService || new FileService(context)
    fileService.setContext(context)
    filterService = filterService || new FilterService(context)
    filterService.setContext(context)
    flowService = flowService || new FlowService(context)
    flowService.setContext(context)
    geoLocationService = geoLocationService || new GeoLocationService(context)
    geoLocationService.setContext(context)
    exchangeService = exchangeService || new ExchangeService(context)
    exchangeService.setContext(context)
    orgService = orgService || new OrgService(context)
    orgService.setContext(context)
    planService = planService || new PlanService(context)
    planService.setContext(context)
    statusService = statusService || new StatusService(context)
    statusService.setContext(context)
    scheduleService = scheduleService || new ScheduleService(context)
    scheduleService.setContext(context)
    ghgService = ghgService || new GHGService(context)
    ghgService.setContext(context)
    cpcService = cpcService || new CpcService(context)
    cpcService.setContext(context)
    subscriptionService = subscriptionService || new SubscriptionService(context)
    subscriptionService.setContext(context)
    syncService = syncService || new SyncService(context)
    syncService.setContext(context)
    tagService = tagService || new TagService(context)
    tagService.setContext(context)
    taxonomyService = taxonomyService || new TaxonomyService(context)
    taxonomyService.setContext(context)
    tokenService = tokenService || new TokenService(context)
    tokenService.setContext(context)
    unitService = unitService || new UnitService(context)
    unitService.setContext(context)
    userService = userService || new UserService(context)
    userService.setContext(context)
    productService = productService || new ProductService(context)
    productService.setContext(context)
    inputService = inputService || new InputService(context)
    inputService.setContext(context)
    processingService = processingService || new ProcessingService(context)
    processingService.setContext(context)
    transportService = transportService || new TransportService(context)
    transportService.setContext(context)
    useStageService = useStageService || new UseStageService(context)
    useStageService.setContext(context)
    partService = partService || new PartService(context)
    partService.setContext(context)
    inventoryService = inventoryService || new InventoryService(context)
    inventoryService.setContext(context)
    activityService = activityService || new ActivityService(context)
    activityService.setContext(context)
    aiService = aiService || new AiService(context)
    aiService.setContext(context)
    uiService = uiService || new UiService(context)
    uiService.setContext(context)
    pactService = pactService || new PactService(context)
    pactService.setContext(context)
    supportService = supportService || new SupportService(context)
    supportService.setContext(context)
    locationService = locationService || new LocationService(context)
    locationService.setContext(context)

    const services: VariableServices = {
        httpService: httpService,
        authenticationService: authenticationService,
        analyticsService: analyticsService,
        batchService: batchService,
        calculatorService: calculatorService,
        categoryModelService: categoryModelService,
        companyService: companyService,
        dataImportService: dataImportService,
        dataRequestService: dataRequestService,
        dataSourceService: dataSourceService,
        electricityService: electricityService,
        flagService: flagService,
        fileService: fileService,
        filterService: filterService,
        flowService: flowService,
        geoLocationService: geoLocationService,
        exchangeService: exchangeService,
        orgService: orgService,
        planService: planService,
        statusService: statusService,
        scheduleService: scheduleService,
        ghgService: ghgService,
        cpcService: cpcService,
        subscriptionService: subscriptionService,
        syncService: syncService,
        tagService: tagService,
        taxonomyService: taxonomyService,
        tokenService: tokenService,
        unitService: unitService,
        userService: userService,
        productService: productService,
        inputService: inputService,
        processingService: processingService,
        transportService: transportService,
        useStageService: useStageService,
        partService: partService,
        inventoryService: inventoryService,
        activityService: activityService,
        aiService: aiService,
        uiService: uiService,
        pactService: pactService,
        supportService: supportService,
        locationService: locationService,
    }
    return <VariableServicesContext.Provider value={{ ...services }}>{children}</VariableServicesContext.Provider>
}
