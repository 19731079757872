import Tooltip from './Tooltip'
import { useCallback, useContext, useEffect, useState } from 'react'
import { VariableServicesContext } from '../services'
import { VariableNodeState, VariableNodeWithState } from '../types'
import { ActivityStateUpdate } from '../services/activity'
import { ActivityContext } from '../services/activityContext'
import { ClockCounterClockwise } from '@phosphor-icons/react'
import { StateItem } from './StateItem'
import { StateHelp } from './StateHelp'

export const StateLabel = (props: { node?: VariableNodeWithState; showIcon?: boolean }) => {
    const activityContext = useContext(ActivityContext)
    const { activityService } = useContext(VariableServicesContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [stateHistory, setStateHistory] = useState<ActivityStateUpdate[]>([])

    const getBadge = useCallback((state: VariableNodeState) => {
        switch (state) {
            case 'pending':
                return <span className='badge rounded-1 bg-secondary'>Pending</span>
            case 'approved':
                return <span className='badge rounded-1 bg-success'>Approved</span>
            case 'rejected':
                return <span className='badge rounded-1 bg-danger'>Rejected</span>
            default:
                return <span className='badge rounded-1 bg-dark bg-opacity-10 text-slightly-muted'>Draft</span>
        }
    }, [])

    useEffect(() => setStateHistory([]), [activityContext.activity?.state])

    if (!props.node?.state) return null

    return (
        <Tooltip
            placement='bottom'
            interactive={true}
            disabled={!props.node?.uuid}
            tooltipStyle={{ maxWidth: '400px', maxHeight: '40vh' }}
            tooltipClassName='overflow-auto p-3'
            tooltipContent={
                <>
                    {loading && <span className='spinner-border spinner-border-sm' />}
                    <div className='small d-flex flex-column gap-3 flex-border-between'>
                        {stateHistory.map((sh) => (
                            <StateItem stateUpdate={sh} getBadge={getBadge} key={`sh-${sh.uuid}`} />
                        ))}
                    </div>
                    <div className='border-top mt-3 pt-2'>
                        <StateHelp extraClassName='text-muted'>Read about activity approval</StateHelp>
                    </div>
                </>
            }
            onClick={() => {
                setLoading(true)
                activityService
                    .getStateHistory(props.node!)
                    .then(setStateHistory)
                    .finally(() => setLoading(false))
            }}
        >
            {getBadge(props.node.state)}
            {props.showIcon && <ClockCounterClockwise className='ms-1' />}
        </Tooltip>
    )
}
