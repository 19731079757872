import * as d3 from 'd3'
import { MutableRefObject, useEffect, useMemo, useState } from 'react'
import { BarChartProps } from '../components/Charts/BarChart'

interface DimensionProps extends BarChartProps {
    cxRef: MutableRefObject<any>
}

export const useDimensions = (props: DimensionProps) => {
    const [shouldUpdate, setShouldUpdate] = useState<boolean>(true)

    useEffect(() => {
        d3.select(window).on('resize', () => setShouldUpdate(true))
        setShouldUpdate(true)
        return () => {
            d3.select(window).on('resize', null)
        }
    }, [])

    const margin = useMemo(() => ({ top: 10, right: 10, bottom: 10, left: 10, ...props.margin }), [props.margin])

    const [width, height] = useMemo(() => {
        if (props.debug) console.log('useDimensions', props.cxRef.current)
        const gcr = props.cxRef.current?.getBoundingClientRect()
        if (shouldUpdate) setShouldUpdate(false)
        return [gcr?.width, gcr?.height]
    }, [props.cxRef.current, shouldUpdate])

    return { margin, width, height }
}
