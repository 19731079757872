import { ActionMap } from '../context'
import { VariableBaseNode } from '../types'

export interface IndustrySector extends VariableBaseNode {
    name: string
    otherName?: string
}

export enum IndustryActionType {
    Set = 'SetIndustries',
}

type IndustryActionPayload = {
    [IndustryActionType.Set]: IndustrySector[]
}

export type IndustryActions = ActionMap<IndustryActionPayload>[keyof ActionMap<IndustryActionPayload>]

export const IndustryReducer = (state: IndustrySector[], action: IndustryActions): IndustrySector[] => {
    switch (action.type) {
        case IndustryActionType.Set:
            return action.payload
        default:
            return state
    }
}
