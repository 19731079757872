import TaxonomyService, { Taxonomy } from '../services/taxonomy'
import { Link } from 'react-router-dom'
import ProductService from '../services/product'
import { useCallback, useContext, useMemo } from 'react'
import { VariableServicesContext } from '../services'
import { ApplicationContext } from '../context'
import { StandardAttributes } from '../types'

export const TaxonomyLabel = (
    props: StandardAttributes & {
        taxonomy?: Taxonomy | null
        followLink?: boolean
        onClick?: () => void
    },
) => {
    const context = useContext(ApplicationContext)
    const { taxonomyService } = useContext(VariableServicesContext)
    const tx = useMemo(
        () => TaxonomyService.byId.get(props.taxonomy?.uuid || ''),
        [props.taxonomy, context.stores.ui?.taxonomyReady],
    )
    const onClick = useCallback(() => {
        if (props.disabled) return
        props.onClick?.()
    }, [props.disabled, props.onClick])
    return (
        <Link
            to={`${ProductService.webRootDatabase}${tx?.path ? `/${tx.path}` : ''}`}
            onClick={(e) => {
                if (!props.followLink || props.onClick) {
                    e.preventDefault()
                }
                onClick()
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter' && props.onClick) {
                    e.preventDefault()
                    onClick()
                }
            }}
            className={[
                tx?.uuid ? 'd-inline-block' : 'd-none',
                props.className || 'text-muted small bg-primary bg-opacity-5 px-1 rounded-1',
                props.extraClassName || '',
                props.disabled ? 'not-clickable' : '',
            ].join(' ')}
        >
            {taxonomyService.getTaxonomyString(tx, ' / ')}
        </Link>
    )
}
