import { useCallback, useEffect, useMemo, useRef } from 'react'
import * as d3 from 'd3'
import { useDimensions } from '../../hooks/useDimensions'
import Utils from '../../services/utils'
import { BarChartProps } from './BarChart'
import { D3Data } from '../../types'

export const BarChartHorizontal = (props: BarChartProps) => {
    const cxRef = useRef<any>()
    const svgRef = useRef<any>()
    const { margin, width, height } = useDimensions({
        cxRef,
        margin: { left: 90, right: 25 },
        ...props,
    })

    const barSize = useMemo(() => props.barSize || 5, [props.barSize])
    const minBarWidth = useMemo(() => props.minBarSize || barSize, [props.minBarSize, barSize])

    const data = useMemo(() => props.data || [], [props.data])

    const svg = useMemo(
        () => d3.select(svgRef.current).attr('width', width).attr('height', height),
        [svgRef.current, width, height],
    )

    const maxValue = useMemo(() => d3.max(data, (d) => d.amount) || 0, [data])
    const minValue = useMemo(() => d3.min(data, (d) => d.amount) || 0, [data])

    const xScale = useMemo(() => {
        return d3
            .scaleLinear()
            .domain([Math.min(minValue, 0), maxValue])
            .range([margin.left, width - margin.left - margin.right])
            .nice()
    }, [width, height, maxValue, minValue, margin])

    const yScale = useMemo(
        () =>
            d3
                .scaleBand()
                .domain(data.map((d) => d.key))
                .range([0, height]),
        [data, height],
    )

    const getBarZero = useCallback(
        (d: any) => {
            if (d.amount < 0) return xScale(d.amount)
            return xScale(0)
        },
        [xScale],
    )

    const getBarWidth = useCallback(
        (d: D3Data) => {
            if (!d.amount) return minBarWidth
            if (d.amount < 0) {
                return xScale(0) - xScale(d.amount)
            }
            return Math.max(xScale(d.amount) - xScale(0), minBarWidth)
        },
        [minBarWidth, xScale],
    )

    const getYPosition = useCallback(
        (d: D3Data) => {
            return (yScale(d.key) || 0) + yScale.bandwidth() / 2
        },
        [yScale],
    )

    useEffect(() => {
        if (!svg?.size()) return

        const bars = svg.selectAll('rect.bar').data(data)
        bars.join('rect')
            .classed('bar', true)
            .attr('x', (d) => getBarZero(d))
            .attr('y', (d) => getYPosition(d))
            .attr('rx', (d) => {
                if (props.barRadius) return props.barRadius
                const _bw = getBarWidth(d)
                if (_bw <= minBarWidth) return `${_bw / 2}px`
                return `${barSize / 2}px`
            })
            .attr('width', (d) => getBarWidth(d))
            .attr('height', `${barSize}px`)
            .attr('style', `transform: translateY(${Math.round(barSize / 2) + 1}px)`)
            .attr('fill', (d) => (d.amount !== undefined ? d.color || Utils.gray2 : Utils.gray2))
            .transition()

        const labels = svg.selectAll('foreignObject.co2e-label').data(data)
        labels.selectAll('*').remove()
        labels
            .join('foreignObject')
            .classed('co2e-label', true)
            .classed('position-relative', true)
            .classed('overflow-visible', true)
            .classed('font-monospace', true)
            .attr('x', 0)
            .attr('y', (d) => getYPosition(d))
            .attr('width', margin.left)
            .attr('height', '1rem')
            .append('xhtml:div')
            .classed('text-height-1', true)
            .classed('small', true)
            .classed('text-start', true)
            .classed('text-primary', true)
            .classed('text-overflow-ellipsis', true)
            .style('max-width', `${margin.left}px`)
            .attr('title', (d) => d.name)
            .text((d) => d.name)
            .transition()

        const valueLabels = svg.selectAll('foreignObject.co2e-value-label').data(data)
        valueLabels.selectAll('*').remove()
        valueLabels
            .join('foreignObject')
            .classed('co2e-value-label', true)
            .classed('position-relative', true)
            .classed('overflow-visible', true)
            .attr('y', (d) => getYPosition(d))
            .attr('x', width - 200)
            .attr('width', 200)
            .attr('height', '1rem')
            .append('xhtml:div')
            .classed('text-height-1', true)
            .classed('small', true)
            .classed('text-nowrap', true)
            .classed('text-end', true)
            .classed('text-primary', true)
            .classed('font-monospace', true)
            .text((d) => (d.valueLabel !== undefined ? d.valueLabel : '—'))
            .transition()
        if (props.debug) console.log(props.id, svg, data)
    }, [svg, data])

    return (
        <div
            ref={cxRef}
            className={`fs-base ${props.className || ''}`}
            style={{ minHeight: '180px', maxWidth: '100%', ...props.style }}
        >
            <svg id={props.id} ref={svgRef} />
        </div>
    )
}
