import { Database, DiamondsFour, IconProps } from '@phosphor-icons/react'
import { Inventory, InventoryType } from '../../services/inventory'
import ProductService from '../../services/product'
import { PartIcon } from './PartIcon'
import { ProductIcon } from './ProductIcon'
import { TransportIcons } from './TransportIcons'
import { useMemo } from 'react'
import Utils from '../../services/utils'
import { EnergyIcon } from './EnergyIcon'
import { Input } from '../../services/input'

export const InventoryIcon = (
    props: IconProps & {
        allInventory?: boolean
        inventoryType?: InventoryType
        inv?: Inventory
        input?: Input
    },
) => {
    const iconProps = useMemo(() => {
        const { allInventory, inv, inventoryType, ...rest } = props
        return rest
    }, [props])

    const type = useMemo(() => {
        if (props.inv) return props.inv?.type.toLowerCase()
        return props.inventoryType?.toLowerCase()
    }, [props.inv?.type, props.inv?.taxonomy?.path, props.inventoryType])

    if (type === 'transport') {
        return (
            <TransportIcons
                limit={1}
                transportType={props.inv?.originalTransportType}
                iconProps={{ size: Utils.largeIconSize, ...iconProps }}
            />
        )
    }

    if (type === 'energy' || props.inv?.taxonomy?.path?.startsWith('energy')) {
        return <EnergyIcon inv={props.inv} {...iconProps} />
    }

    if (ProductService.isEmissionFactor(props.inv?.originalProduct)) {
        return <Database {...iconProps} />
    }

    if (props.inv?.originalPart) {
        return <PartIcon part={props.inv.originalPart} {...iconProps} />
    }

    if (props.allInventory) {
        return <DiamondsFour {...iconProps} />
    }

    return <ProductIcon product={props.inv?.originalProduct} input={props.input} {...iconProps} />
}
