import { D3Data, D3Margin, VisualDirection } from '../../types'
import { CSSProperties } from 'react'
import { BarChartHorizontal } from './BarChartHorizontal'
import { BarChartVertical } from './BarChartVertical'

export interface BarChartProps {
    id?: string
    data?: D3Data[]
    direction?: VisualDirection
    bandPadding?: number
    barSize?: number | null
    minBarSize?: number | null
    barRadius?: number
    nodeWidth?: number
    nodePadding?: number
    margin?: Partial<D3Margin>
    className?: string
    style?: CSSProperties
    debug?: boolean
}

export const BarChart = (props: BarChartProps) => {
    if (props.direction === 'horizontal') {
        return <BarChartHorizontal {...props} />
    }
    return <BarChartVertical {...props} />
}
