import { AmountOfEmissionFactor } from './AmountOfEmissionFactor'
import UnitService from '../../services/unit'
import { Product } from '../../services/product'
import { ND } from '../../types'

export const ProductTypeFactor = (props: {
    product: Product
    disabled?: boolean
    onChange: (properties: Partial<Product>) => void
}) => {
    return (
        <div className='d-block overflow-auto'>
            <AmountOfEmissionFactor
                targetNode={props.product}
                targetType={ND.Product}
                disabled={props.disabled}
                factor={props.product.factor}
                onChange={(factor, quantity, co2e) => {
                    props.onChange({
                        factor: factor,
                        co2e: co2e,
                        unit: quantity === 1 ? factor.unit : UnitService.getDefaultUnit(),
                    })
                }}
            />
            <div className='alert bg-light mt-2' style={{ maxWidth: '650px' }}>
                You can search for a materials or use the taxonomy and look through our database of emission factors.
                <br />
                <br />
                <strong>Example 1: This is a "part"</strong>
                <br />
                If you're entering materials for a guitar, the "body" part might be made up of 2 kg of wood, so you can
                search for "wood" and enter 2 kg for the amount.
                <br />
                <br />
                <strong>Example 2: This is a "material"</strong>
                <br />
                Maybe you just want to have a reference to the wood that goes into a guitar. In that case, search for
                "wood" and enter an amount of 1.
            </div>
        </div>
    )
}
