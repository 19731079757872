import { useCallback, useContext, useMemo, useState } from 'react'
import { GeoLocation } from '../../services/geoLocation'
import ProductService, { Product, ProductFootprintType } from '../../services/product'
import { Selector, SelectorProps } from './Selector'
import { VariableServicesContext } from '../../services'
import { KeyValuePair, StandardAttributes } from '../../types'
import Utils from '../../services/utils'
import { InventoryService } from '../../services/inventory'
import { GridFour } from '@phosphor-icons/react'
import { InventoryIcon } from '../Icons/InventoryIcon'
import ElectricityService from '../../services/electricity'

export const ElectricityGridSelector = (
    props: StandardAttributes & {
        geoLocation?: GeoLocation | null
        electricity?: Product | null
        selectorProps?: Partial<SelectorProps>
        onChange: (footprints?: Product[]) => void
    },
) => {
    const { geoLocationService } = useContext(VariableServicesContext)
    const [footprints, setFootprints] = useState<Product[]>()

    const fetchElectricity = useCallback((gl?: GeoLocation | null) => {
        if (!gl) return
        geoLocationService.getElectricityGeoLocation(gl).then((fps) => setFootprints(fps.footprints))
    }, [])

    const gridOptions = useMemo(() => {
        const opts: KeyValuePair<string>[] = []
        const ids: Set<string> = new Set()

        const addToOpts = (f: Product, placement: 'before' | 'after') => {
            const p = InventoryService.byId.get(f.uuid || '')
            if (ids.has(p?.uuid || f.uuid || '')) return
            ids.add(p?.uuid || f.uuid || '')
            const icon = p ? <InventoryIcon inv={p} /> : <GridFour />
            let description = p?.productOf?.name || p?.description || f.description
            if (p?.originalProduct?.type === ProductFootprintType.ELECTRICITY) {
                description = `${ElectricityService.webTitle()} ${ProductService.elementTitle().toLowerCase()}`
            }
            const node = p?.originalProduct || f
            opts[placement === 'before' ? 'unshift' : 'push'](
                Utils.nodeToKeyValuePair(p || f, { icon, description, node }),
            )
        }

        if (footprints?.length) footprints?.forEach((f) => addToOpts(f, 'after'))
        if (props.electricity) addToOpts(props.electricity, 'before')

        return opts
    }, [footprints, props.electricity])

    return (
        <span hidden={props.hidden} className={[props.className, props.extraClassName].join(' ')} style={props.style}>
            <Selector
                ariaLabel='Electricity Grid'
                placeholder='Select'
                className='variable-form-select'
                placement='bottom-start'
                options={gridOptions}
                option={props.electricity?.uuid}
                disabled={props.disabled}
                onClick={() => !footprints && fetchElectricity(props.geoLocation)}
                onSelect={(newValue) => props.onChange([newValue.node])}
                {...props.selectorProps}
            />
        </span>
    )
}
