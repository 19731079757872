import { ReactNode, useMemo } from 'react'
import { Airplane, Boat, IconProps, TrainSimple, Truck } from '@phosphor-icons/react'
import { StandardAttributes, VariableNode } from '../../types'
import TransportService, { TransportationMode, TransportType } from '../../services/transport'

export const TransportIcons = (
    props: StandardAttributes & {
        node?: VariableNode
        transportType?: TransportType
        transportMode?: TransportationMode
        showUniqueModes?: boolean
        limit?: number
        iconProps?: IconProps
    },
) => {
    const modeList: TransportationMode[] = useMemo(() => {
        let _modeList: TransportationMode[]
        if (props.showUniqueModes) {
            const _modes = new Set<TransportationMode>()
            props.transportType?.segments?.forEach((s) =>
                _modes.add(TransportService.getMode(s.footprint?.taxonomy, s.mode)),
            )
            _modeList = Array.from(_modes)
        } else {
            _modeList =
                props.transportType?.segments?.map((s) => TransportService.getMode(s.footprint?.taxonomy, s.mode)) || []
        }
        if (props.limit) {
            _modeList = _modeList.slice(0, props.limit)
        }
        if (!_modeList.length) _modeList.push(props.transportMode || 'truck')
        return _modeList
    }, [props.transportMode, props.transportType, props.showUniqueModes, props.limit])

    const icons = useMemo(() => {
        let _icons: ReactNode[] = []
        modeList?.forEach((mode, idx) => {
            switch (mode) {
                case 'truck':
                    _icons.push(<Truck key={`truck-${props.transportType?.uuid}-${idx}`} {...props.iconProps} />)
                    break
                case 'train':
                    _icons.push(<TrainSimple key={`train-${props.transportType?.uuid}-${idx}`} {...props.iconProps} />)
                    break
                case 'tanker':
                case 'inland-waterways-barge':
                case 'inland-waterways-tanker':
                case 'ferry':
                case 'container-ship':
                    _icons.push(<Boat key={`boat-${props.transportType?.uuid}-${idx}`} {...props.iconProps} />)
                    break
                case 'air':
                    _icons.push(<Airplane key={`air-${props.transportType?.uuid}-${idx}`} {...props.iconProps} />)
                    break
            }
        })
        return _icons
    }, [modeList, props.transportType?.uuid, props.iconProps])

    return <span className={props.className || `d-flex align-items-center ${props.extraClassName}`}>{icons}</span>
}
