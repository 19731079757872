import DromoReactUploader from 'dromo-uploader-react'
import { MutableRefObject, ReactNode, useMemo } from 'react'
import { ActivityDirection } from '../../services/activity'
import { useNavigate } from 'react-router-dom'
import { DataRequest } from '../../services/dataRequest'
import DataImportService, { DataImportResponse } from '../../services/dataImport'
import { useDataImport } from '../../hooks/useDataImport'
import TaxonomyService from '../../services/taxonomy'

export const AirTravelImport = (props: {
    children?: ReactNode
    showButton?: boolean
    className?: string
    dataRequest?: DataRequest
    defaultDirection?: ActivityDirection
    dromoRef: MutableRefObject<any>
    onDone?: (result: DataImportResponse) => void
    onCancel?: () => void
    onError?: (e: any) => void
}) => {
    const navigate = useNavigate()
    const dataImportService = useDataImport(props.defaultDirection)
    const IMPORT_TYPE = 'Activity: Air Travel'

    const flightTaxonomy = useMemo(() => {
        return TaxonomyService.byPath?.get('transport/business-travel/flight')
    }, [])

    const button = useMemo(() => {
        if (props.children) return props.children
        if (props.showButton === false) return null
        return 'Import data'
    }, [props.children, props.showButton])

    if (!flightTaxonomy) return null

    return (
        <DromoReactUploader
            schemaName='Air Travel Import'
            schemaId='air-travel-import'
            ref={props.dromoRef}
            className={props.className || 'btn btn-primary'}
            licenseKey={DataImportService.dromoLicenseKey}
            fields={[
                dataImportService.purchaseSaleField,
                dataImportService.descriptionField('Activity Description'),
                dataImportService.dateField('Departure Date', 'startDate', true),
                dataImportService.itemNameField('Emission Factor'),
                dataImportService.companyNameField('Booking Agency'),
                dataImportService.bookingReferenceField,
                dataImportService.flightCodeField,
                dataImportService.flightAirlineField,
                dataImportService.flightRouteField,
                dataImportService.flightDomesticInternational,
                dataImportService.flightCabinClassField,
                dataImportService.flightDistanceField,
                dataImportService.totalCo2eField(),
            ]}
            stepHooks={[
                dataImportService.uploadStepHook(IMPORT_TYPE),
                dataImportService.activityReviewStepHook(IMPORT_TYPE, props.dataRequest),
                dataImportService.addTaxonomyIdHook(),
            ]}
            columnHooks={[
                dataImportService.numberColumnHook(dataImportService.totalCo2eField().key),
                dataImportService.numberColumnHook(dataImportService.flightDistanceField.key),
                dataImportService.directionColumnHook(props.dataRequest),
                dataImportService.dateColumnHook('startDate'),
                dataImportService.companyNameColumnHook(props.dataRequest),
                dataImportService.companyIdColumnHook(props.dataRequest),
                dataImportService.taxonomyColumnHook(flightTaxonomy),
            ]}
            settings={dataImportService.dromoSettings({
                importIdentifier: IMPORT_TYPE,
                templateDownloadFilename: 'Air_Travel_Import.csv',
            })}
            user={dataImportService.dromoUser()}
            onResults={dataImportService.standardResult({
                importType: IMPORT_TYPE,
                dataRequest: props.dataRequest,
                navigate: navigate,
                onDone: props.onDone,
                onError: props.onError,
            })}
            onCancel={props.onCancel}
        >
            {button}
        </DromoReactUploader>
    )
}
