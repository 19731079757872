import { ND, StandardAttributes } from '../../types'
import PartService, { Part, PartType } from '../../services/part'
import { Input } from '../../services/input'
import ProductService, { Product } from '../../services/product'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { VariableServicesContext } from '../../services'
import CO2e from '../CO2e'
import Tooltip from '../Tooltip'
import { Link } from 'react-router-dom'
import Utils from '../../services/utils'
import { ConnectionStatus } from '../Icons/ConnectionStatus'
import Button from '../Input/Button'
import { Check, Percent } from '@phosphor-icons/react'
import InputField from '../Input/InputField'
import { UnitHeader } from '../UnitHeader'
import { AddSourceProduct } from './AddSourceProduct'
import Delete from '../Delete'
import { useProduct } from '../../hooks/useProduct'

type PartSourceProductsProps = StandardAttributes & {
    part?: Part
    input?: Input
    sourceProducts?: Product[]
    sourceProduct?: Product
    onSelect?: (sourceProduct: Product) => void
    onAdd?: (sourceProduct: Product) => void
    onEditWeight?: (sourceProduct: Product, weight: number) => void
    onWeightsUpdated?: (sourceProducts: Product[]) => void
    onRemove?: (sourceProducts: Product[]) => void
}

export const PartSourceProducts = (props: PartSourceProductsProps) => {
    const { partService } = useContext(VariableServicesContext)
    const [totalWeight, setTotalWeight] = useState<number>(0)
    const [hasEditedWeights, setHasEditedWeights] = useState<boolean>(false)
    const [canSaveWeights, setCanSaveWeights] = useState<boolean>(false)
    const [sourceProducts, setSourceProducts] = useState<Product[]>()

    const fetchSourceProducts = useCallback(() => {
        if (!props.part?.uuid) return
        partService.getSourceProducts(props.part).then(setSourceProducts)
    }, [props.part?.uuid])

    useEffect(() => fetchSourceProducts(), [props.part?.uuid])

    const calcWeights = useCallback(
        (sps?: Product[]) => {
            if (props.part?.type !== PartType.MIX) return 0
            const total =
                sps?.reduce((prev, curr) => prev.plus(curr.bucketWeight || 0), Utils.Decimal(0)).toNumber() || 0
            setTotalWeight(total)
            setCanSaveWeights(total === 100)
            return total
        },
        [props.part?.type],
    )

    const onEditWeight = useCallback(
        (sourceProduct: Product, weight: number) => {
            setHasEditedWeights(true)
            const sp = [...(sourceProducts || [])]
            const idx = sp.findIndex((sp) => sp.uuid === sourceProduct.uuid)
            sp[idx].bucketWeight = Utils.Decimal(weight || 0).toNumber()
            const total = calcWeights(sp)
            if (total === 100) setSourceProducts(() => [...sp])
        },
        [sourceProducts, calcWeights],
    )

    const saveWeightsButton = useMemo(() => {
        if (props.part?.type !== PartType.MIX) return null
        return (
            <div>
                <Button
                    ariaLabel='Save Weights'
                    hidden={!hasEditedWeights}
                    disabled={!canSaveWeights}
                    className='btn btn-sm btn-primary'
                    onClick={() => {
                        if (props.part && sourceProducts) {
                            setCanSaveWeights(false)
                            partService
                                .updateSourceProducts(props.part, sourceProducts)
                                .then(() => {
                                    props.onWeightsUpdated?.(sourceProducts)
                                    setHasEditedWeights(false)
                                })
                                .catch((err) => {
                                    Utils.errorToast(err)
                                    setCanSaveWeights(true)
                                })
                        }
                    }}
                >
                    Save Weights (Total: {totalWeight}%)
                </Button>
            </div>
        )
    }, [hasEditedWeights, canSaveWeights, totalWeight, props.part, sourceProducts])

    const addSourceProduct = useMemo(() => {
        if (!props.part) return null
        return (
            <AddSourceProduct
                className='mt-2'
                targetType={ND.Part}
                node={props.part}
                queryOptions={{ unitType: props.part.unit?.type }}
                onDone={(sourceProduct) => {
                    if (!props.part || !sourceProduct) return
                    partService.addSourceProduct(props.part, sourceProduct).then((sp) => {
                        setSourceProducts(sp)
                        calcWeights(sp)
                        props.onAdd?.(sourceProduct)
                    })
                }}
            />
        )
    }, [props.part, props.onAdd, sourceProducts, calcWeights])

    const firstCellHeader = useMemo(() => {
        if (props.part?.type === PartType.MIX && props.onWeightsUpdated) {
            return <div className='d-table-cell text-start'>Weight</div>
        } else if (props.part?.type === PartType.SWITCH && props.onSelect) {
            return <div className='d-table-cell text-center'>Selected</div>
        } else {
            return null
        }
    }, [props.part?.type, props.onWeightsUpdated, props.onSelect])

    return (
        <div>
            <div className='d-table small w-100'>
                <div className='d-table-row table-header'>
                    {firstCellHeader}
                    <div className='d-table-cell'>Sources</div>
                    <div className='d-table-cell text-end'>
                        <UnitHeader unitSize='small' extraClassName='fw-bold' />
                    </div>
                    <div className='d-table-cell' />
                </div>
                {sourceProducts?.sort(Utils.sortByName)?.map((sp) => (
                    <PartSourceProductLine
                        key={sp.uuid}
                        part={props.part}
                        input={props.input}
                        sourceProduct={sp}
                        onSelect={props.onSelect}
                        onEditWeight={onEditWeight}
                        onRemove={(newSourceProducts) => {
                            setSourceProducts(newSourceProducts)
                            calcWeights(newSourceProducts)
                            props.onRemove?.(newSourceProducts)
                        }}
                    />
                ))}
            </div>
            {saveWeightsButton}
            {addSourceProduct}
        </div>
    )
}

export const PartSourceProductLine = (props: PartSourceProductsProps) => {
    const { partService, productService } = useContext(VariableServicesContext)

    const sp = useProduct({ product: props.sourceProduct })

    const co2eCell = useMemo(() => {
        const unitTypeMatches = props.part?.unit?.type === sp?.unit?.type
        let _co2e = (
            <CO2e
                co2e={productService.getEmbeddedCO2e(sp)}
                product={sp}
                unitsClassName={['d-block small', !unitTypeMatches ? 'text-warning' : 'text-muted'].join(' ')}
                showUnit={false}
                showZeroesToPrecision={true}
                functionalUnit={sp?.unit?.code}
                unitSize='small'
            />
        )
        if (!unitTypeMatches) {
            _co2e = (
                <Tooltip
                    trigger='hover'
                    showArrow={false}
                    tooltipContent={`Unit type does not match ${PartService.webTitle()} unit type`}
                    className='d-block'
                >
                    {_co2e}
                </Tooltip>
            )
        }
        return <div className='d-table-cell align-middle text-end'>{_co2e}</div>
    }, [props.part?.unit?.type, sp?.co2e, sp?.unit?.code, sp?.unit?.type])

    const nameCell = useMemo(() => {
        if (!sp) return null
        return (
            <div className='d-table-cell align-middle'>
                <Link
                    to={ProductService.getProductUrl(sp)}
                    className='d-block underline-on-hover'
                    onClick={(e) => {
                        if (Utils.isModifierKey(e)) return
                        e.preventDefault()
                        productService.openPreview(sp)
                    }}
                >
                    <ConnectionStatus className='me-1' company={sp?.productOf} />
                    {sp?.name || 'Unnamed Product'}
                </Link>
                {sp?.productOf?.name ? (
                    `by ${sp?.productOf?.name}`
                ) : (
                    <span className='text-very-muted'>(Unspecified Supplier)</span>
                )}
            </div>
        )
    }, [sp])

    const switchCell = useMemo(() => {
        if (props.part?.type !== PartType.SWITCH || !sp || !props.onSelect) return null
        const _isCurrent = props.input?.sourceProduct?.uuid === sp.uuid
        return (
            <span className='d-table-cell p-1 text-center align-middle' style={{ width: '2rem' }}>
                <Button
                    disabled={!props.input?.uuid}
                    className={['btn btn-xs', _isCurrent ? 'btn-secondary' : 'btn-light'].join(' ')}
                    onClick={() => props.onSelect?.(sp)}
                >
                    <Check size={Utils.verySmallIconSize} color={_isCurrent ? Utils.white : Utils.fadedColor} />
                </Button>
            </span>
        )
    }, [props.input?.uuid, sp?.uuid, props.part?.type, props.input?.sourceProduct?.uuid, props.onSelect])

    const mixCell = useMemo(() => {
        if (props.part?.type !== PartType.MIX || !sp) return null
        if (!props.onEditWeight) {
            return (
                <div className='d-table-cell align-middle text-center' style={{ width: '3rem' }}>
                    {sp.bucketWeight}%
                </div>
            )
        }
        return (
            <div className='d-table-cell align-middle' style={{ width: '3rem' }}>
                <label htmlFor={`weight-${sp.uuid}`} className='d-flex align-items-center justify-content-center w-100'>
                    <InputField
                        id={`weight-${sp.uuid}`}
                        ariaLabel={`Weight for ${sp.name}`}
                        className={[
                            'variable-form-control border active-border text-end',
                            sp.co2e === null ? 'opacity-50' : '',
                        ].join(' ')}
                        placeholder='0'
                        type='number'
                        disabled={sp.co2e === null}
                        min={0}
                        max={100}
                        style={{ width: '3rem' }}
                        defaultValue={sp.bucketWeight}
                        followDefaultValue={true}
                        onChange={(newValue, validity) => {
                            if (!validity?.badInput) props.onEditWeight?.(sp, parseFloat(newValue))
                        }}
                    />
                    <Percent className='nt--1 ms-1' />
                </label>
            </div>
        )
    }, [sp, props.part?.type, props.onEditWeight])

    const editCell = useMemo(() => {
        if (!sp) return null
        switch (props.part?.type) {
            case PartType.SWITCH:
                return switchCell
            case PartType.MIX:
                return mixCell
            default:
                return null
        }
    }, [sp, switchCell, mixCell, props.part?.type])

    const etcCell = useMemo(() => {
        if (!props.onRemove || !sp) return null
        return (
            <div className='d-table-cell text-end align-middle' style={{ width: '3rem' }}>
                <Delete
                    iconOnly={true}
                    className='btn btn-xs bg-light-hover text-center show-on-hover'
                    warningText='Are you sure you want to remove this source product?'
                    confirmText='Yes, remove it!'
                    deleteFn={async () => {
                        if (!props.part || !sp) return Promise.resolve()
                        return partService
                            .removeSourceProduct(props.part, sp)
                            .then(props.onRemove)
                            .catch(Utils.errorToast)
                    }}
                />
            </div>
        )
    }, [sp, props.onRemove, props.part])

    return (
        <div className={['d-table-row table-body hover-parent', props.extraClassName].join(' ')} style={props.style}>
            {editCell}
            {nameCell}
            {co2eCell}
            {etcCell}
        </div>
    )
}
