import Utils from '../services/utils'
import { CSSProperties, ReactNode, useContext } from 'react'
import { ApplicationContext } from '../context'

export const Img = (props: {
    src?: string
    data?: string
    alt?: string
    className?: string
    style?: CSSProperties
    placeholder?: string
    placeholderClassName?: string
    placeholderStyle?: CSSProperties
    placeholderIcon?: ReactNode
    placeholderIconClassName?: string
    initials?: boolean
    width?: string
    height?: string
    size?: string
}) => {
    const context = useContext(ApplicationContext)
    if (!props.src && !props.data) {
        if (props.placeholder || props.placeholderIcon) {
            return (
                <span
                    className={[
                        props.placeholderIcon
                            ? `d-flex align-items-center justify-content-center rounded-2 opacity-25 ${props.placeholderIconClassName}`
                            : props.placeholderClassName || `d-block img-fit rounded-2 opacity-25 ${props.className}`,
                    ].join(' ')}
                    style={{
                        ...props.style,
                        ...props.placeholderStyle,
                        width: props.size || props.placeholder,
                        height: props.size || props.placeholder,
                        backgroundColor: Utils.veryFadedColor,
                    }}
                >
                    {props.initials && Utils.toInitials(props.alt || context.stores.company?.name, 3)}
                    {props.placeholderIcon}
                </span>
            )
        }

        return <></>
    }

    const _width = props.size || props.width || '100%'
    const _height = props.size || props.height || '100%'

    return (
        <img
            src={props.data || Utils.getFullImageUrl(props.src)}
            alt={props.alt || ''}
            className={`img-fit ${props.className}`}
            style={{
                maxWidth: _width,
                maxHeight: _height,
                ...props.style,
            }}
        />
    )
}
