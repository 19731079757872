import { IntRange, KeyValuePair, VariableBaseNode } from '../types'
import { DataRequest, ScheduledDataRequest } from './dataRequest'
import Utils from './utils'
import { Contact, User } from './user-context'
import { Company } from './company'
import VariableService from './service'

export type ScheduleFrequency = 'weekly' | 'monthly' | 'quarterly' | 'yearly'
export type DayOfWeek = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun'
export type WeekInMonth = 1 | 2 | 3 | 4 | 5
export type WeekOfYear = IntRange<1, 52>
export type DayOfYear = IntRange<1, 366>
export type ScheduleRepeat = 'until' | 'after'

export const scheduleFrequencies: KeyValuePair<ScheduleFrequency>[] = [
    { name: 'Weekly', value: 'weekly' },
    { name: 'Monthly', value: 'monthly' },
    { name: 'Quarterly', value: 'quarterly' },
    { name: 'Yearly', value: 'yearly' },
]

export const scheduleRepeatOptions: KeyValuePair<ScheduleRepeat>[] = [
    { name: 'Until', description: 'Requests will be sent until the specified date', value: 'until' },
    { name: 'After', description: 'Requests will be sent the specified number of times', value: 'after' },
]

export const daysOfWeek: KeyValuePair<number | null>[] = [
    { value: null, name: '(any weekday)' },
    { value: 0, name: 'Sunday' },
    { value: 1, name: 'Monday' },
    { value: 2, name: 'Tuesday' },
    { value: 3, name: 'Wednesday' },
    { value: 4, name: 'Thursday' },
    { value: 5, name: 'Friday' },
    { value: 6, name: 'Saturday' },
]
// export const weekOfYear: WeekOfYear[] = Array.from({ length: 52 }, (value, key) => (key + 1) as WeekOfYear)
// export const dayOfYear: DayOfYear[] = Array.from({ length: 366 }, (value, key) => (key + 1) as DayOfYear)

// loosely based on: https://www.rfc-editor.org/rfc/rfc2445#section-4.3.10
export interface Schedule extends VariableBaseNode {
    frequency: ScheduleFrequency
    interval?: number
    dayOfWeek?: number
    // weekInMonth?: WeekOfMonth
    // weekOfYear?: WeekOfYear
    // dayOfYear?: DayOfYear
    repeat: ScheduleRepeat
    startDate: number
    endDate?: number
    sender?: User
    count?: number
    contact?: Contact
    supplier?: Company
    dataRequest?: DataRequest
    occurrences?: ScheduledDataRequest[]
}

export interface NewScheduleOptions {
    startDate?: Date | number
    dataRequest?: DataRequest
    contact?: Contact
    supplier?: Company
}

export default class ScheduleService extends VariableService {
    private basePath: string = '/schedule'

    public newSchedule(opts?: NewScheduleOptions): Promise<Schedule> {
        let startDate = Utils.dayjs(opts?.startDate)
        return this.saveSchedule({
            frequency: 'monthly',
            repeat: 'until',
            count: 6,
            startDate: startDate.valueOf(),
            endDate: startDate.add(1, 'year').valueOf(),
            contact: opts?.contact || opts?.dataRequest?.contact,
            supplier: opts?.supplier || opts?.dataRequest?.supplier,
            dataRequest: opts?.dataRequest,
        })
    }

    public isPaused(schedule?: Schedule): boolean {
        if (!schedule) {
            return false
        }
        return schedule.endDate !== undefined && Utils.dayjs(schedule.endDate).isBefore(Utils.dayjs())
    }

    public static getTermly(frequency: ScheduleFrequency, count: number = 0): string {
        switch (frequency) {
            case 'yearly':
                return Utils.pluralize('year', count)
            case 'quarterly':
                return Utils.pluralize('quarter', count)
            case 'monthly':
                return Utils.pluralize('month', count)
            case 'weekly':
                return Utils.pluralize('week', count)
            default:
                return ''
        }
    }

    public static getNextOccurrence(schedule: Schedule): ScheduledDataRequest | undefined {
        const now = Utils.dayjs()
        const _occurrences = [...(schedule.occurrences || [])].sort((a, b) => a.sendAt - b.sendAt)
        while (_occurrences?.length) {
            const next = _occurrences.shift()
            if (next?.sendAt && Utils.dayjs(next?.sendAt).isAfter(now)) {
                return next
            }
        }
    }

    public static getNextOccurrenceString(schedule: Schedule): string {
        const next = ScheduleService.getNextOccurrence(schedule)
        return next ? Utils.toDateString(next.sendAt) : ''
    }

    public async getAllSchedules(queryString?: string): Promise<Schedule[]> {
        return this.httpService.get<Schedule[]>(`${this.basePath}?${queryString || ''}`)
    }

    public async getSchedule(scheduleId: string): Promise<Schedule> {
        return this.httpService.get<Schedule>(`${this.basePath}/${scheduleId}`)
    }

    public async deleteSchedule(schedule: Schedule): Promise<void> {
        return this.httpService.delete<void>(`${this.basePath}/${schedule.uuid}`)
    }

    public async getSchedulesForDateRange(
        startDate?: number,
        endDate?: number,
        supplier?: Company,
    ): Promise<ScheduledDataRequest[]> {
        const qs = new URLSearchParams()
        if (startDate) {
            qs.set('startDate', startDate.toString())
        }
        if (endDate) {
            qs.set('endDate', endDate.toString())
        }
        if (supplier?.uuid) {
            qs.set('supplier', supplier.uuid)
        }
        return this.httpService.post<ScheduledDataRequest[]>(`${this.basePath}/range?${qs.toString()}`)
    }

    public async getToday(cronId?: string): Promise<ScheduledDataRequest[]> {
        return this.httpService.post<ScheduledDataRequest[]>(`${this.basePath}/today`, {
            body: JSON.stringify({ cronId: cronId }),
        })
    }

    public async saveSchedule(schedule: Schedule): Promise<Schedule> {
        return this.httpService.post<Schedule>(this.basePath, {
            body: JSON.stringify({ schedule: schedule }),
        })
    }
}
