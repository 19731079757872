import { Product } from './product'
import VariableService from './service'
import { Input } from './input'

export default class AiService extends VariableService {
    private basePath: string = '/ai'

    public async getBom(product: Product): Promise<void> {
        return this.httpService.post<void>(this.basePath, { body: JSON.stringify({ bom: product }) })
    }

    public async getEmissionFactorsForInput(input: Input): Promise<Product[]> {
        return this.httpService.post<Product[]>(this.basePath, { body: JSON.stringify({ inputId: input.uuid }) })
    }
}
