import { Selector, SelectorProps } from '../Input/Selector'
import { useContext, useMemo, useState } from 'react'
import { ApplicationContext } from '../../context'
import TransportService, { TransportType } from '../../services/transport'
import { Plus } from '@phosphor-icons/react'
import Utils from '../../services/utils'
import Button from '../Input/Button'
import { VariableServicesContext } from '../../services'

export const TransportTypeSelector = (props: SelectorProps & { onNew?: () => void }) => {
    const context = useContext(ApplicationContext)
    const [menuOpen, setMenuOpen] = useState(false)
    const { transportService } = useContext(VariableServicesContext)

    const transportTypes = useMemo(
        () =>
            TransportService.transportTypes.sort((a, b) => {
                const aName = TransportService.getTransportTypeName(a)
                const bName = TransportService.getTransportTypeName(b)
                return aName.localeCompare(bName)
            }),
        [context.stores.transport?.transportTypesUpdated],
    )

    return (
        <Selector
            ariaLabel='Transport Lane Selector'
            options={transportTypes}
            menuOpen={menuOpen}
            onVisibilityChange={setMenuOpen}
            filterBy={(tt: TransportType, searchText) => {
                if (!searchText) return true
                const searchData = [TransportService.getTransportTypeName(tt)]
                if (tt.summary) searchData.push(tt.summary)
                return Utils.filterItem(searchData, searchText)
            }}
            footer={
                <div
                    className={[
                        'd-flex align-items-center gap-2',
                        'bg-info bg-opacity-10',
                        'mt-2 mx--2 mb--2 py-2 px-3',
                    ].join(' ')}
                >
                    <Button
                        // hidden={!!context.stores.transport.transportTypes?.length}
                        className='btn btn-unstyled underline-on-hover'
                        onClick={() => {
                            if (props.onNew) {
                                setMenuOpen(false)
                                props.onNew()
                            } else {
                                transportService.setTransportType()
                            }
                        }}
                    >
                        <Plus size={Utils.verySmallIconSize} className='nt--2 me-1' />
                        New transport lane
                    </Button>
                </div>
            }
            renderValueLabel={(t) => {
                const tt = TransportService.transportTypeById.get(t.uuid)
                return TransportService.getTransportTypeName(tt)
            }}
            renderItemValue={(t) => (
                <div>
                    <div>{TransportService.getTransportTypeName(t)}</div>
                    <div className='text-muted small text-wrap'>{t.summary}</div>
                </div>
            )}
            tooltipStyle={{ maxWidth: '300px' }}
            {...props}
        />
    )
}
