import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath } from 'reactflow'
import Utils from '../../services/utils'
import { EDGE_WIDTH } from '../../services/flow'
import { useProduct } from '../../hooks/useProduct'
import { useInput } from '../../hooks/useInput'

export const InputEdge = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
    data,
}: EdgeProps) => {
    const [edgePath] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    })
    const input = useInput({ input: data?.input })
    const product = useProduct({ product: data?.product })

    const [, percent] = Utils.percentOfTotal(parseFloat(input?.co2e || '0'), parseFloat(product?.co2e || '0'))
    let _transform = `translate(10px, -50%) translate(${sourceX}px, ${sourceY}px)`
    let _strokeColor = Utils.upstreamColor
    if (input?.useStageCategory?.type === 'Direct') {
        _strokeColor = Utils.scope2Color
    } else if (input?.useStageCategory?.type === 'Downstream') {
        _strokeColor = Utils.downStreamColor
        _transform = `translate(calc(-100% - 10px), -50%) translate(${targetX}px, ${targetY}px)`
    }
    return (
        <>
            <BaseEdge
                id={id}
                path={edgePath}
                markerEnd={markerEnd}
                style={{ ...style, stroke: style.stroke || _strokeColor, opacity: 0.9 }}
                interactionWidth={10}
            />
            <EdgeLabelRenderer>
                {product?.uuid && (
                    <div
                        style={{ transform: _transform, maxWidth: `${EDGE_WIDTH}px`, zIndex: style.zIndex }}
                        className='position-absolute'
                    >
                        <div className='bg-white border rounded-1 px-2 py-1 shadow-sm nodrag nopan small'>
                            {percent}%
                        </div>
                    </div>
                )}
            </EdgeLabelRenderer>
        </>
    )
}
