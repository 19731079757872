import { EdgeProps } from 'reactflow'

export const ByproductEdge = ({ sourceX, sourceY, targetX, targetY, style }: EdgeProps) => {
    return (
        <g>
            <path
                fill='none'
                stroke='5,5'
                style={{ ...style, strokeDasharray: '5,5', animation: 'dash 5s linear infinite' }}
                d={`M ${sourceX} ${sourceY}
                    C ${sourceX + 100} ${sourceY + 100}
                      ${targetX - 100} ${targetY - 75}
                      ${targetX} ${targetY}
                  `}
            />
        </g>
    )
}
