import ActivityService, { ActivityItem, ActivityState } from '../../services/activity'
import Button from '../Input/Button'
import Utils from '../../services/utils'
import { CSSProperties, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { VariableServicesContext } from '../../services'
import InputField from '../Input/InputField'
import { ApplicationContext } from '../../context'
import Tooltip from '../Tooltip'
import { Warning } from '@phosphor-icons/react'
import OrgService from '../../services/org'
import { StateHelp } from '../StateHelp'
import { DataRequestContext } from '../../services/dataRequestContext'

export const ActivityStateManager = (props: {
    activityItem: ActivityItem
    className?: string
    style?: CSSProperties
    inputClassName?: string
    onChange: (state: ActivityState) => void
}) => {
    const context = useContext(ApplicationContext)
    const drContext = useContext(DataRequestContext)
    const { activityService, userService } = useContext(VariableServicesContext)
    const [activityItem, setActivityItem] = useState<ActivityItem>(props.activityItem)
    const [settingState, setSettingState] = useState<boolean>(false)
    const [stateMessage, setStateMessage] = useState<string>()
    const [errorMessage, setErrorMessage] = useState<ReactNode>()
    const [userCanChangeState, setUserCanChangeState] = useState<boolean>(false)
    const [adminOverride, setAdminOverride] = useState<boolean>(false)
    const inputRef = useRef<HTMLTextAreaElement>(null)

    useEffect(() => setActivityItem(props.activityItem), [props.activityItem])

    const activityPerspective = useMemo(
        () => activityService.getActivityPerspective(activityItem),
        [activityItem?.uuid],
    )

    useEffect(() => {
        if (!activityItem?.uuid) return
        activityService.canUpdateState(activityItem).then(setUserCanChangeState)
    }, [activityItem.uuid, activityItem.state, context.stores.user?.role, activityPerspective])

    const disabled = useMemo(() => {
        if (adminOverride) {
            setErrorMessage('')
            return false
        }
        const missingFields: ReactNode[] = []
        if (activityItem.staticData) {
            if (!Utils.Decimal(activityItem.staticCo2e || 0).gt(0)) {
                missingFields.push(Utils.co2e)
            }
        } else {
            if (!activityItem.product?.uuid && !activityItem.transportType?.uuid) {
                missingFields.push('Element')
            }
            if (!activityItem.quantity) {
                if (activityItem.renewableProduction?.quantity || activityItem.electricityCertificate?.quantity) {
                    // do nothing
                } else if (isNaN(activityItem.quantity)) {
                    missingFields.push('Quantity')
                }
            }
        }
        if (!activityItem.org?.uuid || OrgService.isOther(activityItem.org)) {
            missingFields.push('Org')
        }
        if (!activityItem.scope?.uuid) {
            missingFields.push('Scope')
        }
        if (missingFields.length) {
            setErrorMessage(`To submit, please enter: ${missingFields.join(', ')}`)
            return true
        }
        return false
    }, [
        adminOverride,
        activityItem.state,
        activityItem.scope?.uuid,
        activityItem.org?.uuid,
        activityItem.product?.uuid,
        activityItem.quantity,
        activityItem.staticCo2e,
        activityItem.staticData,
        activityItem.renewableProduction?.quantity,
        activityItem.electricityCertificate?.quantity,
        context.stores.user?.role,
    ])

    const setState = useCallback(
        (state: ActivityState, toastMessage: string) => {
            setSettingState(true)
            activityService
                .updateState(activityItem, state, stateMessage, adminOverride)
                .then((updatedActivityItem) => {
                    setActivityItem(updatedActivityItem)
                    Utils.infoToast(toastMessage)
                    props.onChange(updatedActivityItem.state)
                    setStateMessage(undefined)
                    inputRef.current && (inputRef.current.value = '')
                })
                .catch(Utils.errorToast)
                .finally(() => setSettingState(false))
        },
        [activityItem?.uuid, activityItem?.state, stateMessage, adminOverride, inputRef, props.onChange],
    )

    const buttons = useMemo(() => {
        if (activityItem.dataRequest) return null
        const btnClassName = 'btn btn-sm d-block w-100'
        switch (activityItem.state) {
            case ActivityState.APPROVED:
                return (
                    <Button
                        saving={settingState}
                        disabled={!stateMessage}
                        disabledTooltip={`Please provide a reason for re-opening this ${ActivityService.webTitle().toLowerCase()}`}
                        disabledTooltipProps={{ tooltipClassName: 'small' }}
                        className={[btnClassName, 'btn-light'].join(' ')}
                        onClick={() => setState(ActivityState.DRAFT, 'Activity re-opened')}
                    >
                        Re-open
                    </Button>
                )
            case ActivityState.PENDING:
                return (
                    <div className='d-flex align-items-center gap-3'>
                        <Button
                            saving={settingState}
                            className={[btnClassName, disabled ? 'btn-success' : 'btn-outline-success'].join(' ')}
                            onClick={() => setState(ActivityState.APPROVED, 'Activity approved')}
                        >
                            Approve
                        </Button>
                        <Button
                            saving={settingState}
                            className={[btnClassName, disabled ? 'btn-danger' : 'btn-outline-danger'].join(' ')}
                            onClick={() => setState(ActivityState.REJECTED, 'Activity rejected')}
                        >
                            Reject
                        </Button>
                    </div>
                )
            default:
                return (
                    <Button
                        saving={settingState}
                        className={[btnClassName, disabled ? 'btn-primary' : 'btn-outline-primary'].join(' ')}
                        onClick={() => setState(ActivityState.PENDING, 'Activity submitted for approval')}
                    >
                        {activityItem.state === ActivityState.REJECTED ? 'Re-submit' : 'Submit'}
                    </Button>
                )
        }
    }, [
        activityItem?.uuid,
        activityItem?.state,
        activityItem.dataRequest,
        setState,
        stateMessage,
        settingState,
        disabled,
    ])

    if (activityItem.dataRequest || drContext.dataRequest?.uuid) {
        return null
    }

    if (!buttons || !userCanChangeState) {
        return null
    }

    const fieldset = (
        <fieldset disabled={disabled} className={props.className}>
            <div className='d-flex align-items-start gap-1'>
                <div className='flex-grow-1'>
                    <InputField
                        passedRef={inputRef}
                        onChange={setStateMessage}
                        placeholder='Optional message attached to this status change'
                        multiLine={true}
                        rows={3}
                        className={props.inputClassName || 'd-block variable-form-control bg-light w-100 mb-3'}
                    />
                </div>
                <div className='flex-shrink-0'>
                    <StateHelp />
                </div>
            </div>
            {buttons}
        </fieldset>
    )

    if (disabled) {
        return (
            <Tooltip
                trigger='hover'
                interactive={true}
                tooltipClassName='text-start small border-warning'
                tooltipStyle={{ maxWidth: '380px' }}
                tooltipContent={
                    <>
                        <div className='d-flex align-items-start gap-1'>
                            <Warning color={Utils.warningColor} />
                            {errorMessage}
                        </div>
                        {userService.hasAdminRole() && (
                            <Button
                                className='btn btn-sm btn-outline-danger mt-1'
                                onClick={() => setAdminOverride(true)}
                            >
                                Use your admin privileges to submit
                            </Button>
                        )}
                    </>
                }
            >
                {fieldset}
            </Tooltip>
        )
    }

    return fieldset
}
