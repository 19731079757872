import Utils from '../../services/utils'
import { PrettyNumber } from '../PrettyNumber'
import { CSSProperties, useMemo } from 'react'

export const Notification = (props: {
    style?: CSSProperties
    size?: number
    color?: string
    count: number
    showNumber?: boolean
}) => {
    const style: CSSProperties = useMemo(
        () => ({
            minWidth: props.size ? `${props.size}px` : '1.2rem',
            minHeight: props.size ? `${props.size}px` : '1.2rem',
            backgroundColor: props.color || Utils.dangerColor,
            ...props.style,
        }),
        [props.size, props.color, props.style],
    )

    return useMemo(() => {
        if (!props.count) return null
        const count = props.count
        return (
            <span
                className={[
                    'position-absolute d-inline-flex align-items-center justify-content-center px-1 text-height-1 ms-1',
                    count < 10 ? 'rounded-circle' : 'rounded-pill',
                    props.showNumber ? 'nt--5' : '',
                ].join(' ')}
                style={style}
            >
                {props.showNumber && (
                    <PrettyNumber friendlyLargeNumbers={true} num={count} className='text-white small nt-1' />
                )}
            </span>
        )
    }, [props.count, props.showNumber, style])
}
