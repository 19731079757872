import Logo from './Logo'

export const LoadingPage = () => {
    return (
        <div className='d-flex flex-column dvh-100 w-100 align-items-center justify-content-center'>
            <Logo style={{ width: '70px' }} />
            <div className='mt-2'>
                Loading
                <span className='spinner-border spinner-border-sm ms-1' />
            </div>
        </div>
    )
}
