import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { SlideIn } from '../SlideIn'
import { Selector } from './Selector'
import CO2e from '../CO2e'
import { AmountInput } from './Amount'
import UnitService, { UnitType } from '../../services/unit'
import Delete from '../Delete'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'
import { PrettyNumber } from '../PrettyNumber'
import InputField from './InputField'
import { Footnotes } from '../Footnotes'
import { UseStageType } from '../../services/useStage'
import { ElectricitySelector } from './ElectricitySelector'
import { useUseStageType } from '../../hooks/useUseStageType'
import { ND } from '../../types'

const useStageMethods = [
    { name: 'Calculate from usage frequency', value: 'use-frequency-duration' },
    { name: 'Enter total energy use', value: 'static' },
]

export const UseStageTypeEditor = () => {
    const context = useContext(ApplicationContext)
    const { inputService, useStageService, partService } = useContext(VariableServicesContext)
    const [saving, setSaving] = useState<boolean>(false)
    const nameRef = useRef<any>()
    const inputRef = useRef<any>()

    const useStageType = useUseStageType({ useStageTypeId: context.stores.useStageType?.useStageId })

    useEffect(() => {
        return () => useStageService.clearUseStageType()
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (!useStageType?.name || context.stores.useStageType?.focus === 'name') {
                nameRef.current?.focus()
            } else {
                inputRef.current?.focus()
            }
        }, 301)
    }, [context.stores.useStageType?.useStageId])

    const nodeId = useMemo(() => context.stores.useStageType?.node?.uuid, [context.stores.useStageType?.node?.uuid])

    const save = useCallback(
        async (properties?: Partial<UseStageType>) => {
            if (!useStageType || !nodeId) return
            setSaving(true)
            return useStageService
                .updateInputUseStageType({ uuid: useStageType.uuid, ...properties }, nodeId)
                .finally(() => setSaving(false))
        },
        [useStageType, nodeId],
    )

    const staticDataEntry = useMemo(
        () => (
            <div className='row align-items-center py-1'>
                <div className='col-4 small text-nowrap'>Energy used</div>
                <div className='col-8'>
                    <AmountInput
                        ariaLabel='Energy used'
                        amount={useStageType?.totalEnergy}
                        inputFieldProps={{ className: 'variable-form-control bg-light' }}
                        unitSelectorProps={{
                            unitType: UnitType.ENERGY,
                            unit: useStageType?.totalEnergy?.unit || UnitService.baseUnitByType.get(UnitType.ENERGY),
                        }}
                        onChange={(totalEnergy) => save({ totalEnergy })}
                    />
                </div>
            </div>
        ),
        [useStageType?.totalEnergy, save],
    )

    const useFrequencyDuration = useMemo(
        () => (
            <div className='d-flex flex-column gap-1'>
                <div className='row align-items-center'>
                    <div className='col-4 small text-nowrap'>Energy per use</div>
                    <div className='col-8'>
                        <AmountInput
                            ariaLabel='Energy per use'
                            amount={useStageType?.energyPerUse}
                            inputFieldProps={{ passedRef: inputRef }}
                            unitSelectorProps={{ unitType: UnitType.ENERGY, unit: useStageType?.energyPerUse?.unit }}
                            onChange={(energyPerUse) => save({ energyPerUse })}
                        />
                    </div>
                </div>
                <div className='row align-items-center'>
                    <div className='col-4 small text-nowrap'>Use frequency</div>
                    <div className='col-8'>
                        <AmountInput
                            ariaLabel='Use frequency'
                            amount={useStageType?.useFrequency}
                            separator={<span className='px-2 me--2'>times /</span>}
                            unitSelectorProps={{ unitType: UnitType.TIME, unit: useStageType?.useFrequency?.unit }}
                            onChange={(useFrequency) => save({ useFrequency })}
                        />
                    </div>
                </div>
                <div className='row align-items-center'>
                    <div className='col-4 small text-nowrap'>Time in use</div>
                    <div className='col-8'>
                        <AmountInput
                            ariaLabel='Time in use'
                            amount={useStageType?.timeInUse}
                            unitSelectorProps={{ unitType: UnitType.TIME, unit: useStageType?.timeInUse?.unit }}
                            onChange={(timeInUse) => save({ timeInUse })}
                        />
                    </div>
                </div>
            </div>
        ),
        [useStageType?.energyPerUse, useStageType?.useFrequency, useStageType?.timeInUse, save],
    )

    const details = useMemo(() => {
        if (!useStageType) return null
        return (
            <div className='d-flex flex-column gap-3'>
                <div className='row align-items-center py-1'>
                    <div className='col-4 small text-nowrap'>Method:</div>
                    <div className='col-8'>
                        <Selector
                            ariaLabel='Method'
                            placeholder='Select'
                            placement='bottom-start'
                            className='variable-form-select'
                            options={useStageMethods}
                            option={useStageMethods.find((usm) => usm.value === useStageType?.method)}
                            onSelect={(newValue) => save({ method: newValue.value })}
                        />
                    </div>
                </div>
                <div>
                    {useStageType.method === 'static' && staticDataEntry}
                    {useStageType.method === 'use-frequency-duration' && useFrequencyDuration}
                </div>
                <div className='row align-items-center py-1'>
                    <div className='col-4 small text-nowrap'>Location</div>
                    <div className='col-8'>
                        <ElectricitySelector
                            geoLocation={useStageType.geoLocation}
                            location={useStageType.location}
                            electricity={useStageType.footprint}
                            showGridSelector={true}
                            onChange={(geoLocation, location, footprint) => {
                                save({
                                    geoLocation,
                                    location,
                                    footprint: footprint?.[0],
                                }).then(() => partService.getAllSources())
                            }}
                        />
                    </div>
                </div>
                <div className='small border-top pt-2'>
                    <div className='d-flex align-items-center justify-content-between mb-1'>
                        <span>Total kWh</span>
                        <span>
                            <PrettyNumber num={useStageType?.totalKWh} precision={0} /> kWh
                        </span>
                    </div>
                    <div className='d-flex align-items-center justify-content-between mb-1'>
                        <span>Total Footprint</span>
                        <CO2e co2e={useStageType?.co2e} unitSize='small' />
                    </div>
                </div>
            </div>
        )
    }, [useStageType, save])

    if (!useStageType) return null

    return (
        <SlideIn
            ariaLabel='Use Stage Editor'
            show={true}
            onVisibilityChange={(v) => !v && useStageService.clearUseStageType()}
            useBackdrop={true}
            header={
                <InputField
                    passedRef={nameRef}
                    placeholder='Name'
                    className='variable-form-control bg-light fs-6 w-100'
                    defaultValue={useStageType?.name || context.stores.useStageType?.node?.name}
                    onChanged={(name) => save({ name })}
                />
            }
        >
            <div className='d-flex flex-column justify-content-between gap-3 flex-grow-1'>
                {details}
                <div className='fs-base text-center'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <Footnotes node={useStageType} saving={saving} />
                        <div hidden={context.stores.useStageType?.nodeType !== ND.Input} className='small'>
                            <Delete
                                deleteFn={() =>
                                    inputService.removeInput(nodeId).then(() => useStageService.clearUseStageType())
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </SlideIn>
    )
}
