import { ReactNode, useMemo } from 'react'
import Tooltip, { TooltipProps } from '../Tooltip'
import { IconProps, Info } from '@phosphor-icons/react'
import { StandardAttributes } from '../../types'

export type InfoIconProps = StandardAttributes & {
    size?: number
    color?: string
    href?: string
    iconPlacement?: 'before' | 'after'
    iconClassName?: string
    interactive?: boolean
    iconProps?: Partial<IconProps>
    tooltip?: ReactNode
    tooltipProps?: Partial<TooltipProps>
    children?: ReactNode
    onClick?: () => void
}

export const InfoIcon = (props: InfoIconProps) => {
    const iconPlacement = useMemo(() => props.iconPlacement || 'before', [props.iconPlacement])
    const icon = useMemo(() => {
        return (
            <Info
                color={props.color}
                size={props.size}
                className={props.iconClassName || (iconPlacement === 'before' ? 'me-1' : 'ms-1')}
                {...props.iconProps}
            />
        )
    }, [props.color, props.size, iconPlacement, props.iconClassName, props.iconProps])

    if (props.hidden) return null

    if (props.tooltip) {
        return (
            <Tooltip
                trigger='hover'
                tooltipContent={props.tooltip}
                interactive={props.interactive}
                className={props.className}
                tooltipStyle={{ maxWidth: '320px' }}
                onClick={() => {
                    props.onClick?.()
                    if (props.href) {
                        window.open(props.href, '_blank')
                    }
                }}
                {...props.tooltipProps}
            >
                {iconPlacement === 'before' && icon}
                {props.children}
                {iconPlacement === 'after' && icon}
            </Tooltip>
        )
    }

    if (props.href && props.children) {
        return (
            <a href={props.href} target='_blank' rel='noreferrer' className={props.className} onClick={props.onClick}>
                {iconPlacement === 'before' && <>{icon}</>}
                {props.children}
                {iconPlacement === 'after' && <>{icon}</>}
            </a>
        )
    }

    return icon
}
