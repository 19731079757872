import { StandardAttributes } from '../../types'
import TransportService, { TransportSegment, TransportType } from '../../services/transport'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import Delete from '../Delete'
import Tooltip from '../Tooltip'
import { ArrowCounterClockwise, ArrowRight, CursorText, Database, GlobeSimple, MapTrifold } from '@phosphor-icons/react'
import Utils from '../../services/utils'
import { TransportModeSelector } from './TransportModeSelector'
import { useElementCreator } from '../../hooks/useElementCreator'
import ProductService, { DataQuality, Product } from '../../services/product'
import { CreateProductFrom, InventoryService } from '../../services/inventory'
import { AmountInput } from '../Input/Amount'
import UnitService, { UnitType } from '../../services/unit'
import { NiceAmount } from '../NiceAmount'
import Button from '../Input/Button'
import { VariableServicesContext } from '../../services'
import { DebugData } from '../Footnotes'
import { InventoryIcon } from '../Icons/InventoryIcon'
import GoogleMap, { MapProps } from '../GoogleMap'
import { DistanceResponse } from '../../services/geoLocation'
import { LocationSelector } from '../Location/LocationSelector'

export const TransportSegmentEditor = (props: {
    transportType: TransportType
    segment: TransportSegment
    idx: number
    onDistance?: (distance: DistanceResponse) => void
    onSaving: (isSaving: boolean) => void
    onSaved: (transportType: TransportType) => void
}) => {
    const { transportService, geoLocationService } = useContext(VariableServicesContext)
    const [segment, setSegment] = useState<TransportSegment>(props.segment)
    const [previousFootprint, setPreviousFootprint] = useState<Partial<Product>>()
    const [createFrom, setCreateFrom] = useState<CreateProductFrom>()
    const [mapProps, setMapProps] = useState<MapProps>()
    const instanceId = useMemo(() => `tse-cef-${segment.uuid}-${props.idx}`, [segment.uuid, props.idx])
    const { createElementFrom } = useElementCreator({
        onCreated: (newProduct, id) => {
            if (id === instanceId) {
                save({ customFootprint: true, footprint: newProduct })
                setCreateFrom(undefined)
            }
        },
    })

    useEffect(() => {
        if (segment.uuid !== props.segment.uuid) setSegment(props.segment)
    }, [props.segment])

    const save = useCallback(
        (segmentData: Partial<TransportSegment>) => {
            props.onSaving(true)
            const updatedSegment: TransportSegment = { ...segment, ...segmentData }
            setSegment(updatedSegment)
            transportService
                .saveTransportSegment(props.transportType, updatedSegment)
                .then((tt) => {
                    setSegment(tt.segments?.find((s) => s.uuid === segment.uuid) || segment)
                    props.onSaved(tt)
                })
                .finally(() => props.onSaving(false))
        },
        [segment, props.transportType],
    )

    const selectedMode = useMemo(
        () => TransportService.transportModes.find((tt) => tt.value === segment.mode),
        [segment.mode],
    )

    useEffect(() => {
        if (segment.customFootprint && segment.footprint) {
            setPreviousFootprint(segment.footprint)
            setCreateFrom(undefined)
        }
    }, [segment])

    useEffect(() => {
        if (segment.origin?.uuid && segment.destination?.uuid && segment.mode) {
            geoLocationService
                .getDistance(segment.origin?.uuid, segment.destination?.uuid, segment.mode)
                .then((dr) => {
                    setMapProps({
                        origin: dr.origin,
                        destination: dr.destination,
                        overviewPolyline: dr?.distance?.overviewPolyline,
                        coordinatePath: dr?.distance?.coordinatePath,
                    })
                    props.onDistance?.(dr)
                })
                .catch(() => setMapProps(undefined))
        }
    }, [segment.origin?.uuid, segment.destination?.uuid, segment.mode])

    useEffect(() => {
        if (segment.footprint?.quality === DataQuality.AVERAGE) {
            const transportTypeOption = TransportService.transportModes.find((tt) => tt.value === segment.mode)
            setCreateFrom({
                id: segment?.footprint.uuid!,
                instanceId: instanceId,
                properties: { name: (transportTypeOption?.text || transportTypeOption?.name || '') as string },
            })
        }
    }, [segment.footprint?.productOf?.type])

    return (
        <div className='d-flex align-items-start gap-2 border-bottom mt-2 border-last-none pb-2'>
            <div className='flex-grow-1'>
                <div className='row align-items-center'>
                    <div className='col-3 small'>Origin</div>
                    <div className='col-7'>
                        <LocationSelector
                            location={segment.originLocation}
                            geoLocation={segment.origin}
                            placeholder='Select'
                            placement='bottom-start'
                            className='variable-form-select'
                            onLocation={(origin, originLocation) => {
                                save({ originLocation, origin })
                            }}
                        />
                    </div>
                    <div className='col-2 d-flex justify-content-end'>
                        <Delete
                            hidden={props.idx === 0}
                            deleteFn={() =>
                                transportService
                                    .deleteTransportSegment(props.transportType, segment)
                                    .then(props.onSaved)
                            }
                            iconOnly={true}
                        />
                    </div>
                </div>
                <div className='row align-items-center'>
                    <div className='col-3 small'>Destination</div>
                    <div className='col-7'>
                        <LocationSelector
                            location={segment.destinationLocation}
                            geoLocation={segment.destination}
                            placeholder='Select'
                            placement='bottom-start'
                            className='variable-form-select'
                            onLocation={(destination, destinationLocation) => {
                                save({ destinationLocation, destination })
                            }}
                        />
                    </div>
                </div>
                <div className='row align-items-center my-1'>
                    <div className='col-3 small'>Distance</div>
                    <div className='col-7 d-flex align-items-center gap-2'>
                        <TransportSegmentDistance
                            segment={segment}
                            className='ms-2'
                            onToggle={(isChecked) => {
                                if (isChecked) {
                                    save({
                                        staticDistance: {
                                            quantity: segment.distance?.quantity,
                                            unit: segment.distance?.unit,
                                        },
                                    })
                                } else {
                                    save({ staticDistance: undefined })
                                }
                            }}
                            onChange={save}
                        />
                    </div>
                </div>
                <div className='row align-items-center mt-3'>
                    <div className='col-3 small'>Mode</div>
                    <div className='col-7'>
                        <TransportModeSelector
                            segment={segment}
                            onSelect={(newValue) => {
                                if (newValue.node?.uuid) {
                                    const mode = TransportService.getMode(newValue.node?.taxonomy)
                                    save({
                                        mode: mode,
                                        footprintSyncId: newValue.node?.syncId,
                                        customFootprint: true,
                                        footprint: ProductService.basicProduct(newValue.node),
                                    })
                                    setCreateFrom(undefined)
                                } else {
                                    save({
                                        mode: newValue.value,
                                        customFootprint: false,
                                        footprintSyncId: newValue.node?.syncId,
                                    })
                                    setCreateFrom({
                                        id: newValue.node.syncId,
                                        properties: { name: (newValue?.text || newValue?.name || '') as string },
                                        instanceId: instanceId,
                                    })
                                }
                            }}
                        />
                    </div>
                </div>
                <div className='row'>
                    {createFrom?.id && (
                        <div className='col offset-3 mt-2'>
                            <Tooltip
                                trigger='hover'
                                tooltipClassName='small'
                                tooltipContent={
                                    <div style={{ width: '370px' }}>
                                        Add{' '}
                                        <span className='d-inline-block bg-dark bg-opacity-10 px-1 rounded-1'>
                                            <Database />
                                            {selectedMode?.text || selectedMode?.name || ''}
                                        </span>{' '}
                                        to your {InventoryService.webTitle()} to re-use it across multiple lanes
                                        {/*<Link to='' className='text-decoration-underline'>*/}
                                        {/*    Read more*/}
                                        {/*    <ArrowSquareOut size={Utils.smallIconSize} className='nt--1 ms-1' />*/}
                                        {/*</Link>*/}
                                    </div>
                                }
                                className='btn btn-xs btn-light border text-nowrap'
                                onClick={() => createElementFrom(createFrom)}
                            >
                                <span className='small'>
                                    Add to {InventoryService.webTitle()} <ArrowRight weight='regular' />{' '}
                                    <InventoryIcon allInventory={true} />
                                </span>
                            </Tooltip>
                            <Tooltip
                                trigger='hover'
                                tooltipClassName='small'
                                hidden={!previousFootprint}
                                tooltipContent='Revert to previous mode'
                                className='btn btn-xs'
                                onClick={() => save({ customFootprint: true, footprint: previousFootprint })}
                            >
                                <ArrowCounterClockwise size={Utils.verySmallIconSize} />
                            </Tooltip>
                        </div>
                    )}
                </div>
                <DebugData node={segment} />
            </div>
            <GoogleMap {...mapProps} size={130} extraClassName='flex-shrink-0' />
        </div>
    )
}

export const TransportSegmentDistance = (
    props: StandardAttributes & {
        segment: TransportSegment
        onToggle: (isChecked: boolean) => void
        onChange: (segment: Partial<TransportSegment>) => void
    },
) => {
    const inputRef = useRef<any>()

    const icon = useMemo(() => {
        if (props.segment.staticDistance?.unit) {
            return <CursorText />
        }
        if (props.segment.distanceSource === 'google' || props.segment.distanceSource === 'searoutes') {
            return <MapTrifold />
        }
        return <GlobeSimple />
    }, [props.segment.distance, props.segment.staticDistance, props.segment.distanceSource])

    const tooltip: string = useMemo(() => {
        if (props.segment.staticDistance?.unit) {
            return 'Distance entered manually'
        }
        if (props.segment.distanceSource === 'google') {
            return 'Distance calculated using Google Maps'
        }
        if (props.segment.distanceSource === 'searoutes') {
            return 'Distance calculated using EuroStat sea routes'
        }
        return 'Cartesian distance'
    }, [props.segment.distance, props.segment.staticDistance, props.segment.distanceSource])

    const toggle = useMemo(
        () => (
            <Button
                className='btn btn-xs btn-light border rounded-1'
                onClick={() => {
                    const usingStatic = !props.segment.staticDistance?.unit
                    if (usingStatic) setTimeout(() => inputRef.current?.select(), 100)
                    props.onToggle(usingStatic)
                }}
            >
                {props.segment.staticDistance?.unit ? 'Use calculated distance' : 'Enter distance'}
            </Button>
        ),
        [props.onToggle, props.segment.staticDistance?.unit],
    )

    const tooltipContent = useMemo(
        () => (
            <div className='small d-flex flex-column align-items-center gap-1'>
                <div className='d-flex align-items-start gap-1'>
                    <span className='flex-shrink-0'>{icon}</span> {tooltip}
                </div>
                <div className='text-center'>{toggle}</div>
            </div>
        ),
        [icon, tooltip, toggle],
    )

    const manualEntry = useMemo(() => {
        return (
            <AmountInput
                amount={props.segment.staticDistance}
                inputFieldProps={{ passedRef: inputRef }}
                unitSelectorProps={{ unitType: UnitType.DISTANCE, style: { width: 'auto' } }}
                onChange={(amount) => props.onChange({ staticDistance: amount })}
            />
        )
    }, [props.onChange, inputRef, props.segment.staticDistance])

    const display = useMemo(
        () => (
            <>
                <span className='nt--1'>{icon}</span>{' '}
                <NiceAmount
                    amount={{ quantity: props.segment.distance?.quantity, unit: UnitService.unitByCode['km'] }}
                    prettyNumberProps={{ precision: 1, showZeroesToPrecision: false }}
                />
            </>
        ),
        [icon, props.segment.distance?.quantity],
    )

    if (!props.segment?.uuid || !props.segment.distance) return null

    return (
        <div className={props.className} style={props.style}>
            <Tooltip
                trigger='hover'
                placement='left'
                positioning='fixed'
                interactive={true}
                disabled={!props.segment}
                tooltipStyle={{ width: '220px', maxWidth: '90%' }}
                tooltipContent={tooltipContent}
                className='small text-end text-nowrap'
            >
                {props.segment.staticDistance?.unit ? manualEntry : display}
            </Tooltip>
        </div>
    )
}
