import Button from '../Input/Button'
import { CaretRight } from '@phosphor-icons/react'
import Utils from '../../services/utils'
import { CalculatorInstance } from '../Calculator/CalculatorInstance'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { VariableServicesContext } from '../../services'
import { Calculation, Calculator } from '../../services/calculator'
import { Input } from '../../services/input'

export const InputCalculator = (props: {
    input?: Input
    dragHandle: ReactNode
    disabled?: boolean
    onChange: (input: Input) => void
}) => {
    const { inputService, calculatorService } = useContext(VariableServicesContext)
    const [calculator, setCalculator] = useState<Calculator>()
    const [calculation, setCalculation] = useState<Calculation>()
    const [showCalculator, setShowCalculator] = useState<boolean>(false)
    const [shouldSaveInputCalculation, setShouldSaveInputCalculation] = useState<boolean>(false)

    useEffect(() => {
        if (props.input?.calculation) {
            setCalculator(props.input.calculation.calculator)
            setCalculation(props.input.calculation)
        }
    }, [])

    useEffect(() => {
        if (!shouldSaveInputCalculation || !calculation) {
            return
        }
        setShouldSaveInputCalculation(false)
        inputService.updateInputCalculation(calculation, props.input?.uuid!).then(props.onChange)
    }, [shouldSaveInputCalculation])

    if (!calculator) {
        return <></>
    }

    return (
        <>
            <td className='align-top'>
                <div className='d-flex align-items-center flex-nowrap'>
                    {props.dragHandle}
                    <Button
                        disabled={props.disabled}
                        className='btn btn-plain shadow-none nt--2'
                        onClick={() => setShowCalculator(!showCalculator)}
                    >
                        <CaretRight
                            className='transition-all-300ms'
                            style={{ transform: showCalculator ? 'rotate(90deg)' : 'rotate(0deg)' }}
                            size={Utils.verySmallIconSize}
                        />
                    </Button>
                </div>
            </td>
            <td colSpan={5} className='align-middle'>
                <Button
                    disabled={props.disabled}
                    className='btn btn-plain'
                    onClick={() => setShowCalculator(!showCalculator)}
                >
                    {calculation && calculation.calculator?.name}
                </Button>
                {showCalculator && (
                    <CalculatorInstance
                        style={{ width: '500px', maxWidth: '100%' }}
                        calculatorId={calculator.uuid}
                        calculationId={calculation?.uuid}
                        onResult={(c) => {
                            calculatorService.updateInstance(c).then((newCalculation) => {
                                setCalculation((state) => ({ ...state, ...newCalculation, calculator: calculator }))
                                setShouldSaveInputCalculation(true)
                                setShowCalculator(false)
                            })
                        }}
                        cancelable={true}
                        onCancel={() => setShowCalculator(false)}
                    />
                )}
            </td>
        </>
    )
}
