import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { ApplicationProvider } from './context'
import './style.scss'
import { ServicesProvider } from './services'

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
    <React.StrictMode>
        <ApplicationProvider>
            <ServicesProvider>
                <App />
            </ServicesProvider>
        </ApplicationProvider>
    </React.StrictMode>,
)
