import Button from './Input/Button'
import { UIOptionActionType } from '../services/ui'
import { Selector } from './Input/Selector'
import { newThingsKeyValue } from '../hooks/useKeyboard'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { UAParser } from 'ua-parser-js'
import { Command, Plus } from '@phosphor-icons/react'
import { ApplicationContext } from '../context'
import Utils from '../services/utils'

export const CommandLauncher = (props: { className?: string }) => {
    const context = useContext(ApplicationContext)
    const navigate = useNavigate()
    const [shortcut, setShortcut] = useState<ReactNode>()

    useEffect(() => {
        const ua = new UAParser(navigator.userAgent)
        if (ua.getDevice().vendor === 'Apple') {
            setShortcut(
                <>
                    <Command size={Utils.verySmallIconSize} />K
                </>,
            )
        } else {
            setShortcut('Ctrl + K')
        }
    }, [])

    return (
        <div className={`d-flex align-items-center justify-content-center gap-2 ${props.className}`}>
            <Button
                onClick={() => {
                    context.dispatch({
                        type: UIOptionActionType.SetFootprintSearchConfig,
                        payload: { view: 'global', visible: true },
                    })
                }}
                className='btn btn-sm btn-plain border p-2 rounded-2 d-flex justify-content-between'
            >
                <span className='text-start'>Type a command or search</span>{' '}
                <span
                    hidden={!shortcut}
                    className='ms-2 px-1 bg-dark bg-opacity-10 text-muted rounded-1 text-alt-font text-nowrap'
                >
                    {shortcut}
                </span>
            </Button>
            <Selector
                placement='bottom'
                hideTextFilter={true}
                label={<Plus size={10} className='nt--3' />}
                placeholder='+'
                readonly={true}
                hotKey='c'
                header={<div className='text-muted border-bottom px-2'>Create:</div>}
                className='d-block px-1 pb-0 pt-1 text-height-1 rounded-1 shadow-none outline-0'
                tooltipClassName='small'
                options={newThingsKeyValue}
                onSelect={(nt) => navigate(nt.value)}
            />
        </div>
    )
}
