import { VariableBaseNode } from '../types'
import VariableService from './service'
import { ActionMap } from '../context'

export interface CpcCode extends VariableBaseNode {
    code: string
}

export enum CpcCodeActionType {
    Set = 'SetCpcCodes',
}

type CpcCodeActionPayload = {
    [CpcCodeActionType.Set]: Map<string, CpcCode>
}

export type CpcCodeActions = ActionMap<CpcCodeActionPayload>[keyof ActionMap<CpcCodeActionPayload>]

export const CpcCodeReducer = (state: Map<string, CpcCode>, action: CpcCodeActions): Map<string, CpcCode> => {
    switch (action.type) {
        case CpcCodeActionType.Set:
            return action.payload
        default:
            return state
    }
}

export default class CpcService extends VariableService {
    private basePath: string = '/cpc'

    public async getAll(): Promise<CpcCode[]> {
        if (this.context.stores.cpcCodes.size > 0) {
            return Array.from(this.context.stores.cpcCodes.values())
        }
        return this.httpService.get<CpcCode[]>(this.basePath).then((cpcCodes) => {
            const cpcCodeMap = new Map<string, CpcCode>()
            cpcCodes.forEach((cpcCode) => cpcCodeMap.set(cpcCode.code, cpcCode))
            this.context.dispatch({ type: CpcCodeActionType.Set, payload: cpcCodeMap })
            return cpcCodes
        })
    }
}
