import CompanyService from '../../services/company'
import { useContext, useEffect, useMemo, useState } from 'react'
import Button from '../Input/Button'
import { Plus } from '@phosphor-icons/react'
import Utils from '../../services/utils'
import { WorkspaceActionType } from '../../services/workspace'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'
import FlagService, { FlagType } from '../../services/flag'

export const UserCompanyList = (props: { extraClassName?: string; showCreateButton?: boolean }) => {
    const context = useContext(ApplicationContext)
    const { companyService, userService } = useContext(VariableServicesContext)
    const [isCreatingCompany, setIsCreatingCompany] = useState(false)

    const userHasMultipleCompanies = useMemo(
        () => (context.stores.user?.companies?.length || 0) > 1,
        [context.stores.user?.companies?.length],
    )

    useEffect(() => {
        if (isCreatingCompany && context.stores.workspace.newWorkspace?.uuid) {
            setIsCreatingCompany(false)
            userService.get().then((updatedUser) => {
                companyService.setCompany(context.stores.workspace.newWorkspace?.uuid, updatedUser?.companies)
                context.dispatch({ type: WorkspaceActionType.Create, payload: undefined })
                document.location.reload()
            })
        } else if (!context.stores.workspace.newWorkspace) {
            setIsCreatingCompany(false)
        }
    }, [context.stores.workspace.newWorkspace?.uuid])

    if (!userHasMultipleCompanies && !props.showCreateButton) return null

    return (
        <div
            className={`overflow-y-auto overflow-x-hidden small ${props.extraClassName || ''}`}
            style={{ maxHeight: '50vh' }}
        >
            {context.stores.user?.companies?.map((company) => (
                <Button
                    key={`uch-${company.uuid}`}
                    onClick={() => {
                        if (company.uuid !== CompanyService.companyId) {
                            companyService.setCompany(company.uuid)
                            document.location.reload()
                        }
                    }}
                    className={['dropdown-item', CompanyService.companyId === company?.uuid ? 'fw-bold' : ''].join(' ')}
                >
                    {company.name}
                </Button>
            ))}
            {FlagService.enabledFlags.has(FlagType.CanCreateAccounts) && props.showCreateButton && (
                <Button
                    className='dropdown-item border-top pt-3'
                    onClick={() => {
                        setIsCreatingCompany(true)
                        context.dispatch({ type: WorkspaceActionType.Create, payload: { name: '' } })
                    }}
                >
                    <Plus size={Utils.verySmallIconSize} className='nt--2' /> Add Company
                </Button>
            )}
        </div>
    )
}
