import Button from '../Input/Button'
import { ArrowElbowDownRight } from '@phosphor-icons/react'
import { ProcessingCalculator } from '../Input/Processing'
import { Input } from '../../services/input'
import { ReactNode, useContext } from 'react'
import { VariableServicesContext } from '../../services'
import { ModelSelectCell } from '../CategoryModel/ModelSelectCell'
import { AmountInput } from '../Input/Amount'
import { useInput } from '../../hooks/useInput'
import { useProcessingType } from '../../hooks/useProcessingType'

export const InputProcessing = (props: {
    input?: Input
    dragHandle: ReactNode
    disabled?: boolean
    onChange: (input: Input) => void
}) => {
    const { inputService, processingService } = useContext(VariableServicesContext)

    const input = useInput({ input: props.input })
    const processingType = useProcessingType({ processingType: input?.processingType })

    if (!input || !processingType) return null

    return (
        <>
            <td className='align-middle'>{props.dragHandle}</td>
            <td className='align-middle small text-nowrap' style={{ paddingLeft: '.75rem' }}>
                <Button
                    className='btn p-0 fs-inherit text-inherit d-flex align-items-center gap-2 bg-light-hover text-start rounded-1'
                    disabled={props.disabled}
                    onClick={() => {
                        if (!input) return
                        processingService.editProcessingType({ node: input, focus: 'name' })
                    }}
                >
                    {input?.processingFor && <ArrowElbowDownRight className='nt--4' />}
                    {processingType.name || input.name}
                </Button>
            </td>
            <td className='align-middle position-relative' style={{ maxWidth: '14rem' }}>
                <ModelSelectCell input={input} field='processing' />
                <ProcessingCalculator
                    node={input}
                    startOpen={true}
                    processingType={processingType}
                    disabled={props.disabled}
                    onChange={(pt) => {
                        if (!input) return
                        inputService
                            .updateInput({
                                uuid: input.uuid,
                                quantity: pt.energy?.quantity || 0,
                                processingType: pt,
                                unit: pt.energy?.unit,
                            })
                            .then((ui) => props.onChange({ ...input, ...ui, processingType: pt }))
                    }}
                />
            </td>
            <td />
            <td style={{ width: '5rem' }} className='align-middle position-relative'>
                <AmountInput
                    amount={processingType.energy}
                    disabled={true}
                    inputFieldProps={{ isNumber: true, required: true, extraClassName: 'bg-transparent' }}
                    prettyNumberProps={{ showZeroesToNumber: true }}
                    onChange={() => {}}
                />
            </td>
            <td />
        </>
    )
}
