import { DotsThree, DotsThreeVertical } from '@phosphor-icons/react'
import Tooltip, { TooltipProps } from './Tooltip'
import Utils from '../services/utils'
import { IconProps } from '@phosphor-icons/react/dist/lib/types'

export const EtcMenu = (
    props: TooltipProps & {
        extraClassName?: string
        verticalDots?: boolean
        iconProps?: IconProps
    },
) => {
    return (
        <Tooltip
            ariaLabel='Etc Menu'
            placement='bottom-end'
            interactive={true}
            {...props}
            tooltipContent={props.children}
            className={[
                props.className || 'btn bg-light-hover',
                props.verticalDots ? 'btn-xxs p-0' : 'btn-xs py-0',
                props.extraClassName || '',
            ].join(' ')}
        >
            {props.verticalDots ? (
                <DotsThreeVertical size={Utils.largeIconSize} className=' ' {...props.iconProps} />
            ) : (
                <DotsThree size={Utils.largeIconSize} className=' ' {...props.iconProps} />
            )}
        </Tooltip>
    )
}
