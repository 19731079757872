import { PartType } from '../../services/part'
import UnitService from '../../services/unit'
import ProductService, { Product, ProductFootprintType } from '../../services/product'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { Input } from '../../services/input'
import { Selector } from '../Input/Selector'
import { PartOptions } from '../Part/PartOptions'
import Button from '../Input/Button'
import Utils from '../../services/utils'
import { useFootprintSelector } from '../../hooks/useFootprintSelector'
import Tooltip from '../Tooltip'
import { Nut, PlusCircle, Sparkle, X } from '@phosphor-icons/react'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'
import { useInputType } from '../../hooks/useInputType'
import { InventoryIcon } from '../Icons/InventoryIcon'
import { useProduct } from '../../hooks/useProduct'
import { ND } from '../../types'

export type ElementSelectorType = 'footprint' | 'ai' | 'live-footprint'

export interface ElementSelectorOption {
    name: ReactNode
    description: ReactNode
    value: ElementSelectorType
}

export const ElementSelector = (props: {
    product?: Product
    input: Input
    partIds?: string[]
    disabled?: boolean
    onChange: (input?: Partial<Input>, showPartDetails?: boolean) => void
    onClear: () => void
}) => {
    const context = useContext(ApplicationContext)
    const { productService, partService, analyticsService, aiService, categoryModelService } =
        useContext(VariableServicesContext)
    const [fetchingAi, setFetchingAi] = useState<boolean>(false)
    const [aiOptions, setAiOptions] = useState<Product[]>([])
    const [aiNoResults, setAiNoResults] = useState<boolean>(false)
    const [shouldBeOpen, setShouldBeOpen] = useState<boolean>(false)
    const [inputType, setInputType] = useState<ElementSelectorType>()
    const { createTransportInstanceForInput } = useInputType({ instanceId: `use-input-type-${props.input.uuid}` || '' })

    const sourceProduct = useProduct({ product: props.input.sourceProduct })
    const product = useProduct({ product: props.input.product })

    const showFootprintModal = useFootprintSelector({
        searchConfig: {
            instanceId: `input-${props.input.uuid}`,
            title: `Select ${ProductService.elementTitle()}${props.input.name ? ` for "${props.input.name}"` : ''}`,
            targetType: ND.Input,
            targetNode: props.input,
            queryOptions: { unitType: props.input.unit?.type },
            view: 'inventory',
            visible: true,
        },
        onSelectProduct: async (footprint) => {
            if (footprint && footprint?.uuid !== sourceProduct?.uuid) {
                if (footprint?.uuid === product?.uuid) {
                    Utils.warningToast(`Recursion error: ${product?.name} cannot be a line item of itself`, {
                        autoClose: false,
                        closeButton: true,
                        position: 'bottom-center',
                    })
                } else {
                    let _efSourceProduct = await productService.createProductFromFactor(footprint)
                    if (!_efSourceProduct?.uuid) {
                        _efSourceProduct = footprint
                    }
                    props.onChange({
                        sourceProduct: _efSourceProduct,
                        unit: UnitService.getNewUnit(props.input?.unit, _efSourceProduct),
                    })
                }
            }
        },
        onSelectPart: async (part) => {
            if (part?.uuid !== props.input.part?.uuid) {
                if (part?.uuid) part = await partService.getPart(part.uuid)
                let sp = part?.sourceProducts?.[0]
                if (part?.type === PartType.MIX) {
                    sp = undefined
                } else if (sp?.uuid) {
                    sp = await productService.getById(sp?.uuid)
                }
                props.onChange({
                    part: part,
                    sourceProduct: sp,
                    unit: UnitService.getNewUnit(props.input?.unit, sp || part),
                })
            }
        },
        onSelectTransport: async (transportType) => {
            if (props.input.transportInstance?.uuid) return
            const transportInstance = await createTransportInstanceForInput(props.input, transportType)
            if (transportInstance?.uuid !== props.input.transportInstance?.uuid) {
                props.onChange({ transportInstance })
            }
        },
    })

    useEffect(() => {
        if (sourceProduct?.uuid || props.input.part?.uuid) {
            setInputType('footprint')
        }
    }, [props.input.transportInstance, props.input.useStageType, sourceProduct, props.input.part])

    useEffect(() => {
        if (
            context.stores.categoryModel.deletedInstance?.node?.uuid !== undefined &&
            context.stores.categoryModel.deletedInstance?.node?.uuid === props.input?.uuid
        ) {
            clearElement()
        }
    }, [context.stores.categoryModel.deletedInstance])

    useEffect(() => {
        setAiNoResults(false)
        if (inputType === 'ai') {
            setAiOptions([])
            setShouldBeOpen(false)
            setInputType(undefined)
            analyticsService.track('Changed Input name after AI suggestions')
        }
    }, [props.input.name])

    const elementSelectorOptions: ElementSelectorOption[] = [
        {
            name: (
                <>
                    <InventoryIcon /> Search {ProductService.elementTitle(true)}
                </>
            ),
            description: (
                <span>Re-use one of your {ProductService.elementTitle(true)}, or search the global database</span>
            ),
            value: 'footprint',
        },
        {
            name: (
                <>
                    <PlusCircle /> Create new {ProductService.elementTitle()} for{' '}
                    {props.input.name ? `"${props.input.name}"` : 'this Input'}
                </>
            ),
            description: (
                <span>
                    Create a new {ProductService.elementTitle()} for{' '}
                    <span hidden={!props.input.name} className='bg-primary bg-opacity-10 p-1 fw-bold rounded-2'>
                        {props.input.name}
                    </span>
                    {!props.input.name && 'the currently selected Input'} and continue modeling it
                </span>
            ),
            value: 'live-footprint',
        },
    ]

    const clearElement = () => {
        props.onClear()
        setInputType(undefined)
        analyticsService.track('Clicked Clear Element')
    }

    const typeSelector = (
        <div className='fs-base'>
            {/*<Button*/}
            {/*    className='btn btn-sm border border-dashed bg-light-hover'*/}
            {/*    onClick={() => {*/}
            {/*        showFootprintModal()*/}
            {/*        setInputType(undefined)*/}
            {/*    }}*/}
            {/*>*/}
            {/*    Add {ProductService.elementTitle()}*/}
            {/*</Button>*/}
            <Selector
                ariaLabel='Element'
                disabled={props.disabled}
                placeholder={`Assign ${ProductService.elementTitle()}`}
                options={elementSelectorOptions}
                readonly={true}
                hideTextFilter={true}
                placement='bottom-start'
                openOnRender={shouldBeOpen}
                onSelect={async (selectedItem) => {
                    setShouldBeOpen(false)
                    const selectedValue = selectedItem.value as ElementSelectorType
                    if (selectedValue === 'footprint') {
                        showFootprintModal()
                        setInputType(undefined)
                    } else if (selectedValue === 'live-footprint') {
                        let newSourceProduct = await productService.createProduct({ name: props.input.name || '' })
                        if (!newSourceProduct?.uuid) {
                            Utils.errorToast('Error creating new Footprint')
                        }
                        analyticsService.trackProductFootprintCreated()
                        newSourceProduct = await productService.update({
                            uuid: newSourceProduct.uuid,
                            type: ProductFootprintType.LIVE,
                        })
                        await productService.createInput(newSourceProduct.uuid!)
                        props.onChange(
                            {
                                unit: UnitService.getNewUnit(props.input?.unit, newSourceProduct),
                                sourceProduct: newSourceProduct,
                                supplier: newSourceProduct?.productOf || undefined,
                            },
                            true,
                        )
                        setInputType('footprint')
                    } else {
                        setInputType(selectedValue)
                    }
                }}
            />
            <Tooltip
                hidden={aiNoResults}
                trigger='hover'
                disabled={fetchingAi || !props.input.name || props.disabled}
                showWhenDisabled={true}
                tooltipClassName='small'
                tooltipContent={
                    !props.input.name
                        ? 'Enter a name to get an AI recommendation'
                        : 'Ask our AI to recommend a Footprint'
                }
                onClick={async () => {
                    if (props.input.name) {
                        setFetchingAi(true)
                        const efs = await aiService.getEmissionFactorsForInput(props.input)
                        setFetchingAi(false)
                        if (efs?.length) {
                            const maxLength = 200
                            setAiOptions(
                                efs.map((ef) => {
                                    const _description =
                                        (ef.description?.length || 0) > maxLength
                                            ? `${ef.description?.substring(0, maxLength - 3)}...`
                                            : ef.description
                                    return { ...ef, description: _description }
                                }),
                            )
                            setShouldBeOpen(true)
                            setInputType('ai')
                        } else {
                            setAiNoResults(true)
                            Utils.warningToast(`No recommendations were found.`)
                        }
                        analyticsService.track('Clicked AI Footprint Recommendation', {
                            searchText: props.input.name,
                            resultCount: efs.length || 0,
                        })
                    }
                }}
                className='btn btn-xs ms-1'
            >
                <Sparkle color={!props.input.name ? Utils.mutedTextColor : Utils.warningColor} />
                {fetchingAi && <span className='ms-1 mt--1 nt-2 spinner-border spinner-border-sm' />}
            </Tooltip>
        </div>
    )

    if (inputType === 'ai') {
        return (
            <span className='d-flex align-items-center'>
                <Selector
                    placeholder='Select a Footprint'
                    options={aiOptions}
                    placement='bottom-start'
                    disabled={props.disabled}
                    openOnRender={shouldBeOpen}
                    tooltipStyle={{ maxWidth: '480px' }}
                    onEscape={() => setInputType(undefined)}
                    onSelect={async (sp) => {
                        const _sp = sp as Product
                        let _efSourceProduct = await productService.createProductFromFactor(_sp)
                        if (!_efSourceProduct?.uuid && !ProductService.isEmissionFactor(_sp)) {
                            _efSourceProduct = _sp
                        }
                        await partService.fetchInventory()
                        props.onChange({
                            sourceProduct: _efSourceProduct,
                            unit: UnitService.getNewUnit(props.input?.unit, _efSourceProduct),
                        })
                        analyticsService.track('Selected AI Footprint Recommendation')
                    }}
                />
                <Button
                    onClick={() => {
                        setInputType(undefined)
                        analyticsService.track('Canceled AI Footprint Recommendation')
                    }}
                    className='btn btn-xs'
                >
                    <X color={Utils.bodyColor} />
                </Button>
            </span>
        )
    }

    if (inputType === 'footprint') {
        if (sourceProduct?.categoryModel?.uuid) {
            return (
                <Button
                    disabled={props.disabled}
                    onClick={() => {
                        if (!sourceProduct?.categoryModel) return
                        categoryModelService.setCategoryModelConfig(sourceProduct?.categoryModel, props.input)
                    }}
                    className={[
                        'input-element text-start',
                        context.stores.categoryModel.model?.uuid === sourceProduct?.categoryModel?.uuid ? 'active' : '',
                    ].join(' ')}
                >
                    <Nut /> {sourceProduct?.name}
                </Button>
            )
        }
        return (
            <PartOptions
                input={props.input}
                disabled={props.disabled}
                onChange={props.onChange}
                onClear={clearElement}
            />
        )
    }

    return typeSelector
}
