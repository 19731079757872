import { useContext, useMemo } from 'react'
import { ApplicationContext } from '../context'
import CompanyService, { Company } from '../services/company'

export const useCompany = (props: { company?: Company | null; companyId?: string; condition?: boolean }) => {
    const context = useContext(ApplicationContext)
    return useMemo(() => {
        if (props.condition === false) return undefined
        return CompanyService.byId.get(props.companyId || props.company?.uuid || '') || props.company || undefined
    }, [props.companyId, props.company?.uuid, props.condition, context.stores.ui?.companiesUpdated])
}
