import VariableService from './service'
import { Product, ProductFootprintType } from './product'
import { UIOptionActionType } from './ui'
import TaxonomyService, { Taxonomy } from './taxonomy'
import FlagService, { FlagType } from './flag'
import { KeyValuePair, VariableBaseNode, VariableNode } from '../types'
import { FileData } from './file'
import { Unit } from './unit'

export enum ElectricityMethod {
    LOCATION = 'location',
    MARKET = 'market',
    CUSTOM = 'custom',
}

export interface ElectricityFactors {
    location?: Product[]
    market?: Product[]
    custom?: Product[]
}

export interface RenewableProduction extends VariableBaseNode {
    node?: VariableNode
    quantity?: number
    unit?: Unit
    documents?: FileData[]
}

export interface ElectricityCertificate extends VariableBaseNode {
    node?: VariableNode
    quantity?: number
    unit?: Unit
    documents?: FileData[]
}

export default class ElectricityService extends VariableService {
    private basePath: string = '/electricity'
    public static webTitle = (): string => 'Electricity'

    public static isElectricity(product?: Product | null, taxonomy?: Taxonomy | null): boolean {
        if (!FlagService.enabledFlags.has(FlagType.EnableElectricity)) return false
        if (product?.type === ProductFootprintType.ELECTRICITY) return true
        if (!taxonomy?.path) taxonomy = product?.taxonomy
        if (!taxonomy?.path) return false
        return taxonomy?.path?.startsWith(TaxonomyService.electricityPath)
    }

    public static getEmissionFactorMethods(): KeyValuePair<ElectricityMethod>[] {
        return [
            { name: 'Location-based', value: ElectricityMethod.LOCATION },
            { name: 'Market-based', value: ElectricityMethod.MARKET },
            { name: 'Custom', value: ElectricityMethod.CUSTOM },
        ]
    }

    public showElectricityCreator = (show: boolean): void => {
        this.context.dispatch({ type: UIOptionActionType.ShowElectricityCreator, payload: show })
    }

    public async getElectricity(id?: string): Promise<ElectricityFactors> {
        return this.httpService.get<ElectricityFactors>(`${this.basePath}/${id}`)
    }

    public async getElectricityForFactor(
        factorId: string,
        locationId?: string,
        geoLocationId?: string,
    ): Promise<Product> {
        return this.httpService.post<Product>(`${this.basePath}/factor/${factorId}`, {
            body: JSON.stringify({ locationId, geoLocationId }),
        })
    }

    public async getCertificate(id: string): Promise<ElectricityCertificate> {
        return this.httpService.get<ElectricityCertificate>(`${this.basePath}/certificate/${id}`)
    }

    public async saveCertificate(
        electricityCertificate?: Partial<ElectricityCertificate>,
    ): Promise<ElectricityCertificate> {
        return this.httpService.put<ElectricityCertificate>(`${this.basePath}/certificate`, {
            body: JSON.stringify({ electricityCertificate }),
        })
    }

    public async getRenewableProduction(id: string): Promise<RenewableProduction> {
        return this.httpService.get<RenewableProduction>(`${this.basePath}/production/${id}`)
    }

    public async saveRenewableProduction(
        renewableProduction?: Partial<RenewableProduction>,
    ): Promise<RenewableProduction> {
        return this.httpService.put<RenewableProduction>(`${this.basePath}/production`, {
            body: JSON.stringify({ renewableProduction }),
        })
    }
}
