import DromoReactUploader, { IDeveloperField } from 'dromo-uploader-react'
import { MutableRefObject, ReactNode, useContext, useMemo } from 'react'
import { VariableServicesContext } from '../../services'
import DataImportService, { DataImportResponse, DataImportType } from '../../services/dataImport'
import { DownloadSimple } from '@phosphor-icons/react'
import { ApplicationContext } from '../../context'
import CompanyService from '../../services/company'

export const ProductImport = (props: {
    className?: string
    children?: ReactNode
    showButton?: boolean
    importType: DataImportType
    dromoRef?: MutableRefObject<any>
    onDone: (result: DataImportResponse) => void
    onCancel?: () => void
    onError?: (e: any) => void
}) => {
    const context = useContext(ApplicationContext)
    const { productService, dataImportService, analyticsService } = useContext(VariableServicesContext)

    const productOfList = useMemo(() => {
        return Array.from(CompanyService.suppliersById.values()).map((supplier) => ({
            value: supplier.uuid!,
            label: supplier.name,
        }))
    }, [context.stores.ui?.companiesUpdated])

    const fields: IDeveloperField[] = useMemo(() => {
        const _fields: IDeveloperField[] = [
            { label: 'Name', key: 'name', alternateMatches: ['title'], validators: [{ validate: 'required' }] },
            { label: 'SKU', key: 'sku', alternateMatches: ['Part Number', 'part', 'number', 'id'] },
            { label: 'Notes', key: 'notes' },
            dataImportService.totalCo2eField('kgCO2e'),
            dataImportService.unitField('Unit', 'unitCode'),
        ]
        if (props.importType === 'materials') {
            _fields.push({
                label: 'Supplier',
                key: 'supplier',
                type: ['select', { allowCustom: true }],
                selectOptions: productOfList,
            })
            _fields.push({ label: 'Supplier ID', key: 'supplierId' })
        }
        return _fields
    }, [productOfList, props.importType])

    const button = useMemo(() => {
        if (props.children) return props.children
        if (props.showButton === false) return null
        return (
            <>
                <DownloadSimple /> Import
            </>
        )
    }, [props.children, props.showButton])

    return (
        <DromoReactUploader
            schemaName='Import'
            schemaId='model-import'
            ref={props.dromoRef}
            className={props.className || 'btn btn-primary'}
            licenseKey={DataImportService.dromoLicenseKey}
            stepHooks={[
                {
                    // type: EStepHook.UPLOAD_STEP,
                    type: 'UPLOAD_STEP',
                    callback: (_, data) => {
                        analyticsService.track('Product Import: Uploaded CSV', {
                            rows: (data as any)?.dataPreview?.length,
                        })
                    },
                },
                {
                    // type: EStepHook.REVIEW_STEP,
                    type: 'REVIEW_STEP',
                    callback: (_, data) => {
                        analyticsService.track('Product Import: Review Data', {
                            rows: (data as any)?.dataPreview?.length,
                        })
                    },
                },
            ]}
            fields={fields}
            columnHooks={[dataImportService.numberColumnHook('kgCO2e'), dataImportService.unitColumnHook('unitCode')]}
            settings={dataImportService.dromoSettings({
                importIdentifier: 'Footprints',
                templateDownloadFilename: 'Product_Import.csv',
            })}
            user={dataImportService.dromoUser()}
            onResults={(response) => {
                // console.log(response, metadata)
                analyticsService.track('Product Import: Submitted Data', {
                    rows: response.length,
                })
                productService
                    .importProducts(response, props.importType)
                    .then((result) => {
                        analyticsService.track('Product Import: Success', {
                            nodesCreated: result?.imported,
                            nodeTypes: result?.dataImport?.types,
                        })
                        props.onDone(result)
                    })
                    .catch((errorMessage) => {
                        analyticsService.track('Product Import: Error', {
                            errorMessage: errorMessage,
                        })
                        props.onError?.(errorMessage)
                    })
            }}
            onCancel={props.onCancel}
        >
            {button}
        </DromoReactUploader>
    )
}
