import {
    CSSProperties,
    KeyboardEvent,
    MouseEvent,
    ReactNode,
    RefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import Tooltip from '../Tooltip'
import InputField from './InputField'
import Button from './Button'
import * as PopperJS from '@popperjs/core'
import UiService from '../../services/ui'
import Utils from '../../services/utils'

export type SelectorContainerProps = {
    containerClassName?: string
    containerExtraClassName?: string
    onClear?: () => void
}

export type SelectorProps = {
    id?: string
    ariaLabel?: string
    passedRef?: RefObject<any>
    placeholder?: string
    filterPlaceholder?: string
    prefixValueWithPlaceholder?: boolean
    header?: ReactNode
    footer?: ReactNode
    label?: ReactNode
    labelPrefix?: ReactNode
    labelKey?: string
    hotKey?: string
    options?: any[]
    option?: any
    menuOpen?: boolean
    openOnRender?: boolean
    contentOnly?: boolean
    readonly?: boolean
    noValueOption?: ReactNode
    displayNoValue?: boolean
    className?: string
    buttonClassName?: string
    tooltipClassName?: string
    extraClassName?: string
    hoverClassName?: string
    activeClassName?: string
    inactiveClassName?: string
    itemClassName?: string
    itemExtraClassName?: string
    tooltipStyle?: CSSProperties
    style?: CSSProperties
    placement?: PopperJS.Placement
    positioning?: PopperJS.PositioningStrategy
    offset?: [number, number]
    loading?: boolean
    hidden?: boolean
    required?: boolean
    highlightRequired?: boolean
    disabled?: boolean
    hideTextFilter?: boolean
    beforeInputField?: ReactNode
    createPrefix?: ReactNode
    searchText?: string
    filterBy?: (o: any, searchText?: string) => boolean
    renderValueLabel?: (o: any) => ReactNode
    renderItem?: (o: any, item: ReactNode, id: string, idx: number, selectorProps: SelectorProps) => ReactNode
    renderItemValue?: (o: any, currentOpt: any, selectorProps: SelectorProps) => ReactNode
    onSearchText?: (searchText?: string) => void
    onSelect: (newValue?: any) => void
    onCreate?: (newOpt?: any) => void
    onClick?: (e: any) => void
    onEscape?: () => void
    onVisibilityChange?: (isVisible: boolean) => void
    debug?: boolean
}

export const Selector = (props: SelectorProps) => {
    const [doSearch, setDoSearch] = useState<boolean>(false)
    const [showFilter] = useState<boolean>(props.hideTextFilter !== true)
    const [isOpen, setIsOpen] = useState<boolean>(
        props.menuOpen === true || props.openOnRender === true || props.contentOnly === true,
    )
    const [activeIndex, setActiveIndex] = useState<number>(-1)
    const [searchText, setSearchText] = useState<string | undefined>(props.searchText)
    const [rnd, setRnd] = useState<number>(0)
    const [opts, setOpts] = useState<any[] | undefined>(props.options)
    const [opt, setOpt] = useState<any | undefined>(props.option)
    const [tooltipId] = useState<string>(`selector-${Math.random()}`)
    const menu = useRef<any>()
    const _ref = useRef<any>()
    const inputRef = props.passedRef || _ref

    const optValueLabel = useMemo(() => {
        if (!opt) return undefined
        let labelValue = opt?.[props.labelKey || 'name']
        if (props.renderValueLabel) {
            labelValue = props.renderValueLabel(opt)
        }
        return labelValue
    }, [opt, props.labelKey, props.renderValueLabel])

    useEffect(() => {
        setRnd(Math.random())
        if (props.hotKey) {
            UiService.hotKeys.set(props.hotKey, () => {
                setIsOpen(true)
                setTimeout(() => document.getElementById(tooltipId)?.focus(), 100)
            })
        }
    }, [])

    useEffect(() => {
        if (props.menuOpen !== undefined) {
            setIsOpen(props.menuOpen)
            if (props.menuOpen) {
                setTimeout(() => {
                    inputRef.current && inputRef.current.focus()
                }, 10)
            }
        }
    }, [props.menuOpen])

    useEffect(() => {
        setOptions()
        menu.current && (menu.current.scrollTop = 0)
    }, [props.options])

    useEffect(() => {
        let foundOption = undefined
        if (typeof props.option === 'string' || typeof props.option === 'number' || typeof props.option === 'boolean') {
            foundOption = props.options?.find((_o) => _o.value === props.option || _o.uuid === props.option)
        }
        if (!props.readonly) {
            if (Utils.isNull(props.option) && props.noValueOption) {
                foundOption = { ...noValueOption }
            }
            setOpt(foundOption || props.option)
        }
    }, [props.option, props.options])

    useEffect(() => {
        if (doSearch && props.onSearchText) {
            setDoSearch(false)
            props.onSearchText(searchText)
        }
    }, [doSearch])

    useEffect(() => {
        if (!isOpen) {
            return
        }
        const current = menu.current?.querySelector('.dropdown-item.selected') as HTMLElement
        const highlighted = document.getElementById(`selector-item-${rnd}-${activeIndex}`)
        const elm = highlighted || current
        const _idx = elm?.id?.replace(`selector-item-${rnd}-`, '')
        const container = elm?.parentNode as HTMLElement
        if (container && elm && container.offsetHeight > elm.offsetHeight * 5) {
            elm.scrollIntoView({ block: 'nearest' })
        }
        if (_idx && !isNaN(parseInt(_idx))) {
            setActiveIndex(parseInt(_idx))
        }
    }, [activeIndex, isOpen])

    useEffect(() => {
        setActiveIndex(-1)
        setOptions()
    }, [searchText])

    const filterBy = useCallback(
        (o: any, st?: string) => {
            if (props.filterBy) {
                return props.filterBy(o, st)
            }
            if (st) {
                const searchData: string[] = []
                if (typeof o?.name === 'string') {
                    searchData.push(o.name)
                }
                if (typeof o?.text === 'string') {
                    searchData.push(o.text)
                }
                if (typeof o?.value === 'string') {
                    searchData.push(o.value)
                }
                if (typeof o?.code === 'string') {
                    searchData.push(o.code)
                }
                if (typeof o?.description === 'string') {
                    searchData.push(o.description)
                }
                return Utils.filterItem(searchData, st)
            }
            return true
        },
        [props.filterBy],
    )

    const noValueOption = useMemo(
        () => ({
            name: props.noValueOption,
            isNoValue: true,
            uuid: null,
            value: null,
        }),
        [],
    )

    const setOptions = useCallback(() => {
        let _opts = [...(props.options || [])]
        if (props.noValueOption) {
            _opts.unshift({ ...noValueOption })
        }
        _opts = _opts?.filter((o) => filterBy(o, searchText)) || []
        if (props.onCreate && searchText) {
            _opts.push({ isNew: true, name: searchText })
        }
        setOpts(_opts)
    }, [props.options, searchText, filterBy])

    const _setOpt = useCallback(
        (_opt?: any) => {
            if (_opt?.isNew && props.onCreate) {
                props.onCreate(_opt)
            } else {
                props.onSelect(_opt)
                if (!props.readonly) {
                    setOpt(_opt)
                }
            }
            setSearchText('')
            setIsOpen(false)
        },
        [props.onCreate, props.onSelect, props.readonly],
    )

    const onEnter = useCallback(() => {
        if (opts?.[activeIndex]?.disabled) {
            return
        }
        if (opts?.[activeIndex] !== undefined) {
            _setOpt(opts[activeIndex])
        } else if (opts?.length === 1) {
            _setOpt(opts[0])
        }
    }, [activeIndex, opts, _setOpt])

    const renderItemValue = useCallback(
        (o: any) => {
            if (props.renderItemValue) {
                return props.renderItemValue(o, opt, props)
            }
            if (!o.description) {
                return (
                    <>
                        {o.icon} {o.name}
                    </>
                )
            }
            return (
                <>
                    <strong>
                        {o.icon} {o.name}
                    </strong>
                    <div className='small'>{o.description}</div>
                </>
            )
        },
        [props.renderItemValue, opt],
    )

    const renderItem = useCallback(
        (o: any, idx: number) => {
            const id = `selector-item-${rnd}-${idx}`
            const addPrefix = o.isNew ? <>{props.createPrefix || <strong className='me-1'>Add:</strong>}</> : ''
            let isSelected = false
            if (opt?.uuid !== undefined && (opt.uuid === o.uuid || opt === o.uuid)) {
                isSelected = true
            } else if (opt?.value !== undefined && (opt?.value === o.value || opt === o.value)) {
                isSelected = true
            } else if (o?.value === null && !opt?.value && !opt?.uuid) {
                isSelected = props.displayNoValue !== true
            }
            const btn = (
                <Button
                    key={id}
                    id={id}
                    ariaLabel={`Selector Option: ${o.text || o.value || o.code || o.name?.toString()}`}
                    disabled={o.disabled}
                    className={[
                        props.itemClassName || 'dropdown-item text-wrap px-2 text-start',
                        props.itemExtraClassName || '',
                        activeIndex === idx ? 'active' : '',
                        isSelected ? 'selected' : '',
                    ].join(' ')}
                    onClick={() => {
                        setActiveIndex(idx)
                        _setOpt(o)
                    }}
                >
                    {addPrefix}
                    {renderItemValue(o)}
                </Button>
            )
            if (props.renderItem) {
                return props.renderItem(o, btn, id, idx, props)
            }
            return btn
        },
        [
            props.renderItem,
            props.createPrefix,
            props.displayNoValue,
            props.itemClassName,
            props.itemExtraClassName,
            rnd,
            activeIndex,
            renderItemValue,
            _setOpt,
            opt,
        ],
    )

    const handleKeyNavigation = useCallback(
        (e: KeyboardEvent<any>) => {
            switch (e.key) {
                case 'ArrowUp':
                    e.preventDefault()
                    e.stopPropagation()
                    setIsOpen(true)
                    if (activeIndex === 0) {
                        setActiveIndex((opts?.length || 0) - 1)
                    } else {
                        setActiveIndex(Math.max(activeIndex - 1, -1))
                    }
                    break
                case 'ArrowDown':
                    e.preventDefault()
                    e.stopPropagation()
                    setIsOpen(true)
                    if (activeIndex === (opts?.length || 0) - 1) {
                        setActiveIndex(0)
                    } else {
                        setActiveIndex(Math.min(activeIndex + 1, (opts?.length || 0) - 1))
                    }
                    break
                case 'Escape':
                    setActiveIndex(-1)
                    setIsOpen(false)
                    props.onEscape && props.onEscape()
                    break
                case 'Enter':
                    if (e.currentTarget?.id === tooltipId) {
                        e.preventDefault()
                        e.stopPropagation()
                        if (isOpen) {
                            onEnter()
                        }
                    }
                    break
            }
        },
        [activeIndex, props.onEscape, onEnter, isOpen, tooltipId, opts?.length],
    )

    const content = useMemo(
        () => (
            <div
                id={props.id || `option-menu-${rnd}`}
                role='menu'
                ref={menu}
                tabIndex={0}
                onKeyDown={handleKeyNavigation}
                className={props.tooltipClassName || 'text-base-font'}
                style={{ maxWidth: '100%', ...props.tooltipStyle }}
            >
                {isOpen && showFilter && (
                    <span className='position-relative d-flex align-items-center'>
                        {props.beforeInputField}
                        <InputField
                            ariaLabel={`Selector Filter: ${props.ariaLabel || ''}`}
                            passedRef={inputRef}
                            focusOnRender={!Utils.isTouchScreen()}
                            placeholder={props.filterPlaceholder || props.placeholder || 'Type to filter'}
                            onChange={setSearchText}
                            defaultValue={searchText}
                            // why 'new-password'?
                            // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
                            autoComplete='new-password'
                            className='variable-form-control border-0 w-100'
                            onKeyPress={() => setActiveIndex(-1)}
                            onDebounced={() => setDoSearch(true)}
                            onEscapeKey={() => setSearchText('')}
                            onEnter={onEnter}
                        />
                        {props.loading && (
                            <span className='position-absolute opacity-50' style={{ top: '.45rem', right: '.5rem' }}>
                                <span className='spinner-border spinner-border-sm' />
                            </span>
                        )}
                    </span>
                )}
                {props.header}
                <div
                    id={`selector-menu-${rnd}`}
                    className='position-relative overflow-auto'
                    style={{ maxHeight: '25vh' }}
                >
                    {opts?.map(renderItem)}
                </div>
                {props.footer}
            </div>
        ),
        [
            rnd,
            menu,
            handleKeyNavigation,
            isOpen,
            showFilter,
            inputRef,
            onEnter,
            setSearchText,
            searchText,
            renderItem,
            opts,
            props.id,
            props.tooltipClassName,
            props.tooltipStyle,
            props.beforeInputField,
            props.filterPlaceholder,
            props.placeholder,
            props.loading,
            props.header,
            props.footer,
        ],
    )

    const label = useMemo(() => {
        return (
            <span className={props.buttonClassName || `nt-1`}>
                {opt?.icon} {optValueLabel && props.labelPrefix}
                {optValueLabel && props.prefixValueWithPlaceholder && `${props.placeholder}: `}
                {props.displayNoValue && opt?.isNoValue && <>{props.noValueOption}</>}
                {(!props.displayNoValue || !opt?.isNoValue) &&
                    (props.label || optValueLabel || props.placeholder || 'Select')}
            </span>
        )
    }, [
        props.buttonClassName,
        props.label,
        props.labelPrefix,
        props.placeholder,
        props.prefixValueWithPlaceholder,
        props.displayNoValue,
        props.noValueOption,
        props.displayNoValue,
        optValueLabel,
        opt,
    ])

    const className = useMemo(
        () =>
            [
                props.className || `d-inline-block px-2 py-1 small border rounded-1 ${props.extraClassName}`,
                props.required && props.highlightRequired && !opt && 'required',
                props.hoverClassName || 'bg-primary-hover',
                isOpen ? props.activeClassName || 'bg-primary bg-opacity-10 open' : props.inactiveClassName || '',
                props.disabled && 'cursor-default not-clickable',
                !props.className &&
                    (optValueLabel || props.disabled ? 'border-transparent text-primary' : 'border-dashed'),
            ].join(' '),
        [
            opt,
            isOpen,
            optValueLabel,
            props.className,
            props.extraClassName,
            props.required,
            props.highlightRequired,
            props.hoverClassName,
            props.activeClassName,
            props.inactiveClassName,
            props.disabled,
        ],
    )

    const onVisibilityChange = useCallback(
        (isVisible: boolean) => {
            setIsOpen(isVisible)
            if (!isVisible && props.readonly) {
                setActiveIndex(-1)
            }
            props.onVisibilityChange?.(isVisible)
        },
        [props.onVisibilityChange, props.readonly],
    )

    const onClick = useCallback(
        (e: MouseEvent<any>) => {
            setIsOpen(!isOpen)
            props.onClick?.(e)
        },
        [isOpen, props.onClick],
    )

    if (props.contentOnly) {
        return content
    }

    return (
        <Tooltip
            id={tooltipId}
            ariaLabel={`Selector: ${props.ariaLabel || ''}`}
            interactive={true}
            type='widget'
            className={className}
            disabled={props.disabled}
            hidden={props.hidden}
            style={props.style}
            placement={props.placement || 'right-start'}
            positioning={props.positioning || 'fixed'}
            offset={props.offset || [0, 0]}
            visible={isOpen}
            onClick={onClick}
            onKeyDown={handleKeyNavigation}
            onVisibleChange={onVisibilityChange}
            tooltipClassName='bg-white bg-opacity-90 blur-background small'
            tooltipContent={content}
        >
            {label}
        </Tooltip>
    )
}
