import VariableService from './service'

export default class SupportService extends VariableService {
    private static basePath = '/support'

    public async sendMessage(message?: string): Promise<void> {
        return this.httpService.post(SupportService.basePath, {
            body: JSON.stringify({ message }),
        })
    }

    public async requestFootprint(requestFootprint?: {}): Promise<void> {
        return this.httpService.post(SupportService.basePath, {
            body: JSON.stringify({ requestFootprint }),
        })
    }

    public async joinWaitlist(joinWaitlist?: {}): Promise<void> {
        return this.httpService.post(SupportService.basePath, {
            body: JSON.stringify({ joinWaitlist }),
        })
    }
}
