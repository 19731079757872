import InputField from './InputField'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { StandardAttributes, VariableNode } from '../../types'
import { VariableServicesContext } from '../../services'

export const TitleEditor = (
    props: StandardAttributes & {
        node?: VariableNode
        property?: string
        setPageTitle?: boolean
        titleCategory?: string
        inputExtraClassName?: string
        inputPlaceholder?: string
        focusOnRender?: boolean
        onChanged?: (newValue: string) => void
    },
) => {
    const { uiService, userService } = useContext(VariableServicesContext)
    const [nameValue, setNameValue] = useState<string>()
    const property = useMemo(() => props.property || 'name', [props.property])
    const ref = useRef<HTMLInputElement>()

    useEffect(() => {
        // @ts-ignore
        const propValue = props.node?.[property] || ''
        if (propValue !== nameValue) {
            setNameValue(propValue)
            setInputValue(propValue)
        }
    }, [props.node, property])

    useEffect(() => {
        if (nameValue && props.setPageTitle) uiService.setTitle(nameValue, props.titleCategory)
    }, [nameValue, props.setPageTitle, props.titleCategory])

    const setInputValue = useCallback((value: string) => ref.current && (ref.current.value = value), [ref.current])

    const onChanged = useCallback(
        (newValue: string) => {
            if (newValue === nameValue) return
            setNameValue(newValue)
            props.onChanged?.(newValue)
        },
        [nameValue, props.onChanged],
    )

    if (props.hidden || nameValue === undefined) return null

    return (
        <InputField
            passedRef={ref}
            ariaLabel={props.ariaLabel || 'Name'}
            className={[
                props.className || 'variable-form-control-minimal',
                !props.disabled ? 'bg-light-hover' : '',
                props.inputExtraClassName,
            ].join(' ')}
            placeholder={props.inputPlaceholder || 'Name'}
            defaultValue={nameValue}
            disabled={props.disabled || userService.isReadonly()}
            focusOnRender={props.focusOnRender === true || (!nameValue && props.focusOnRender !== false)}
            onChanged={onChanged}
            onEscapeKey={() => setInputValue(nameValue)}
        />
    )
}
