import { CSSProperties, useContext, useEffect, useState } from 'react'
import OrgService, { Org } from '../../services/org'
import { Selector, SelectorProps } from '../Input/Selector'
import { OrgPath } from './OrgPath'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'

export type OrgSelectorProps = SelectorProps & {
    org?: Org | null
    allowAnyOrg?: boolean
    hideOther?: boolean
    containerStyle?: CSSProperties
    onSelect: (org?: Org) => void
}

export const OrgSelector = (props: OrgSelectorProps) => {
    const context = useContext(ApplicationContext)
    const { orgService } = useContext(VariableServicesContext)
    const [orgs, setOrgs] = useState<Org[]>([])
    const [org, setOrg] = useState<Org | undefined>(props.org !== null ? props.org : undefined)

    useEffect(() => {
        orgService.getStructuredList().then((_orgs) => {
            if (props.hideOther) {
                _orgs = _orgs.filter((o) => !OrgService.isOther(o))
            }
            if (props.allowAnyOrg || context.stores.ui?.altKey) {
                setOrgs(_orgs)
            } else {
                setOrgs(_orgs.map((o) => ({ ...o, disabled: (o.children?.length || 0) > 0 })))
            }
        })
    }, [context.stores.ui?.altKey])

    useEffect(() => {
        if (props.org === null) {
            setOrg(context.stores.orgs.list.find((o) => o.isUncategorized))
        } else if (props.org?.uuid !== org?.uuid) {
            setOrg(props.org)
        }
    }, [props.org?.uuid])

    return (
        <Selector
            {...props}
            placeholder={props.placeholder || OrgService.webTitle()}
            label={props.label || (!props.readonly && org?.uuid ? <OrgPath org={org} /> : undefined)}
            options={orgs}
            option={org?.uuid}
            onSelect={(newValue) => {
                if (newValue.disabled) return
                setOrg(newValue)
                props.onSelect({ ...newValue, disabled: undefined })
            }}
            renderItemValue={(o) => (
                <div
                    className={[
                        'text-base-font',
                        o.disabled ? 'opacity-50 not-clickable' : '',
                        o.uuid === org?.uuid ? 'fw-bold' : '',
                    ].join(' ')}
                    style={{ marginLeft: `${o.level * 10}px` }}
                >
                    {orgService.getOrgName(o)}
                </div>
            )}
        />
    )
}
