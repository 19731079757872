import { ProductVisibility } from '../../services/product'
import { useContext, useMemo } from 'react'
import { ApplicationContext } from '../../context'
import { Inventory } from '../../services/inventory'

export const ProductSource = (props: { inv: Inventory; productOfString?: string; sourceString?: string }) => {
    const context = useContext(ApplicationContext)
    const productOf = useMemo(() => props.inv.productOf?.name, [props.inv.productOf])
    const dataSources = useMemo(() => (props.inv.dataSources || []).map((ds) => ds.name), [props.inv.dataSources])
    const isPremium = useMemo(
        () =>
            !!context.stores.company?.subscription?.premiumDataPoints &&
            props.inv.originalProduct?.visibility === ProductVisibility.PAID_USERS,
        [context.stores.company, props.inv.originalProduct?.visibility],
    )
    if (!productOf && !dataSources.length) return null
    return (
        <span className='d-block small mt-1'>
            <div hidden={!productOf}>
                {props.productOfString || 'Product of: '}
                {productOf}
            </div>
            <div hidden={!dataSources.length}>
                {props.sourceString || 'Source: '}
                {props.inv.dataSources?.map((ds) => ds.name).join(', ')}
            </div>
            {isPremium && <span className='premium-label very-small fw-bold ms-1 nt--1'>Premium</span>}
        </span>
    )
}
