import { useContext, useMemo, useRef } from 'react'
import { Product } from '../../services/product'
import { Company } from '../../services/company'
import { Selector, SelectorProps } from '../Input/Selector'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'
import CO2e from '../CO2e'

export const ProductFilter = (
    props: SelectorProps & {
        product?: Product
        productOf?: Company
        onSelect: (product: Product) => void
        onEscape?: (value: string) => void
    },
) => {
    const context = useContext(ApplicationContext)
    const { inventoryService } = useContext(VariableServicesContext)
    const ref = useRef<any>()

    const products = useMemo(
        () => inventoryService.getProducts(props.productOf),
        [context.stores.inventory?.updated, context.stores.company?.uuid, props.productOf],
    )

    return (
        <Selector
            passedRef={ref}
            tooltipStyle={{ maxWidth: '350px' }}
            {...props}
            options={products}
            option={props.product?.uuid}
            onSelect={(inv) => props.onSelect(inv.originalProduct)}
            onEscape={() => props.onEscape && props.onEscape(ref.current.value)}
            renderItemValue={(p: Product) => {
                return (
                    <div className='d-flex align-items-start justify-content-between gap-2'>
                        <div>
                            <strong>{p.name}</strong>
                            <div className='text-muted small'>{p.description}</div>
                        </div>
                        <CO2e
                            co2e={p.co2e}
                            unitSize='small'
                            functionalUnit={p.unit?.code}
                            className='text-end'
                            unitsClassName='d-block very-small text-muted'
                            functionalUnitClassName='ms-1'
                        />
                    </div>
                )
            }}
        />
    )
}
