import { useContext, useEffect, useState } from 'react'
import { Selector, SelectorProps } from './Selector'
import BatchService, { ProductionBatch } from '../../services/batch'
import { GoTo } from '../GoTo'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'

interface BatchSelectorProps extends SelectorProps {
    batch?: ProductionBatch
    showGoToBatch?: boolean
    showNoValueOption?: boolean
    onSelect: (batch: ProductionBatch | null) => void
}

export const BatchSelector = (props: BatchSelectorProps) => {
    const context = useContext(ApplicationContext)
    const { batchService } = useContext(VariableServicesContext)
    const [batch, setBatch] = useState<ProductionBatch | undefined>(props.batch)

    useEffect(() => {
        if (!context.stores.batch.list.length) {
            batchService.getBatches().then()
        }
    }, [])

    useEffect(() => {
        if (props.batch?.uuid !== batch?.uuid) {
            setBatch(props.batch)
        }
    }, [props.batch])

    return (
        <span className='d-flex align-items-center gap-2 hover-parent-direct'>
            <Selector
                placeholder='Batch'
                options={context.stores.batch.list}
                option={batch}
                itemClassName='dropdown-item text-wrap px-2 text-start text-base-font'
                noValueOption={props.showNoValueOption ? <span className='text-muted'>No Batch</span> : undefined}
                {...props}
                onSelect={(newValue) => {
                    if (newValue?.uuid) {
                        setBatch(newValue)
                        props.onSelect(newValue)
                    } else {
                        setBatch(undefined)
                        props.onSelect(null)
                    }
                }}
            />
            {props.showGoToBatch && batch?.uuid && (
                <GoTo url={BatchService.getBatchUrl(batch)} className='display-on-hover' />
            )}
        </span>
    )
}
