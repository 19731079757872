import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Modal } from '../Modal'
import ProductService from '../../services/product'
import PartService, { Part, PartType } from '../../services/part'
import Button from '../Input/Button'
import InputField from '../Input/InputField'
import Utils from '../../services/utils'
import { ArrowLeft } from '@phosphor-icons/react'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'
import { Input, InputActionType } from '../../services/input'
import { useInput } from '../../hooks/useInput'
import { useProduct } from '../../hooks/useProduct'

export const PartCreator = () => {
    const context = useContext(ApplicationContext)
    const { partService, inputService, analyticsService } = useContext(VariableServicesContext)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [creating, setCreating] = useState<boolean>(false)
    const [part, setPart] = useState<Part>()

    useEffect(() => {
        if (context.stores.input?.currentId && context.stores.input.action === 'create-part') {
            setShowModal(true)
        }
        return () => setPart(undefined)
    }, [context.stores.input?.currentId])

    const input = useInput({ inputId: context.stores.input?.currentId })
    const sourceProduct = useProduct({ product: input?.sourceProduct })

    const onChooseType = useCallback(
        (type: PartType) => {
            const props: Partial<Part> = { type: type, unit: sourceProduct?.unit }
            if (ProductService.isEmissionFactor(sourceProduct)) {
                props.emissionFactor = {
                    quantity: 1,
                    footprint: sourceProduct || undefined,
                    footprintUnit: sourceProduct?.unit,
                    unit: sourceProduct?.unit,
                }
            }
            setPart((state) => ({ ...state, ...props }))
            analyticsService.track('Element Creator: Choose Type', { type })
        },
        [sourceProduct],
    )

    const createPart = useCallback(async () => {
        if (!part || !sourceProduct) {
            return
        }
        setCreating(true)

        const newPart = await partService.createOrUpdatePart(part).catch(Utils.errorToast)
        if (!newPart) {
            setCreating(false)
            return
        }
        await partService.addSourceProduct(newPart, sourceProduct).catch(Utils.errorToast)

        const inputProperties: Partial<Input> = {
            uuid: context.stores.input?.currentId,
            part: newPart,
        }
        if (newPart.type === PartType.MIX) {
            inputProperties.sourceProduct = null
        }

        await inputService.updateInput(inputProperties).catch(Utils.errorToast)
        await partService.getPart(newPart.uuid).catch(Utils.errorToast)
        setTimeout(() => {
            partService.openPartEditor(newPart, context.stores.input?.currentId)
            setShowModal(false)
            context.dispatch({ type: InputActionType.DeselectPartForInput })
            analyticsService.track('Element Creator: Created New Element')
            setCreating(false)
        }, 100)
    }, [part, sourceProduct, context.stores.input?.currentId])

    const elementExplainer = useMemo(
        () => (
            <>
                <div className='alert bg-light'>
                    Alternatives are stored in {PartService.webTitle(true)}. There are two types of{' '}
                    {PartService.webTitle(true)}:
                </div>
                <div className='row'>
                    <div className='col d-flex flex-column'>
                        <div className='d-flex flex-column flex-grow-1 align-items-center px-3 py-3'>
                            <div className='fs-5'>{PartService.getPartTypeString(PartType.SWITCH)}</div>
                            <div className='mt-2 mb-3 flex-grow-1 small'>
                                <strong>Switch</strong> between multiple Footprints so that you can choose the best
                                alternative.
                            </div>
                            <Button
                                ariaLabel={`Select ${PartService.getPartTypeString(PartType.SWITCH)}`}
                                onClick={() => onChooseType(PartType.SWITCH)}
                                className='btn btn-sm btn-outline-primary w-100'
                            >
                                Select
                            </Button>
                        </div>
                    </div>
                    <div className='col d-flex flex-column border-start'>
                        <div className='d-flex flex-column flex-grow-1 align-items-center px-3 py-3'>
                            <div className='fs-5'>{PartService.getPartTypeString(PartType.MIX)}</div>
                            <div className='mt-2 mb-3 flex-grow-1 small'>
                                <strong>Aggregate</strong> multiple Footprints into a single value. This is useful for
                                commodities.
                            </div>
                            <Button
                                ariaLabel={`Select ${PartService.getPartTypeString(PartType.MIX)}`}
                                onClick={() => onChooseType(PartType.MIX)}
                                className='btn btn-sm btn-outline-primary w-100'
                            >
                                Select
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        ),
        [onChooseType],
    )

    const partCreator = useMemo(
        () => (
            <>
                <Button
                    onClick={() => {
                        setPart(undefined)
                        analyticsService.track('Element Creator: Back')
                    }}
                    className='btn btn-sm bg-light-hover position-relative shadow-none'
                    style={{ left: '-.5rem' }}
                >
                    <ArrowLeft size={Utils.verySmallIconSize} /> Back
                </Button>
                <div className='alert bg-light my-2'>
                    <strong className='d-block'>{PartService.getPartTypeString(part?.type)} name</strong>
                    <span className='small' hidden={part?.type !== 'marketplace'}>
                        A good name is representative of the Footprints it contains. For example, if I'm comparing a few
                        option for a handle for a kettle, I might call the {PartService.webTitle()} "Kettle Handle".
                    </span>
                    <span className='small' hidden={part?.type !== PartType.MIX}>
                        A good name is representative of the Footprints it contains. For example, if I'm sourcing
                        aluminium from 4 different suppliers, I might call the {PartService.webTitle()} "Aluminium".
                    </span>
                </div>
                <InputField
                    focusOnRender={true}
                    placeholder='Name'
                    className='variable-form-control bg-light fs-5 w-100 mt-3'
                    onChange={(name) => setPart((state) => ({ ...state, name }))}
                    onEnter={() => createPart()}
                />
                <Button
                    disabled={!part?.name}
                    saving={creating}
                    className='btn btn-outline-primary w-100 mt-4 shadow-none'
                    onClick={() => createPart()}
                >
                    Create
                </Button>
            </>
        ),
        [part, creating, createPart],
    )

    return (
        <Modal
            ariaLabel='Add alternative'
            hidden={!showModal}
            header={<span>Add alternative</span>}
            content={
                <div style={{ minHeight: '32vh' }}>
                    {!part?.type && elementExplainer}
                    {part?.type && partCreator}
                </div>
            }
            onVisibilityChange={(isVisible) => {
                if (!showModal) return
                if (!isVisible) {
                    context.dispatch({ type: InputActionType.DeselectPartForInput })
                    analyticsService.track('Element Creator: Close')
                } else {
                    analyticsService.track('Element Creator: Open')
                }
                setShowModal(isVisible)
            }}
        />
    )
}
