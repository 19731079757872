import ProductService, {
    Product,
    ProductActionType,
    ProductFootprintType,
    productFootprintTypeOptions,
} from '../../services/product'
import { ProductTypeStatic } from './ProductTypeStatic'
import { ProductTypeFactor } from './ProductTypeFactor'
import { Modal } from '../Modal'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import Utils from '../../services/utils'
import { AuthButton } from '../Auth0'
import { UnitSelector } from '../Input/UnitSelector'
import { Selector } from '../Input/Selector'
import { ImageUploader } from '../Input/ImageUploader'
import { Link, useLocation } from 'react-router-dom'
import { InputItems } from './InputItems'
import Delete from '../Delete'
import Button from '../Input/Button'
import { CompanyFilter } from '../Company/CompanyFilter'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'
import { CannotBeDeletedTooltip } from './CannotBeDeletedTooltip'
import { GlobeSimple } from '@phosphor-icons/react'
import { Footnotes } from '../Footnotes'
import { ElectricityDetail } from '../Electricity/ElectricityDetail'
import { useSave } from '../../hooks/useSave'
import { TitleEditor } from '../Input/TitleEditor'
import ElectricityService from '../../services/electricity'
import { UnitType } from '../../services/unit'
import { AmountInput } from '../Input/Amount'
import FlagService, { FlagType } from '../../services/flag'
import { useProduct } from '../../hooks/useProduct'
import { ND } from '../../types'
import { useCompany } from '../../hooks/useCompany'
import { DigitalProductPassport } from '../DigitalProductPassport'

export const ProductDetailModal = () => {
    const context = useContext(ApplicationContext)
    const { productService, companyService, authenticationService, analyticsService } =
        useContext(VariableServicesContext)
    const location = useLocation()

    const product = useProduct({ product: context.stores.products.preview })
    const productCompany = useCompany({ company: product?.productOf })
    const productOfEditable = useMemo(() => {
        if (companyService.isMyCompany(productCompany)) return false
        return !productCompany || companyService.isEditable(productCompany)
    }, [productCompany])

    useEffect(() => {
        if (context.stores.products.preview?.uuid && context.stores.products.preview?.uuid !== ProductService.newId) {
            productService.getById(context.stores.products.preview?.uuid).catch(Utils.errorToast)
        }
    }, [context.stores.products.preview?.uuid])

    const editable = useMemo(() => {
        if (!product?.uuid && !product?.productOf) return true
        return productService.isEditable(product)
    }, [product])

    const instanceId = useMemo(() => `preview-${product?.uuid || Math.random()}`, [product?.uuid])

    const isElectricity = useMemo(
        () => ElectricityService.isElectricity(product),
        [product?.taxonomy?.path, context.stores.ui?.flagsReady],
    )

    const partialSaveFn = useCallback(async (properties?: Partial<Product>) => {
        if (!properties) return
        return productService.update(properties).then((p) => {
            if (!properties?.uuid && p?.uuid) {
                analyticsService.track('Create Product', { uuid: p.uuid, name: p.name })
            }
            return p
        })
    }, [])

    const { pSave, saving } = useSave({ instanceId, node: product, partialSaveFn })

    useEffect(() => closeModal(), [location.pathname])

    const closeModal = useCallback(() => {
        context.dispatch({ type: ProductActionType.UnsetProductPreview })
    }, [])

    const onVisibilityChange = useCallback((v: boolean) => !v && closeModal(), [closeModal])

    const live = useMemo(() => {
        if (authenticationService.isOnboarded()) {
            if (editable) {
                return (
                    <>
                        <div className='text-center'>
                            <Link className='btn btn-sm btn-light border' to={ProductService.getProductUrl(product)}>
                                Edit live footprint on detail page
                            </Link>
                        </div>
                        <InputItems locked={true} disabled={true} product={product} />
                    </>
                )
            }
            return <>You do not have permission to edit this</>
        }
        return (
            <>
                <AuthButton>Create an account</AuthButton> to create a Live Footprint
            </>
        )
    }, [editable, product])

    const header = useMemo(
        () => (
            <div className='d-flex align-items-center mb--2 w-100'>
                <span
                    className='position-fixed d-block small bg-light border rounded-1 px-1'
                    style={{ marginTop: '-4.5rem' }}
                >
                    {companyService.isMyCompany(productCompany)
                        ? ProductService.webTitle()
                        : ProductService.elementTitle()}
                </span>
                <ImageUploader
                    disabled={!editable}
                    nodeType={ND.Product}
                    nodeId={product?.uuid}
                    filePath={product?.productImageUrl}
                    className='me-2'
                    iconSize={25}
                    style={{ width: '50px', height: 'auto' }}
                    onUpload={(productImageUrl) => pSave({ productImageUrl })}
                />
                <TitleEditor
                    node={product}
                    disabled={isElectricity}
                    inputExtraClassName='fs-5'
                    onChanged={(name) => pSave({ name })}
                />
            </div>
        ),
        [editable, isElectricity, product?.uuid, product?.name, productCompany, product?.productImageUrl],
    )

    const body = useMemo(() => {
        if (!product || isElectricity) return null
        if (product.type === ProductFootprintType.LIVE) {
            return live
        }
        if (product.type === ProductFootprintType.STATIC) {
            return (
                <ProductTypeStatic
                    thinView={true}
                    disabled={!editable}
                    product={product}
                    onChange={(properties) => pSave(properties)}
                />
            )
        }
        if (product.type === ProductFootprintType.FACTOR || product.type === ProductFootprintType.ELECTRICITY) {
            return <ProductTypeFactor disabled={!editable} product={product} onChange={(p) => pSave(p)} />
        }
        return undefined
    }, [isElectricity, product, editable])

    const content = useMemo(() => {
        if (isElectricity) return <ElectricityDetail product={product} disabled={!editable} isPreview={true} />
        return (
            <div className='d-flex flex-column flex-md-row gap-4' style={{ minHeight: '35vh' }}>
                <div className='d-flex flex-column gap-4'>
                    <div>
                        <span className='small text-nowrap'>Type:</span>
                        <Selector
                            ariaLabel='Element Type'
                            disabled={!editable || isElectricity}
                            placeholder='Select'
                            placement='bottom-start'
                            className='bg-light variable-select-arrow text-nowrap'
                            hideTextFilter={true}
                            tooltipStyle={{ maxWidth: '400px' }}
                            options={productFootprintTypeOptions}
                            option={productFootprintTypeOptions.find((pfo) => pfo.value === product?.type)}
                            onSelect={(newValue) => pSave({ type: newValue.value })}
                        />
                    </div>
                    <div>
                        <div className='small text-nowrap'>Unit of measure:</div>
                        <UnitSelector
                            ariaLabel='Declared Unit'
                            disabled={!editable || isElectricity}
                            placement='bottom-start'
                            className='bg-light variable-select-arrow'
                            placeholder='Declared unit of measure'
                            unit={product?.unit}
                            onChange={(unit) => pSave({ unit })}
                        />
                    </div>
                    <div>
                        <div className='small text-nowrap'>Product of:</div>
                        {!productOfEditable && <span className='small'>{productCompany?.name}</span>}
                        {productOfEditable && (
                            <CompanyFilter
                                perspective='supplier'
                                placement='bottom-start'
                                disabled={!editable}
                                className='bg-light variable-select-arrow w-100'
                                company={productCompany || undefined}
                                showGoTo={true}
                                onSelect={(productOf) => pSave({ productOf })}
                                onClear={() => pSave({ productOf: null })}
                            />
                        )}
                    </div>
                    {FlagService.enabledFlags.has(FlagType.EnableWeight) && (
                        <div className='small'>
                            <div className='d-flex align-items-center gap-1'>
                                <span className='small'>Weight</span>
                                <AmountInput
                                    disabled={product?.unit?.type === UnitType.WEIGHT}
                                    amount={ProductService.getProductWeight(product)}
                                    inputFieldProps={{ extraClassName: 'text-center' }}
                                    unitSelectorProps={{ unitType: UnitType.WEIGHT }}
                                    onChange={(weight) => pSave({ weight })}
                                />
                            </div>
                        </div>
                    )}
                    {product?.uuid && editable && productOfEditable && (
                        <div className='mt-auto'>
                            <Delete
                                hidden={companyService.isMyCompany(productCompany)}
                                disabled={!productService.canBeDeleted(product)}
                                disabledTooltip={<CannotBeDeletedTooltip className='small' />}
                                tooltipProps={{ placement: 'top-start', tooltipClassName: 'small text-start' }}
                                className='small'
                                deleteFn={() =>
                                    productService
                                        .deleteProduct(product.uuid!)
                                        .then(() => {
                                            setTimeout(() => {
                                                context.dispatch({
                                                    type: ProductActionType.SetDeletedProductId,
                                                    payload: product?.uuid,
                                                })
                                            })
                                            Utils.deletedToast('Product deleted')
                                            analyticsService.track('Delete Product', {
                                                uuid: product?.uuid,
                                                name: product?.name,
                                            })
                                        })
                                        .then(() => closeModal())
                                        .catch(Utils.errorToast)
                                }
                            />
                        </div>
                    )}
                </div>
                <div className='h-100 overflow-y-auto container-fluid'>{body}</div>
            </div>
        )
    }, [
        body,
        editable,
        isElectricity,
        pSave,
        productCompany,
        productOfEditable,
        product?.uuid,
        product?.type,
        product?.unit,
        product?.factor?.footprint?.unit,
    ])

    const footer = useMemo(
        () => (
            <div className='d-flex align-items-center justify-content-between'>
                <Footnotes node={product} />
                <Button className='btn btn-sm btn-dark' onClick={() => closeModal()}>
                    Done
                </Button>
            </div>
        ),
        [product?.uuid, product?.updated, product?.created, saving],
    )

    if (!editable) {
        return (
            <Modal
                ariaLabel='Passport'
                size='lg'
                hidden={!product}
                header={
                    <>
                        <GlobeSimple size={22} className='nt--1 me-1' /> Digital Product Passport
                    </>
                }
                content={<DigitalProductPassport product={product} isModal={true} />}
                onVisibilityChange={onVisibilityChange}
            />
        )
    }

    return (
        <Modal
            ariaLabel='Product Preview'
            hidden={!product}
            size='xl'
            header={header}
            expandLink={ProductService.getProductUrl(product)}
            footerClassName='px-3 py-2 mt-2 border-top text-start small text-muted'
            footer={footer}
            content={content}
            onVisibilityChange={onVisibilityChange}
        />
    )
}
