import { ActivityActionType, ActivityContext } from '../../services/activityContext'
import { ActivityDetails } from './ActivityDetails'
import { SlideIn } from '../SlideIn'
import { useContext, useEffect } from 'react'
import { VariableServicesContext } from '../../services'
import { StateLabel } from '../StateLabel'
import usePaginate from '../../hooks/usePaginate'
import { ActivityState } from '../../services/activity'

export const ActivitySlider = (props: { hideApprovalActions?: boolean }) => {
    const { analyticsService } = useContext(VariableServicesContext)
    const activityContext = useContext(ActivityContext)
    const { queryOptions, setQueryParams } = usePaginate()

    useEffect(() => {
        return () => activityContext.dispatch({ type: ActivityActionType.Deselect })
    }, [])

    return (
        <SlideIn
            ariaLabel='Activity Detail'
            style={{ width: '500px' }}
            show={activityContext.activity !== undefined}
            onVisibilityChange={(isVisible) => {
                if (activityContext.activity && !isVisible) {
                    if (activityContext.isOnboarding) {
                        activityContext.dispatch({ type: ActivityActionType.SetIsOnboarding, payload: false })
                        if (
                            activityContext.activity.state === ActivityState.APPROVED &&
                            queryOptions?.activityStates === ActivityState.DRAFT
                        ) {
                            setQueryParams({ activityStates: undefined })
                        }
                    }
                    activityContext.dispatch({ type: ActivityActionType.Deselect })
                    analyticsService.track('Close Activity Item')
                }
            }}
            useBackdrop={true}
            header={<StateLabel node={activityContext.activity} showIcon={true} />}
        >
            {activityContext.activity && (
                <ActivityDetails
                    activityItem={activityContext.activity}
                    hideApprovalActions={props.hideApprovalActions}
                />
            )}
        </SlideIn>
    )
}
