import { Link } from 'react-router-dom'
import UserService from '../../services/user'
import Utils from '../../services/utils'
import { Check, Warning } from '@phosphor-icons/react'
import { StandardAttributes } from '../../types'
import { useMemo } from 'react'

export const DecimalWarning = (props: StandardAttributes & { isMatching: boolean }) => {
    const preferredDecimal = useMemo(() => Utils.getPreferredDecimal(), [])
    const profileLink = useMemo(() => `${UserService.webRootProfile}?highlight=numberLocale`, [])
    if (props.hidden) return null
    return (
        <div
            data-warning='decimal'
            data-testid={props.isMatching ? 'DecimalMatching' : 'DecimalWarning'}
            className={`bg-white ${props.className}`}
            style={props.style}
        >
            <div
                className={[
                    'bg-opacity-5 border p-1 rounded-1 text-alt-font small',
                    props.isMatching ? 'bg-success border-success' : 'bg-warning border-warning',
                ].join(' ')}
            >
                {!props.isMatching && <Warning color={Utils.warningColor} />}
                {props.isMatching && <Check color={Utils.successColor} />} You currently have{' '}
                <span className='bg-primary bg-opacity-10 border font-monospace fw-bolder px-1 rounded-1'>
                    {preferredDecimal}
                </span>{' '}
                ({Utils.getDecimalName(preferredDecimal)}) set as your decimal separator. Use a{' '}
                {Utils.getDecimalName(preferredDecimal)} now so that the value is correct. You can update this setting
                in your{' '}
                <Link className='text-decoration-underline' to={profileLink}>
                    profile preferences
                </Link>
            </div>
        </div>
    )
}
