import { Org } from '../../services/org'
import { ReactNode, useContext, useMemo } from 'react'
import { VariableServicesContext } from '../../services'

export const OrgPath = (props: { className?: string; org?: Org; onOrg?: (org: Org) => ReactNode }) => {
    const { orgService } = useContext(VariableServicesContext)

    const onOrg = useMemo(() => props.onOrg || ((org: Org) => org.name), [props.onOrg])
    const orgArray = useMemo(() => orgService.getOrgArray(props.org), [props.org])

    if (!orgArray.length) return null

    return (
        <span className={props.className || 'd-inline-flex flex-wrap gap-1'}>
            {orgArray.map((o, idx) => (
                <span key={`op-${o.uuid}`}>
                    {onOrg(o)}
                    {idx < orgArray.length - 1 ? ': ' : ''}
                </span>
            ))}
        </span>
    )
}
