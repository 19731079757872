import {
    CSSProperties,
    FocusEvent,
    FormEvent,
    HTMLInputTypeAttribute,
    KeyboardEvent,
    ReactNode,
    RefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import Utils from '../../services/utils'
import { DecimalWarning } from './DecimalWarning'
import { StandardAttributes } from '../../types'

let timer: NodeJS.Timeout

export interface InputFieldProps {
    id?: string
    type?: HTMLInputTypeAttribute
    label?: ReactNode
    inputLabel?: ReactNode
    inputContainerProps?: StandardAttributes
    ariaLabel?: string
    multiLine?: boolean
    passedRef?: RefObject<any>
    pattern?: string
    focusOnRender?: boolean
    className?: string
    extraClassName?: string
    numberCxClassName?: string
    style?: CSSProperties
    placeholder?: string
    value?: string | number
    defaultValue?: string | number
    followDefaultValue?: boolean
    rows?: number
    cols?: number
    isNumber?: boolean
    numberPrecision?: number
    required?: boolean
    highlightRequired?: boolean
    disabled?: boolean
    hidden?: boolean
    isValid?: boolean
    highlightValid?: boolean
    highlightInvalid?: boolean
    message?: string
    min?: number
    max?: number
    step?: number
    minLength?: number
    maxLength?: number
    autoComplete?: string
    debounceCadence?: number
    onClick?: (e: any) => void
    onFocus?: () => void
    onBlur?: (e: any, hasChanged: boolean) => void
    onDebounced?: (hasChanged: boolean) => void
    onKeyPress?: (e: any) => void
    onEnterOrBlur?: (hasChanged: boolean) => void
    onEnter?: (hasChanged: boolean) => void
    onKeyUp?: (e: any) => void
    onChange?: (newValue: string, validity?: ValidityState) => void
    onChanged?: (newValue: string, validity?: ValidityState) => void
    onChangedDebounced?: (newValue: string, validity?: ValidityState) => void
    onEscapeKey?: () => void
    onInvalid?: () => void
}

const InputField = (props: InputFieldProps) => {
    const [validityState, setValidityState] = useState<ValidityState>()
    const [isDecimalMatching, setIsDecimalMatching] = useState<boolean>(false)
    const [showDecimalWarning, setShowDecimalWarning] = useState<boolean>(false)
    const [value, setValue] = useState<string | number>(props.defaultValue || props.value || '')
    const [numberValue, setNumberValue] = useState<number>()
    const [hasChanged, setHasChanged] = useState<boolean>(false)
    const [shouldSendChange, setShouldSendChange] = useState<boolean>(false)
    const [hasFocused, setHasFocused] = useState<boolean>(false)
    const [hasBlurred, setHasBlurred] = useState<boolean>(false)
    const [htmlId, setHtmlId] = useState<string>(props.id || Math.random().toString())
    const [preferredDecimal] = useState<string>(Utils.getPreferredDecimal())
    const internalRef = useRef<HTMLInputElement>(null)
    const ref = props.passedRef || internalRef

    useEffect(() => {
        if (props.focusOnRender && ref.current && !hasFocused) {
            ref.current?.focus()
            setTimeout(() => ref.current?.focus(), 10)
        }
        if (props.type === 'number') {
            ref.current?.addEventListener('wheel', preventWheel)
        }
        return () => ref.current?.removeEventListener('wheel', preventWheel)
    }, [])

    useEffect(() => {
        if (props.id) setHtmlId(props.id)
    }, [props.id])

    useEffect(() => {
        if (!props.followDefaultValue) return
        const newValue = props.defaultValue || props.value || ''
        if (!hasFocused) setValue(newValue)
        if (!props.isNumber && ref.current?.value) ref.current.value = newValue as string
    }, [props.defaultValue])

    useEffect(() => {
        if (!hasFocused) {
            setFriendlyInputValue()
        }
    }, [numberValue])

    useEffect(() => {
        if (props.isNumber) {
            if (hasFocused) {
                setNumberValue(Utils.parseAsNumber(value as string))
            } else {
                setNumberValue(value as number)
            }
        }
        if (shouldSendChange) {
            setShouldSendChange(false)
            if (props.isNumber) {
                const _numberValue = Utils.parseAsNumber(value as string)
                props.onChange?.(_numberValue?.toString() || '', validityState)
            } else {
                props.onChange?.((value || '').toString(), validityState)
            }
        }
    }, [value, shouldSendChange])

    const preventWheel = useCallback((e: any) => e.preventDefault(), [])

    const className = useMemo(
        () =>
            [
                props.className,
                validityState?.badInput === true ? 'native-invalid' : '',
                props.isValid === undefined || !hasBlurred
                    ? ''
                    : props.isValid
                      ? props.highlightValid !== false && 'is-valid'
                      : props.highlightInvalid !== false && 'is-invalid',
                props.required && props.highlightRequired && hasBlurred && !value ? 'required' : '',
                props.isNumber || props.type === 'number' ? 'font-monospace' : '',
                props.extraClassName,
            ].join(' '),
        [
            value,
            validityState,
            props.className,
            props.extraClassName,
            props.isValid,
            props.required,
            props.highlightRequired,
            props.type,
            props.isNumber,
        ],
    )

    const onChange = useCallback(
        (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            let newValue = e.currentTarget.value
            setValidityState(e.currentTarget.validity)
            if (newValue !== value) {
                if (props.isNumber) {
                    const decimalMatch = newValue.match(/.*([,.]).*/)?.[1]
                    const nonPreferredMatch = decimalMatch !== preferredDecimal
                    const preferredMatch = decimalMatch === preferredDecimal
                    const _isDecimalProblem = decimalMatch !== undefined && nonPreferredMatch && !preferredMatch
                    setIsDecimalMatching(!_isDecimalProblem)
                    if (_isDecimalProblem) {
                        setShowDecimalWarning(true)
                    }
                }
                setValue(newValue)
                setHasChanged(true)
                setShouldSendChange(true)
            }
        },
        [value, props.isNumber, preferredDecimal],
    )

    const setFriendlyInputValue = useCallback(() => {
        if (!props.isNumber || !ref.current) return
        if (numberValue === undefined || numberValue?.toString() === '' || isNaN(numberValue)) return
        ref.current.value = Utils.toFixedFloat(
            numberValue,
            props.numberPrecision !== undefined ? props.numberPrecision : 4,
        )
    }, [ref.current, numberValue, props.isNumber, props.numberPrecision])

    const setEntryInputValue = useCallback(() => {
        if (!props.isNumber || !ref.current) return
        if (numberValue === undefined || numberValue?.toString() === '' || isNaN(numberValue)) return
        ref.current.value = Utils.toEditableNumber(numberValue)
    }, [ref.current, numberValue, props.isNumber])

    const onFocus = useCallback(() => {
        setHasFocused(true)
        setEntryInputValue()
        props.onFocus?.()
    }, [setEntryInputValue])

    const getValueToReturn = useCallback(
        (returnValue?: string | number): string => {
            if (props.isNumber) {
                // console.log(ref.current?.value, Utils.parseAsNumber(ref.current?.value))
                return Utils.parseAsNumber(ref.current?.value)?.toString() || ''
            }
            return returnValue?.toString() || value.toString() || ''
        },
        [props.isNumber, numberValue, value],
    )

    const onBlur = useCallback(
        (e: FocusEvent, inputValue?: string | number) => {
            setHasBlurred(true)
            if (props.onBlur) {
                props.onBlur(e, hasChanged)
            } else if (props.onEnterOrBlur || props.onChanged) {
                props.onEnterOrBlur?.(hasChanged)
                if (hasChanged) props.onChanged?.(getValueToReturn(inputValue))
            } else if (props.onChangedDebounced && hasChanged) {
                props.onChangedDebounced?.(getValueToReturn(inputValue))
            }
            setHasChanged(false)
            setTimeout(() => {
                setFriendlyInputValue()
                setShowDecimalWarning(false)
            }, 300)
        },
        [
            hasChanged,
            setFriendlyInputValue,
            props.onBlur,
            props.onEnterOrBlur,
            props.onChanged,
            props.onChangedDebounced,
        ],
    )

    const onDebounce = useCallback(() => {
        clearTimeout(timer)
        if (!props.onDebounced && !props.onChangedDebounced) return
        timer = setTimeout(() => {
            const _hasChanged = value !== ref.current?.value
            props.onDebounced?.(_hasChanged)
            if (_hasChanged) props.onChangedDebounced?.(ref.current?.value)
        }, props.debounceCadence || 500)
    }, [props.onDebounced, props.onChangedDebounced, ref.current, value])

    const onKeyDown = useCallback(
        (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, inputValue?: string | number) => {
            if (e.key === 'Enter') {
                clearTimeout(timer)
                if (props.onEnter) {
                    props.onEnter(hasChanged)
                } else if (props.onEnterOrBlur || props.onChanged) {
                    props.onEnterOrBlur?.(hasChanged)
                    if (hasChanged) props.onChanged?.(getValueToReturn(inputValue))
                } else if (props.onChangedDebounced && hasChanged) {
                    props.onChangedDebounced?.(getValueToReturn(inputValue))
                }
                if (!props.multiLine) setHasChanged(false)
            } else {
                onDebounce()
            }
            if (e.key === 'Escape' && props.onEscapeKey) {
                props.onEscapeKey()
            }
            props.onKeyPress?.(e)
        },
        [
            hasChanged,
            onDebounce,
            props.onEnter,
            props.onEnterOrBlur,
            props.onEscapeKey,
            props.onKeyPress,
            props.onChanged,
            props.onChangedDebounced,
            props.multiLine,
        ],
    )

    const message = useMemo(() => {
        if (!props.message) return ''
        return (
            <div
                className={[
                    `position-absolute top-100 start-0 bg-white z-index-tooltip`,
                    props.isValid === undefined ? 'form-text' : props.isValid ? 'valid-feedback' : 'invalid-feedback',
                ].join(' ')}
                style={{ minWidth: '300px', maxWidth: '400px' }}
            >
                {props.message}
            </div>
        )
    }, [props.message, props.isValid])

    const inputField = useMemo(() => {
        if (props.multiLine) {
            return (
                <textarea
                    id={htmlId}
                    ref={ref}
                    aria-label={props.ariaLabel}
                    className={className}
                    style={props.style}
                    placeholder={props.placeholder}
                    value={props.value}
                    defaultValue={props.defaultValue}
                    onClick={props.onClick}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={(e) => onBlur(e, value)}
                    onKeyUp={props.onKeyUp}
                    onKeyDown={(e) => onKeyDown(e, value)}
                    required={props.required}
                    disabled={props.disabled}
                    hidden={props.hidden}
                    autoComplete={props.autoComplete}
                    minLength={props.minLength}
                    maxLength={props.maxLength}
                    rows={props.rows}
                    cols={props.cols}
                />
            )
        }
        return (
            <input
                id={htmlId}
                ref={ref}
                aria-label={props.ariaLabel}
                className={className}
                style={props.style}
                placeholder={props.placeholder}
                value={props.value}
                defaultValue={props.defaultValue}
                onClick={props.onClick}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={(e) => onBlur(e, value)}
                onKeyUp={props.onKeyUp}
                onKeyDown={(e) => onKeyDown(e, value)}
                required={props.required}
                disabled={props.disabled}
                hidden={props.hidden}
                autoComplete={props.autoComplete}
                type={props.type}
                pattern={props.pattern}
                min={props.min}
                max={props.max}
                minLength={props.minLength}
                maxLength={props.maxLength}
                step={props.step}
            />
        )
    }, [
        props.multiLine,
        htmlId,
        ref,
        className,
        props.ariaLabel,
        props.style,
        props.placeholder,
        props.value,
        props.defaultValue,
        props.onClick,
        onChange,
        onFocus,
        onBlur,
        props.onKeyUp,
        onKeyDown,
        props.required,
        props.disabled,
        props.hidden,
        props.autoComplete,
        props.minLength,
        props.maxLength,
        props.min,
        props.max,
        props.type,
        props.pattern,
        props.rows,
        props.cols,
        props.step,
    ])

    const decimalWarning = useMemo(
        () => (
            <DecimalWarning
                hidden={!showDecimalWarning}
                className='position-fixed'
                style={{ width: '300px', zIndex: 9999 }}
                isMatching={isDecimalMatching}
            />
        ),
        [showDecimalWarning, isDecimalMatching],
    )

    const label = useMemo(() => {
        if (!props.label) return null
        return <label htmlFor={htmlId}>{props.label}</label>
    }, [props.label, htmlId])

    const html = useMemo(() => {
        if (props.isNumber) {
            return (
                <span className={`position-relative ${props.numberCxClassName}`}>
                    {label}
                    <span
                        className={[
                            props.inputContainerProps?.className || 'd-flex align-items-center',
                            props.inputContainerProps?.extraClassName,
                        ].join(' ')}
                        style={props.inputContainerProps?.style}
                    >
                        {inputField}
                        {decimalWarning}
                        {message}
                        {props.inputLabel}
                    </span>
                </span>
            )
        }

        if (props.label) {
            return (
                <div>
                    {label}
                    <span
                        className={[
                            props.inputContainerProps?.className || 'd-flex align-items-center',
                            props.inputContainerProps?.extraClassName,
                        ].join(' ')}
                        style={props.inputContainerProps?.style}
                    >
                        {inputField}
                        {message}
                        {props.inputLabel}
                    </span>
                </div>
            )
        }

        return (
            <>
                {inputField}
                {message}
            </>
        )
    }, [props.isNumber, inputField, message, decimalWarning, label])

    return <>{html}</>
}

export default InputField
