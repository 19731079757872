import { CSSProperties, ReactNode } from 'react'
import Tooltip from './Tooltip'
import Utils from '../services/utils'
import { Link } from 'react-router-dom'

// SVG code adapted from: https://stackoverflow.com/a/66990982/1106199
export const ProgressCircle = (props: {
    size?: number
    progressPercent?: number
    textValue?: number | string
    round?: number
    className?: string
    style?: CSSProperties
    hidden?: boolean
    textColor?: string
    tooltip?: ReactNode
    onClick?: () => void
}) => {
    const svgSize = props.size || 60
    const circleRadius = svgSize / 2 - 4
    const progressPercent = props.progressPercent || 0
    let strokeWidth = 6
    let textFontSize = 'x-large'
    let textMarginTop = '3px'
    if (svgSize <= 30) {
        strokeWidth = 2
        textFontSize = 'x-small'
        textMarginTop = '1px'
    }
    let textValue: string | number = props.textValue || 0
    if (!isNaN(props.textValue as number)) {
        if (props.round === 0) {
            textValue = Math.round((props.textValue as number) || 0)
        } else if (props.round && props.round > 0) {
            textValue = Utils.toFixedFloat(props.textValue, props.round)
        }
    }
    const strokeBackgroundColor: string = Math.round(progressPercent) === 100 ? Utils.tertiaryColor : Utils.dangerColor
    const strokeColor: string = Utils.tertiaryColor

    const style =
        progressPercent === 100
            ? {}
            : {
                  strokeDasharray: 100,
                  strokeDashoffset: 100 - progressPercent || 0,
              }

    const containerClass = [
        'position-relative overflow-hidden fs-base mx-auto',
        props.className || '',
        props.hidden ? 'd-none' : 'd-block',
    ].join(' ')

    const containerStyle = {
        ...props.style,
        width: `${svgSize}px`,
        height: `${svgSize}px`,
    }

    const svgAndText = (
        <>
            <svg onClick={props.onClick} viewBox={`0 0 ${svgSize} ${svgSize}`} style={{ transform: 'rotate(-90deg)' }}>
                <g>
                    <circle
                        cx={svgSize / 2}
                        cy={svgSize / 2}
                        r={circleRadius}
                        stroke={strokeBackgroundColor}
                        strokeWidth={strokeWidth}
                        fill='none'
                    />
                    {progressPercent > 0 && (
                        <circle
                            style={style}
                            cx={svgSize / 2}
                            cy={svgSize / 2}
                            r={circleRadius}
                            stroke={strokeColor}
                            strokeWidth={strokeWidth}
                            fill='none'
                            strokeDashoffset='50'
                            pathLength='100'
                        />
                    )}
                </g>
            </svg>
            <span
                className='align-items-center position-absolute top-50 start-50 translate-middle fw-bold text-base-font'
                style={{ marginTop: textMarginTop, color: props.textColor || '', fontSize: textFontSize }}
            >
                {textValue}
            </span>
        </>
    )

    if (props.tooltip) {
        return (
            <Tooltip
                className={containerClass}
                style={containerStyle}
                trigger='hover'
                tooltipClassName='fs-base small'
                tooltipContent={props.tooltip}
                onClick={props.onClick}
            >
                {svgAndText}
            </Tooltip>
        )
    }

    if (props.onClick) {
        return (
            <Link
                to='#'
                className={containerClass}
                style={containerStyle}
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    props.onClick?.()
                }}
            >
                {svgAndText}
            </Link>
        )
    }

    return (
        <span className={containerClass} style={containerStyle} title={props.textValue?.toString()}>
            {svgAndText}
        </span>
    )
}
