import { Amount, KeyValuePair, VariableBaseNode, VariableNode } from '../types'
import { Product } from './product'
import { GeoLocation } from './geoLocation'
import { Input } from './input'
import VariableService from './service'
import UnitService from './unit'
import { Location } from './location'
import { ActionMap } from '../context'

export enum ProcessingTypeMethod {
    FOOTPRINT = 'footprint',
    GEOLOCATION = 'geolocation',
}

export interface ProcessingType extends VariableBaseNode {
    co2e?: string
    method?: ProcessingTypeMethod
    energy?: Amount
    geoLocation?: GeoLocation | null
    location?: Location | null
    footprint?: Product | null
    node?: VariableNode
    forInput?: Input
    byproducts?: ByproductType[]
    created?: number
    updated?: number
}

export interface ByproductType extends VariableBaseNode {
    co2e?: string
    amount?: Amount
    footprint?: Product | null
    forInput?: Input
    created?: number
    updated?: number
}

export interface IProcessingTypeEditor {
    processingId?: string
    byproductId?: string
    node?: VariableNode
    deletedProcessingTypeId?: string
    focus?: string
    updates: number
}

export const emptyIProcessingTypeEditor: IProcessingTypeEditor = {
    processingId: undefined,
    byproductId: undefined,
    deletedProcessingTypeId: undefined,
    node: undefined,
    updates: 0,
}

export enum ProcessingActionType {
    SetProcessing = 'SetProcessing',
    SetProcessingId = 'SetProcessingId',
    UpdateProcessing = 'UpdateProcessing',
    UnsetProcessing = 'UnsetProcessing',
}

type ProcessingActionPayload = {
    [ProcessingActionType.SetProcessing]: Omit<IProcessingTypeEditor, 'updates' | 'byId'>
    [ProcessingActionType.SetProcessingId]: string | undefined
    [ProcessingActionType.UpdateProcessing]: undefined
    [ProcessingActionType.UnsetProcessing]: undefined
}

export type ProcessingTypeActions = ActionMap<ProcessingActionPayload>[keyof ActionMap<ProcessingActionPayload>]

export const ProcessingTypeReducer = (
    state: IProcessingTypeEditor,
    action: ProcessingTypeActions,
): IProcessingTypeEditor => {
    switch (action.type) {
        case ProcessingActionType.SetProcessing:
            return { ...state, ...action.payload, updates: 0 }
        case ProcessingActionType.SetProcessingId:
            return { ...state, processingId: action.payload }
        case ProcessingActionType.UpdateProcessing:
            return { ...state, updates: state.updates + 1 }
        case ProcessingActionType.UnsetProcessing:
            return { ...emptyIProcessingTypeEditor }
        default:
            return state
    }
}

export default class ProcessingService extends VariableService {
    private basePath: string = '/processing'

    public static byId: Map<string, ProcessingType> = new Map<string, ProcessingType>()
    public static byproductById: Map<string, ByproductType> = new Map<string, ByproductType>()
    public static newId = 'newProcessing'

    public static updateProcessingTypeContext(processingTypes: ProcessingType[]): void {
        processingTypes.forEach((pt) => {
            if (pt.uuid) {
                const existing = ProcessingService.byId.get(pt.uuid)
                ProcessingService.byId.set(pt.uuid, { ...existing, ...pt })
                pt.byproducts?.forEach((bp) => {
                    if (bp.uuid) {
                        const existingByproduct = ProcessingService.byproductById.get(bp.uuid)
                        ProcessingService.byproductById.set(bp.uuid, { ...existingByproduct, ...bp })
                    }
                })
            }
        })
    }

    public updateProcessingTypeContext(processingTypes: ProcessingType[]): void {
        ProcessingService.updateProcessingTypeContext(processingTypes)
        this.context.dispatch({ type: ProcessingActionType.UpdateProcessing })
    }

    public static getEmptyProcessingType(): ProcessingType {
        return { uuid: ProcessingService.newId, name: 'Process', energy: { unit: UnitService.unitByCode['kWh'] } }
    }

    public static processingTypeMethods: KeyValuePair<ProcessingTypeMethod>[] = [
        { value: ProcessingTypeMethod.FOOTPRINT, name: 'Element', description: `Use an Element` },
        {
            value: ProcessingTypeMethod.GEOLOCATION,
            name: 'Location-based',
            description: 'Search by location to use the grid mix',
        },
    ]

    public editProcessingType(opts: {
        node: Input
        processingType?: ProcessingType
        byproduct?: ByproductType
        focus?: string
    }): void {
        if (this.context.stores.processingType?.processingId) return
        this.context.dispatch({
            type: ProcessingActionType.SetProcessing,
            payload: {
                processingId: opts.processingType?.uuid || opts.node.processingType?.uuid || undefined,
                byproductId: opts.byproduct?.uuid,
                ...opts,
            },
        })
    }

    public addByproduct(processingType?: ProcessingType): void {
        if (!processingType) return
        if (!processingType.byproducts) processingType.byproducts = []
        processingType.byproducts.push({})
        this.updateProcessingTypeContext([processingType])
    }

    public clearProcessingType(): void {
        this.context.dispatch({ type: ProcessingActionType.UnsetProcessing })
    }

    public async getProcessing(
        processingType: ProcessingType,
        nodeId: string,
        cacheOk: boolean = false,
    ): Promise<ProcessingType> {
        if (cacheOk && processingType?.uuid) {
            const cached = ProcessingService.byId.get(processingType.uuid)
            if (cached) return Promise.resolve(cached)
        }
        return this.httpService
            .get<ProcessingType>(`${this.basePath}/${processingType.uuid}?nodeId=${nodeId}`)
            .then((pt) => {
                this.updateProcessingTypeContext([pt])
                return pt
            })
    }

    public async updateInputProcessing(processingType: ProcessingType, nodeId: string): Promise<ProcessingType> {
        return this.httpService
            .put<ProcessingType>(this.basePath, { body: JSON.stringify({ nodeId, processingType }) })
            .then((pt) => {
                this.updateProcessingTypeContext([pt])
                if (this.context.stores.processingType.processingId === ProcessingService.newId) {
                    this.context.dispatch({ type: ProcessingActionType.SetProcessingId, payload: pt.uuid })
                }
                return pt
            })
    }
}
