import { KeyValuePair, ListResponse, VariableBaseNode, VariableNodeState } from '../types'
import { ActivityDirection, ActivityItem, ActivityState } from './activity'
import { Product } from './product'
import { Part } from './part'
import { Company } from './company'
import Utils from './utils'
import { Email } from './email'
import { Token } from './token'
import { Contact, User } from './user-context'
import { Schedule } from './schedule'
import VariableService from './service'
import { DataRequestActionType, DataRequestContextInterface } from './dataRequestContext'
import { AnalyticsService } from './analytics'
import { ImpactResult, ImpactSummary } from './impact'

export enum DataRequestType {
    COLLECT = 'collect',
    SHARE = 'share',
}

export enum DataRequestState {
    DRAFT = 'draft',
    SENT = 'sent',
    RECEIVED = 'received',
    IN_PROGRESS = 'in progress',
    PENDING = 'pending',
    APPROVED = 'approved',
    REJECTED = 'rejected',
}

export const dataRequestStates: KeyValuePair<DataRequestState>[] = [
    { value: DataRequestState.DRAFT, name: 'Draft' },
    { value: DataRequestState.SENT, name: 'Sent' },
    { value: DataRequestState.RECEIVED, name: 'Received' },
    { value: DataRequestState.IN_PROGRESS, name: 'In progress' },
    { value: DataRequestState.PENDING, name: 'Pending' },
    { value: DataRequestState.APPROVED, name: 'Approved' },
    { value: DataRequestState.REJECTED, name: 'Rejected' },
]

export interface DataRequest extends VariableBaseNode {
    subject?: string
    summary?: string
    type?: DataRequestType
    activityCount?: number
    state?: DataRequestState
    contact?: Contact
    startDate?: number
    endDate?: number
    impacts?: ImpactResult[]
    impact?: ImpactSummary

    owner?: Company
    ownerId?: string
    sender?: User
    supplier?: Company
    supplierId?: string
    customer?: Company
    customerId?: string
    activities?: ActivityItem[]
    products?: Product[]
    parts?: Part[]
    emails?: Email[]
    token?: Token
    schedule?: Schedule
}

export interface ScheduledDataRequest extends DataRequest {
    sendAt: number
}

export interface DataRequestPerspective {
    isOwner: boolean
    incoming: boolean
    outgoing: boolean
    type?: DataRequestType
    direction: ActivityDirection
    owner?: Company
    isSupplier?: boolean
    supplier?: Company
    isCustomer?: boolean
    customer?: Company
    otherCompany?: Company
}

export class DataRequestManager {
    private readonly dataRequestContext: DataRequestContextInterface
    private readonly dataRequestService: DataRequestService
    private readonly analyticsService: AnalyticsService

    constructor(
        dataRequestContext: DataRequestContextInterface,
        dataRequestService: DataRequestService,
        analyticsService: AnalyticsService,
    ) {
        this.dataRequestContext = dataRequestContext
        this.dataRequestService = dataRequestService
        this.analyticsService = analyticsService
    }

    public async getDataRequest(dataRequestId: string) {
        const dr = await this.dataRequestService.getDataRequest(dataRequestId)
        this.selectDataRequest(dr)
        return dr
    }

    public async getDataRequests(queryString?: string) {
        const drs = await this.dataRequestService.getDataRequests(queryString)
        this.setDataRequestData(drs)
        return drs
    }

    public async createNewDataRequest() {
        const newDr = await this.dataRequestService.createOrUpdate(
            this.dataRequestService.emptyDataRequest(DataRequestType.COLLECT),
        )
        this.dataRequestContext.dispatch({ type: DataRequestActionType.Add, payload: newDr })
        this.selectDataRequest(newDr)
        this.analyticsService.track('Create New Data Request', { uuid: newDr?.uuid, name: newDr?.name })
        return newDr
    }

    public async createNewDataShare() {
        const newDr = await this.dataRequestService.createOrUpdate(
            this.dataRequestService.emptyDataRequest(DataRequestType.SHARE),
        )
        this.dataRequestContext.dispatch({ type: DataRequestActionType.Add, payload: newDr })
        this.selectDataRequest(newDr)
        this.analyticsService.track('Create New Data Delivery', { uuid: newDr?.uuid, name: newDr?.name })
        return newDr
    }

    public async saveProperty(dataRequest?: Partial<DataRequest>) {
        const updatedDr = this.updateDataRequest(dataRequest)
        const updated = await this.dataRequestService.createOrUpdate(updatedDr)
        this.updateDataRequest(updated)
    }

    public setDataRequestData(dataRequestData: ListResponse<DataRequest>) {
        this.dataRequestContext.dispatch({ type: DataRequestActionType.SetDataRequestData, payload: dataRequestData })
    }

    public selectDataRequest(dataRequest: DataRequest) {
        this.dataRequestContext.dispatch({ type: DataRequestActionType.Select, payload: dataRequest })
        this.dataRequestContext.dispatch({
            type: DataRequestActionType.SetPerspective,
            payload: this.dataRequestService.getDataRequestPerspective(dataRequest),
        })
        if (!dataRequest.emails?.length) {
            this.analyticsService.track('Open Draft Data Request', { uuid: dataRequest?.uuid, name: dataRequest?.name })
        } else {
            this.analyticsService.track('Viewing Data Request', { uuid: dataRequest?.uuid, name: dataRequest?.name })
        }
    }

    public updateDataRequest(dataRequest?: Partial<DataRequest>): DataRequest {
        const dr: DataRequest = { ...this.dataRequestContext.dataRequest, ...dataRequest }
        this.dataRequestContext.dispatch({ type: DataRequestActionType.Update, payload: dr })
        this.dataRequestContext.dispatch({
            type: DataRequestActionType.SetPerspective,
            payload: this.dataRequestService.getDataRequestPerspective(dr),
        })
        return dr
    }

    public backgroundUpdateDataRequest(dataRequest: DataRequest) {
        this.dataRequestContext.dispatch({
            type: DataRequestActionType.BackgroundUpdate,
            payload: { ...this.dataRequestContext.dataRequest, ...dataRequest },
        })
    }

    public deselectDataRequest() {
        this.dataRequestContext.dispatch({ type: DataRequestActionType.Deselect })
    }

    public async removeDataRequest() {
        if (this.dataRequestContext.dataRequest) {
            await this.dataRequestService.deleteDataRequest(this.dataRequestContext.dataRequest)
            this.dataRequestContext.dispatch({
                type: DataRequestActionType.Remove,
                payload: this.dataRequestContext.dataRequest,
            })
        }
    }

    public async sendDataRequest() {
        if (this.dataRequestContext.dataRequest) {
            try {
                const dr = await this.dataRequestService.emailDataRequest(this.dataRequestContext.dataRequest)
                Utils.successToast('Request sent')
                this.deselectDataRequest()
                this.backgroundUpdateDataRequest(dr)
                this.analyticsService.track('Sent Data Request', { uuid: dr?.uuid, name: dr?.name })
            } catch (e) {
                Utils.errorToast(e, 'Error sending email')
            }
        }
    }

    public async sendDataShare() {
        if (this.dataRequestContext.dataRequest) {
            try {
                const dr = await this.dataRequestService.emailDataShare(this.dataRequestContext.dataRequest)
                Utils.successToast('Delivery sent')
                this.updateDataRequest(dr)
                this.analyticsService.track('Sent Delivery', { uuid: dr?.uuid, name: dr?.name })
            } catch (e) {
                Utils.errorToast(e, 'Error sending email')
            }
        }
    }

    public reset() {
        this.dataRequestContext.dispatch({ type: DataRequestActionType.Reset })
    }
}

export default class DataRequestService extends VariableService {
    private basePath: string = '/data-requests'
    public static webRootRequests: string = '/request'
    public static webRootCollect: string = '/collect'
    public static webRootShare: string = '/share'
    public static webTitleRequest = (plural: boolean = false): string => Utils.pluralize('Request', plural ? 2 : 1)
    public static webTitleShare = (plural: boolean = false): string => Utils.pluralize('Delivery', plural ? 2 : 1)
    public static webTitle = (plural: boolean = false, dataRequest?: DataRequest): string => {
        if (dataRequest?.type === DataRequestType.SHARE) {
            return DataRequestService.webTitleShare(plural)
        }
        return DataRequestService.webTitleRequest(plural)
    }

    public static defaultDataRequestSubject = 'Data request'
    public static defaultDataRequestSummary = `I'm reaching out to you as one of our key suppliers to collect data to measure the carbon emissions in our value chain.`
    public static defaultDataShareSubject = 'Data delivery'
    public static defaultDataShareSummary = `Here's a summary of carbon emissions for this delivery.`

    public emptyDataRequest = (type?: DataRequestType): DataRequest => {
        return {
            name: '',
            sender: this.context.stores.user!,
            type: type || DataRequestType.COLLECT,
            startDate: Utils._dayjs().startOf('month').valueOf(),
            endDate: Utils._dayjs().endOf('month').valueOf(),
            subject:
                type === DataRequestType.SHARE
                    ? DataRequestService.defaultDataShareSubject
                    : DataRequestService.defaultDataRequestSubject,
            summary:
                type === DataRequestType.SHARE
                    ? DataRequestService.defaultDataShareSummary
                    : DataRequestService.defaultDataRequestSummary,
        }
    }

    public getDataRequestPerspective(dataRequest?: DataRequest): DataRequestPerspective {
        let incoming: boolean = true
        let outgoing: boolean = false
        let isSupplier: boolean | undefined = undefined
        let isCustomer: boolean | undefined = undefined
        let type: DataRequestType | undefined = undefined
        let owner: Company | undefined = dataRequest?.owner
        let otherCompany: Company | undefined
        let supplier: Company | undefined = dataRequest?.supplier
        const supplierId = supplier?.uuid || dataRequest?.supplierId
        let customer: Company | undefined = dataRequest?.customer
        const customerId = customer?.uuid || dataRequest?.customerId
        if (
            (!dataRequest?.uuid && dataRequest?.type === DataRequestType.COLLECT) ||
            customerId === this.context.stores.company?.uuid
        ) {
            incoming = true
            outgoing = false
            type = DataRequestType.COLLECT
            supplier = dataRequest?.supplier
            otherCompany = supplier
            isSupplier = false
            customer = this.context.stores.company
            isCustomer = true
        } else if (
            (!dataRequest?.uuid && dataRequest?.type === DataRequestType.SHARE) ||
            supplierId === this.context.stores.company?.uuid
        ) {
            incoming = false
            outgoing = true
            type = DataRequestType.SHARE
            supplier = this.context.stores.company
            isSupplier = true
            customer = dataRequest?.customer
            otherCompany = customer
            isCustomer = false
        }
        return {
            isOwner:
                dataRequest?.owner?.uuid !== undefined &&
                dataRequest?.owner?.uuid === this.context.stores.company?.uuid,
            owner: owner,
            supplier: supplier,
            customer: customer,
            otherCompany: otherCompany,
            incoming: incoming,
            outgoing: outgoing,
            type: type,
            direction: incoming ? ActivityDirection.INPUT : ActivityDirection.OUTPUT,
            isSupplier: isSupplier,
            isCustomer: isCustomer,
        }
    }

    public getDataRequestStateFromActivityState(activityState: VariableNodeState): DataRequestState {
        switch (activityState) {
            case ActivityState.PENDING:
                return DataRequestState.PENDING
            case ActivityState.REJECTED:
                return DataRequestState.REJECTED
            case ActivityState.APPROVED:
                return DataRequestState.APPROVED
            default:
                return DataRequestState.IN_PROGRESS
        }
    }

    public getDataRequestState(
        dataRequest?: DataRequest,
        activities?: ActivityItem[],
        useDrState?: boolean,
    ): DataRequestState {
        if (!activities?.length) {
            activities = dataRequest?.activities || []
        }
        const perspective = this.getDataRequestPerspective(dataRequest)
        const state = useDrState && dataRequest?.state ? dataRequest.state : activities?.[0]?.state
        const activityStates = new Set<ActivityState>(activities?.map((a) => a.state))

        if (useDrState && dataRequest?.state) {
            return this.getDataRequestStateFromActivityState(state)
        }

        if (activityStates.has(ActivityState.REJECTED)) {
            return DataRequestState.REJECTED
        }

        if (activityStates.size === 1) {
            if (activityStates.has(ActivityState.APPROVED)) {
                return DataRequestState.APPROVED
            }
            if (activityStates.has(ActivityState.PENDING)) {
                if (dataRequest?.type === DataRequestType.SHARE && !perspective.customer?.claimed) {
                    return DataRequestState.SENT
                }
                return DataRequestState.PENDING
            }
            if (dataRequest?.type === DataRequestType.SHARE) {
                if (activityStates.has(ActivityState.DRAFT) && dataRequest?.emails?.length) {
                    return DataRequestState.IN_PROGRESS
                }
                return DataRequestState.DRAFT
            } else {
                return DataRequestState.IN_PROGRESS
            }
        }

        if (activityStates.size === 0 && dataRequest?.emails?.length) {
            if (perspective.isSupplier) {
                return DataRequestState.RECEIVED
            }
            return DataRequestState.SENT
        }

        return DataRequestState.DRAFT
    }

    public getDataRequestStateValue(dataRequest?: DataRequest): number {
        const state = this.getDataRequestState(dataRequest)
        switch (state) {
            case DataRequestState.RECEIVED:
                return 1
            case DataRequestState.SENT:
                return 2
            case DataRequestState.IN_PROGRESS:
                return 3
            case DataRequestState.PENDING:
                return 4
            case DataRequestState.REJECTED:
                return 5
            case DataRequestState.APPROVED:
                return 6
            default:
                return 0
        }
    }

    public static getDataRequestUrl(dataRequest: DataRequest, fullUrl: boolean = false): string {
        let url = `${fullUrl ? document.location.origin : ''}`
        if (dataRequest.token?.value) {
            return `${url}/t/${dataRequest.token?.value}`
        }
        url += this.webRootRequests
        if (!dataRequest?.uuid) {
            return url
        }
        return `${url}/${dataRequest.uuid}`
    }

    public getDataRequests(queryString?: string): Promise<ListResponse<DataRequest>> {
        return this.httpService.get<ListResponse<DataRequest>>(`${this.basePath}?${queryString || ''}`)
    }

    public getSlimDataRequests(queryString?: string): Promise<ListResponse<DataRequest>> {
        const qs = new URLSearchParams(queryString)
        qs.set('return', 'uuid,name,activityCount,ownerId,supplierId,updated,state,type,impact')
        qs.set('limit', '-1')
        return this.httpService.get<ListResponse<DataRequest>>(`${this.basePath}?${qs.toString()}`)
    }

    public getDataRequest(dataRequestId: string): Promise<DataRequest> {
        return this.httpService.get<DataRequest>(`${this.basePath}/${dataRequestId}`)
    }

    public createOrUpdate(dataRequest: DataRequest): Promise<DataRequest> {
        return this.httpService.post<DataRequest>(this.basePath, {
            body: JSON.stringify({ dataRequest: dataRequest }),
        })
    }

    public deleteDataRequest(dataRequest: DataRequest): Promise<DataRequest> {
        return this.httpService.delete<DataRequest>(`${this.basePath}/${dataRequest.uuid}`)
    }

    public emailDataRequest(dataRequest: DataRequest): Promise<DataRequest> {
        return this.httpService.post<DataRequest>(this.basePath, {
            body: JSON.stringify({
                dataRequest: dataRequest,
                sendEmail: true,
            }),
        })
    }

    public emailDataShare(dataRequest: DataRequest): Promise<DataRequest> {
        return this.httpService.post<DataRequest>(this.basePath, {
            body: JSON.stringify({
                dataShare: dataRequest,
                sendEmail: true,
            }),
        })
    }
}
