import { CSSProperties, ReactNode, RefObject } from 'react'

const Card = (props: {
    passedRef?: RefObject<any>
    children: ReactNode
    innerClassName?: string
    className?: string
    style?: CSSProperties
    hidden?: boolean
}) => (
    <div ref={props.passedRef} className={`card ${props.className || ''}`} style={props.style} hidden={props.hidden}>
        <div className={`card-body ${props.innerClassName || ''}`}>{props.children}</div>
    </div>
)

export default Card
