const Spinner = (props: { className?: string; hidden?: boolean; size?: 'sm' | ''; padding?: string }) => {
    const spinnerClass = props.size === 'sm' ? 'spinner-border-sm' : ''
    return (
        <div
            className={[
                props.hidden ? 'd-none' : 'd-flex',
                'align-items-center justify-content-center',
                props.padding || 'm-4',
                props.className || '',
            ].join(' ')}
        >
            <div className={`spinner-border ${spinnerClass}`} role='status'>
                <span className='visually-hidden'>Loading...</span>
            </div>
        </div>
    )
}

export default Spinner
