import { Handle, NodeProps, Position } from 'reactflow'
import ProductService from '../../services/product'
import { Img } from '../Img'
import CO2e from '../CO2e'

import { PRODUCT_NODE_HEIGHT, PRODUCT_NODE_WIDTH } from '../../services/flow'
import { ConnectionStatus } from '../Icons/ConnectionStatus'
import { useContext, useEffect } from 'react'
import { VariableServicesContext } from '../../services'
import { ProductIcon } from '../Icons/ProductIcon'
import Utils from '../../services/utils'
import { useProduct } from '../../hooks/useProduct'
import { useInput } from '../../hooks/useInput'
import { ApplicationContext } from '../../context'

export const ProductNode = ({ id, zIndex, data }: NodeProps) => {
    const context = useContext(ApplicationContext)
    const { productService, companyService } = useContext(VariableServicesContext)
    const product = useProduct({ product: data?.product })
    const input = useInput({ input: data.input })
    const _isSourceProduct = input?.sourceProduct?.uuid === product?.uuid

    useEffect(() => {
        if (product?.uuid) productService.getById(product?.uuid).then()
    }, [context.stores.input.updates])

    return (
        <div
            className={[
                data.isMain ? 'shadow-lg' : _isSourceProduct ? '' : 'border-dashed',
                'border border-primary rounded-2 p-2 bg-white small overflow-hidden',
            ].join(' ')}
            style={{ width: `${PRODUCT_NODE_WIDTH}px`, maxHeight: `${PRODUCT_NODE_HEIGHT}px`, zIndex: zIndex }}
        >
            <div className='d-flex align-items-center gap-2'>
                <div className='d-flex flex-column'>
                    <Img
                        src={product?.productImageUrl}
                        size='50px'
                        placeholderIcon={<ProductIcon isProduct={data.isMain} size={Utils.largeIconSize} />}
                    />
                </div>
                <div className='fs-base overflow-hidden'>
                    <div className='text-overflow-ellipsis' title={ProductService.getProductName(product)}>
                        {ProductService.getProductName(product)}
                    </div>
                    {product?.productOf?.uuid && (
                        <div className='d-flex align-items-baseline'>
                            {!companyService.isMyCompany(product?.productOf) && (
                                <ConnectionStatus company={product?.productOf} />
                            )}
                            <div className='text-overflow-ellipsis small text-muted'>{product?.productOf?.name}</div>
                        </div>
                    )}
                    <CO2e
                        product={product}
                        co2e={product?.co2e}
                        unitSize='small'
                        unitsClassName='small text-muted'
                        functionalUnitClassName='ms-1'
                        functionalUnit={product?.unit?.code}
                    />
                </div>
            </div>
            <Handle type='target' position={Position.Left} id={id} />
            <Handle type='source' position={Position.Right} id={id} />
        </div>
    )
}
