import {
    CSSProperties,
    KeyboardEvent,
    MouseEvent,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { TriggerType, usePopperTooltip } from 'react-popper-tooltip'
import * as PopperJS from '@popperjs/core'
import Button from './Input/Button'
import { X } from '@phosphor-icons/react'
import Utils from '../services/utils'
import { UIOptionActionType } from '../services/ui'
import { ApplicationContext } from '../context'

export interface TooltipProps {
    id?: string
    className?: string
    ariaLabel?: string
    tooltipClassName?: string
    style?: CSSProperties
    tooltipContent?: ReactNode
    tooltipStyle?: CSSProperties
    placement?: PopperJS.Placement
    positioning?: PopperJS.PositioningStrategy
    tabIndex?: number
    trigger?: TriggerType
    showArrow?: boolean
    showWhenDisabled?: boolean
    hidden?: boolean
    tooltipHidden?: boolean
    visible?: boolean
    disabled?: boolean
    offset?: [number, number]
    type?: 'menu' | 'widget'
    interactive?: boolean
    delayHide?: number
    onFocus?: (e: any) => void
    onBlur?: (e: any) => void
    onClick?: (e: MouseEvent<any>, isVisible: boolean) => void
    onDoubleClick?: (e: MouseEvent<any>) => void
    onKeyDown?: (e: KeyboardEvent<any>) => void
    closeOnInteraction?: boolean
    onVisibleChange?: (state: boolean) => void
    closeOnOutsideClick?: boolean
    closeOnEscapeKey?: boolean
    closeButton?: boolean
    children: ReactNode
}

const Tooltip = (props: TooltipProps) => {
    const context = useContext(ApplicationContext)
    const [ready, setReady] = useState<boolean>(false)
    const [hasHovered, setHasHovered] = useState<boolean>(false)
    const [controlledVisible, setControlledVisible] = useState<boolean>(props.visible === true)
    const [tooltipId] = useState<string>(`tooltip-${Math.random()}`)

    const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, tooltipRef, visible } = usePopperTooltip({
        placement: props.placement || 'top',
        offset: props.offset || [0, 6],
        interactive: props.interactive === true,
        trigger: props.trigger || 'click',
        delayHide: props.delayHide || props.interactive ? 100 : 0,
        // closeOnOutsideClick: props.closeOnOutsideClick,
        visible: props.trigger === 'hover' ? props.visible : controlledVisible,
        onVisibleChange: props.onVisibleChange,
    })

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        setReady(true)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    useEffect(() => setControlledVisible(props.visible === true), [props.visible])

    useEffect(() => {
        if (controlledVisible) {
            context.dispatch({ type: UIOptionActionType.AddUiLayer, payload: tooltipId })
        } else if (ready) {
            setTimeout(() => {
                context.dispatch({ type: UIOptionActionType.RemoveUiLayer, payload: tooltipId })
            }, 100)
        }
    }, [controlledVisible])

    useEffect(() => {
        if (props.closeOnEscapeKey !== false && context.stores.ui?.layers?.[0] === tooltipId) {
            setControlledVisible(false)
        }
    }, [context.stores.ui?.escapeKey])

    const handleClickOutside = useCallback(
        (e: any) => {
            if (controlledVisible && tooltipRef && !tooltipRef.contains(e.target)) {
                setControlledVisible(false)
            }
        },
        [controlledVisible, tooltipRef],
    )

    const invisibleLayer = useMemo(() => {
        if ((Utils.isTouchScreen() || props.closeOnOutsideClick) && controlledVisible) {
            return (
                <Button
                    className='btn-unstyled fill-parent z-index-tooltip cursor-default'
                    onClick={() => setControlledVisible(false)}
                />
            )
        }
        return null
    }, [props.closeOnOutsideClick, controlledVisible])

    const onClick = useCallback(
        (e: MouseEvent<any>) => {
            if (props.disabled || (props.trigger === 'right-click' && !controlledVisible)) {
                return
            }
            setControlledVisible(!controlledVisible)
            props.onClick?.(e, visible)
        },
        [props.disabled, props.trigger, controlledVisible, visible, props.onClick],
    )

    const onAuxClick = useCallback(
        (e: MouseEvent<any>) => {
            if (props.disabled || props.trigger !== 'right-click') {
                return
            }
            setControlledVisible(!controlledVisible)
            props.onClick?.(e, visible)
        },
        [props.disabled, props.trigger, controlledVisible, visible, props.onClick],
    )

    const onDoubleClick = useCallback(
        (e: MouseEvent<any>) => {
            if (!props.disabled) {
                props.onDoubleClick?.(e)
            }
        },
        [props.disabled, props.onDoubleClick],
    )

    const onKeyDown = useCallback(
        (e: KeyboardEvent<any>) => {
            if (props.disabled) {
                return
            }
            if (e.key === 'Enter') {
                setControlledVisible(true)
                props.onVisibleChange?.(true)
            }
            props.onKeyDown?.(e)
        },
        [props.disabled, props.onKeyDown, props.onVisibleChange],
    )

    const button = useMemo(
        () => (
            <span
                id={props.id}
                role='link'
                aria-label={props.ariaLabel}
                tabIndex={props.tabIndex || 0}
                hidden={props.hidden}
                ref={setTriggerRef}
                className={[props.className, 'clickable', props.disabled ? 'disabled cursor-default' : ''].join(' ')}
                onClick={onClick}
                onAuxClick={onAuxClick}
                onDoubleClick={onDoubleClick}
                onKeyDown={onKeyDown}
                onFocus={props.onFocus}
                onBlur={(e) => {
                    if (!hasHovered && !Utils.isTouchScreen()) {
                        setControlledVisible(false)
                    }
                    props.onBlur?.(e)
                }}
                style={props.style}
            >
                {props.children}
            </span>
        ),
        [
            props.id,
            props.tabIndex,
            props.hidden,
            props.ariaLabel,
            setTriggerRef,
            props.className,
            props.disabled,
            onClick,
            onAuxClick,
            onDoubleClick,
            onKeyDown,
            props.onFocus,
            props.onBlur,
            hasHovered,
            props.style,
            props.children,
        ],
    )

    const closeButton = useMemo(() => {
        if (props.closeButton === true) {
            return (
                <Button
                    hidden={props.visible !== undefined}
                    onClick={(e) => {
                        e.preventDefault()
                        setControlledVisible(false)
                    }}
                    className='btn btn-xs position-absolute end-0 top-0 z-index-fixed text-primary-hover bg-light-hover'
                >
                    <X size={Utils.verySmallIconSize} />
                </Button>
            )
        }
    }, [props.closeButton, props.visible])

    const arrow = useMemo(() => {
        if (props.trigger === 'hover' && props.showArrow !== false) {
            return <span {...getArrowProps({ className: 'tooltip-arrow' })} />
        }
    }, [props.trigger, props.showArrow, getArrowProps])

    const tooltipProps = useMemo(() => {
        return getTooltipProps({
            className: [
                'tooltip-container',
                props.tooltipClassName,
                props.positioning === 'fixed' ? 'position-fixed' : 'position-absolute',
            ].join(' '),
            style: { ...props.tooltipStyle },
        })
    }, [props.tooltipClassName, props.positioning, props.tooltipStyle, getTooltipProps])

    const menu = useMemo(() => {
        if ((props.disabled && !props.showWhenDisabled) || !visible || props.tooltipHidden) return

        return (
            <span
                role='menu'
                tabIndex={0}
                ref={setTooltipRef}
                onMouseOver={() => setHasHovered(true)}
                onMouseOut={() => setHasHovered(false)}
                onBlur={() => setHasHovered(false)}
                onFocus={() => setHasHovered(true)}
                onClick={() => props.closeOnInteraction && setControlledVisible(false)}
                onKeyDown={() => {}}
                {...tooltipProps}
            >
                {closeButton}
                {props.tooltipContent}
                {arrow}
            </span>
        )
    }, [
        visible,
        tooltipProps,
        arrow,
        closeButton,
        props.disabled,
        props.showWhenDisabled,
        props.closeOnInteraction,
        props.tooltipHidden,
        props.tooltipContent,
    ])

    return (
        <>
            {invisibleLayer}
            {button}
            {menu}
        </>
    )
}

export default Tooltip
