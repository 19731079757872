import { createContext, Dispatch, ReactNode, useReducer } from 'react'
import { User, UserActions, UserReducer } from './services/user-context'
import { Company, CompanyActions, CompanyReducer } from './services/company'
import { UnitActions, UnitLargeReducer, UnitSmallReducer } from './services/unit'
import { OrgActions, OrgContext, OrgReducer } from './services/org'
import { UnitString } from './components/CO2e'
import Utils from './services/utils'
import { DataImport, DataImportActions, DataImportReducer } from './services/dataImport'
import { IndustryActions, IndustryReducer, IndustrySector } from './services/industries'
import { Onboarding, OnboardingActions, OnboardingReducer } from './services/onboarding'
import UiService, { UIOptionActions, UIOptionReducer, UIOptions } from './services/ui'
import { PartActions, PartContext, PartReducer } from './services/part'
import { ProductActions, ProductContext, ProductReducer } from './services/product'
import { TokenValueActions, TokenValueReducer } from './services/token'
import { CategoryModelActions, CategoryModelReducer, ICategoryModel } from './services/category-model'
import { InventoryActions, InventoryContext, InventoryReducer } from './services/inventory'
import { FlowConfig, FootprintFlowActions, FootprintFlowReducer } from './services/flow'
import { IWorkspaceContext, WorkspaceActions, WorkspaceReducer } from './services/workspace'
import {
    GlobalActivityActions,
    GlobalActivityReducer,
    IGlobalActivityContext,
    initialGlobalActivityState,
} from './services/bulkActivityContext'
import { BatchActions, BatchContext, BatchReducer } from './services/batch'
import { initialStatusState, StatusActions, StatusReducer, StatusResponse } from './services/status'
import { FilterActions, FilterReducer, IFilterContext } from './services/filter'
import { CpcCode, CpcCodeActions, CpcCodeReducer } from './services/cpc'
import {
    emptyITransportTypeEditor,
    ITransportTypeEditor,
    TransportActions,
    TransportReducer,
} from './services/transport'
import { defaultInputContext, InputActions, InputContext, InputReducer } from './services/input'
import {
    emptyIProcessingTypeEditor,
    IProcessingTypeEditor,
    ProcessingTypeActions,
    ProcessingTypeReducer,
} from './services/processing'
import { IUseStageEditor, UseStageTypeActions, UseStageTypeReducer } from './services/useStage'

type ActionMap<M extends { [index: string]: unknown }> = {
    [Key in keyof M]: M[Key] extends undefined ? { type: Key } : { type: Key; payload: M[Key] }
}

type Actions =
    | UserActions
    | CompanyActions
    | UnitActions
    | OrgActions
    | DataImportActions
    | IndustryActions
    | OnboardingActions
    | UIOptionActions
    | PartActions
    | InventoryActions
    | ProductActions
    | InputActions
    | TransportActions
    | ProcessingTypeActions
    | UseStageTypeActions
    | TokenValueActions
    | CategoryModelActions
    | FootprintFlowActions
    | WorkspaceActions
    | GlobalActivityActions
    | BatchActions
    | StatusActions
    | FilterActions
    | CpcCodeActions

interface IApplicationContext {
    status: StatusResponse
    user: User | null
    company: Company | undefined
    workspace: IWorkspaceContext
    filters: IFilterContext
    activity: IGlobalActivityContext
    unitLarge: UnitString | undefined
    unitSmall: UnitString | undefined
    cpcCodes: Map<string, CpcCode>
    orgs: OrgContext
    industries: IndustrySector[]
    dataImports: DataImport[]
    onboarding: Onboarding | undefined
    ui: UIOptions | undefined
    parts: PartContext
    products: ProductContext
    input: InputContext
    transport: ITransportTypeEditor
    processingType: IProcessingTypeEditor
    useStageType: IUseStageEditor
    footprintFlow: FlowConfig
    inventory: InventoryContext
    batch: BatchContext
    categoryModel: ICategoryModel
    tokenValue: string | undefined
}

const initialState: IApplicationContext = {
    status: initialStatusState,
    user: null,
    company: undefined,
    workspace: { newWorkspace: undefined },
    filters: { list: [], showManager: false },
    activity: { ...initialGlobalActivityState },
    unitLarge: Utils?.bigCo2eNumbers || 't',
    unitSmall: Utils?.smallCo2eNumbers || 'kg',
    cpcCodes: new Map<string, CpcCode>(),
    orgs: { list: [] },
    industries: [],
    dataImports: [],
    onboarding: undefined,
    ui: { ...UiService.uiOptions, footprintSearchConfig: { view: 'database' } },
    parts: { selectedId: undefined, updates: 0 },
    products: {},
    input: { ...defaultInputContext },
    transport: { ...emptyITransportTypeEditor },
    processingType: { ...emptyIProcessingTypeEditor },
    useStageType: {},
    footprintFlow: { nodes: [], edges: [], refresh: 0 },
    inventory: {},
    batch: { list: [] },
    categoryModel: {},
    tokenValue: undefined,
}

export interface ApplicationContextInterface {
    stores: IApplicationContext
    dispatch: Dispatch<Actions>
}

/** The React Context that initializes the stores and sets the reducer. */
const ApplicationContext = createContext<ApplicationContextInterface>({
    stores: initialState,
    dispatch: () => null,
})

/** Composes the reducers for the individual stores into one. */
const mainReducer = (state: IApplicationContext, action: Actions): IApplicationContext => ({
    status: StatusReducer(state.status, action as StatusActions),
    user: UserReducer(state.user, action as UserActions),
    company: CompanyReducer(state.company, action as CompanyActions),
    workspace: WorkspaceReducer(state.workspace, action as WorkspaceActions),
    activity: GlobalActivityReducer(state.activity, action as GlobalActivityActions),
    unitLarge: UnitLargeReducer(state.unitLarge, action as UnitActions),
    unitSmall: UnitSmallReducer(state.unitSmall, action as UnitActions),
    cpcCodes: CpcCodeReducer(state.cpcCodes, action as CpcCodeActions),
    orgs: OrgReducer(state.orgs, action as OrgActions),
    industries: IndustryReducer(state.industries, action as IndustryActions),
    dataImports: DataImportReducer(state.dataImports, action as DataImportActions),
    onboarding: OnboardingReducer(state.onboarding, action as OnboardingActions),
    ui: UIOptionReducer(state.ui, action as UIOptionActions),
    parts: PartReducer(state.parts, action as PartActions),
    inventory: InventoryReducer(state.inventory, action as InventoryActions),
    products: ProductReducer(state.products, action as ProductActions),
    input: InputReducer(state.input, action as InputActions),
    transport: TransportReducer(state.transport, action as TransportActions),
    processingType: ProcessingTypeReducer(state.processingType, action as ProcessingTypeActions),
    useStageType: UseStageTypeReducer(state.useStageType, action as UseStageTypeActions),
    footprintFlow: FootprintFlowReducer(state.footprintFlow, action as FootprintFlowActions),
    batch: BatchReducer(state.batch, action as BatchActions),
    filters: FilterReducer(state.filters, action as FilterActions),
    tokenValue: TokenValueReducer(state.tokenValue, action as TokenValueActions),
    categoryModel: CategoryModelReducer(state.categoryModel, action as CategoryModelActions),
})

const ApplicationProvider = ({ children }: { children: ReactNode }) => {
    const [stores, dispatch] = useReducer(mainReducer, initialState)
    return <ApplicationContext.Provider value={{ stores, dispatch }}>{children}</ApplicationContext.Provider>
}

export type { ActionMap }
export { ApplicationContext, ApplicationProvider }
