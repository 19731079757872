import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { GeoLocation } from '../../services/geoLocation'
import { Product } from '../../services/product'
import { SelectorProps } from './Selector'
import { VariableServicesContext } from '../../services'
import { LocationSelector } from '../Location/LocationSelector'
import { Location } from '../../services/location'
import { StandardAttributes } from '../../types'
import Utils from '../../services/utils'
import { ElementBadge } from '../Product/ElementBadge'
import { ElectricityGridSelector } from './ElectricityGridSelector'

export const ElectricitySelector = (
    props: StandardAttributes & {
        geoLocation?: GeoLocation | null
        location?: Location | null
        electricity?: Product | null
        showGridSelector?: boolean
        showEnergySource?: boolean
        selectorProps?: Partial<SelectorProps>
        locationProps?: Partial<SelectorProps>
        gridProps?: Partial<SelectorProps>
        energySourceProps?: Partial<SelectorProps>
        onChange: (geoLocation?: GeoLocation | null, location?: Location | null, footprints?: Product[]) => void
    },
) => {
    const { geoLocationService } = useContext(VariableServicesContext)
    const [geoLocation, setGeoLocation] = useState<GeoLocation | undefined | null>(props.geoLocation)
    const [location, setLocation] = useState<Location | undefined | null>(props.location)

    useEffect(() => setGeoLocation(props.geoLocation), [props.geoLocation])

    const save = useCallback(
        (gl?: GeoLocation | null, loc?: Location | null, fps?: Product[]) => {
            setGeoLocation(gl)
            setLocation(loc)
            props.onChange(gl, loc, fps)
        },
        [props.onChange],
    )

    const fetchElectricity = useCallback(async (gl?: GeoLocation | null) => {
        if (!gl) return Promise.reject()
        return geoLocationService.getElectricityGeoLocation(gl)
    }, [])

    const gridSelector = useMemo(() => {
        if (!props.showGridSelector && !Utils.inDebugMode()) return null
        return (
            <ElectricityGridSelector
                electricity={props.electricity}
                geoLocation={geoLocation}
                disabled={props.disabled}
                selectorProps={{ placeholder: 'Select', className: 'variable-form-select', placement: 'bottom-start' }}
                onChange={(products) => save(geoLocation, location, products)}
                {...props.selectorProps}
                {...props.gridProps}
            />
        )
    }, [
        geoLocation,
        location,
        props.showGridSelector,
        props.electricity?.uuid,
        props.disabled,
        props.selectorProps,
        props.gridProps,
    ])

    const energySourceSelector = useMemo(() => {
        if (!props.showEnergySource && !Utils.inDebugMode()) return null
        if (!props.electricity) return null
        return <ElementBadge product={props.electricity} />
    }, [props.showEnergySource, props.energySourceProps, props.electricity])

    return (
        <span hidden={props.hidden} className={[props.className, props.extraClassName].join(' ')} style={props.style}>
            <LocationSelector
                className='variable-form-select'
                placement='bottom-start'
                location={location}
                geoLocation={geoLocation}
                disabled={props.disabled}
                onLocation={async (gl, loc) => {
                    const electricityFootprints = await fetchElectricity(gl)
                    save(gl, loc, electricityFootprints.footprints)
                }}
                {...props.selectorProps}
                {...props.locationProps}
            />
            {gridSelector}
            {energySourceSelector}
        </span>
    )
}
