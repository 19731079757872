import { AsteriskSimple } from '@phosphor-icons/react'
import Utils from '../../services/utils'
import { StandardAttributes } from '../../types'
import Tooltip from '../Tooltip'
import { useContext, useMemo } from 'react'
import { ApplicationContext } from '../../context'
import { Product, ProductVisibility } from '../../services/product'

export const PactRequired = (props: StandardAttributes & { product?: Product; required?: boolean }) => {
    const context = useContext(ApplicationContext)
    const productVisibility = useMemo(
        () => props.product?.visibility || ProductVisibility.PRIVATE,
        [props.product?.visibility],
    )
    if (!context.stores.products.highlightPactFields) {
        if (productVisibility < ProductVisibility.API || !context.stores.company?.slug || !props.required) return null
    }
    return (
        <Tooltip
            trigger='hover'
            interactive={true}
            tooltipContent={
                <span className='small' style={{ maxWidth: '14rem' }}>
                    This field is required to be conformant to the{' '}
                    <a
                        href='https://www.carbon-transparency.com'
                        className='text-decoration-underline mt-1'
                        target='_blank'
                        rel='noreferrer'
                    >
                        PACT standard
                    </a>
                </span>
            }
        >
            <AsteriskSimple
                size={Utils.tinyIconSize}
                className={[props.className || 'nt--1', props.extraClassName].join(' ')}
                style={
                    props.style || {
                        color: context.stores.products.highlightPactFields ? Utils.dangerColor : '#00a47d',
                    }
                }
            />
        </Tooltip>
    )
}
