import { useContext, useEffect, useState } from 'react'
import { ApplicationContext } from '../context'
import { Product } from '../services/product'
import { VariableServicesContext } from '../services'
import { CreateProductFrom } from '../services/inventory'

export const useElementCreator = (props?: { onCreated?: (product: Product, instanceId?: string) => void }) => {
    const context = useContext(ApplicationContext)
    const { inventoryService } = useContext(VariableServicesContext)
    const [instanceId, setInstanceId] = useState<string>()

    useEffect(() => {
        if (context.stores.inventory.createdFrom) {
            props?.onCreated?.(context.stores.inventory.createdFrom, instanceId)
            inventoryService.setCreateFrom(undefined)
            setInstanceId(undefined)
        }
    }, [context.stores.inventory.createdFrom])

    const createElementFrom = (createFrom: CreateProductFrom) => {
        setInstanceId(createFrom.instanceId)
        inventoryService.setCreateFrom(createFrom)
    }

    return { createElementFrom }
}
