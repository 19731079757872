import { IconProps } from '@phosphor-icons/react'

export const UploadIcon = (props: IconProps) => (
    <svg
        style={{
            maxWidth: props.size || '100%',
            height: 'auto',
        }}
        xmlns='http://www.w3.org/2000/svg'
        version='1.1'
        x='0px'
        y='0px'
        viewBox='0 0 149 135'
        enableBackground='new 0 0 100 100'
        id='svg12'
        width='149'
        height='135'
    >
        <g id='g6' transform='matrix(1.4348883,0,0,1.4348883,2.8255585,-4.0733844)'>
            <path
                d='m 85.631,38.233 c -0.942,-0.569 -2.136,-1.087 -2.657,-1.304 0.061,-0.535 0.188,-1.758 0.188,-2.84 0,-13.39 -10.894,-24.281 -24.283,-24.281 -7.702,0 -15.025,3.716 -19.589,9.937 -0.498,0.681 -0.997,1.496 -1.319,2.047 -0.569,-0.332 -1.445,-0.812 -2.285,-1.13 -1.729,-0.655 -3.55,-0.988 -5.417,-0.988 -8.493,0 -15.403,6.909 -15.403,15.401 0,1.093 0.192,2.258 0.28,2.742 -0.438,0.238 -1.488,0.832 -2.332,1.462 C 7.92,42.915 5,48.722 5,54.809 c 0,10.67 8.681,19.35 19.351,19.35 h 7.176 c 0.88,0 1.593,-0.712 1.593,-1.592 0,-0.881 -0.713,-1.593 -1.593,-1.593 h -7.176 c -8.915,0 -16.167,-7.251 -16.167,-16.165 0,-5.162 2.38,-9.891 6.528,-12.977 0.873,-0.648 2.109,-1.302 2.116,-1.307 1.095,-0.57 1.73,-1.915 1.479,-3.127 -0.002,-0.013 -0.258,-1.262 -0.258,-2.323 0,-6.736 5.482,-12.217 12.22,-12.217 1.479,0 2.921,0.262 4.287,0.781 1.055,0.399 2.298,1.198 2.309,1.206 0.581,0.376 1.294,0.497 1.958,0.33 0.667,-0.166 1.24,-0.611 1.577,-1.219 0.007,-0.014 0.757,-1.373 1.458,-2.329 3.967,-5.407 10.329,-8.636 17.021,-8.636 11.635,0 21.099,9.464 21.099,21.098 0,1.193 -0.191,2.684 -0.194,2.697 -0.165,1.253 0.595,2.54 1.762,2.997 0.014,0.005 1.455,0.582 2.442,1.176 4.902,2.956 7.829,8.133 7.829,13.849 0,8.914 -7.252,16.165 -16.167,16.165 h -7.176 c -0.88,0 -1.593,0.712 -1.593,1.593 0,0.88 0.713,1.592 1.593,1.592 h 7.176 c 10.67,0 19.351,-8.68 19.351,-19.35 C 95,47.968 91.498,41.771 85.631,38.233 Z'
                id='path2'
            />
            <path
                d='m 50,90.191 c 0.879,0 1.592,-0.714 1.592,-1.592 V 46.606 L 64.195,59.21 c 0.312,0.311 0.719,0.467 1.125,0.467 0.407,0 0.815,-0.156 1.126,-0.467 0.622,-0.622 0.622,-1.63 0,-2.251 L 52.149,42.663 C 51.004,41.515 48.995,41.515 47.851,42.661 L 33.554,56.959 c -0.622,0.621 -0.622,1.629 0,2.251 0.621,0.622 1.63,0.622 2.251,0 L 48.409,46.606 V 88.6 c -10e-4,0.878 0.712,1.591 1.591,1.591 z'
                id='path4'
            />
        </g>
    </svg>
)
