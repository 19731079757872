import { Org } from './org'
import { Taxonomy } from './taxonomy'
import { CO2ByYear, VariableBaseNode } from '../types'
import VariableService from './service'
import { UIOptionActionType } from './ui'

export interface GHGScope extends VariableBaseNode {
    name: string
    order?: number
    parent?: GHGScope
    children?: GHGScope[]
    orgs?: Org[]
    taxonomies?: Taxonomy[]
    totalCo2e?: number
    co2eByYear?: CO2ByYear[]
    upstreamCo2e?: number
    downstreamCo2e?: number
    marketTotalCo2e?: number
    marketCo2eByYear?: CO2ByYear[]
}

export default class GHGService extends VariableService {
    private basePath: string = '/ghg'

    public static list: GHGScope[] = []
    public static byId: Map<string, GHGScope> = new Map<string, GHGScope>()
    public static byName: Map<string, GHGScope> = new Map<string, GHGScope>()

    public static fullScopeName(scope?: GHGScope): string {
        switch (scope?.parent?.name) {
            case 'Scope 1':
                return `S1: ${scope.name}`
            case 'Scope 2':
                return `S2: ${scope.name}`
            case 'Scope 3':
                return `S3: ${scope.name}`
        }
        return scope?.name || ''
    }

    public async getScopes(): Promise<GHGScope[]> {
        if (GHGService.list.length > 0) {
            return GHGService.list
        }
        return this.httpService.get<GHGScope[]>(this.basePath).then((scopes) => {
            scopes.forEach((scope) => GHGService.byName.set(scope.name.toLowerCase(), scope))
            scopes.forEach((scope) => scope.uuid && GHGService.byId.set(scope.uuid, scope))
            GHGService.list = scopes
            this.context.dispatch({ type: UIOptionActionType.GhgReady, payload: true })
            return scopes
        })
    }
}
