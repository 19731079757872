import { ActionMap } from '../context'
import { Product } from './product'
import { ActivityItem } from './activity'
import Utils from './utils'
import { Company } from './company'
import VariableService from './service'
import { VariableBaseNode } from '../types'

export interface ProductionBatch extends VariableBaseNode {
    name: string
    description?: string
    company?: Company
    activities?: ActivityItem[]
    product?: Product
    co2eIntensity?: string
    co2e?: string
}

export interface BatchContext {
    list: ProductionBatch[]
    selected?: ProductionBatch
}

export enum BatchActionType {
    SetList = 'SetBatch',
}

type BatchActionPayload = {
    [BatchActionType.SetList]: ProductionBatch[]
}

export type BatchActions = ActionMap<BatchActionPayload>[keyof ActionMap<BatchActionPayload>]

export const BatchReducer = (state: BatchContext, action: BatchActions): BatchContext => {
    switch (action.type) {
        case BatchActionType.SetList:
            return { ...state, list: action.payload }
        default:
            return state
    }
}

export default class BatchService extends VariableService {
    public static webTitle = (plural: boolean = false): string => Utils.pluralize('Batch', plural ? 2 : 1)
    public static webRootList: string = '/batches'
    public static webRoot: string = '/batch'

    public static getBatchUrl(batch?: ProductionBatch, fullUrl: boolean = false): string {
        let url = fullUrl ? document.location.origin : ''
        if (batch?.uuid) {
            url += `${BatchService.webRoot}/${batch.uuid}`
        } else {
            url += BatchService.webRootList
        }
        return url
    }

    private basePath: string = '/batch'

    public async getBatches(queryString?: string): Promise<ProductionBatch[]> {
        let url = this.basePath
        if (queryString) {
            url += `?${queryString}`
        }
        return this.httpService.get<ProductionBatch[]>(url).then((batches) => {
            if (!queryString) {
                this.context.dispatch({ type: BatchActionType.SetList, payload: batches.sort(Utils.sortByName) })
            }
            return batches
        })
    }

    public async getBatch(batchId: string): Promise<ProductionBatch> {
        return this.httpService.get<ProductionBatch>(`${this.basePath}/${batchId}`)
    }

    public async createOrUpdateBatch(batch: ProductionBatch): Promise<ProductionBatch> {
        return this.httpService
            .put<ProductionBatch>(this.basePath, {
                body: JSON.stringify({ batch: batch }),
            })
            .then((_batch) => {
                this.getBatches()
                return _batch
            })
    }

    public async deleteBatch(batch: ProductionBatch): Promise<void> {
        return this.httpService.delete<void>(`${this.basePath}/${batch.uuid}`).then((_batch) => {
            this.getBatches()
            return _batch
        })
    }
}
