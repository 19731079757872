import { Filter, FilterActionType } from '../../services/filter'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { VariableServicesContext } from '../../services'
import { Selector, SelectorProps } from './Selector'
import { QueryOptions } from '../../types'
import Utils from '../../services/utils'
import { ApplicationContext } from '../../context'
import Button from './Button'
import { Funnel } from '@phosphor-icons/react'
import { FilterType } from './Filters'

export const FilterSelector = (
    props: SelectorProps & {
        filter?: Filter
        filters?: FilterType[]
        queryOptions?: QueryOptions
        onSelect: (filter: Filter) => void
    },
) => {
    const context = useContext(ApplicationContext)
    const { filterService } = useContext(VariableServicesContext)
    const [menuOpen, setMenuOpen] = useState<boolean>(props.menuOpen || false)
    const [filters, setFilters] = useState<Filter[]>([])
    const [filter, setFilter] = useState<Filter | undefined>(props.filter)

    useEffect(() => {
        if (!context.stores.filters.list.length) filterService.getFilters().then()
    }, [])

    useEffect(() => {
        if (filter?.uuid) {
            const filterMatch = context.stores.filters.list.find((f) => f.uuid === filter?.uuid)
            if (filterMatch) {
                setFilter(filterMatch)
                props.onSelect(filterMatch)
            }
        }
        setFilters([...context.stores.filters.list])
    }, [context.stores.filters.list])

    useEffect(() => {
        const filterMatch = filters.find((f) => Utils.isEqual(f.queryOptions, props.queryOptions))
        setFilter({ ...filterMatch })
    }, [props.queryOptions, filters])

    const filterList = useMemo(() => {
        return context.stores.filters.list
        // const queryOptionKeys = Object.keys(props.queryOptions || {})
        // return context.stores.filters.list.filter((f) => {
        //     const filterKeys = Object.keys(f.queryOptions || {})
        //     console.log(queryOptionKeys, filterKeys)
        //     return queryOptionKeys.some((k) => filterKeys.includes(k))
        // })
    }, [context.stores.filters.list])

    const onCreate = useCallback(
        (newOpt: any) => {
            const _qo: QueryOptions = {}
            props.filters?.forEach((f) => {
                if (props.queryOptions?.[f] !== undefined) {
                    _qo[f] = props.queryOptions[f]
                }
            })
            filterService
                .saveFilter({ name: newOpt.name, queryOptions: _qo })
                .then(props.onSelect)
                .catch(Utils.errorToast)
        },
        [props.filters, props.queryOptions, props.onSelect],
    )

    const header = useMemo(() => {
        if (context.stores.filters.list.length > 0) return null
        return <div className='bg-light px-2 py-1 text-muted'>Type a name to save a new filter</div>
    }, [context.stores.filters.list.length])

    const footer = useMemo(() => {
        return (
            <div className='bg-info bg-opacity-10 px-3 py-2 mx--2 mb--2 mt-2'>
                <Button
                    className='btn btn-xxs underline-on-hover'
                    onClick={() => {
                        context.dispatch({ type: FilterActionType.ShowManager, payload: true })
                        setMenuOpen(false)
                    }}
                >
                    Manage filters
                </Button>
            </div>
        )
    }, [])

    return (
        <Selector
            {...props}
            placeholder={props.placeholder || 'Saved filters'}
            placement={props.placement || 'bottom-end'}
            label={props.label || <Funnel weight={filter?.uuid ? 'duotone' : 'bold'} />}
            createPrefix={<span className='fw-bold'>Save as: </span>}
            menuOpen={menuOpen}
            tooltipClassName='bg-white'
            onVisibilityChange={setMenuOpen}
            onCreate={onCreate}
            header={header}
            footer={footer}
            options={filterList}
            option={filter}
        />
    )
}
