import { KeyValuePair } from '../types'
import { useContext, useMemo } from 'react'
import { ApplicationContext } from '../context'
import Utils from '../services/utils'

type DateOption = 'all' | 'mtd' | 'ytd' | 'last-year' | 'range'

export const useDateOptions = () => {
    const context = useContext(ApplicationContext)

    const activityYears = useMemo(() => {
        const now = Utils._dayjs()
        let sd = Utils._dayjs(context.stores.status.activityRange?.[0] || now.subtract(5, 'years'))
        const ed = Utils.dayjs(context.stores.status.activityRange?.[1] || now)
        let i = 0
        const years: number[] = []
        while (sd.isBefore(ed) && sd.year() <= now.year() && i < 50) {
            years.push(sd.year())
            sd = sd.add(1, 'year')
            i++
        }
        return years.reverse()
    }, [context.stores.status.activityRange])

    const dateOptions: KeyValuePair<DateOption>[] = useMemo(() => {
        const _do: KeyValuePair<DateOption>[] = [
            { name: 'All', value: 'all' },
            { name: 'MTD', value: 'mtd' },
            { name: 'YTD', value: 'ytd' },
        ]
        // _do.push({ name: 'Last year', value: 'last-year' })
        // _do.push({ name: 'From - To', value: 'range' })
        return _do
    }, [activityYears])

    return { dateOptions, activityYears }
}
