import { CSSProperties, useContext } from 'react'
import { Img } from './Img'
import { ApplicationContext } from '../context'

type LogoStyle = 'header' | 'text' | 'icon'

const Logo = (props: { logoStyle?: LogoStyle; style?: CSSProperties; className?: string; version?: string }) => {
    const context = useContext(ApplicationContext)
    if (props.logoStyle === 'header') {
        return (
            <Img
                className={props.className}
                style={props.style}
                data={`/logo/${
                    props.version ||
                    (context.stores.ui?.colorMode === 'dark' ? 'logo-horizontal-ondark' : 'logo-horizontal-onlight')
                }.svg`}
                alt='Variable'
            />
        )
    }
    if (props.logoStyle === 'icon') {
        return (
            <svg
                width='276'
                height='276'
                viewBox='0 0 276 276'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
                className={props.className}
                style={props.style}
            >
                <path d='M257.512 69C232.862 26.3028 187.303 0 138.001 0C88.6984 0 43.139 26.3028 18.4893 69C-6.1631 111.697 -6.1631 164.303 18.4893 207C43.1417 249.697 88.6984 276 138.001 276C187.303 276 232.862 249.697 257.512 207C282.161 164.303 282.164 111.697 257.512 69ZM245.561 200.1C223.445 238.406 182.23 262.2 138.001 262.2C93.7714 262.2 52.5561 238.406 30.4402 200.1C8.32419 161.797 8.32419 114.203 30.4402 75.9C52.5561 37.5967 93.7686 13.8 138.001 13.8C182.23 13.8 223.445 37.5967 245.561 75.9C267.677 114.203 267.677 161.797 245.561 200.1Z' />
                <path d='M138.001 34.7319C78.2133 34.7319 18.6771 137.857 48.5707 189.631C63.519 215.52 100.76 228.465 138.001 228.465C175.241 228.465 212.488 215.52 227.433 189.631C257.327 137.857 197.788 34.7319 138.001 34.7319ZM215.482 182.734C204.282 202.131 173.87 214.665 138.001 214.665C102.132 214.665 71.719 202.129 60.5216 182.731C49.3215 163.334 53.674 130.727 71.6086 99.6664C89.5431 68.6054 115.603 48.5319 138.001 48.5319C160.401 48.5319 186.461 68.6026 204.395 99.6664C222.33 130.73 226.68 163.334 215.482 182.734Z' />
                <path d='M138.001 69.552C132.238 69.552 126.475 72.403 123.179 78.1107L78.7211 155.112C72.1357 166.519 80.3688 180.78 93.5423 180.78H182.459C195.63 180.78 203.863 166.519 197.277 155.112L152.822 78.108C149.526 72.403 143.763 69.552 138.001 69.552ZM185.327 165.324C184.89 166.08 184.038 166.98 182.459 166.98H93.5423C91.9608 166.98 91.1108 166.08 90.6719 165.324C90.2358 164.571 89.8826 163.381 90.6719 162.012L135.13 85.008C135.922 83.639 137.126 83.352 137.998 83.352C138.87 83.352 140.076 83.639 140.868 85.008L185.327 162.012C186.119 163.381 185.765 164.568 185.327 165.324Z' />
            </svg>
        )
    }
    if (props.logoStyle === 'text') {
        return (
            <svg
                width='100%'
                // height='auto'
                viewBox='0 0 713 137'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
                className={props.className}
                style={props.style}
            >
                <path d='M0 29.1027H17.8097L51.5417 119.325H53.4291L87.3706 29.1027H105.18L64.9514 132.89H40.229L0 29.1027Z' />
                <path d='M104.969 106.583C104.969 88.2921 120.891 74.7279 152.32 71.234L181.654 67.7402V63.6298C181.654 49.6546 171.387 40.6118 153.997 40.6118C138.073 40.6118 127.596 48.216 127.806 61.1636H110.207C110.207 39.1731 127.598 25.1962 154.208 25.1962C181.656 25.1962 199.046 40.4046 199.046 64.0393V132.888H181.656V117.681H179.768C172.227 129.6 159.865 136.178 142.892 136.178C120.053 136.178 104.969 124.463 104.969 106.583ZM181.654 91.582V82.332L152.32 85.6203C130.739 88.2921 122.36 96.5128 122.36 106.172C122.36 116.037 131.16 121.997 145.408 121.997C166.989 121.999 181.654 109.051 181.654 91.582Z' />
                <path d='M218.74 29.1027H235.71V43.0796H237.597C242.416 33.6241 254.15 27.0491 265.883 27.0491C270.702 27.0491 275.731 28.0767 278.874 29.7192V45.5442C273.426 43.2851 267.14 42.4614 263.161 42.4614C247.237 42.4614 236.132 56.4383 236.132 76.9885V132.89H218.742L218.74 29.1027Z' />
                <path d='M292.491 29.1027H309.882V132.89H292.491V29.1027Z' />
                <path d='M324.966 106.583C324.966 88.2921 340.889 74.7279 372.318 71.234L401.651 67.7402V63.6298C401.651 49.6546 391.384 40.6118 373.994 40.6118C358.07 40.6118 347.593 48.216 347.803 61.1636H330.203C330.203 39.1731 347.593 25.1962 374.203 25.1962C401.651 25.1962 419.042 40.4046 419.042 64.0393V132.888H401.651V117.681H399.764C392.223 129.6 379.86 136.178 362.887 136.178C340.052 136.178 324.966 124.463 324.966 106.583ZM401.653 91.582V82.332L372.319 85.6203C350.738 88.2921 342.359 96.5128 342.359 106.172C342.359 116.037 351.159 121.997 365.406 121.997C386.986 121.999 401.653 109.051 401.653 91.582Z' />
                <path d='M601.324 79.6619C601.324 48.012 624.793 24.994 657.477 24.994C689.744 24.994 713 48.0137 713 79.8674C713 81.9226 713 84.3888 712.79 86.6479H619.132C621.647 107.611 637.152 121.586 658.733 121.586C674.447 121.586 688.276 113.778 694.981 101.447L709.44 108.638C700.849 126.315 681.781 137 658.315 137C625.212 137 601.324 112.954 601.324 79.6619ZM694.773 71.6467C691.632 52.9445 676.754 40.4079 656.641 40.4079C637.153 40.4079 622.279 52.9445 619.345 71.6467H694.773Z' />
                <path d='M496.985 24.9924C480.014 24.9924 465.766 31.98 458.014 44.5166H456.128V0H438.737V132.89H456.128V116.859H458.014C465.766 129.601 479.385 137 496.776 137C527.575 137 551.46 113.367 551.46 80.8934C551.462 48.4231 527.786 24.9924 496.985 24.9924ZM494.89 121.586C472.052 121.586 455.499 103.706 455.499 80.8934C455.499 58.0809 472.052 40.4063 494.89 40.4063C517.519 40.4063 534.071 58.0809 534.071 80.8934C534.071 104.117 517.517 121.586 494.89 121.586Z' />
                <path d='M585.194 0H567.804V132.89H585.194V0Z' />
            </svg>
        )
    }
    return (
        <svg className={props.className} style={props.style} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
            <path d='M22.3922 6C20.2488 2.2872 16.2871 0 12 0C7.71288 0 3.7512 2.2872 1.60776 6C-0.53592 9.7128 -0.53592 14.2872 1.60776 18C3.75144 21.7128 7.71288 24 12 24C16.2871 24 20.2488 21.7128 22.3922 18C24.5357 14.2872 24.5359 9.7128 22.3922 6ZM21.353 17.4C19.4299 20.731 15.846 22.8 12 22.8C8.154 22.8 4.57008 20.731 2.64696 17.4C0.72384 14.0693 0.72384 9.93072 2.64696 6.6C4.57008 3.26928 8.15376 1.2 12 1.2C15.846 1.2 19.4299 3.26928 21.353 6.6C23.2762 9.93072 23.2762 14.0693 21.353 17.4Z' />
            <path d='M12 3.02016C6.80113 3.02016 1.62409 11.9875 4.22353 16.4897C5.52337 18.7409 8.76169 19.8665 12 19.8665C15.2383 19.8665 18.4771 18.7409 19.7767 16.4897C22.3762 11.9875 17.1989 3.02016 12 3.02016ZM18.7375 15.8899C17.7636 17.5766 15.119 18.6665 12 18.6665C8.88097 18.6665 6.23641 17.5764 5.26273 15.8897C4.28881 14.203 4.66729 11.3676 6.22681 8.66664C7.78633 5.96568 10.0524 4.22016 12 4.22016C13.9478 4.22016 16.2139 5.96544 17.7734 8.66664C19.333 11.3678 19.7112 14.203 18.7375 15.8899Z' />
            <path d='M12 6.048C11.4989 6.048 10.9978 6.29592 10.7112 6.79224L6.84528 13.488C6.27264 14.4799 6.98856 15.72 8.13408 15.72H15.8659C17.0112 15.72 17.7271 14.4799 17.1545 13.488L13.2888 6.792C13.0022 6.29592 12.5011 6.048 12 6.048ZM16.1153 14.376C16.0774 14.4418 16.0032 14.52 15.8659 14.52H8.13408C7.99656 14.52 7.92265 14.4418 7.88449 14.376C7.84657 14.3105 7.81585 14.207 7.88449 14.088L11.7504 7.392C11.8193 7.27296 11.9239 7.248 11.9998 7.248C12.0756 7.248 12.1805 7.27296 12.2494 7.392L16.1153 14.088C16.1842 14.207 16.1534 14.3102 16.1153 14.376Z' />
        </svg>
    )
}

export default Logo
