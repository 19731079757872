import Utils from '../services/utils'
import Button from './Input/Button'
import { VariableNode, VariableNodeWithSyncId } from '../types'
import { DateString } from './DateString'
import { useContext } from 'react'
import { ApplicationContext } from '../context'

export const Footnotes = (props: {
    node?: VariableNodeWithSyncId
    showTime?: boolean
    className?: string
    extraClassName?: string
    saving?: boolean
    extraValues?: any[]
}) => {
    return (
        <div
            className={
                props.className || `d-flex flex-column align-items-start text-very-muted small ${props.extraClassName}`
            }
        >
            <div>
                Last updated:{' '}
                <DateString
                    date={props.node?.updated || props.node?.created}
                    showTime={props.showTime === undefined ? true : props.showTime}
                />
                <span hidden={!props.saving} className='spinner-border spinner-border-sm ms-1' />
            </div>
            {props.node?.syncId && (
                <div className='small'>
                    Sync ID:{' '}
                    <Button
                        element='span'
                        className='text-primary-hover'
                        onClick={() => Utils.copyToClipboard(props.node?.syncId)}
                    >
                        {props.node?.syncId}
                    </Button>
                </div>
            )}
            <DebugData node={props.node} extraValues={props.extraValues} />
        </div>
    )
}

export const DebugData = (props: { node?: VariableNode; extraValues?: any[] }) => {
    useContext(ApplicationContext)
    if (!Utils.inDebugMode()) return null
    return (
        <div className='d-flex flex-column align-items-start gap-1 max-w-100 overflow-hidden very-small'>
            <Button
                onClick={() => Utils.copyToClipboard(props.node?.uuid)}
                className='d-block text-overflow-ellipsis btn btn-xxs fs-inherit p-0 border-0 outline-0 text-very-muted text-primary-hover'
            >
                {props.node?.uuid}
            </Button>
            {props.extraValues?.map((value) => (
                <Button
                    key={`value-${value}-${props.node?.uuid}`}
                    onClick={() => Utils.copyToClipboard(value)}
                    style={{ maxWidth: '20rem' }}
                    className='d-block text-overflow-ellipsis btn btn-xxs fs-inherit p-0 border-0 outline-0 text-very-muted text-primary-hover'
                >
                    {value}
                </Button>
            ))}
        </div>
    )
}
