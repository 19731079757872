import { Selector, SelectorProps } from './Selector'
import Utils from '../../services/utils'
import { StandardAttributes } from '../../types'

export const MonthSelector = (
    props: StandardAttributes & Omit<SelectorProps, 'onSelect'> & { onChange: (month: number) => void },
) => {
    return (
        <Selector
            options={Utils.arrayToKeyValuePair<string>(Utils.months)}
            tooltipStyle={{ maxWidth: '320px' }}
            className='variable-select-arrow text-height-1'
            extraClassName={props.extraClassName}
            itemExtraClassName='font-monospace'
            {...props}
            onSelect={(r) => props.onChange(r.value)}
        />
    )
}
