import { ReactDatePickerCustomHeaderProps } from 'react-datepicker'
import Button from './Button'
import { GreaterThan, LessThan } from '@phosphor-icons/react'
import { YearSelector } from './YearSelector'
import { MonthSelector } from './MonthSelector'
import Utils from '../../services/utils'

export const DateNavigation = (params: ReactDatePickerCustomHeaderProps & { endYear?: number }) => (
    <div className='d-flex align-items-center justify-content-center gap-1'>
        <Button
            className='btn btn-xxs bg-light-hover ms-2 me-auto'
            onClick={params.decreaseMonth}
            disabled={params.prevMonthButtonDisabled}
        >
            <LessThan size={Utils.verySmallIconSize} />
        </Button>
        <MonthSelector
            placement='bottom-start'
            option={params.date.getMonth()}
            onChange={params.changeMonth}
            tooltipClassName='fs-base'
            itemExtraClassName='small'
        />
        <YearSelector
            placement='bottom-start'
            option={params.date.getFullYear()}
            onChange={params.changeYear}
            startYear={1970}
            endYear={params.endYear}
            tooltipClassName='fs-base'
            itemExtraClassName='small'
        />
        <Button
            className='btn btn-xxs bg-light-hover ms-auto me-2'
            onClick={params.increaseMonth}
            disabled={params.nextMonthButtonDisabled}
        >
            <GreaterThan size={Utils.verySmallIconSize} />
        </Button>
    </div>
)
