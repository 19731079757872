import { IconProps } from '@phosphor-icons/react'
import { Company } from '../../services/company'

export const ConnectionStatus = (props: IconProps & { company?: Company | null }) => {
    return (
        <span className={`${props.className} position-relative`} style={props.style}>
            {props.company?.claimed && <span className='pulsating-circle-pseudo me--1' />}
            {!props.company?.claimed && (
                <span className='dot-not-connected' style={{ width: '10px', height: '10px' }} />
            )}
        </span>
    )
}
