import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Selector, SelectorProps } from '../Input/Selector'
import CompanyService, { Company, CompanyPerspective, EmptyCompany } from '../../services/company'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'
import { GoTo } from '../GoTo'
import { Buildings, Factory } from '@phosphor-icons/react'
import { useCompany } from '../../hooks/useCompany'

export type CompanySelectorProps = SelectorProps & {
    company?: Company
    perspective: CompanyPerspective
    allowCreate?: boolean
    showGoTo?: boolean
    onSelect: (company: Company) => void
    onClear?: () => void
}

export const CompanyFilter = (props: CompanySelectorProps) => {
    const context = useContext(ApplicationContext)
    const { companyService, analyticsService } = useContext(VariableServicesContext)
    const [companies, setCompanies] = useState<Company[]>([])
    const ref = useRef<any>()

    const company = useCompany({ company: props.company })

    useEffect(() => {
        setCompanies([
            ...(props.perspective === 'supplier'
                ? Array.from(CompanyService.suppliersById.values())
                : Array.from(CompanyService.customersById.values())),
        ])
    }, [context.stores.ui?.companiesUpdated, props.perspective])

    const labelPrefix = useMemo(() => {
        if (props.perspective === 'customer') return <Buildings className='nt--1 me-1' />
        return <Factory className='nt--1 me-1' />
    }, [props.perspective])

    const onCreate = (newOpt: Company) => {
        if (props.perspective === 'supplier') {
            companyService.createSupplier({ ...EmptyCompany, name: newOpt.name }).then(props.onSelect)
            analyticsService.trackSupplierCreated()
        } else if (props.perspective === 'customer') {
            companyService.createCustomer({ ...EmptyCompany, name: newOpt.name }).then(props.onSelect)
            analyticsService.trackCustomerCreated()
        }
    }

    return (
        <span className='d-flex align-items-center gap-1 hover-parent-direct'>
            <Selector
                {...props}
                passedRef={ref}
                placeholder={props.placeholder || (props.perspective === 'supplier' ? 'Supplier' : 'Customer')}
                label={
                    company?.uuid ? undefined : (
                        <span className='text-nowrap'>
                            {labelPrefix}
                            {props.label ||
                                props.placeholder ||
                                (props.perspective === 'supplier' ? 'Supplier' : 'Customer')}
                        </span>
                    )
                }
                labelPrefix={labelPrefix}
                options={companies}
                option={company?.uuid}
                onSelect={(newValue) => {
                    if (newValue?.uuid === null && props.onClear) {
                        props.onClear()
                    } else {
                        props.onSelect(newValue)
                    }
                }}
                onCreate={props.allowCreate ? onCreate : undefined}
                noValueOption={
                    props.noValueOption || props.onClear !== undefined ? (
                        <span className='text-muted'>{props.noValueOption || 'No Company'}</span>
                    ) : undefined
                }
                renderItemValue={(item) => {
                    const _company = item as Company
                    return (
                        <>
                            {_company?.claimed && <span className='pulsating-circle-pseudo' />}
                            {_company.name}
                        </>
                    )
                }}
            />
            {props.showGoTo && company && (
                <GoTo className='show-on-hover' url={companyService.getCompanyUrl(company, props.perspective)} />
            )}
        </span>
    )
}
