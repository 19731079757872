import { ReactNode, useEffect, useMemo, useState } from 'react'
import { Unit } from '../../services/unit'
import InputField, { InputFieldProps } from './InputField'
import { UnitSelector, UnitSelectorProps } from './UnitSelector'
import { Amount, StandardAttributes } from '../../types'
import { PrettyNumber, PrettyNumberProps } from '../PrettyNumber'

export const AmountInput = (
    props: StandardAttributes & {
        prefix?: ReactNode
        suffix?: ReactNode
        amount?: Amount
        separator?: ReactNode
        inputFieldProps?: Partial<InputFieldProps>
        unitSelectorProps?: Partial<UnitSelectorProps>
        prettyNumberProps?: Partial<PrettyNumberProps>
        onChange: (amount?: Amount) => void
    },
) => {
    const [quantity, setQuantity] = useState<number | undefined>(props.amount?.quantity)
    const [unit, setUnit] = useState<Unit | undefined>(props.amount?.unit)
    const [shouldSave, setShouldSave] = useState<boolean>(false)

    useEffect(() => {
        if (shouldSave) {
            setShouldSave(false)
            props.onChange({ quantity, unit })
        }
    }, [shouldSave])

    useEffect(() => {
        if (props.amount?.unit?.code !== unit?.code) setUnit(props.amount?.unit)
    }, [props.amount?.unit])

    const inputField = useMemo(() => {
        if (props.disabled)
            return (
                <div
                    className='d-flex align-items-center justify-content-end'
                    style={props.inputFieldProps?.style || { width: '4rem' }}
                >
                    <PrettyNumber
                        className={[
                            props.inputFieldProps?.className ||
                                'variable-form-control bg-hover-none flex-grow-1 text-end',
                            props.inputFieldProps?.extraClassName,
                        ].join(' ')}
                        num={props.amount?.quantity}
                        {...props.prettyNumberProps}
                    />
                </div>
            )
        return (
            <InputField
                disabled={props.disabled}
                ariaLabel={`${props.ariaLabel || 'Amount'}: Quantity`}
                placeholder='Qty'
                defaultValue={props.amount?.quantity}
                className='variable-form-control bg-light flex-grow-1 text-end'
                followDefaultValue={true}
                isNumber={true}
                style={{ width: '4rem' }}
                {...props.inputFieldProps}
                onChange={(value) => setQuantity(parseFloat(value))}
                onEnterOrBlur={setShouldSave}
            />
        )
    }, [props.disabled, props.amount?.quantity, props.inputFieldProps])

    const unitSelector = useMemo(() => {
        return (
            <UnitSelector
                ariaLabel={`${props.ariaLabel || 'Amount'}: Unit`}
                disabled={props.disabled}
                unit={props.amount?.unit}
                quantity={props.amount?.quantity}
                onChange={(newUnit) => {
                    setUnit(newUnit)
                    setShouldSave(true)
                }}
                {...props.unitSelectorProps}
            />
        )
    }, [props.disabled, props.amount?.unit, props.amount?.quantity, props.unitSelectorProps])

    if (props.hidden) return null

    return (
        <div
            aria-label={props.ariaLabel}
            className={[props.className || 'd-flex align-items-center gap-1', props.extraClassName].join(' ')}
            style={props.style}
        >
            {props.prefix}
            {inputField}
            {props.separator}
            {unitSelector}
            {props.suffix}
        </div>
    )
}
