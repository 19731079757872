import { KeyValuePair } from '../../types'
import { Airplane, Boat, Snowflake, ThermometerCold, TrainSimple, Truck } from '@phosphor-icons/react'
import { TransportationType } from '../../services/transport'

export const transportationTypes: KeyValuePair<TransportationType>[] = [
    {
        icon: <Airplane />,
        name: 'Air',
        value: 'air-medium',
    },
    {
        icon: <Airplane />,
        name: 'Air Very Short Haul (< 800 km)',
        value: 'air-very-short',
    },
    {
        icon: <Airplane />,
        name: 'Air Short Haul (800 - 1500 km)',
        value: 'air-short',
    },
    {
        icon: <Airplane />,
        name: 'Air Medium Haul (1500 - 4000 km)',
        value: 'air-medium',
    },
    {
        icon: <Airplane />,
        name: 'Air Long Haul (> 4000 km)',
        value: 'air-long',
    },
    {
        name: (
            <span>
                <Airplane /> Air <ThermometerCold /> cooling
            </span>
        ),
        text: 'Air (cooling)',
        value: 'air-cooling',
    },
    {
        name: (
            <span>
                <Airplane /> Air <Snowflake /> freezing
            </span>
        ),
        text: 'Air (freezing)',
        value: 'air-cooling',
    },
    {
        icon: <Boat />,
        name: 'Container ship',
        value: 'container-ship',
    },
    {
        name: (
            <span>
                <Boat /> Container ship <ThermometerCold /> cooling
            </span>
        ),
        text: 'Container ship (cooling)',
        value: 'container-ship-cooling',
    },
    {
        name: (
            <span>
                <Boat /> Container ship <Snowflake /> freezing
            </span>
        ),
        text: 'Container ship (freezing)',
        value: 'container-ship-freezing',
    },
    {
        icon: <Boat />,
        name: 'Ferry',
        value: 'ferry',
    },
    {
        icon: <Boat />,
        name: 'Inland waterways (Barge)',
        value: 'inland-waterways-barge',
    },
    {
        icon: <Boat />,
        name: 'Inland waterways (Tanker)',
        value: 'inland-waterways-tanker',
    },
    {
        name: (
            <span>
                <Boat /> Inland waterways (Tanker) <ThermometerCold /> cooling
            </span>
        ),
        text: 'Inland waterways (Tanker cooling)',
        value: 'inland-waterways-tanker-cooling',
    },
    {
        name: (
            <span>
                <Boat /> Inland waterways (Tanker) <Snowflake /> freezing
            </span>
        ),
        text: 'Inland waterways (Tanker freezing)',
        value: 'inland-waterways-tanker-freezing',
    },
    {
        icon: <Boat />,
        name: 'Tanker',
        value: 'tanker',
    },
    {
        icon: <Boat />,
        name: 'Tanker (Petroleum)',
        value: 'tanker-petroleum',
    },
    {
        icon: <Boat />,
        name: 'Tanker (LNG)',
        value: 'tanker-lng',
    },
    {
        icon: <Boat />,
        name: 'Tanker (Other liquid)',
        value: 'tanker-other-liquid',
    },
    {
        icon: <Boat />,
        name: 'Tanker (Dry goods)',
        value: 'tanker-dry-goods',
    },
    {
        icon: <TrainSimple />,
        name: 'Train',
        value: 'train',
    },
    {
        name: (
            <span>
                <TrainSimple /> Train <ThermometerCold /> cooling
            </span>
        ),
        text: 'Train (cooling)',
        value: 'train-cooling',
    },
    {
        name: (
            <span>
                <TrainSimple /> Train <Snowflake /> freezing
            </span>
        ),
        text: 'Train (freezing)',
        value: 'train-freezing',
    },
    {
        icon: <Truck />,
        name: 'Truck',
        value: 'truck',
    },
]
