import TransportService, { TransportType } from '../../services/transport'
import { TransportIcons } from '../Icons/TransportIcons'
import { StandardAttributes } from '../../types'
import { useMemo } from 'react'
import { useTransportType } from '../../hooks/useTransportType'

export const TransportBadge = (props: StandardAttributes & { transportType?: TransportType | null }) => {
    const transportType = useTransportType({ transportTypeId: props.transportType?.uuid })
    const icons = useMemo(() => <TransportIcons transportType={transportType} className='nt--2' />, [transportType])
    const name = useMemo(() => TransportService.getTransportTypeName(transportType), [transportType])
    if (!transportType) return null
    return (
        <div className={['d-flex align-items-center gap-1', props.extraClassName].join(' ')}>
            {icons}
            {name}
        </div>
    )
}
