import { InfoIcon } from './Icons/InfoIcon'
import Utils from '../services/utils'
import { ReactNode } from 'react'
import { ArrowSquareOut } from '@phosphor-icons/react'

export const StateHelp = (props: { children?: ReactNode; className?: string; extraClassName?: string }) => (
    <InfoIcon
        color={Utils.veryFadedColor}
        href='https://variable.co/blog/activity-approval-flow'
        size={17}
        interactive={true}
        className={
            props.className || `small d-flex align-items-center gap-1 underline-on-hover ${props.extraClassName || ''}`
        }
        tooltip={
            props.children ? undefined : (
                <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://variable.co/blog/activity-approval-flow'
                    className='small text-start underline-on-hover'
                    style={{ maxWidth: '200px' }}
                >
                    Read about the activity approval flow
                    <ArrowSquareOut size={Utils.verySmallIconSize} className='text-muted nt--1 ms-1' />
                </a>
            )
        }
    >
        {props.children}
    </InfoIcon>
)
