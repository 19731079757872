import { Link } from 'react-router-dom'
import Card from './Card'

const NotFound = (props: { className?: string }) => {
    return (
        <Card className={props.className}>
            <h3>404 - Page Not Found</h3>
            <p>We could not find the resource you are looking for.</p>
            <Link to='/'>Go to the home page</Link>
        </Card>
    )
}

export default NotFound
