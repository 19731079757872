import { useContext, useEffect, useState } from 'react'
import { ApplicationContext } from '../context'
import { Product, ProductActionType } from '../services/product'
import { VariableServicesContext } from '../services'

export interface FootprintModal {
    onClose?: (productId?: string) => void
}

export const useFootprintModal = (props: FootprintModal) => {
    const context = useContext(ApplicationContext)
    const { productService } = useContext(VariableServicesContext)
    const [isOpen, setIsOpen] = useState<boolean>(false)

    useEffect(() => {
        if (!isOpen) return

        if (context.stores.products?.previewClosedId) {
            props.onClose?.(context.stores.products.previewClosedId)
            setIsOpen(false)
            context.dispatch({ type: ProductActionType.ClearInstanceId })
        }

        if (context.stores.products?.deletedId) {
            setIsOpen(false)
            context.dispatch({ type: ProductActionType.ClearInstanceId })
        }
    }, [context.stores.products.previewClosedId, context.stores.products.deletedId])

    return (product: Product) => {
        setIsOpen(true)
        productService.openPreview(product)
    }
}
