import { PercentBar, PercentBarProps, PercentBarSegment } from '../PercentBar'
import InputService, { InputUseStageSummary } from '../../services/input'
import ProductService, { Product } from '../../services/product'
import { CSSProperties, useContext, useEffect, useState } from 'react'
import { VariableServicesContext } from '../../services'

import { Co2eDisplay } from '../../types'
import { UseStageCategoryType } from '../../services/useStage'

export const LifecycleBar = (props: {
    product?: Product
    className?: string
    style?: CSSProperties
    co2eDisplay?: Co2eDisplay
    percentBarProps?: Partial<PercentBarProps>
}) => {
    const { productService } = useContext(VariableServicesContext)
    const [useStageSummaries, setUseStageSummaries] = useState<InputUseStageSummary[]>()
    const [inputSegments, setInputSegments] = useState<PercentBarSegment[]>([])
    const [absTotal, setAbsTotal] = useState<number>(0)

    useEffect(() => {
        if (props.product) setUseStageSummaries(props.product.useStageSummary)
    }, [props.product?.useStageSummary])

    useEffect(() => {
        let _absTotal = 0

        const upstream: PercentBarSegment = { name: 'Upstream', className: 'bg-ghg-upstream', amount: 0 }
        const direct: PercentBarSegment = { name: 'Direct', className: 'bg-ghg-scope2', amount: 0 }
        const downstream: PercentBarSegment = { name: 'Downstream', className: 'bg-ghg-downstream', amount: 0 }

        const _productIsNegative = parseFloat(props?.product?.co2e || '0') < 0

        const _co2eDisplay = props.co2eDisplay || 'Upstream'
        const _showDownstream = _co2eDisplay === 'Total' || _co2eDisplay === 'Downstream'
        const _showUpstream = _co2eDisplay === 'Total' || _co2eDisplay === 'Upstream'

        if (props.product?.footprint?.CO2e) {
            upstream.amount = parseFloat(props.product.footprint.CO2e.upstream || '0')
            direct.amount = parseFloat(props.product.footprint.CO2e.direct || '0')
            _absTotal += Math.abs(upstream.amount) + Math.abs(direct.amount)
            if (_showDownstream) {
                if (!downstream.amount) downstream.amount = 0
                downstream.amount += parseFloat(props.product.footprint.CO2e.A4 || '0')
                downstream.amount += parseFloat(props.product.footprint.CO2e.A5 || '0')
                downstream.amount += parseFloat(props.product.footprint.CO2e.B1 || '0')
                downstream.amount += parseFloat(props.product.footprint.CO2e.B2 || '0')
                downstream.amount += parseFloat(props.product.footprint.CO2e.B3 || '0')
                downstream.amount += parseFloat(props.product.footprint.CO2e.B4 || '0')
                downstream.amount += parseFloat(props.product.footprint.CO2e.B5 || '0')
                downstream.amount += parseFloat(props.product.footprint.CO2e.B6 || '0')
                downstream.amount += parseFloat(props.product.footprint.CO2e.B7 || '0')
                downstream.amount += parseFloat(props.product.footprint.CO2e.C1 || '0')
                downstream.amount += parseFloat(props.product.footprint.CO2e.C2 || '0')
                downstream.amount += parseFloat(props.product.footprint.CO2e.C3 || '0')
                downstream.amount += parseFloat(props.product.footprint.CO2e.C4 || '0')
                _absTotal += Math.abs(downstream.amount)
            }
        } else {
            useStageSummaries?.forEach((ius) => {
                const _amount = InputService.getTotalUseStageCO2e([ius])
                if (
                    [
                        UseStageCategoryType.UPSTREAM,
                        UseStageCategoryType.DIRECT,
                        UseStageCategoryType.DOWNSTREAM,
                    ].includes(ius.category as UseStageCategoryType)
                ) {
                    if (_productIsNegative) {
                        _absTotal += InputService.getTotalUseStageCO2e([ius], true)
                    } else {
                        _absTotal += InputService.getTotalUseStageCO2e([ius])
                    }
                }
                if (ius.category === 'Upstream' && _showUpstream) {
                    if (!upstream.amount) upstream.amount = 0
                    upstream.amount += _amount
                } else if (ius.category === 'Direct' && _showUpstream) {
                    if (!direct.amount) direct.amount = 0
                    direct.amount += _amount
                } else if (ius.category === 'Downstream' && _showDownstream) {
                    if (!downstream.amount) downstream.amount = 0
                    downstream.amount += _amount
                }
            })
        }

        setAbsTotal(_absTotal)
        const segments = [upstream, direct, downstream].filter((item) => item.amount)
        setInputSegments([...segments])
    }, [useStageSummaries])

    if (
        productService.isPremium(props.product) ||
        ProductService.isEmissionFactor(props.product) ||
        props.product?.co2e === null
    ) {
        return null
    }

    return (
        <div className={props.className} style={{ maxWidth: '255px', ...props.style }}>
            <PercentBar
                {...props.percentBarProps}
                total={absTotal}
                segments={inputSegments}
                precision={2}
                fillLast={true}
            />
        </div>
    )
}
