import { ActionMap } from '../context'

export type BulkActivityFields = 'org' | 'scope' | 'batch' | 'product' | 'state'

export const defaultBulkFields: BulkActivityFields[] = ['org', 'scope', 'batch', 'product']

export interface IGlobalActivityContext {
    bulkIds: Set<string>
    bulkUpdateCount: number
    bulkFields?: BulkActivityFields[]
}

export enum GlobalActivityActionType {
    SetBulkIds = 'SetBulkIds',
    ResetBulkIds = 'ResetBulkIds',
    AddToBulkIds = 'AddToBulkIds',
    RemoveFromBulkIds = 'RemoveFromBulkIds',
    BulkUpdateDone = 'BulkUpdateDone',
    SetBulkFields = 'SetBulkFields',
}

type GlobalActivityActionPayload = {
    [GlobalActivityActionType.SetBulkIds]: Set<string>
    [GlobalActivityActionType.ResetBulkIds]: undefined
    [GlobalActivityActionType.AddToBulkIds]: string
    [GlobalActivityActionType.RemoveFromBulkIds]: string
    [GlobalActivityActionType.BulkUpdateDone]: undefined
    [GlobalActivityActionType.SetBulkFields]: BulkActivityFields[]
}

export type GlobalActivityActions = ActionMap<GlobalActivityActionPayload>[keyof ActionMap<GlobalActivityActionPayload>]

export const GlobalActivityReducer = (
    state: IGlobalActivityContext,
    action: GlobalActivityActions,
): IGlobalActivityContext => {
    switch (action.type) {
        case GlobalActivityActionType.SetBulkIds:
            return { ...state, bulkIds: action.payload }
        case GlobalActivityActionType.ResetBulkIds:
            return { ...state, bulkIds: new Set<string>() }
        case GlobalActivityActionType.AddToBulkIds:
            return { ...state, bulkIds: state.bulkIds.add(action.payload) }
        case GlobalActivityActionType.RemoveFromBulkIds:
            state.bulkIds.delete(action.payload)
            return { ...state, bulkIds: state.bulkIds }
        case GlobalActivityActionType.BulkUpdateDone:
            return { ...state, bulkIds: new Set<string>(), bulkUpdateCount: ++state.bulkUpdateCount }
        case GlobalActivityActionType.SetBulkFields:
            return { ...state, bulkFields: action.payload }
        default:
            return state
    }
}

export const initialGlobalActivityState: IGlobalActivityContext = {
    bulkIds: new Set<string>(),
    bulkUpdateCount: 0,
    bulkFields: defaultBulkFields,
}
