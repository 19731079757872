import { Selector, SelectorProps } from '../Input/Selector'
import { useContext } from 'react'
import { VariableServicesContext } from '../../services'
import Button, { ButtonProps } from '../Input/Button'

export const ColorModeSelector = (props: Omit<SelectorProps, 'onSelect'>) => {
    const { userService } = useContext(VariableServicesContext)
    return (
        <Selector
            aria-label='Color Mode'
            placeholder='Select'
            hideTextFilter={true}
            placement='bottom-start'
            className='variable-form-select active'
            options={userService.colorModes}
            option={userService.colorMode}
            {...props}
            onSelect={(cm) => (userService.colorMode = cm.value)}
        />
    )
}

export const ColorModeButton = (props: ButtonProps) => {
    const { userService } = useContext(VariableServicesContext)
    return (
        <Button
            className='btn btn-sm'
            onClick={() => (userService.colorMode = userService.colorMode === 'dark' ? 'light' : 'dark')}
            {...props}
        >
            {userService.getColorModeIcon()}
        </Button>
    )
}
