import { Tag } from './tag'
import CompanyService, { Company, CompanyPerspective } from './company'
import { DataImportResponse } from './dataImport'
import {
    CO2ByYear,
    GenericObject,
    KeyValuePair,
    ListResponse,
    UnitTotal,
    VariableBaseNode,
    VariableNode,
    VariableNodeState,
} from '../types'
import { SharingToken } from './token'
import { Org } from './org'
import ProductService, { Product } from './product'
import GHGService, { GHGScope } from './ghg'
import { Calculation } from './calculator'
import Utils from './utils'
import { DataRequest } from './dataRequest'
import TaxonomyService, { Taxonomy } from './taxonomy'
import UnitService, { Unit, UnitType } from './unit'
import { ProductionBatch } from './batch'
import VariableService from './service'
import { User } from './user-context'
import { BulkActivityFields, defaultBulkFields, GlobalActivityActionType } from './bulkActivityContext'
import { FileData } from './file'
import { UseStageType } from './useStage'
import { TransportType } from './transport'
import { ImpactResult, ImpactSummary } from './impact'
import { ElectricityCertificate, RenewableProduction } from './electricity'

export type ReportDetailType = 'Taxonomy' | 'Org' | 'None'

export enum ActivityDirection {
    INPUT = 'in',
    OUTPUT = 'out',
}

export enum ActivityState {
    DRAFT = 'draft',
    PENDING = 'pending',
    APPROVED = 'approved',
    REJECTED = 'rejected',
}

export interface ActivityStateUpdate extends VariableBaseNode {
    previousState?: VariableNodeState
    newState: VariableNodeState
    user?: Partial<User>
    message?: string
    adminOverride?: boolean
}

export const activityStates: KeyValuePair<ActivityState>[] = [
    { name: 'Draft', value: ActivityState.DRAFT },
    { name: 'Pending', value: ActivityState.PENDING },
    { name: 'Approved', value: ActivityState.APPROVED },
    { name: 'Rejected', value: ActivityState.REJECTED },
]

export type ActivityCo2eView = 'perspective' | 'embedded'

export enum ActivityCalculationMethod {
    DOWNSTREAM = 'downstream',
    TOTAL = 'total',
}

export enum ActivityListViewOption {
    MINE = 'my-emissions',
    ALL = 'all-emissions',
    UP = 'upstream',
    DOWN = 'downstream',
}

export const activityListOptions: KeyValuePair<ActivityListViewOption>[] = [
    {
        name: 'Product carbon footprints (upstream + direct)',
        description: 'Show embodied emissions from product carbon footprints',
        value: ActivityListViewOption.MINE,
    },
    {
        name: 'All (upstream + direct + downstream)',
        description: 'Show total life cycle emissions including assumptions of downstream emissions',
        value: ActivityListViewOption.ALL,
    },
]

export interface ActivityRelationships {
    owner?: Company
    sender?: Company | null
    receiver?: Company | null
    product?: Product | null
    calculation?: Calculation
    tags?: Tag[]
    taxonomy?: Taxonomy
    dataRequest?: DataRequest
    org?: Org
    scope?: GHGScope
    transportType?: TransportType | null
    renewableProduction?: RenewableProduction | null
    electricityCertificate?: ElectricityCertificate | null
    token?: SharingToken
    useStageType?: UseStageType
    batch?: ProductionBatch | null
}

export interface IActivityBulkUpdate extends ActivityRelationships {
    stateUpdate?: Partial<ActivityStateUpdate>
}

export interface ActivityItem extends VariableBaseNode, ActivityRelationships {
    name: string
    date: number
    state: ActivityState
    stateHistory?: ActivityStateUpdate[]
    metaData?: GenericObject
    startDate?: number
    endDate?: number
    quantity: number
    unit?: Unit
    direction: ActivityDirection
    staticData: boolean
    staticCo2e?: string
    staticUnit?: Unit
    intensityUpstreamCo2e?: string
    intensityDownstreamCo2e?: string
    documents?: FileData[]
    impacts?: ImpactResult[]
    impact?: ImpactSummary
}

export interface ActivityImportData {
    name?: string
    date?: number
    direction?: ActivityDirection
    quantity?: number
    productName: string
    productSku: string
    companyId: string
    companyName: string
    org?: string
    scope?: string
}

export interface ActivityReportSummary {
    reports: ActivityReport[]
    totalCo2e?: number
    upstreamCo2e?: number
    downstreamCo2e?: number
    startDate?: number
    endDate?: number
}

export interface ActivityReport {
    period: string
    totalCo2e?: number
    upstreamCo2e?: number
    downstreamCo2e?: number
    startDate?: number
    endDate?: number
    scopes: GHGScope[]
}

export interface ActivityChartData {
    years: number[]
    totalCo2e: number
    totalMarketCo2e?: number
    co2eByYear: CO2ByYear[]
    scopes: GHGScope[]
}

export interface ActivityRating {
    rating: number
    specificPercent: number
}

export interface ActivityPerspective {
    co2e?: number | null
    direction: ActivityDirection
    otherCompany?: Company
    otherCompanyPerspective: CompanyPerspective
    liveConnection: boolean
    isOwner: boolean
    canEdit: boolean
    outgoing: boolean
    receiver?: Company
    receiverName: string
    incoming: boolean
    sender?: Company
    senderName: string
}

export const activityFilterOptions: KeyValuePair<ActivityDirection | null>[] = [
    { name: 'All', value: null },
    { name: 'Upstream', value: ActivityDirection.INPUT },
    { name: 'Downstream', value: ActivityDirection.OUTPUT },
]

export const activityTypeOptions: KeyValuePair<ActivityDirection>[] = [
    { name: 'Upstream', value: ActivityDirection.INPUT },
    { name: 'Downstream', value: ActivityDirection.OUTPUT },
]

export default class ActivityService extends VariableService {
    public basePath: string = '/activity'
    public static webRootActivities: string = '/activities'
    public static webRootActivityNew: string = `/activities/new?activityStates=${ActivityState.DRAFT}`
    public static webRootActivityImport: string = '/activities?import=1'
    public static webRootActivityReports: string = '/data-reports'
    public static webRootAnalytics: string = '/analytics'
    public static webRootReport: string = '/report'
    public static webTitle = (plural: boolean = false): string => Utils.pluralize('Activity', plural ? 2 : 1)

    public static unscopedActivities = `${this.webRootActivities}?filterBy=Scope&id=null`
    public static onboardingActivityUrl = `${this.webRootActivities}?activityStates=${ActivityState.DRAFT}&st=Electricity&org=Operations&scope=Electricity&onboarding=1`

    public static chartData: ActivityChartData | undefined = undefined
    public static scopeSummary: GHGScope[] | undefined = undefined
    public static chartTotalCo2e: number | undefined = undefined
    public static chartTotalMarketCo2e: number | undefined = undefined

    public static allStates = [
        ActivityState.DRAFT,
        ActivityState.PENDING,
        ActivityState.APPROVED,
        ActivityState.REJECTED,
    ]
    public static editableStates = [ActivityState.DRAFT, ActivityState.REJECTED]
    public static nonEditableStates = [ActivityState.PENDING, ActivityState.APPROVED]

    public isEditable = (activityItem?: ActivityItem): boolean => {
        if (!activityItem) {
            return false
        }
        return ActivityService.editableStates.includes(activityItem.state)
    }

    public getActivityItemUrl(activityItem: ActivityItem): string {
        return `${ActivityService.webRootActivities}/${activityItem.uuid}`
    }

    public getActivityUnit(
        activityItem?: ActivityItem,
        product?: Product | null,
        transportType?: TransportType | null,
    ): Unit | undefined {
        if (product?.unit && (!activityItem?.unit || product?.unit?.type !== activityItem?.unit?.type)) {
            return product.unit
        }
        if (transportType?.uuid && activityItem?.unit?.type !== UnitType.WEIGHT) {
            return UnitService.baseUnitByType.get(UnitType.WEIGHT)
        }
        return activityItem?.unit
    }

    public getActivityUnitString(activityItem?: ActivityItem): string {
        const _unit = activityItem?.unit
        if (_unit?.type === 'unknown') {
            return Utils.pluralize(_unit.code || 'item', activityItem?.quantity || 1)
        }
        return _unit?.code || ''
    }

    public static getFilterUrl(
        filterBy?: string,
        filterValue?: string,
        queryString?: string,
        view: 'analytics' | 'activities' = 'analytics',
        direction?: ActivityDirection,
        fullUrl: boolean = false,
    ): string {
        let url = fullUrl ? document.location.origin : ''
        url += view === 'analytics' ? this.webRootAnalytics : this.webRootActivities
        if (!filterBy || !filterValue) {
            return url
        }
        const qs = new URLSearchParams(queryString)
        if (direction) {
            qs.set('direction', direction)
        } else {
            qs.delete('direction')
        }
        if (filterValue === 'uncategorized' || filterValue === 'undefined') {
            filterValue = 'null'
        }
        qs.set(filterBy, filterValue)
        return `${url}?${qs.toString()}`
    }

    public static emptyActivityItem = (props?: Partial<ActivityItem>): ActivityItem => {
        return {
            name: '',
            state: ActivityState.DRAFT,
            date: new Date().valueOf(),
            startDate: new Date().valueOf(),
            endDate: new Date().valueOf(),
            direction: ActivityDirection.INPUT,
            staticData: false,
            quantity: 0,
            ...props,
        }
    }

    public async getNewActivityFromQueryString(queryString: string): Promise<ActivityItem> {
        const qs = new URLSearchParams(queryString)
        const searchTerm = qs.get('st')
        const epochFromQs = qs.get('activity.date')
        const quantity = parseFloat(qs.get('activity.quantity') || '0')
        const date = epochFromQs ? new Date(parseInt(epochFromQs, 10)) : new Date()
        const orgString = qs.get('org')
        const scopeString = qs.get('scope')
        const ghgService = new GHGService(this.context)
        let taxonomy: Taxonomy | undefined = undefined
        if (searchTerm?.toLowerCase() === 'electricity' && qs.get('onboarding') === '1') {
            taxonomy = TaxonomyService.byPath.get(TaxonomyService.electricityPath)
        }
        let org: Org | undefined
        if (orgString) {
            org = this.context.stores.orgs.list.find((_es) => _es.name === orgString)
        }
        let scope: GHGScope | undefined
        if (scopeString) {
            const scopes = await ghgService.getScopes()
            scope = scopes.find((_scope) => _scope.name === scopeString)
        }
        const productId = qs.get('productId')
        let sender, product
        if (productId) {
            const productService = new ProductService(this.context)
            product = await productService.getById(productId)
            if (product?.productOf) {
                sender = product.productOf
            }
        }
        return ActivityService.emptyActivityItem({
            name: qs.get('activity.name') || '',
            date: date.valueOf(),
            direction: ActivityDirection.INPUT,
            receiver: this.context.stores.company,
            quantity: quantity,
            product: product,
            sender: sender,
            taxonomy: taxonomy,
            org: org,
            scope: scope,
        })
    }

    public static getActivityTaxonomy(activityItem?: ActivityItem): Taxonomy | undefined {
        const element = activityItem?.product || activityItem?.transportType
        if (element && !element.taxonomy) {
            return undefined
        }
        return element?.taxonomy || activityItem?.taxonomy
    }

    public setBulkIds(bulkIds: Set<string>): void {
        this.context.dispatch({ type: GlobalActivityActionType.SetBulkIds, payload: new Set(bulkIds) })
    }

    public addToBulkIds(itemId: string): void {
        this.context.dispatch({ type: GlobalActivityActionType.AddToBulkIds, payload: itemId })
    }

    public removeFromBulkIds(itemId: string): void {
        this.context.dispatch({ type: GlobalActivityActionType.RemoveFromBulkIds, payload: itemId })
    }

    public resetBulkIds(): void {
        this.context.dispatch({ type: GlobalActivityActionType.ResetBulkIds })
    }

    public bulkUpdateDone(): void {
        this.context.dispatch({ type: GlobalActivityActionType.BulkUpdateDone })
    }

    public setBulkFields(fields?: BulkActivityFields[]): void {
        this.context.dispatch({ type: GlobalActivityActionType.SetBulkFields, payload: fields || defaultBulkFields })
    }

    public get(queryString?: string): Promise<ListResponse<ActivityItem>> {
        return this.httpService.get<ListResponse<ActivityItem>>(`${this.basePath}?${queryString || ''}`)
    }

    public getActivityExport(queryString?: string): Promise<GenericObject> {
        const qs = new URLSearchParams(queryString)
        return this.httpService.post<GenericObject>(`${this.basePath}/export?${qs.toString()}`, {
            body: JSON.stringify({ export: 'activities' }),
        })
    }

    public getReportExport(groupBy: ReportDetailType, queryString?: string): Promise<GenericObject> {
        const qs = new URLSearchParams(queryString)
        return this.httpService.post<GenericObject>(`${this.basePath}/export?${qs.toString()}`, {
            body: JSON.stringify({ export: 'report', groupBy }),
            responseType: 'text',
        })
    }

    public getQuantityReport(queryString?: string): Promise<UnitTotal[]> {
        return this.httpService.get<UnitTotal[]>(`${this.basePath}/report/quantity?${queryString || ''}`)
    }

    public getMonthlyReport(queryString?: string): Promise<ActivityReportSummary> {
        return this.httpService.get<ActivityReportSummary>(`${this.basePath}/report/chart?${queryString || ''}`)
    }

    public getTopLevelScopeSummary(queryString?: string): Promise<GHGScope[]> {
        const qs = new URLSearchParams(queryString)
        return this.httpService.get<GHGScope[]>(`${this.basePath}/report/topLevel?${qs.toString()}`)
    }

    public getActivityTotals(queryString?: string): Promise<ListResponse<ActivityItem>> {
        const qs = new URLSearchParams(queryString)
        return this.httpService.get<ListResponse<ActivityItem>>(`${this.basePath}/report/totals?${qs.toString()}`)
    }

    public getActivityScore(queryString?: string): Promise<ActivityRating> {
        const qs = new URLSearchParams(queryString)
        return this.httpService.get<ActivityRating>(`${this.basePath}/report/score?${qs.toString()}`)
    }

    public getScopeSummary(queryString?: string): Promise<ActivityChartData> {
        const qs = new URLSearchParams(queryString)
        return this.httpService.get<ActivityChartData>(`${this.basePath}/report/scope?${qs.toString()}`)
    }

    public getActivity(itemId?: string): Promise<ActivityItem> {
        if (!itemId) {
            return Promise.reject()
        }
        return this.httpService.get<ActivityItem>(`${this.basePath}/${itemId}`)
    }

    public importActivities(activityData: ActivityImportData[]): Promise<DataImportResponse> {
        return this.httpService.post<DataImportResponse>(this.basePath, {
            body: JSON.stringify({ import: activityData }),
        })
    }

    public getStateHistory(activityItem: VariableNode): Promise<ActivityStateUpdate[]> {
        return this.httpService.get<ActivityStateUpdate[]>(`${this.basePath}/${activityItem.uuid}/state`)
    }

    public updateState(
        item: ActivityItem,
        state: ActivityState,
        message?: string,
        adminOverride?: boolean,
    ): Promise<ActivityItem> {
        return this.httpService.patch<ActivityItem>(`${this.basePath}/${item.uuid}`, {
            body: JSON.stringify({ state: state, message: message, adminOverride: adminOverride }),
        })
    }

    public async canUpdateState(item?: ActivityItem, ifUndefined: boolean = false): Promise<boolean> {
        if (!item?.uuid) {
            return ifUndefined
        }
        return this.httpService
            .get<GenericObject>(`${this.basePath}/${item.uuid}/state?type=p`)
            .then(() => true)
            .catch(() => false)
    }

    public bulkEditActivities(activityIds: Set<string>, fields: IActivityBulkUpdate): Promise<void> {
        return this.httpService.put<void>(this.basePath, {
            body: JSON.stringify({ activityIds: Array.from(activityIds), fields: fields }),
        })
    }

    public createOrUpdate(item: Partial<ActivityItem>): Promise<ActivityItem> {
        const path = this.basePath + (item.uuid !== undefined ? `/${item.uuid}` : '')
        return this.httpService.put<ActivityItem>(path, {
            body: JSON.stringify({ activityItem: Utils.prepareForSave(item) }),
        })
    }

    public deleteActivity(itemId: string): Promise<ActivityItem> {
        return this.httpService.delete<ActivityItem>(`${this.basePath}/${itemId}`)
    }

    public getActivityPerspective(activityItem: ActivityItem): ActivityPerspective {
        let sender = activityItem.sender
        let receiver = activityItem.receiver
        let otherCompany: Company | null | undefined
        let liveConnection = false
        let isOwner = false
        let canEdit = activityItem.dataRequest?.uuid !== undefined
        let outgoing = false
        let incoming = false
        if (activityItem?.owner === undefined || activityItem?.owner?.uuid === this.context.stores.company?.uuid) {
            isOwner = true
            canEdit = true
            if (!sender && activityItem.direction === ActivityDirection.OUTPUT) {
                sender = activityItem.owner
            } else if (!receiver && activityItem.direction === ActivityDirection.INPUT) {
                receiver = activityItem.owner
            }
        }
        if (receiver?.uuid === this.context.stores.company?.uuid) {
            otherCompany = sender
            incoming = true
            liveConnection = sender?.claimed || false
        }
        if (sender?.uuid === this.context.stores.company?.uuid) {
            otherCompany = receiver
            outgoing = true
            liveConnection = receiver?.claimed || false
        }
        let co2e: number | undefined | null = undefined
        if (this.context.stores.company?.downstreamActivityConfiguration === ActivityCalculationMethod.TOTAL) {
            co2e = activityItem.impact?.gwp.total.total
        } else if (incoming) {
            co2e = activityItem.impact?.gwp.total.up
        } else if (outgoing) {
            co2e = activityItem.impact?.gwp.total.down
        }
        const _cachedOtherCompany: Company | null | undefined = CompanyService.byId.get(otherCompany?.uuid || '')
        if (_cachedOtherCompany) otherCompany = { ..._cachedOtherCompany, ...otherCompany }
        return {
            co2e: co2e,
            direction: incoming ? ActivityDirection.INPUT : ActivityDirection.OUTPUT,
            otherCompany: otherCompany as Company,
            otherCompanyPerspective: incoming ? 'supplier' : 'customer',
            liveConnection: liveConnection,
            isOwner: isOwner,
            canEdit: canEdit,
            outgoing: outgoing,
            receiver: receiver as Company,
            receiverName: receiver?.name || '',
            incoming: incoming,
            sender: sender as Company,
            senderName: sender?.name || '',
        }
    }
}
