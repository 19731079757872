import { Selector, SelectorProps } from './Input/Selector'
import { OrderDir, QueryOptions } from '../types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { SortAscending, SortDescending } from '@phosphor-icons/react'
import Button from './Input/Button'

export const SortSelector = (
    props: Omit<SelectorProps, 'onSelect'> & {
        queryOptions?: QueryOptions
        queryString?: string
        showOrderBy?: boolean
        onSelect: (orderBy: string, orderDir: OrderDir) => void
    },
) => {
    const [ready, setReady] = useState<boolean>(false)
    const [opt, setOpt] = useState<any>()
    const [queryOptions, setQueryOptions] = useState<QueryOptions | undefined>(props.queryOptions)

    useEffect(() => {
        const qs = new URLSearchParams(props.queryString)
        const qo: QueryOptions = {}
        qs.forEach((v, k) => {
            qo[k as keyof QueryOptions] = v as any
        })
        setQueryOptions(qo)
    }, [props.queryString])

    useEffect(() => setQueryOptions(props.queryOptions), [props.queryOptions])

    useEffect(() => {
        if (!opt) {
            setOpt(props.options?.find((o) => o.value === queryOptions?.orderBy || o.default) || props.options?.[0])
        }
    }, [queryOptions?.orderBy])

    const orderBy = useMemo(
        () => queryOptions?.orderBy || opt?.value || props.options?.[0]?.value,
        [queryOptions, props.options],
    )
    const orderLabel = useMemo(() => props.options?.find((o) => o.value === orderBy)?.name, [orderBy, props.options])
    const orderDir = useMemo(
        () => queryOptions?.orderDir || opt?.orderDir || 'ASC',
        [queryOptions?.orderDir, opt?.orderDir],
    )
    const newDir: OrderDir = useMemo(() => (orderDir === 'ASC' ? 'DESC' : 'ASC'), [orderDir])

    useEffect(() => {
        if (ready) {
            props.onSelect(orderBy, orderDir)
        } else {
            setTimeout(() => setReady(true), 100)
        }
    }, [orderBy, orderDir])

    const setQueryOption = useCallback(
        (qo: Partial<QueryOptions>) => setQueryOptions((prev) => ({ ...prev, ...qo })),
        [],
    )

    const orderIcon = useMemo(() => {
        if (orderDir === 'DESC') return <SortDescending />
        return <SortAscending />
    }, [orderDir])

    const orderText = useMemo(() => {
        if (orderDir === 'DESC') {
            return 'Descending'
        } else {
            return 'Ascending'
        }
    }, [orderDir])

    const footer = useMemo(() => {
        if (props.showOrderBy === false || opt?.orderDir) return null
        return (
            <Button
                className='btn btn-sm bg-info bg-opacity-10 w-100 text-start'
                onClick={() => setQueryOption({ orderDir: newDir })}
            >
                {orderIcon} {orderText}
            </Button>
        )
    }, [orderDir, newDir, props.showOrderBy, opt?.value])

    return (
        <Selector
            placeholder='Sort by'
            label={
                <>
                    {orderLabel} {orderIcon}
                </>
            }
            className='btn btn-sm'
            hideTextFilter={true}
            placement='bottom-end'
            tooltipStyle={{ minWidth: '8rem' }}
            options={props.options}
            option={orderBy}
            footer={footer}
            {...props}
            onSelect={(nv) => {
                setOpt(nv)
                setQueryOption({ orderBy: nv.value, orderDir: nv.orderDir || nv.defaultDir || orderDir })
            }}
        />
    )
}
