import { useContext, useMemo } from 'react'
import { ApplicationContext } from '../context'
import TransportService, { TransportType } from '../services/transport'

export const useTransportType = (props: {
    transportType?: TransportType | null
    transportTypeId?: string
    condition?: boolean
}) => {
    const context = useContext(ApplicationContext)
    return useMemo(() => {
        if (props.condition === false) return undefined
        const id = props.transportTypeId || props.transportType?.uuid || ''
        if (id === TransportService.transportNewId) return TransportService.getEmptyTransportType()
        return TransportService.transportTypeById.get(id) || props.transportType || undefined
    }, [
        props.transportTypeId,
        props.transportType?.uuid,
        context.stores.transport.updates,
        context.stores.transport.transportTypesUpdated,
        props.condition,
    ])
}
