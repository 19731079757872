import { User } from '../../services/user-context'
import { Img } from '../Img'
import { Buildings, User as UserIcon } from '@phosphor-icons/react'
import { CSSProperties, ReactNode, useMemo } from 'react'
import { Company } from '../../services/company'

export const UserCard = (props: {
    children?: ReactNode
    user?: Partial<User>
    company?: Partial<Company> | null
    inline?: boolean
    iconOnly?: boolean
    iconSize?: string
    iconClassName?: string
    className?: string
    extraClassName?: string
    placeholderExtraClassName?: string
    style?: CSSProperties
}) => {
    const content = useMemo(() => {
        if (!props.user) {
            return null
        }

        let _iconClassName = 'rounded-circle nt--1 flex-shrink-0'
        if (props.inline) {
            _iconClassName = 'rounded-circle nt-4 flex-shrink-0'
        }

        if (props.user.type === 'guest') {
            return (
                <>
                    <Buildings className={_iconClassName} />
                    <span className='text-overflow-ellipsis max-w-100'>{props.company?.name || ''}</span>
                </>
            )
        }
        return (
            <>
                <Img
                    src={props.user.profileImageUrl}
                    alt=''
                    size={props.iconSize || '20px'}
                    className={props.iconClassName || _iconClassName}
                    placeholderClassName={[
                        'nt--1 d-flex align-items-center justify-content-center rounded-circle bg-primary bg-opacity-10 very-small text-muted fw-bold',
                        props.placeholderExtraClassName,
                    ].join(' ')}
                    placeholderIconClassName='rounded-circle nt--1'
                    placeholderIcon={<UserIcon />}
                    // initials={true}
                />
                {!props.iconOnly && props.user.name}
                {!props.iconOnly && props.children}
            </>
        )
    }, [
        props.user?.uuid,
        props.company?.uuid,
        props.children,
        props.iconOnly,
        props.iconSize,
        props.iconClassName,
        props.inline,
        props.placeholderExtraClassName,
    ])

    if (!props.user) {
        return null
    }

    let _className = 'd-flex align-items-center gap-1 text-height-1'
    if (props.inline) {
        _className = 'd-inline-flex align-items-baseline gap-1 text-height-1'
    }

    return (
        <span
            className={props.className || `${_className} ${props.extraClassName}`}
            title={props.user.name}
            style={props.style}
        >
            {content}
        </span>
    )
}
