import { ND, VariableBaseNode } from '../types'
import VariableService from './service'

export interface FileData extends VariableBaseNode {
    bucket: string
    path: string
    originalFileName?: string
    hashedPath?: string
    url?: string
}

interface FileUrlResponse {
    fileData: FileData
    url: string
}

export const allowedImageFileTypes = ['jpg', 'jpeg', 'svg', 'png', 'avif', 'heif', 'heic', 'webp']
export const allowedDocumentFileTypes = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'csv'].concat(
    allowedImageFileTypes,
)

export default class FileService extends VariableService {
    private basePath: string = '/file'
    public static webRoot: string = '/documents'

    public friendlyPath(path: string): string {
        if (path.startsWith('http')) return path
        return path?.replace(/[a-zA-Z0-9]{8}-doc-[a-zA-Z0-9]{32}-/g, '')
    }

    public getUrl(hashedPath: string): Promise<FileUrlResponse> {
        return this.httpService.post<FileUrlResponse>(this.basePath, {
            body: JSON.stringify({ hashedPath: hashedPath }),
        })
    }

    public uploadImage(nodeType: ND, nodeId: string, file: Blob): Promise<FileData> {
        const data = new FormData()
        data.append('nodeType', nodeType)
        data.append('nodeId', nodeId)
        data.append('image', file)
        return this.httpService.post<FileData>(`${this.basePath}/image`, {
            json: false,
            body: data,
        })
    }

    public uploadDocument(nodeType: ND, nodeId: string, file: Blob): Promise<FileData> {
        const data = new FormData()
        data.append('nodeType', nodeType)
        data.append('nodeId', nodeId)
        data.append('document', file)
        return this.httpService.post<FileData>(`${this.basePath}/document`, {
            json: false,
            body: data,
        })
    }

    public getFilesByNodeId(nodeId: string): Promise<FileData[]> {
        return this.httpService.get<FileData[]>(`${this.basePath}?nodeId=${nodeId}`)
    }

    public deleteFile(file: FileData, nodeId: string): Promise<void> {
        return this.httpService.delete(`${this.basePath}/document/${file.uuid}/node/${nodeId}`)
    }
}
