import Utils from '../../services/utils'
import { Modal } from '../Modal'
import Button, { ButtonProps } from '../Input/Button'
import { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import DataImportService, { DataImportResponse, DataImportType } from '../../services/dataImport'
import { ActivityImport } from '../Activity/ActivityImport'
import { AirTravelImport } from '../Activity/AirTravelImport'
import { LogisticsImport } from '../Activity/LogisticsImport'
import { ActivityDirection } from '../../services/activity'
import { Selector } from '../Input/Selector'
import { Airplane, DownloadSimple, Factory, ListBullets, PlusCircle, Truck } from '@phosphor-icons/react'
import { VariableServicesContext } from '../../services'
import { ApplicationContext } from '../../context'
import { DataRequestContext } from '../../services/dataRequestContext'
import { ActivityContext } from '../../services/activityContext'
import { KeyValuePair, StandardAttributes } from '../../types'
import { ProductImport } from '../Product/ProductImport'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ProductIcon } from '../Icons/ProductIcon'
import { SupplierImport } from '../Company/SupplierImport'
import { InventoryIcon } from '../Icons/InventoryIcon'
import { BomImport } from '../Product/BomImport'

export const DataImportStart = (
    props: StandardAttributes & {
        children?: ReactNode
        types?: DataImportType[]
        btnProps?: Partial<ButtonProps>
        onClick?: () => void
        onResult?: (result: DataImportResponse) => void
        onClose?: () => void
    },
) => {
    const context = useContext(ApplicationContext)
    const drContext = useContext(DataRequestContext)
    const activityContext = useContext(ActivityContext)
    const [qs] = useSearchParams()
    const navigate = useNavigate()
    const { dataImportService, productService, partService, companyService, userService } =
        useContext(VariableServicesContext)
    const [direction, setDirection] = useState<ActivityDirection | undefined>()
    const [importType, setImportType] = useState<DataImportType>(DataImportType.Unknown)
    const [dromoReady, setDromoReady] = useState<boolean>(false)
    const [shouldContinue, setShouldContinue] = useState<boolean>(false)
    const [dateLocale, setDateLocale] = useState<string>()
    const [numberLocale, setNumberLocale] = useState<string>()
    const locales = Utils.getLocales()
    const dromoRef = useRef<any>()

    useEffect(() => {
        setDateLocale(DataImportService.dromoDateLocale || Utils.dateLocale)
        setNumberLocale(DataImportService.dromoNumberLocale || Utils.numberLocale)
    }, [])

    useEffect(() => {
        if (drContext.drPerspective?.direction) setDirection(drContext.drPerspective?.direction)
    }, [drContext.drPerspective?.direction])

    useEffect(() => {
        if (qs.get('start') === 'true') {
            dataImportService.showStartImport(undefined)
            navigate({ search: '' }, { replace: true })
        }
    }, [qs])

    useEffect(() => {
        if (shouldContinue) setTimeout(() => showDromo(), 100)
    }, [shouldContinue])

    const showDromo = useCallback(
        (attempts: number = 0) => {
            if (attempts > 100) return
            setDromoReady(true)
            dromoRef.current?.open()
        },
        [dromoRef],
    )

    const closeModal = useCallback(() => {
        if (props.types?.length !== 1) setImportType(DataImportType.Unknown)
        setDirection(undefined)
        setShouldContinue(false)
        setDromoReady(false)
        dataImportService.showStartImport(undefined)
        props.onClose?.()
    }, [props.types, props.onClose])

    useEffect(() => {
        if (props.types?.length === 1) setImportType(props.types[0])
    }, [])

    const setResult = useCallback(
        async (result: DataImportResponse) => {
            dataImportService.setDataImportResult(result)
            switch (importType) {
                case 'products':
                    productService.getSlimProducts()
                    break
                case 'materials':
                case 'suppliers':
                case 'activity':
                case 'air-travel':
                case 'transport':
                    await partService.fetchInventory()
                    await companyService.getSuppliers()
                    productService.getSlimProducts()
                    break
            }
            props.onResult?.(result)
            closeModal()
        },
        [importType, props.onResult],
    )

    const button = useMemo(() => {
        const content = props.children || (
            <>
                <DownloadSimple /> Import
            </>
        )
        return (
            <Button
                element='div'
                disabled={props.disabled}
                className={props.className || 'btn btn-primary shadow-none'}
                onClick={() => {
                    props.onClick?.()
                    dataImportService.showStartImport(
                        props.types?.length === 1 ? props.types?.[0] : DataImportType.Unknown,
                    )
                }}
                {...props.btnProps}
            >
                {content}
            </Button>
        )
    }, [props.children, props.disabled, props.className, props.btnProps])

    const formatSettings = useMemo(() => {
        let title = 'Dates & Numbers'
        if (importType === DataImportType.Inputs) title = 'Number format'

        let description = 'Check your file and select the date and number formats used in the file.'
        if (importType === DataImportType.Inputs)
            description = 'Check your file and select the number format used in the file.'
        return (
            <>
                <h6 className='mb-1'>{title}</h6>
                <p className='small text-muted mb-3'>{description}</p>
                <div className='d-flex flex-column gap-3'>
                    <div hidden={importType === DataImportType.Inputs}>
                        Date format
                        <Selector
                            placeholder='Select'
                            placement='bottom-start'
                            hideTextFilter={true}
                            className='variable-select-arrow'
                            itemExtraClassName='font-monospace letter-spacing-1 py-2 text-end'
                            options={locales.date}
                            option={dateLocale}
                            onSelect={(newDateFormat) => {
                                setDateLocale(newDateFormat.value)
                                DataImportService.dromoDateLocale = newDateFormat.value
                            }}
                        />
                    </div>
                    <div>
                        Number format
                        <Selector
                            placeholder='Select'
                            placement='bottom-start'
                            hideTextFilter={true}
                            className='variable-select-arrow'
                            itemExtraClassName='font-monospace letter-spacing-1 py-2 text-end'
                            options={locales.number}
                            option={numberLocale}
                            onSelect={(newNumberFormat) => {
                                setNumberLocale(newNumberFormat.value)
                                DataImportService.dromoNumberLocale = newNumberFormat.value
                            }}
                        />
                    </div>
                </div>
            </>
        )
    }, [dateLocale, numberLocale, locales, importType])

    const continueButton = useMemo(() => {
        let disabled = true
        if (!importType) {
            disabled = true
        } else if (dataImportService.elementImportTypes().includes(importType)) {
            disabled = false
        } else if (
            dataImportService.activityImportTypes().includes(importType) &&
            direction &&
            dateLocale &&
            numberLocale
        ) {
            disabled = false
        }
        return (
            <Button
                saving={shouldContinue}
                disabled={disabled}
                onClick={() => setShouldContinue(true)}
                className='btn btn-secondary'
            >
                Continue
            </Button>
        )
    }, [shouldContinue, importType, direction, dateLocale, numberLocale])

    const importTypes: KeyValuePair<DataImportType>[] = useMemo(() => {
        const it: KeyValuePair<DataImportType>[] = []
        let types: DataImportType[] | undefined = props.types
        if (!props.types) types = dataImportService.defaultImportTypes()
        if (types?.includes(DataImportType.Products)) {
            it.push({
                name: 'Products',
                icon: <ProductIcon size={Utils.largeIconSize} isProduct={true} />,
                value: DataImportType.Products,
            })
        }
        if (types?.includes(DataImportType.Materials)) {
            it.push({
                name: 'Materials',
                icon: <InventoryIcon size={Utils.largeIconSize} />,
                value: DataImportType.Materials,
            })
        }
        if (types?.includes(DataImportType.Suppliers)) {
            it.push({
                name: 'Suppliers',
                icon: <Factory size={Utils.largeIconSize} />,
                value: DataImportType.Suppliers,
            })
        }
        if (types?.includes(DataImportType.Inputs)) {
            it.push({ name: 'BOM', icon: <ListBullets size={Utils.largeIconSize} />, value: DataImportType.Inputs })
        }
        if (types?.includes(DataImportType.Activity)) {
            it.push({
                name: 'Activity Data',
                icon: <PlusCircle size={Utils.largeIconSize} />,
                value: DataImportType.Activity,
            })
        }
        if (types?.includes(DataImportType.AirTravel)) {
            it.push({
                name: 'Air Travel',
                icon: <Airplane size={Utils.largeIconSize} />,
                value: DataImportType.AirTravel,
            })
        }
        if (types?.includes(DataImportType.Transport)) {
            it.push({ name: 'Transport', icon: <Truck size={Utils.largeIconSize} />, value: DataImportType.Transport })
        }
        return it
    }, [props.types])

    const purchaseOrSale = useMemo(() => {
        if (!importType || drContext.drPerspective?.direction) return null
        if (dataImportService.elementImportTypes().includes(importType)) return null
        return (
            <div className='border-top mt-3 pt-3 text-center'>
                <h6 className='mb-3'>Is this Purchase or Sales data?</h6>
                <fieldset
                    disabled={!!drContext.drPerspective?.direction}
                    className='d-grid gap-4'
                    style={{ gridTemplate: 'auto / 1fr 1fr' }}
                >
                    <Button
                        element='div'
                        className={[
                            'border border-primary rounded-2 p-2 text-center flex-grow-1',
                            direction === ActivityDirection.INPUT ? 'bg-primary text-white' : 'bg-primary-hover',
                        ].join(' ')}
                        onClick={() => setDirection(ActivityDirection.INPUT)}
                    >
                        <span className='d-block fs-6 m-0'>Purchase</span>
                    </Button>
                    <Button
                        element='div'
                        className={[
                            'border border-primary rounded-2 p-2 text-center flex-grow-1',
                            direction === ActivityDirection.OUTPUT ? 'bg-primary text-white' : 'bg-primary-hover',
                        ].join(' ')}
                        onClick={() => setDirection(ActivityDirection.OUTPUT)}
                    >
                        <span className='d-block fs-6 m-0'>Sales</span>
                    </Button>
                </fieldset>
            </div>
        )
    }, [importType, direction, activityContext.activity?.direction])

    const modalHeader = useMemo(() => {
        if (props.types?.length === 1 && importType !== DataImportType.Unknown) {
            return (
                <h5 className='m-0'>
                    <DownloadSimple /> Import {importType}
                </h5>
            )
        }
        return (
            <h5 className='m-0'>
                <DownloadSimple /> Import
            </h5>
        )
    }, [props.types, importType])

    const startModal = useMemo(
        () => (
            <Modal
                hidden={false}
                size={props.types?.length === 1 ? '' : 'lg'}
                header={modalHeader}
                bodyClassName='p-0'
                content={
                    <div className='d-flex align-items-stretch gap-3'>
                        <div
                            className='col-7 p-3'
                            hidden={importTypes.length === 1 && importType !== DataImportType.Unknown}
                        >
                            <h6 className='mb-3'>What kind of data are you importing?</h6>
                            <div className='d-grid gap-4' style={{ gridTemplate: 'auto / 1fr 1fr' }}>
                                {importTypes?.map((it) => (
                                    <Button
                                        element='div'
                                        key={`import-type-${it.value}`}
                                        className={[
                                            'd-flex flex-column align-items-center justify-content-center',
                                            'border border-primary rounded-2 p-2 text-center',
                                            importType === it.value ? 'bg-primary text-white' : 'bg-primary-hover',
                                        ].join(' ')}
                                        style={{ height: '5rem' }}
                                        onClick={() => setImportType(it.value)}
                                    >
                                        <span className='d-flex flex-column align-items-center gap-2 text-height-1'>
                                            {it.icon}
                                            {it.name}
                                        </span>
                                    </Button>
                                ))}
                            </div>
                            {purchaseOrSale}
                        </div>
                        <div className='p-3 bg-light flex-grow-1'>{formatSettings}</div>
                    </div>
                }
                footer={<div className='d-flex align-items-center justify-content-end'>{continueButton}</div>}
                onVisibilityChange={(v) => !v && dataImportService.showStartImport(undefined)}
            />
        ),
        [modalHeader, importType, formatSettings, continueButton, purchaseOrSale],
    )

    const modal = useMemo(() => {
        if (!context.stores.ui?.showStartImport) return null
        if (!shouldContinue || importType === DataImportType.Unknown) return startModal

        switch (importType) {
            case DataImportType.Products:
            case DataImportType.Materials:
                return (
                    <ProductImport
                        dromoRef={dromoRef}
                        importType={importType}
                        className='btn-unstyled'
                        onDone={setResult}
                        onCancel={closeModal}
                        onError={closeModal}
                        showButton={false}
                    />
                )
            case DataImportType.Inputs:
                return (
                    <BomImport
                        dromoRef={dromoRef}
                        onDone={setResult}
                        onCancel={closeModal}
                        onError={closeModal}
                        className={props.className}
                    />
                )
            case DataImportType.Suppliers:
                return (
                    <SupplierImport
                        dromoRef={dromoRef}
                        className='btn-unstyled'
                        showButton={false}
                        onCancel={closeModal}
                        onError={closeModal}
                    />
                )
        }

        if (!direction) return startModal

        switch (importType) {
            case DataImportType.Activity:
                return (
                    <ActivityImport
                        dromoRef={dromoRef}
                        showButton={false}
                        dataRequest={drContext.dataRequest}
                        defaultDirection={direction}
                        className='btn-unstyled'
                        onDone={setResult}
                        onCancel={closeModal}
                        onError={closeModal}
                    />
                )
            case DataImportType.AirTravel:
                return (
                    <AirTravelImport
                        dromoRef={dromoRef}
                        showButton={false}
                        dataRequest={drContext.dataRequest}
                        defaultDirection={direction}
                        className='btn-unstyled'
                        onDone={setResult}
                        onCancel={closeModal}
                        onError={closeModal}
                    />
                )
            case DataImportType.Transport:
                return (
                    <LogisticsImport
                        dromoRef={dromoRef}
                        showButton={false}
                        dataRequest={drContext.dataRequest}
                        defaultDirection={direction}
                        className='btn-unstyled'
                        onDone={setResult}
                        onCancel={closeModal}
                        onError={closeModal}
                    />
                )
        }
    }, [shouldContinue, importType, startModal, dromoReady, direction, dromoRef, context.stores.ui?.showStartImport])

    if (userService.isReadonly()) return null

    return (
        <>
            {modal}
            {button}
        </>
    )
}
