import { useContext } from 'react'
import { ModelConfigContext } from './ModelConfig'
import { Input } from '../../services/input'

export const ModelSelectCell = (props: { input: Input; field: string }) => {
    const { modelSelectArea } = useContext(ModelConfigContext)
    let modelSelect = modelSelectArea(props.input, 'element', props.field)
    if (props.input?.useStageType) {
        modelSelect = modelSelectArea(props.input, 'use-stage', 'input')
    } else if (props.input?.transportInstance) {
        modelSelect = modelSelectArea(props.input, 'transport', 'input')
    } else if (props.input?.processingType) {
        modelSelect = modelSelectArea(props.input, 'processing', 'input')
    }
    return modelSelect
}
