import { useContext, useMemo } from 'react'
import { ApplicationContext } from '../context'
import TransportService, { TransportInstance } from '../services/transport'

export const useTransportInstance = (props: {
    transportInstance?: TransportInstance | null
    transportInstanceId?: string
    condition?: boolean
}) => {
    const context = useContext(ApplicationContext)
    return useMemo(() => {
        if (props.condition === false) return undefined
        const id = props.transportInstanceId || props.transportInstance?.uuid || ''
        if (id === TransportService.transportNewId) return TransportService.getEmptyTransportInstance()
        return TransportService.transportInstanceById.get(id) || props.transportInstance || undefined
    }, [props.transportInstanceId, props.transportInstance?.uuid, context.stores.transport.updates, props.condition])
}
