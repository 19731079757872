import ProductService, { Product } from '../../services/product'
import Utils from '../../services/utils'
import { Link } from 'react-router-dom'
import { CSSProperties, useContext, useMemo } from 'react'
import { VariableServicesContext } from '../../services'
import Tooltip, { TooltipProps } from '../Tooltip'
import { MiniPassport } from '../MiniPassport'
import { InventoryIcon } from '../Icons/InventoryIcon'
import { InventoryService } from '../../services/inventory'
import { IconProps } from '@phosphor-icons/react'

export const ElementBadge = (props: {
    product?: Product
    className?: string
    showCompany?: boolean
    style?: CSSProperties
    extraClassName?: string
    nameClassName?: string
    notClickable?: boolean
    iconProps?: Partial<IconProps>
    tooltipProps?: Partial<TooltipProps>
    noTooltip?: boolean
    onClick?: (product?: Product) => void
}) => {
    const { productService } = useContext(VariableServicesContext)

    const content = useMemo(
        () => (
            <span className='d-flex flex-column'>
                <span className='d-flex align-items-center gap-1 w-100'>
                    <InventoryIcon
                        inv={props.product && InventoryService.productToInventory(props.product)}
                        className='flex-shrink-0 nt--1'
                        {...props.iconProps}
                    />
                    <span className={props.nameClassName || 'd-block text-start text-overflow-ellipsis'}>
                        {props.product?.name}
                    </span>
                </span>
                {props.showCompany && props.product?.productOf?.name && (
                    <span className='fs-body align-self-start small'>{props.product?.productOf?.name}</span>
                )}
            </span>
        ),
        [props.product, props.nameClassName, props.iconProps],
    )

    if (!props.product) {
        return null
    }

    if (props.notClickable) {
        return (
            <span className={props.className || `not-clickable ${props.extraClassName || ''}`} style={props.style}>
                {content}
            </span>
        )
    }

    return (
        <Tooltip
            trigger='hover'
            placement='top'
            positioning='fixed'
            disabled={props.noTooltip}
            tooltipStyle={{ maxWidth: '400px' }}
            tooltipContent={<MiniPassport product={props.product} hideIcon={true} />}
            className='max-w-90'
            showArrow={false}
            {...props.tooltipProps}
        >
            <Link
                to={ProductService.getProductUrl(props.product)}
                className={props.className || `input-element ${props.extraClassName || ''}`}
                style={props.style}
                onClick={(e) => {
                    if (!Utils.isModifierKey(e)) {
                        e.preventDefault()
                        if (props.onClick) {
                            props.onClick?.(props.product)
                        } else {
                            productService.openPreview(props.product)
                        }
                    }
                }}
            >
                {content}
            </Link>
        </Tooltip>
    )
}
