import { Company, VariableCustomer } from './company'
import VariableService from './service'
import { VariableBaseNode } from '../types'

export interface Subscription extends VariableBaseNode {
    name: string
    priceInNok: number
    stripeId?: string
    stripeName?: string
    users: number
    userCount: number
    usersLeft: number
    suppliers: number
    count?: number
    database: 'standard' | 'premium'
    premiumDataPoints?: number
    companies?: Company[]
}

export class SubscriptionService extends VariableService {
    private basePath: string = '/subscription'
    public static websitePricingLink = process.env.REACT_APP_PRICING_LINK!
    public static stripeProductIdFree = process.env.REACT_APP_STRIPE_PRODUCT_ID_FREE!
    public static stripeProductIdTeam = process.env.REACT_APP_STRIPE_PRODUCT_ID_TEAM!
    public static stripeProductIdBusiness = process.env.REACT_APP_STRIPE_PRODUCT_ID_BUSINESS!
    public static stripeProductIdEnterprise = process.env.REACT_APP_STRIPE_PRODUCT_ID_ENTERPRISE!
    public static readonly webTitle = 'Pricing'
    public static appPricingPath = '/pricing'

    public static isFree(subscription?: Subscription): boolean {
        if (!subscription) return true
        return subscription?.name === 'Free'
    }

    public static isPremium(subscription?: Subscription): boolean {
        if (!subscription) return false
        return subscription?.database === 'premium' || subscription?.name !== 'Free'
    }

    public async getVariableCustomer(): Promise<VariableCustomer | undefined> {
        return this.httpService.get<VariableCustomer>(`${this.basePath}/customer`).catch((e) => {
            console.warn(e)
            return undefined
        })
    }

    public getCheckoutUrl(stripeId: string): Promise<any> {
        return this.httpService.post<any>(this.basePath, {
            body: JSON.stringify({ subId: stripeId }),
        })
    }

    public getManageUrl(upgradeTo?: string): Promise<any> {
        return this.httpService.put<any>(this.basePath, {
            body: JSON.stringify({ subId: upgradeTo }),
        })
    }
}
