import {
    FileCsv,
    FileDoc,
    FileImage,
    FileJpg,
    FilePdf,
    FilePng,
    FilePpt,
    FileSvg,
    FileText,
    FileXls,
    FileZip,
} from '@phosphor-icons/react'
import { allowedImageFileTypes, FileData } from '../../services/file'
import { IconWeight } from '@phosphor-icons/react/dist/lib/types'
import Utils from '../../services/utils'

export const FileIcon = (props: {
    file?: FileData
    size?: number
    weight?: IconWeight
    className?: string
    extraClassName?: string
}) => {
    const attrs = {
        size: props.size || Utils.largeIconSize,
        weight: props.weight || 'regular',
        className: props.className || `nt--1 ${props.extraClassName || ''}`,
    }
    let icon = <FileText {...attrs} />
    const ext = props.file?.originalFileName?.split('.')?.pop()?.toLowerCase()
    if (!ext) {
        return icon
    }
    if (allowedImageFileTypes.includes(ext)) {
        icon = <FileImage {...attrs} />
    }
    switch (ext) {
        case 'pdf':
            return <FilePdf {...attrs} />
        case 'doc':
        case 'docx':
            return <FileDoc {...attrs} />
        case 'xls':
        case 'xlsx':
            return <FileXls {...attrs} />
        case 'ppt':
        case 'pptx':
            return <FilePpt {...attrs} />
        case 'csv':
        case 'tsv':
            return <FileCsv {...attrs} />
        case 'zip':
            return <FileZip {...attrs} />
        case 'jpg':
        case 'jpeg':
            return <FileJpg {...attrs} />
        case 'svg':
            return <FileSvg {...attrs} />
        case 'png':
            return <FilePng {...attrs} />
        default:
            return icon
    }
}
