import { Modal } from './Modal'
import { useContext, useEffect, useMemo, useState } from 'react'
import { ApplicationContext } from '../context'
import { useLocation, useSearchParams } from 'react-router-dom'
import { ArrowRight } from '@phosphor-icons/react'
import UiService from '../services/ui'
import AuthenticationService from '../services/authentication'
import { VariableServicesContext } from '../services'

const UpdateAvailableModal = () => {
    const context = useContext(ApplicationContext)
    const { analyticsService, uiService } = useContext(VariableServicesContext)
    const [qs] = useSearchParams()
    const location = useLocation()
    const [showModal, setShowModal] = useState<boolean>(false)

    const currentVersion = useMemo(
        () => qs.get('uiVersion') || context.stores.ui?.uiVersion,
        [context.stores.ui?.uiVersion],
    )

    const serverVersion = useMemo(() => context.stores.status?.uiVersion, [context.stores.status?.uiVersion])

    const from = useMemo(() => {
        if (!location.pathname || !serverVersion) return
        qs.delete('uiVersion')
        let queryString = ''
        if (qs.size) {
            queryString = `?${qs.toString()}`
        }
        return encodeURIComponent(`${location.pathname}${queryString}`)
    }, [serverVersion, location.pathname, qs])

    const versionDiff = useMemo(
        () => uiService.getVersionDiff(currentVersion, serverVersion),
        [currentVersion, serverVersion],
    )

    useEffect(() => {
        if (
            location.pathname?.startsWith(UiService.versionPath) ||
            AuthenticationService.isAuthPath(location.pathname)
        ) {
            return
        }
        if (['major'].includes(versionDiff || '')) {
            console.debug('UI Upgrade', currentVersion, serverVersion, versionDiff)
            setShowModal(true)
            analyticsService.track('Showed Upgrade UI Modal', { currentVersion, serverVersion })
        }
    }, [versionDiff, currentVersion, serverVersion, location.pathname])

    if (!showModal) return null

    return (
        <Modal
            hidden={false}
            closeOnOutsideClick={false}
            closeButton={false}
            header={<h5>Update required</h5>}
            content={<div>A new version of Variable is available. Please update to continue.</div>}
            footer={
                <div className='d-flex align-items-center justify-content-between gap-2 w-100'>
                    <span className='d-flex align-items-center gap-1 text-muted font-monospace'>
                        {currentVersion}
                        <ArrowRight />
                        {serverVersion}
                    </span>
                    <a href={uiService.getVersionUpdateUrl(serverVersion, from)} className='btn btn-sm btn-secondary'>
                        Update
                    </a>
                </div>
            }
            onVisibilityChange={() => {}}
        />
    )
}

export default UpdateAvailableModal
