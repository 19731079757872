import { Modal } from '../Modal'
import { useContext, useMemo } from 'react'
import { ApplicationContext } from '../../context'
import { EnergyIcon } from '../Icons/EnergyIcon'
import { ElectricitySelector } from '../Input/ElectricitySelector'
import { VariableServicesContext } from '../../services'
import { useNavigate } from 'react-router-dom'
import ProductService from '../../services/product'

export const ElectricityCreator = () => {
    const context = useContext(ApplicationContext)
    const navigate = useNavigate()
    const { electricityService } = useContext(VariableServicesContext)

    const visible = useMemo(
        () => context.stores.ui?.showElectricityCreator,
        [context.stores.ui?.showElectricityCreator],
    )

    if (!visible) return null

    return (
        <Modal
            hidden={false}
            header={
                <>
                    <EnergyIcon /> Create electricity element
                </>
            }
            content={
                <div className='' style={{ height: '30vh' }}>
                    <ElectricitySelector
                        selectorProps={{ placeholder: 'Select location', contentOnly: true }}
                        onChange={(geoLocation, location, footprints) => {
                            const factorId = footprints?.[0].uuid
                            if (!factorId) return
                            electricityService
                                .getElectricityForFactor(factorId, location?.uuid, geoLocation?.uuid)
                                .then((el) => {
                                    electricityService.showElectricityCreator(false)
                                    navigate(ProductService.getProductUrl(el))
                                })
                        }}
                    />
                </div>
            }
        />
    )
}
