import { useLocation, useNavigate } from 'react-router-dom'

export const Forward = (props: { from: string; to: string }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const url = location.pathname.replace(props.from, props.to)
    const qs = location.search
    const newUrl = `${url}${qs}`
    setTimeout(() => navigate(newUrl, { replace: true }))
    return <></>
}
