import { User } from './user-context'
import HttpService from './http'
import { UnknownError } from '../types'
import CompanyService from './company'
import UserService from './user'
import { Token } from './token'
import { User as Auth0User } from '@auth0/auth0-react'
import Utils from './utils'
import VariableService from './service'
import { ApplicationContextInterface } from '../context'
import FlagService from './flag'
import { AnalyticsService } from './analytics'
import { Buffer } from 'buffer'
import { Dayjs } from 'dayjs'

export type AuthenticateScreen = 'signin' | 'signup' | 'reset'

interface AuthResponse {
    accessToken: string
    refreshToken: string
}

type JWTPayload = {
    sub: string
    iat: number
    exp: number
}

class AuthenticationService extends VariableService {
    public static welcomePath: string = '/welcome'
    public static signUpPath: string = '/signup'
    public static signInPath: string = '/signin'
    public static signOutPath: string = '/signout'
    public static joinPath: string = '/join'
    public static waitlistPath: string = '/waitlist'
    public static resetPasswordPath: string = '/reset'
    public static pageCategory = 'Authentication'
    public static onboardingCategory = 'Onboarding'
    public static basePath = '/auth'
    private companyService: CompanyService
    private flagService: FlagService
    private analyticsService: AnalyticsService
    private userService: UserService
    public static isRefreshingToken: boolean = false
    public static accessTokenExpires: Dayjs | undefined = undefined

    constructor(context: ApplicationContextInterface) {
        super(context)
        this.analyticsService = new AnalyticsService(context)
        this.companyService = new CompanyService(context)
        this.flagService = new FlagService(context)
        this.userService = new UserService(context)
    }

    setContext(context: ApplicationContextInterface) {
        super.setContext(context)
        this.analyticsService.setContext(context)
        this.companyService.setContext(context)
        this.flagService.setContext(context)
        this.userService.setContext(context)
    }

    public hasVariableCredentials = (user: User): boolean => {
        return user.authId?.startsWith('auth0|') === true
    }

    public isOauthUser = (user: User): boolean => {
        return user.authId !== undefined && !user.authId.startsWith('auth0|')
    }

    public static isAuthPath = (path: string): boolean => {
        return (
            path.startsWith(AuthenticationService.signInPath) ||
            path.startsWith(AuthenticationService.signUpPath) ||
            path.startsWith(AuthenticationService.signOutPath) ||
            path.startsWith(AuthenticationService.resetPasswordPath) ||
            path.startsWith(AuthenticationService.welcomePath)
        )
    }

    public getAuthService = (user: User): 'db' | 'google' | 'microsoft' => {
        if (user.authId?.startsWith('google-apps|') === true || user.authId?.startsWith('google-oauth2|') === true) {
            return 'google'
        }
        if (user.authId?.startsWith('windowslive|') === true || user.authId?.startsWith('waad|') === true) {
            return 'microsoft'
        }
        return 'db'
    }

    private static documentListenersReady = false

    public static setupDocumentListeners() {
        if (!AuthenticationService.documentListenersReady) {
            AuthenticationService.documentListenersReady = true
            window.addEventListener('focus', this.checkRefreshToken)
            window.onunload = () => window.removeEventListener('focus', this.checkRefreshToken)
        }
    }

    private lastReload: number = new Date().valueOf()

    public checkAuthOnWindowFocus() {
        // console.info('Context', this.context.stores.company?.name)
        if (
            this.context.stores.company?.uuid &&
            CompanyService.companyId &&
            this.context.stores.company?.uuid !== CompanyService.companyId
        ) {
            // console.info('Company changed, reloading', this.context.stores.company?.uuid, CompanyService.companyId)
            // prevent crazy reload loop and limit reloads to once every 5 seconds.
            // I realize this still won't be fun for the user, but it's better than nothing.
            if (new Date().valueOf() - 5000 > this.lastReload) {
                this.lastReload = new Date().valueOf()
                window.location.reload()
            }
        }
    }

    public async initUser(): Promise<void> {
        if (AuthenticationService.accessToken !== null) {
            const fullUser = await this.userService.get()
            this.companyService.setCompany(undefined, fullUser?.companies)
            const myCompany = await this.companyService.getMyCompany()
            await this.flagService.getFlags()
            this.analyticsService.identify(fullUser.uuid, {
                name: fullUser.name,
                firstName: fullUser.firstName,
                lastName: fullUser.lastName,
                email: fullUser.email,
                companyId: myCompany?.uuid,
                companyName: myCompany?.name,
                subscriptionName: myCompany?.subscription?.stripeName || myCompany?.subscription?.name,
            })
            this.analyticsService.group(myCompany?.uuid, {
                id: myCompany?.uuid,
                name: myCompany?.name,
                industry: myCompany?.industry?.name,
                createdAt: myCompany?.created ? Utils.dayjs(myCompany?.created).toISOString() : undefined,
                plan: myCompany?.subscription?.stripeName || myCompany?.subscription?.name,
            })
        }
    }

    public static get nextPage(): string | null {
        return decodeURIComponent(localStorage.getItem('nextPage') || '/')
    }

    public static set nextPage(nextPage: string | null) {
        const currentNextPage = this.nextPage
        if (nextPage === null) {
            localStorage.removeItem('nextPage')
        } else if (nextPage !== currentNextPage && !this.isAuthPath(nextPage)) {
            localStorage.setItem('nextPage', encodeURIComponent(nextPage))
        }
    }

    public static getPostAuthUrl(): string {
        const nextPage = this.nextPage
        const next = nextPage || '/'
        if (this.isAuthPath(next) || next === UserService.webRootProfile) {
            return '/'
        }
        setTimeout(() => {
            this.nextPage = null
        }, 1000)
        return next
    }

    public async sso(auth0User: Auth0User): Promise<void> {
        await this.httpService
            .post<AuthResponse>(`${AuthenticationService.basePath}/sso`, {
                auth: false,
                body: JSON.stringify(auth0User),
            })
            .then(AuthenticationService.handleAuthResponse)
    }

    public async signOut(): Promise<void> {
        await this.httpService.post<boolean>(`${AuthenticationService.basePath}${AuthenticationService.signOutPath}`)
    }

    public getSessions(): Promise<Token[]> {
        return this.httpService.get<Token[]>(`${AuthenticationService.basePath}/sessions`)
    }

    public revokeSession(session?: Token): Promise<Token[]> {
        let url = `${AuthenticationService.basePath}/sessions`
        if (session?.uuid || session?.ttl) {
            url += `/${session?.uuid || session?.ttl || ''}`
        }
        return this.httpService.delete<Token[]>(url)
    }

    public static clearTokens() {
        AuthenticationService.accessToken = null
        AuthenticationService.refreshToken = null
        // CompanyService.companyId = null
    }

    public resetPassword(email: string): Promise<void> {
        return this.httpService.post(`${AuthenticationService.basePath}${AuthenticationService.resetPasswordPath}`, {
            body: JSON.stringify({ email }),
        })
    }

    public static async checkRefreshToken(): Promise<void> {
        if (AuthenticationService.accessTokenExpires?.isBefore(Utils.dayjs()) && this.refreshToken !== null) {
            await AuthenticationService.refreshAccessToken()
        }
    }

    private static toResolve: {
        resolve: (value: any) => void
        reject: (reason: any) => void
    }[] = []

    public static async refreshAccessToken(): Promise<void> {
        if (this.isRefreshingToken) {
            return new Promise((resolve, reject) => {
                this.toResolve.push({ resolve, reject })
            })
        }
        this.isRefreshingToken = true
        return HttpService.fetch<AuthResponse>('post', '/auth/refresh', {
            auth: false,
            body: JSON.stringify({ rt: AuthenticationService.refreshToken }),
        })
            .then((r) => {
                this.handleAuthResponse(r)
                this.isRefreshingToken = false
                // console.log(this.toResolve)
                let i = 0
                while (this.toResolve.length > 0 && i < 20) {
                    const tr = this.toResolve.pop()
                    if (tr) tr.resolve(true)
                    i++
                }
            })
            .catch((e) => {
                AuthenticationService.clearTokens()
                this.isRefreshingToken = false
                throw new Error(e)
            })
    }

    public static sendUserToAuth(nextPage?: string): void {
        if (!this.isAuthPath(document.location.pathname)) {
            const _nextPage = nextPage || `${document.location.pathname}${document.location.search}`
            let _from = ''
            if (_nextPage !== '/') {
                _from = `?from=${_nextPage}`
            }
            if (CompanyService.companyId) {
                document.location.href = `${Utils.getBaseUrl()}${this.signInPath}${_from}`
            } else {
                document.location.href = `${Utils.getBaseUrl()}${this.welcomePath}${_from}`
            }
        }
    }

    private static handleAuthResponse(response: AuthResponse): void {
        AuthenticationService.accessToken = response.accessToken
        AuthenticationService.refreshToken = response.refreshToken

        if (AuthenticationService.accessToken === null) {
            throw new UnknownError('Something went wrong while setting the access token and decoding the JWT.')
        }

        const at = AuthenticationService.decodeJwt(response.accessToken)
        if (at?.exp && !isNaN(at.exp)) {
            AuthenticationService.accessTokenExpires = Utils.dayjs(at.exp * 1000)
        }
    }

    public static isOnboarded(): boolean {
        return this.accessToken !== null && !!CompanyService.companyId
    }

    public isOnboarded(): boolean {
        return this.context.stores.user?.uuid !== undefined && this.context.stores.company?.uuid !== undefined
    }

    public static get isAuthenticated(): boolean {
        return AuthenticationService.accessToken !== null
    }

    public static get accessToken(): string | null {
        return localStorage.getItem('accessToken')
    }

    private static set accessToken(accessToken: string | null) {
        if (accessToken === null) {
            localStorage.removeItem('accessToken')
        } else {
            localStorage.setItem('accessToken', accessToken)
        }
    }

    private static get refreshToken(): string | null {
        return localStorage.getItem('refreshToken')
    }

    private static set refreshToken(refreshToken: string | null) {
        if (refreshToken === null) {
            localStorage.removeItem('refreshToken')
        } else {
            localStorage.setItem('refreshToken', refreshToken)
        }
    }

    private static decodeJwt(encodedJwt: string): JWTPayload | null {
        try {
            const encodedPayload = encodedJwt.split('.')[1]
            const decodedBase64Payload = Buffer.from(encodedPayload, 'base64').toString()
            return JSON.parse(decodedBase64Payload)
        } catch (e) {
            if (e instanceof Error) {
                console.error(e)
            }
            console.error('Could not decode JWT!', encodedJwt)
            return null
        }
    }
}

export default AuthenticationService
