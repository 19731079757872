import {
    ActivityCalculationMethod,
    ActivityCo2eView,
    ActivityItem,
    ActivityListViewOption,
    ActivityPerspective,
} from '../../services/activity'
import { Checkbox } from '../Input/Checkbox'
import { CSSProperties, useContext, useEffect, useMemo, useState } from 'react'
import CO2e from '../CO2e'
import Utils from '../../services/utils'
import { TaxonomyLabel } from '../TaxonomyLabel'
import { ActivityTableColumnType } from './ActivityTable'
import { QualitySummary } from '../User/QualitySummary'
import { OrgPath } from '../Org/OrgPath'
import GHGService from '../../services/ghg'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'
import { PrettyNumber } from '../PrettyNumber'
import { StateLabel } from '../StateLabel'
import { ElementBadge } from '../Product/ElementBadge'
import { UserCard } from '../User/UserCard'
import Tooltip from '../Tooltip'
import { DateString } from '../DateString'
import { DataRequestContext } from '../../services/dataRequestContext'
import { DataRequestType } from '../../services/dataRequest'
import { ActivityContext } from '../../services/activityContext'
import { TransportBadge } from '../Transport/TransportBadge'
import LocationService from '../../services/location'
import { Leaf, SolarPanel } from '@phosphor-icons/react'
import FlagService, { FlagType } from '../../services/flag'

export const ActivityLine = (props: {
    activityItem: ActivityItem
    highlight?: boolean
    co2eView?: ActivityCo2eView
    cols?: ActivityTableColumnType[]
    rowClassName?: string
    rowStyle?: CSSProperties
    checked?: boolean
    disabled?: boolean
    onClick: (activityItem: ActivityItem) => void
    onCheck: (checked: boolean) => void
}) => {
    const context = useContext(ApplicationContext)
    const drContext = useContext(DataRequestContext)
    const activityContext = useContext(ActivityContext)
    const { activityService } = useContext(VariableServicesContext)
    const [perspective, setActivityPerspective] = useState<ActivityPerspective>(
        activityService.getActivityPerspective(props.activityItem),
    )

    const co2eVisible = useMemo(() => {
        if (drContext.dataRequest?.type === DataRequestType.SHARE) {
            return true
        }
        return context.stores.company?.claimed === true
    }, [drContext.dataRequest?.type, context.stores.company?.claimed])

    const activityProduct = useMemo(() => {
        if (props.activityItem.staticData) {
            return undefined
        }
        return props.activityItem.product
    }, [props.activityItem.staticData, props.activityItem.product])

    const useStageLocation = useMemo(
        () => LocationService.getNodeLocation(props.activityItem.useStageType),
        [props.activityItem.useStageType?.location, props.activityItem.useStageType?.geoLocation],
    )

    useEffect(
        () => setActivityPerspective(activityService.getActivityPerspective(props.activityItem)),
        [props.activityItem],
    )

    return useMemo(() => {
        let co2eView: ActivityCo2eView = props.co2eView || 'perspective'
        if (context.stores.ui?.activityListCo2e === ActivityListViewOption.ALL) {
            co2eView = 'embedded'
        }
        const showDownstream = context.stores.ui?.activityListCo2e === ActivityListViewOption.ALL
        let startDate: number | undefined = props.activityItem.startDate || props.activityItem.date
        let endDate: number | undefined = props.activityItem.endDate || props.activityItem.date
        if (!startDate && endDate) {
            startDate = endDate
            endDate = undefined
        }
        const firstState = props.activityItem.stateHistory?.[0]
        return (
            <tr
                className={[
                    props.rowClassName,
                    props.highlight ? 'bg-primary bg-opacity-10' : '',
                    'text-alt-font small bg-primary-hover clickable',
                ].join(' ')}
                onClick={(e: any) => {
                    if (context.stores.activity.bulkIds.size > 0) {
                        props.onCheck(!props.checked)
                    } else if (e.target.type !== 'checkbox') {
                        props.onClick(props.activityItem)
                    }
                }}
                style={props.rowStyle}
            >
                <td className='align-middle' hidden={props.cols && !props.cols.includes('checkbox')}>
                    <Checkbox
                        disabled={!activityContext.queryString}
                        hidden={!activityContext.queryString}
                        checked={props.checked}
                        onChange={props.onCheck}
                    />
                </td>
                <td className='align-middle' hidden={props.cols && !props.cols.includes('submittedBy')}>
                    <UserCard
                        style={{ maxWidth: '10rem' }}
                        user={firstState?.user || props.activityItem.createdBy}
                        company={props.activityItem.sender}
                    />
                    <Tooltip
                        trigger='hover'
                        positioning='fixed'
                        tooltipContent={
                            <DateString date={firstState?.created || props.activityItem.created} showTime={true} />
                        }
                        className='d-inline-block text-nowrap text-muted'
                    >
                        <DateString date={firstState?.created || props.activityItem.created} relativeTime={true} />
                    </Tooltip>
                </td>
                {/*<td hidden={props.cols && !props.cols.includes('type')}>*/}
                {/*    {activityTypeOptions.find((ato) => ato.value === perspective.direction)?.name}*/}
                {/*</td>*/}
                <td className='align-middle' hidden={props.cols && !props.cols.includes('input')}>
                    <TaxonomyLabel
                        taxonomy={props.activityItem.taxonomy || props.activityItem.product?.taxonomy}
                        extraClassName='mb-1'
                    />
                    <TransportBadge transportType={props.activityItem.transportType} />
                    <div className='d-flex align-items-center gap-1'>
                        <ElementBadge
                            product={props.activityItem.product || undefined}
                            notClickable={true}
                            extraClassName='d-block'
                            style={{ maxWidth: '12rem' }}
                        />
                        {FlagService.enabledFlags.has(FlagType.EnableElectricity) && (
                            <span className='d-flex align-items-center gap-1'>
                                {!!props.activityItem.renewableProduction?.quantity && <SolarPanel />}
                                {!!props.activityItem.electricityCertificate?.quantity && (
                                    <Tooltip
                                        trigger='hover'
                                        tooltipContent='Guarantees of Origin Certificate or Renewable Energy Certificate'
                                        className='text-nowrap'
                                    >
                                        <Leaf />
                                        GO
                                    </Tooltip>
                                )}
                            </span>
                        )}
                    </div>
                    {props.activityItem.name && (
                        <span className='d-block text-muted small'>{props.activityItem.name}</span>
                    )}
                    {useStageLocation && (
                        <span className='d-inline-block text-muted small px-1 bg-light rounded-1'>
                            {LocationService.getLocationName(useStageLocation)}
                        </span>
                    )}
                </td>
                <td
                    hidden={props.cols && !props.cols.includes('quality')}
                    className='text-center text-height-1 text-base-font align-middle'
                >
                    <QualitySummary size={30} product={props.activityItem.product || undefined} />
                </td>
                <td hidden={props.cols && !props.cols.includes('quantity')} className='text-end align-middle'>
                    <PrettyNumber num={props.activityItem.quantity} precision={2} />
                </td>
                <td className='align-middle' hidden={props.cols && !props.cols.includes('quantity')}>
                    {activityService.getActivityUnitString(props.activityItem)}
                </td>
                <td className='align-middle' hidden={props.cols && !props.cols.includes('company')}>
                    {perspective.otherCompany?.name}
                </td>
                <td className='align-middle' hidden={props.cols && !props.cols.includes('date')}>
                    <span className='d-flex align-items-center flex-wrap gap-1'>
                        <DateString className='text-end' date={startDate} highlightFuture={true} />
                        {!Utils._dayjs(startDate).isSame(endDate, 'day') && (
                            <>
                                <span>-</span>
                                <DateString date={endDate} highlightFuture={true} />
                            </>
                        )}
                    </span>
                </td>
                <td className='align-middle' hidden={props.cols && !props.cols.includes('org')}>
                    <OrgPath org={props.activityItem.org} />
                </td>
                <td className='align-middle' hidden={props.cols && !props.cols.includes('scope')}>
                    {GHGService.fullScopeName(props.activityItem.scope)}
                </td>
                <td hidden={props.cols && !props.cols.includes('co2e')} className='text-end align-middle'>
                    {co2eView === 'perspective' && (
                        <CO2e
                            hidden={!co2eVisible}
                            co2e={perspective.co2e}
                            product={activityProduct}
                            unitSize='large'
                            unitsClassName='d-block text-muted small'
                            numberClassName={props.activityItem.staticData ? 'bg-info bg-opacity-10 p-1' : ''}
                            showZeroesToPrecision={true}
                        />
                    )}
                    {co2eView !== 'perspective' && (
                        <>
                            <div className='text-end'>
                                <CO2e
                                    hidden={!co2eVisible}
                                    co2e={props.activityItem.impact?.gwp.total.up}
                                    product={activityProduct}
                                    showUnit={false}
                                    unitSize='large'
                                    className={
                                        perspective.incoming &&
                                        context.stores.company?.downstreamActivityConfiguration !==
                                            ActivityCalculationMethod.TOTAL
                                            ? 'border-dashed border-bottom'
                                            : ''
                                    }
                                    showZeroesToPrecision={true}
                                />
                            </div>
                            <div className='text-end'>
                                <CO2e
                                    hidden={!co2eVisible || !showDownstream}
                                    co2e={props.activityItem.impact?.gwp.total.down}
                                    product={activityProduct}
                                    showUnit={false}
                                    unitSize='large'
                                    className={[
                                        perspective.outgoing &&
                                        context.stores.company?.downstreamActivityConfiguration ===
                                            ActivityCalculationMethod.DOWNSTREAM
                                            ? 'border-dashed border-bottom'
                                            : '',
                                        'text-muted',
                                    ].join(' ')}
                                    showZeroesToPrecision={true}
                                />
                            </div>
                        </>
                    )}
                </td>
                <td hidden={props.cols && !props.cols.includes('state')} className='text-end align-middle'>
                    <StateLabel node={props.activityItem} />
                </td>
            </tr>
        )
    }, [
        props.activityItem,
        props.highlight,
        props.co2eView,
        props.cols,
        props.rowClassName,
        props.rowStyle,
        props.checked,
        props.disabled,
        activityContext.queryString,
        context.stores.activity.bulkIds,
        context.stores.ui?.activityListCo2e,
        context.stores.company?.downstreamActivityConfiguration,
        perspective,
    ])
}
