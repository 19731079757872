import { PrettyNumber, PrettyNumberProps } from './PrettyNumber'
import { Amount } from '../types'
import { useCallback, useContext, useMemo } from 'react'
import { ApplicationContext } from '../context'
import { Unit, UnitType } from '../services/unit'
import Utils from '../services/utils'
import Tooltip, { TooltipProps } from './Tooltip'
import { VariableServicesContext } from '../services'
import { UnitSelector } from './Input/UnitSelector'

export const NiceAmount = (props: {
    quantity?: number
    amount?: Amount
    unitType?: UnitType
    tooltipProps?: Partial<TooltipProps>
    prettyNumberProps?: Partial<PrettyNumberProps>
}) => {
    const context = useContext(ApplicationContext)
    const { userService } = useContext(VariableServicesContext)

    const unitType = useMemo(
        () => props.unitType || props.amount?.unit?.type,
        [props.unitType, props.amount?.unit?.type],
    )

    const preferredUnit = useMemo(
        () => (unitType && userService.preferredUnit(unitType)) || props.amount?.unit,
        [props.amount?.unit, context.stores.user?.unitPreferences, unitType],
    )

    const quantity = useMemo(
        () =>
            Utils.Decimal(props.quantity || props.amount?.quantity || 0)
                .times(props.amount?.unit?.fromBaseUnit || 1)
                .times(preferredUnit?.toBaseUnit || 1)
                .toNumber(),
        [props.quantity, props.amount, preferredUnit],
    )

    if (!preferredUnit?.code) return null

    return (
        <Tooltip
            trigger='right-click'
            interactive={true}
            closeOnInteraction={true}
            placement='bottom'
            tooltipContent={<NiceAmountList unitType={unitType} />}
            {...props.tooltipProps}
        >
            <PrettyNumber num={quantity} suffix={` ${preferredUnit?.code}`} {...props.prettyNumberProps} />
        </Tooltip>
    )
}

export const NiceAmountList = (props: { unitType?: UnitType }) => {
    const { userService } = useContext(VariableServicesContext)

    const onSetUnit = useCallback(
        (u?: Unit) => {
            if (!props.unitType || !u) return
            userService.setUnitPreferences({ [props.unitType]: u.code }).catch((e) => console.warn(e))
        },
        [props.unitType],
    )

    if (!props.unitType) return null

    return (
        <UnitSelector
            unit={userService.preferredUnit(props.unitType)}
            onChange={onSetUnit}
            unitType={props.unitType}
            hideTextFilter={true}
            contentOnly={true}
        />
    )
}
