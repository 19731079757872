import { useContext, useMemo } from 'react'
import { ApplicationContext } from '../context'
import ProcessingService, { ByproductType } from '../services/processing'

export const useByproductType = (props: {
    byproductType?: ByproductType | null
    byproductTypeId?: string
    condition?: boolean
}) => {
    const context = useContext(ApplicationContext)
    return useMemo(() => {
        if (props.condition === false) return undefined
        const id = props.byproductTypeId || props.byproductType?.uuid || ''
        if (id === 'new') return {}
        return ProcessingService.byproductById.get(id) || props.byproductType || undefined
    }, [props.byproductTypeId, props.byproductType?.uuid, context.stores.processingType.updates, props.condition])
}
