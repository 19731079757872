import { CSSProperties, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import Button from './Input/Button'
import { ArrowsOutSimple, X } from '@phosphor-icons/react'
import UiService, { UIOptionActionType } from '../services/ui'
import { ApplicationContext } from '../context'
import { Link } from 'react-router-dom'
import { StandardAttributes } from '../types'

export type ModalSize = '' | 'sm' | 'lg' | 'xl' | 'fullscreen' | 'custom'

export type ModalProps = {
    ariaLabel?: string
    hidden?: boolean
    size?: ModalSize
    closeButton?: boolean
    closeOnOutsideClick?: boolean
    canClose?: boolean
    scrollable?: boolean
    content: ReactNode
    header?: ReactNode
    footer?: ReactNode
    style?: CSSProperties
    expandLink?: string
    extraClassName?: string
    containerAttrs?: StandardAttributes
    contentAttrs?: StandardAttributes
    headerAttrs?: StandardAttributes
    bodyClassName?: string
    footerClassName?: string
    contentStyle?: CSSProperties
    outsideModal?: ReactNode
    onVisibilityChange?: (isVisible: boolean) => void
}

export const Modal = (props: ModalProps) => {
    const context = useContext(ApplicationContext)
    const [modalId] = useState(`modal-${Math.random()}`)
    const [ready, setReady] = useState<boolean>(false)
    const [visible, setVisible] = useState<boolean>(!props.hidden)

    useEffect(() => setReady(true), [])

    useEffect(() => {
        props.onVisibilityChange?.(visible)
        if (visible) {
            context.dispatch({ type: UIOptionActionType.AddUiLayer, payload: modalId })
        } else if (ready) {
            setTimeout(() => UiService.onModalClose(), 5)
            setTimeout(() => context.dispatch({ type: UIOptionActionType.RemoveUiLayer, payload: modalId }), 100)
        }
    }, [visible])

    useEffect(() => setVisible(props.hidden === false), [props.hidden])

    useEffect(() => {
        if (context.stores.ui?.layers?.[0] === modalId) {
            onOutsideClick()
        }
    }, [context.stores.ui?.escapeKey])

    const onOutsideClick = useCallback(() => {
        if (props.closeOnOutsideClick !== false) setVisible(false)
    }, [props.closeOnOutsideClick])

    if (!visible) {
        return null
    }

    let modalClass = 'modal-dialog modal-dialog-centered'
    if (props.scrollable !== false) {
        modalClass += ' modal-dialog-scrollable'
    }
    if (props.size) {
        modalClass += ` modal-${props.size}`
        if (props.size === 'fullscreen') {
            modalClass += ` modal-fullscreen-${props.size}-down`
        }
    }

    return (
        <div
            className={[props.containerAttrs?.className || 'modal d-block', props.containerAttrs?.extraClassName].join(
                ' ',
            )}
            style={props.containerAttrs?.style}
        >
            <div
                aria-label={`Close: ${props.ariaLabel || ''}`}
                className='fill-parent bg-dark bg-opacity-25'
                role='link'
                tabIndex={0}
                onClick={() => onOutsideClick()}
                onKeyDown={() => {}}
            />
            <div
                aria-label={`Modal: ${props.ariaLabel || ''}`}
                className={`${modalClass} ${props.extraClassName || ''}`}
                style={props.style}
            >
                <div
                    className={[
                        props.contentAttrs?.className || 'modal-content border-0 shadow-lg',
                        props.contentAttrs?.extraClassName,
                    ].join(' ')}
                    style={props.contentStyle}
                >
                    {props.header && (
                        <div
                            className={[
                                props.headerAttrs?.className || 'modal-header gap-2',
                                props.headerAttrs?.extraClassName,
                            ].join(' ')}
                            style={props.headerAttrs?.style}
                        >
                            {props.header}
                            <div className='d-flex align-items-center align-self-center ms-auto'>
                                {props.expandLink && (
                                    <Link
                                        aria-label='Navigate to detail page'
                                        to={props.expandLink}
                                        className='btn btn-xs bg-light-hover'
                                    >
                                        <ArrowsOutSimple className=' ' />
                                    </Link>
                                )}
                                <Button
                                    aria-label={`Close: ${props.ariaLabel || ''}`}
                                    hidden={props.closeButton === false}
                                    disabled={props.canClose === false}
                                    className='btn btn-xs bg-light-hover me--1'
                                    onClick={() => setVisible(false)}
                                >
                                    <X className=' ' />
                                </Button>
                            </div>
                        </div>
                    )}
                    <div className={props.bodyClassName || 'modal-body'}>{props.content}</div>
                    {props.footer && <div className={props.footerClassName || 'modal-footer'}>{props.footer}</div>}
                </div>
            </div>
            {props.outsideModal}
        </div>
    )
}
