import Utils from '../../services/utils'
import { SlideIn } from '../SlideIn'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { AmountInput } from '../Input/Amount'
import { UnitType } from '../../services/unit'
import CO2e from '../CO2e'
import Delete from '../Delete'
import { ApplicationContext } from '../../context'
import { PrettyNumber } from '../PrettyNumber'
import { DebugData } from '../Footnotes'
import TransportService, { TransportInstance } from '../../services/transport'
import { TransportTypeSelector } from './TransportTypeSelector'
import { VariableServicesContext } from '../../services'
import TransportDetail from './TransportDetail'
import { TransportTypeLinks } from './TransportTypeLinks'
import ProductService from '../../services/product'
import { Warning } from '@phosphor-icons/react'
import { useTransportInstance } from '../../hooks/useTransportInstance'
import { useTransportType } from '../../hooks/useTransportType'

export const TransportEditor = () => {
    const context = useContext(ApplicationContext)
    const { transportService } = useContext(VariableServicesContext)
    const [saving, setSaving] = useState<boolean>(false)
    const inputRef = useRef<any>()
    const [transportTypeId, setTransportTypeId] = useState<string | undefined>(undefined)

    const transportInstance = useTransportInstance({ transportInstanceId: context.stores.transport?.instanceId })
    const transportType = useTransportType({ transportType: transportInstance?.transportType, transportTypeId })

    const save = useCallback(
        (properties: Partial<TransportInstance>) => {
            if (!context.stores.transport?.node?.uuid) return
            setSaving(true)
            return transportService
                .updateTransportInstance({ ...transportInstance, ...properties }, context.stores.transport?.node?.uuid)
                .finally(() => setSaving(false))
        },
        [transportInstance, context.stores.transport?.node?.uuid],
    )

    useEffect(() => {
        setTimeout(() => inputRef.current?.focus(), 301)
    }, [transportInstance?.uuid])

    if (!transportInstance) return null

    return (
        <SlideIn
            ariaLabel='Transport Editor'
            style={{ width: '500px', maxWidth: '90%' }}
            show={true}
            onVisibilityChange={(isVisible) => !isVisible && transportService.unsetTransportContext()}
            useBackdrop={true}
        >
            <div className='d-flex flex-column justify-content-between gap-3 flex-grow-1'>
                <div className='row align-items-center row-gap-2'>
                    <div className='col-3 text-nowrap small'>Weight</div>
                    <div className='col-9'>
                        <AmountInput
                            amount={transportInstance?.weight}
                            inputFieldProps={{ passedRef: inputRef, focusOnRender: true }}
                            unitSelectorProps={{ unitType: UnitType.WEIGHT }}
                            onChange={(newWeight) => save({ weight: newWeight })}
                        />
                    </div>
                    <div className='col-3 text-nowrap small'>Lane</div>
                    <div className='col-9'>
                        <TransportTypeSelector
                            option={transportType?.uuid}
                            placement='bottom-start'
                            onSelect={(newType) => {
                                setTransportTypeId(undefined)
                                save({ transportType: newType })?.then(() =>
                                    transportService.getTransportTypes(undefined, true),
                                )
                            }}
                            onNew={() => setTransportTypeId(TransportService.transportNewId)}
                        />
                    </div>
                    {transportType && (
                        <TransportDetail
                            className='border-top mt-2 pt-2'
                            transportType={transportType}
                            isEmbedded={true}
                            showDelete={false}
                            onSaving={() => setSaving(true)}
                            onSave={(tt) => {
                                setTransportTypeId(undefined)
                                save({ transportType: tt })
                            }}
                        />
                    )}
                    <div className='small border-top pt-2 mt-4'>
                        <div className='d-flex align-items-center justify-content-between mb-1'>
                            <span>Total distance</span>
                            <span>
                                <PrettyNumber num={transportType?.totalKm} precision={0} /> km
                            </span>
                        </div>
                        <div className='d-flex align-items-center justify-content-between mb-1'>
                            <span>Total Footprint</span>
                            <CO2e co2e={transportInstance.co2e} unitSize='small' />
                        </div>
                        <div className='d-flex align-items-center justify-content-between mb-1'>
                            <span>Tonne kilometers</span>
                            <span>
                                <PrettyNumber num={transportInstance.tkm} precision={2} /> tkm
                            </span>
                        </div>
                    </div>
                    {!!transportType?.partOfCount && transportType.partOfCount > 1 && (
                        <div className='px-2'>
                            <div className='bg-warning bg-opacity-10 shadow-sm small p-2 rounded-1'>
                                <Warning className='text-warning nt--1' />
                                This lane is used in {transportType.partOfCount}{' '}
                                {ProductService.elementTitle(transportType.partOfCount !== 1).toLowerCase()}. Changes
                                made here will affect all of them.
                                <TransportTypeLinks
                                    transportType={transportType}
                                    linkClassName='d-block underline-on-hover'
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className='fs-base text-center'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='small text-very-muted'>
                            Last saved: {Utils.toDateTimeString(transportInstance.updated || transportInstance.created)}
                            <span hidden={!saving} className='ms-1 spinner-border spinner-border-sm' />
                        </div>
                        <div hidden={!context.stores.transport?.canDelete} className='small'>
                            <Delete
                                className='text-muted'
                                deleteFn={() => transportService.deleteTransportInstance(transportInstance)}
                            />
                        </div>
                    </div>
                    <DebugData node={transportInstance} />
                </div>
            </div>
        </SlideIn>
    )
}
