import {
    Decimal,
    GeographyRegionOrSubregion,
    ISO_3166_2_Subdivision_Code,
    ISO_3166_CC,
    KgCO2e,
    URN_ID,
    UTC_ISO_8601,
} from '../types'
import VariableService from './service'
import { Product } from './product'

export type PactCharacterizationFactor = 'AR6' | 'AR5'

export type PactCrossSectoralStandard = 'GHG Protocol Product standard' | 'ISO Standard 14067' | 'ISO Standard 14044'

export type PactBiogenicAccountingMethodology = 'PEF' | 'ISO' | 'GHGP' | 'Quantis'

export type ProductOrSectorSpecificRuleOperator = 'PEF' | 'EPD International' | 'Other'

export type ProductOrSectorSpecificRule = {
    operator: ProductOrSectorSpecificRuleOperator
    ruleNames?: string[]
}

export type CoveragePercentMin5 = number
export type CPC_Code = string // Example value of the CPC code for "wood in chips or particles": 31230
export type DataQualityRatingValue = 1 | 2 | 3

export type ExemptedEmissionsPercent_From_0_to_5 = Decimal // number between 0.0 and 5 including

export interface EmissionFactorDataSource {
    // { "name": "ecoinvent", "version": "3.9.1" }
    name: string
    version: string
}

export interface PactProductImageExtension {
    url: string
}

export interface PactDocumentExtension {
    name?: string
    url: string
}

export interface PactDataModelExtension {
    specVersion: string
    dataSchema: string
    documentation: string
    data: PactDocumentExtension[] | PactProductImageExtension[]
}

export interface PactIntensityMetrics {
    pCfExcludingBiogenic?: KgCO2e
    pCfIncludingBiogenic?: KgCO2e
    fossilGhgEmissions?: KgCO2e
    fossilCarbonContent?: KgCO2e
    biogenicCarbonContent?: KgCO2e
    dLucGhgEmissions?: KgCO2e
    landManagementGhgEmissions?: KgCO2e
    otherBiogenicGhgEmissions?: KgCO2e
    iLucGhgEmissions?: KgCO2e
    biogenicCarbonWithdrawal?: Decimal
    exemptedEmissionsPercent?: ExemptedEmissionsPercent_From_0_to_5
    exemptedEmissionsDescription?: string
    packagingEmissionsIncluded?: boolean
    packagingGhgEmissions?: KgCO2e
    secondaryEmissionFactorSources?: EmissionFactorDataSource[]
    productOrSectorSpecificRules?: ProductOrSectorSpecificRule[]
    pcfPrimaryDataShare?: Decimal
}

export interface PactDataQualityIndicators {
    coveragePercent: CoveragePercentMin5
    technologicalDQR: DataQualityRatingValue
    temporalDQR: DataQualityRatingValue
    geographicalDQR: DataQualityRatingValue
    completenessDQR: DataQualityRatingValue
    reliabilityDQR: DataQualityRatingValue
}

export interface PactProductFootprint {
    id?: string
    specVersion?: string
    precedingPfIds?: string[]
    version?: number
    status?: 'Active' | 'Deprecated'
    statusComment?: string
    validityPeriodStart?: UTC_ISO_8601
    validityPeriodEnd?: UTC_ISO_8601
    companyName?: string
    companyIds?: string[]
    productIds?: URN_ID[] // urn:pathfinder:product:customcode:buyer-assigned:1234, urn:pathfinder:product:customcode:vendor-assigned:8765, urn:pathfinder:product:id:cas:64-17-5
    productCategoryCpc?: CPC_Code
    productNameCompany?: string
    productDescription?: string
    comment?: string
    pcf?: PactCarbonFootprint
    extensions?: PactDataModelExtension[]
    created?: UTC_ISO_8601
    updated?: UTC_ISO_8601
}

export interface PactCarbonFootprint extends PactIntensityMetrics {
    id?: string
    declaredUnit?: string // PactDeclaredUnit
    unitaryProductAmount?: KgCO2e
    aircraftGhgEmissions?: KgCO2e
    characterizationFactors?: PactCharacterizationFactor[]
    crossSectoralStandardsUsed?: PactCrossSectoralStandard[]
    productOrSectorSpecificRules?: ProductOrSectorSpecificRule[]
    biogenicAccountingMethodology?: PactBiogenicAccountingMethodology[]
    boundaryProcessesDescription?: string
    referencePeriodStart?: UTC_ISO_8601
    referencePeriodEnd?: UTC_ISO_8601
    geographyCountrySubdivision?: ISO_3166_2_Subdivision_Code
    geographyCountry?: ISO_3166_CC
    geographyRegionOrSubregion?: GeographyRegionOrSubregion
    secondaryEmissionFactorSources?: EmissionFactorDataSource[]
    allocationRulesDescription?: string
    uncertaintyAssessmentDescription?: string
    primaryDataShare?: Decimal
    dqi?: PactDataQualityIndicators
    assurance?: string
}

export default class PactService extends VariableService {
    public static isPactSynced(product: Product): boolean {
        return product?.syncId?.startsWith('pact_') || false
    }

    public async getFootprints(): Promise<PactProductFootprint[]> {
        return this.httpService.get<{ data: PactProductFootprint[] }>('/pact/2/footprints').then((p) => p.data)
    }
}
