import Tooltip, { TooltipProps } from './Tooltip'
import { Link, useNavigate } from 'react-router-dom'
import { ArrowSquareOut, ArrowSquareRight } from '@phosphor-icons/react'
import Utils from '../services/utils'
import { HTMLAttributeAnchorTarget, ReactNode, useContext, useMemo } from 'react'
import { VariableNode } from '../types'
import { Product } from '../services/product'
import { VariableServicesContext } from '../services'
import { MouseEvent } from 'react'

export const GoTo = (props: {
    children?: ReactNode
    url: string
    preview?: VariableNode
    className?: string
    linkClassName?: string
    tooltip?: ReactNode
    tooltipProps?: Partial<TooltipProps>
    hidden?: boolean
    useAHref?: boolean
    target?: HTMLAttributeAnchorTarget
    onClick?: (e: MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}) => {
    const { productService } = useContext(VariableServicesContext)
    const navigate = useNavigate()

    const icon = useMemo(() => {
        if (props.preview) {
            return <ArrowSquareOut className='nt--1 flex-shrink-0' />
        }
        if (props.useAHref && props.target === '_blank') {
            return <ArrowSquareOut className='nt--1 flex-shrink-0' />
        }
        return <ArrowSquareRight className='nt--1 flex-shrink-0' />
    }, [props.preview, props.useAHref, props.target])

    const linkClassName = useMemo(() => props.linkClassName || 'text-muted text-primary-hover', [props.linkClassName])

    const _innerHtml = useMemo(() => {
        if (props.children) {
            return (
                <span className='d-flex align-items-center gap-1'>
                    {props.children} {icon}
                </span>
            )
        }
        return icon
    }, [])

    if (props.useAHref) {
        return (
            <span hidden={props.hidden} className={props.className}>
                <a
                    target={props.target}
                    rel={props.target === '_blank' ? 'noreferrer' : undefined}
                    href={props.url}
                    className={linkClassName}
                >
                    {_innerHtml}
                </a>
            </span>
        )
    }

    const link = (
        <Link
            to={props.url}
            aria-label='Navigate to detail page'
            className={linkClassName}
            onClick={(e: any) => {
                if (props.preview && !Utils.isModifierKey(e) && !Utils.isAltKey(e)) {
                    e.preventDefault()
                    productService.openPreview(props.preview as Product)
                }
                if (Utils.isAltKey(e) && !props.url.startsWith('http')) {
                    e.preventDefault()
                    navigate(props.url)
                }
                props.onClick?.(e)
            }}
        >
            {_innerHtml}
        </Link>
    )

    if (!props.tooltip)
        return (
            <span hidden={props.hidden} className={props.className}>
                {link}
            </span>
        )

    return (
        <span hidden={props.hidden} className={props.className}>
            <Tooltip
                tooltipClassName='small'
                positioning='fixed'
                trigger='hover'
                tooltipContent={props.tooltip}
                {...props.tooltipProps}
            >
                {link}
            </Tooltip>
        </span>
    )
}
