import Utils from '../../services/utils'
import { IconProps } from '@phosphor-icons/react'

export const CircularIcon = (props: IconProps) => (
    <svg
        width={props.size || Utils.smallIconSize}
        height={props.size || Utils.smallIconSize}
        viewBox='0 0 24 24'
        fill={props.color || 'currentColor'}
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10.5359 6.53591L13.9019 2.70578L9.0718 1.07181L9.54122 2.82372C7.5212 3.36498 5.73623 4.55766 4.46314 6.21678C3.19006 7.8759 2.5 9.90874 2.5 12H4.5C4.5 10.349 5.04478 8.74413 6.04985 7.4343C7.05492 6.12447 8.46411 5.18288 10.0589 4.75557L10.5359 6.53591ZM19.2444 13.9412C19.6717 12.3464 19.5609 10.6552 18.929 9.12988C18.2972 7.60455 17.1798 6.33032 15.7499 5.50482L16.7499 3.77277C18.561 4.81841 19.9765 6.43243 20.7768 8.36452C21.5771 10.2966 21.7175 12.4388 21.1762 14.4588L22.9281 14.9282L19.098 18.2942L17.464 13.4641L19.2444 13.9412ZM11.9983 9.999C13.1013 9.999 13.9983 10.896 13.9983 11.999C13.9983 13.102 13.1013 13.999 11.9983 13.999C10.8953 13.999 9.99826 13.102 9.99826 11.999C9.99826 10.896 10.8963 9.999 11.9983 9.999ZM11.9983 7.999C9.78926 7.999 7.99826 9.79 7.99826 11.999C7.99826 14.208 9.78926 15.999 11.9983 15.999C14.2073 15.999 15.9983 14.208 15.9983 11.999C15.9983 9.79 14.2083 7.999 11.9983 7.999ZM6.69667 17.3033C7.86411 18.4707 9.38414 19.2203 11.021 19.4358C12.6579 19.6513 14.3202 19.3207 15.75 18.4952L16.75 20.2273C14.9389 21.2729 12.8334 21.6917 10.76 21.4187C8.68659 21.1458 6.76121 20.1963 5.28246 18.7175L3.99997 20L2.99997 15L7.99997 16L6.69667 17.3033Z'
        />
    </svg>
)
