import { ActivityStateUpdate } from '../services/activity'
import Utils from '../services/utils'
import { UserCard } from './User/UserCard'
import { ReactNode } from 'react'
import { VariableNodeState } from '../types'

export const StateItem = (props: {
    stateUpdate: ActivityStateUpdate
    getBadge: (state: VariableNodeState) => ReactNode
}) => (
    <div className='text-start'>
        <div className='d-flex gap-2 align-items-center justify-content-between'>
            <span>{Utils.toDateTimeString(props.stateUpdate.created)}</span>
            <span className='text-end'>{props.getBadge(props.stateUpdate.newState)}</span>
        </div>
        {props.stateUpdate.adminOverride && <div className='tag tag-sm bg-dark bg-opacity-10 rounded-1'>Admin</div>}
        <blockquote hidden={!props.stateUpdate.message} className='m-0 border-start border-5 ps-1'>
            {props.stateUpdate.message}
        </blockquote>
        <UserCard user={props.stateUpdate.user} extraClassName='mt-1' />
    </div>
)
