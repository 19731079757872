import { Key, KeyValuePair, ListResponse, VariableBaseNode } from '../types'
import Utils from './utils'
import { Product, ProductVisibility } from './product'
import { Company } from './company'
import VariableService from './service'
import { GoTo } from '../components/GoTo'
import FlagService, { FlagType } from './flag'

export enum DataSourceType {
    MONITOR = 'monitor',
    PACT = 'pact',
    Salesforce = 'salesforce',
    DATABASE = 'database',
}

export interface WhitelabelConfig {
    backgroundColor?: string
    ctaColor?: string
    headerFont?: string
    bodyFont?: string
}

export interface WhitelabelFields extends WhitelabelConfig {
    squareLogo?: string
    wideLogo?: string
    shortcutIcon?: string
    subdomain?: string
    whitelabel?: string
}

export interface DataSource extends VariableBaseNode, WhitelabelFields {
    name: string
    longName?: string
    url: string
    notes?: string
    type?: DataSourceType
    visibility?: ProductVisibility
    productCount?: number
    key?: Key
    lastSync?: number
}

export interface PremiumFactors {
    company: Company
    dataSource: DataSource
    factors: Product[]
}

export default class DataSourceService extends VariableService {
    private basePath: string = '/data-source'

    public static list: DataSource[] = []
    public static publicList: DataSource[] = []
    public static publicListIds: Set<string> = new Set()

    public dataSourcesEnabled(): boolean {
        return FlagService.enabledFlags.has(FlagType.UseDataSources)
    }

    public monitorEnabled(): boolean {
        return FlagService.enabledFlags.has(FlagType.ShowMonitor) && !!DataSourceService.list.length
    }

    public getDataSourceTypeName(dataSourceType?: DataSourceType): string {
        const ds = this.getDataSourceTypes().find((dst) => dst.value === dataSourceType)
        return (ds?.name as string) || ''
    }

    public getDataSourceTypes(isAdmin: boolean = false): KeyValuePair<DataSourceType>[] {
        const dst: KeyValuePair<DataSourceType>[] = [
            {
                name: 'PACT API',
                value: DataSourceType.PACT,
                description: (
                    <>
                        PACT (Partnership for Carbon Transparency) compliant API{' '}
                        <GoTo
                            useAHref={true}
                            url='https://www.carbon-transparency.com'
                            className='text-decoration-underline'
                            target='_blank'
                        >
                            More info
                        </GoTo>
                    </>
                ),
            },
        ]
        if (FlagService.enabledFlags.has(FlagType.EnableSalesforce)) {
            dst.push({
                name: 'Salesforce',
                value: DataSourceType.Salesforce,
                description: 'Salesforce integration',
            })
        }
        if (isAdmin) {
            dst.unshift({
                name: 'Database',
                value: DataSourceType.DATABASE,
                description: 'Database (ecoinvent, DEFRA, etc.)',
            })
        }
        if (this.monitorEnabled()) {
            dst.push({ name: 'Monitor', value: DataSourceType.MONITOR, description: 'Monitor ERP' })
        }
        return dst
    }

    public getDataSourceTypeString(dataSource?: DataSource): string {
        switch (dataSource?.type) {
            case DataSourceType.PACT:
                return 'PACT'
            case DataSourceType.MONITOR:
                return 'Monitor'
            case DataSourceType.DATABASE:
                return 'Database'
            default:
                return ''
        }
    }

    public async getPublic(cacheOk: boolean = true): Promise<DataSource[]> {
        if (DataSourceService.publicList.length > 0 && cacheOk) {
            return Promise.resolve(DataSourceService.publicList)
        }
        return this.httpService.get<DataSource[]>(this.basePath).then((ds) => {
            DataSourceService.publicList = ds
            ds.forEach((d) => DataSourceService.publicListIds.add(d.uuid!))
            return ds
        })
    }

    public async getByTargetNode(targetNodeId: string): Promise<DataSource[]> {
        return this.httpService.get<DataSource[]>(`${this.basePath}?target=${targetNodeId}`)
    }

    public async getPrivate(): Promise<ListResponse<DataSource>> {
        if (!this.monitorEnabled()) {
            return Promise.resolve({ count: 0, page: 1, data: [] })
        }
        return this.httpService.get<ListResponse<DataSource>>(`${this.basePath}?p=1`).then((ds) => {
            DataSourceService.list = ds.data
            return ds
        })
    }

    public getById(dataSourceId: string, withCredentials: boolean = false): Promise<DataSource> {
        const qs = new URLSearchParams()
        qs.set('wc', withCredentials ? 'true' : 'false')
        return this.httpService.get<DataSource>(`${this.basePath}/${dataSourceId}?${qs.toString()}`)
    }

    public async getByName(dataSourceName: string): Promise<DataSource> {
        const dataSources = await this.getPrivate()
        const dataSource = dataSources.data.find((ds) => ds.name === dataSourceName)
        if (!dataSource) {
            Utils.errorToast('DataSource Error')
            throw new Error('data source not found')
        }
        return dataSource
    }

    public createOrUpdate(dataSource: Partial<DataSource>, targetNodeId?: string): Promise<DataSource> {
        return this.httpService.put<DataSource>(this.basePath, {
            body: JSON.stringify({ dataSource: dataSource, targetNodeId: targetNodeId }),
        })
    }

    public delete(dataSource: DataSource): Promise<any> {
        return this.httpService.delete<any>(`${this.basePath}/${dataSource.uuid}`)
    }

    public deleteKey(dataSource: DataSource): Promise<any> {
        return this.httpService.delete<any>(`${this.basePath}/${dataSource.uuid}/key`)
    }
}
