import { TransportType } from '../../services/transport'
import { useContext, useMemo } from 'react'
import { VariableServicesContext } from '../../services'
import { InventoryService } from '../../services/inventory'
import { UsedInItem } from '../UsedInItem'

export const TransportTypeLinks = (props: { transportType?: TransportType; linkClassName?: string }) => {
    const { transportService } = useContext(VariableServicesContext)
    const inv = useMemo(
        () => props.transportType && InventoryService.transportToInventory(props.transportType),
        [props.transportType],
    )
    if (!inv?.usedIn?.length) return null
    return (
        <div className='d-flex flex-column align-items-start'>
            {inv?.usedIn?.map((ui) => (
                <UsedInItem
                    key={`transport-link-${ui.uuid}`}
                    usedIn={ui}
                    extraOnClick={() => transportService.unsetTransportContext()}
                    followLink={true}
                />
            ))}
        </div>
    )
}
