import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import Spinner from './Spinner'
import ProductService, { DataQuality, PassportDisplayConfig, Product, ProductFootprintType } from '../services/product'
import CO2e from './CO2e'
import { QrCode } from './QrCode'
import Card from './Card'
import { DocumentList } from './Input/FileUploader'
import AuthenticationService from '../services/authentication'
import { TaxonomyLabel } from './TaxonomyLabel'
import { ProductUsage } from './Product/ProductUsage'
import { ApplicationContext } from '../context'
import { GoTo } from './GoTo'
import HttpService from '../services/http'
import { DebugData } from './Footnotes'
import { InfoIcon } from './Icons/InfoIcon'
import { Img } from './Img'
import { DateString } from './DateString'
import Logo from './Logo'
import { Link } from 'react-router-dom'
import { Passport } from './Passport'
import { useMainCategories } from '../hooks/useMainCategories'
import { BarChart, BarChartProps } from './Charts/BarChart'
import { InventoryToggle } from './InventoryToggle'
import { Co2eDisplay } from '../types'
import { VariableServicesContext } from '../services'
import { TagList } from './TagList'
import { ProductCO2eDebug } from './Product/ProductCO2eDebug'

export const DigitalProductPassport = (props: {
    isEmbed?: boolean
    isModal?: boolean
    token?: string
    className?: string
    cardClassName?: string
    productId?: string
    product?: Product
    dppOptions?: PassportDisplayConfig
    barChartProps?: Partial<BarChartProps>
    showTaxonomyPath?: boolean
    showLinks?: boolean
    co2eDisplay?: Co2eDisplay
    onProduct?: (product?: Product) => void
    onError?: (err: Error) => void
    onRender?: (width: number, height: number) => void
    onUpdate?: () => void
}) => {
    const context = useContext(ApplicationContext)
    const { productService } = useContext(VariableServicesContext)
    const { barChartData, maxPrecision } = useMainCategories({ product: props.product })
    const ref = useRef<any>()

    const passportUrl = useMemo(() => ProductService.getPassportUrl(props.product), [props.product?.visibility])

    const passportId = useMemo(() => ProductService.getPassportCode(props.product), [props.product?.visibility])

    const isLive = useMemo(
        () =>
            (props.product?.type === ProductFootprintType.LIVE ||
                props.product?.useStageSummary?.some((ust) => ust.code)) &&
            !props.product.syncId?.startsWith('nobb_') &&
            props.product.quality !== DataQuality.MODEL,
        [props.product?.type, props.product?.useStageSummary, props.product?.syncId, props.product?.quality],
    )

    const sendSize = useCallback(() => {
        const _height = ref.current && ref.current.offsetHeight
        const _width = ref.current && ref.current.offsetWidth
        if (window.parent !== window && _height) {
            window.parent.postMessage(JSON.stringify({ id: props.product?.uuid, width: _width, height: _height }), '*')
        }
        if (props.onRender && _height) {
            props.onRender(_width, _height)
        }
    }, [ref.current, props.product?.uuid])

    useEffect(() => {
        window.addEventListener('resize', sendSize)
        return () => window.removeEventListener('resize', sendSize)
    }, [])

    useEffect(() => {
        setTimeout(() => sendSize(), 500)
    }, [props.dppOptions, props.showTaxonomyPath, props.showLinks, props.product?.uuid])

    const documentList = useMemo(() => {
        if (!props.product?.documents?.length) return null
        return (
            <DocumentList
                className='d-flex flex-wrap align-items-center'
                nodeId={props.product.uuid}
                files={props.product.documents}
                documentClassName='d-block mb-1 max-w-50 me-2 mb-2'
                onUnAuthClick={() => AuthenticationService.sendUserToAuth()}
            />
        )
    }, [props.product?.documents])

    const links = useMemo(() => {
        if (!props.product?.externalLink && !props.product?.dataSources?.length) return null
        return (
            <div className='small'>
                {props.product?.externalLink && (
                    <GoTo
                        linkClassName='text-body text-primary-hover'
                        useAHref={true}
                        target='_blank'
                        url={props.product.externalLink}
                    >
                        {props.product.externalLink}
                    </GoTo>
                )}
                {props.product?.dataSources?.map((ds) => (
                    <div key={`pds-${ds.uuid}`}>
                        <GoTo linkClassName='text-body text-primary-hover' useAHref={true} target='_blank' url={ds.url}>
                            {ds.longName || ds.name}
                        </GoTo>
                        <div>{ds.notes}</div>
                    </div>
                ))}
            </div>
        )
    }, [props.product?.externalLink, props.product?.dataSources])

    const dppLabel = useMemo(
        () => (
            <div className='small'>
                <InfoIcon
                    size={12}
                    iconClassName='ms-1 nt--1'
                    interactive={true}
                    iconPlacement='after'
                    tooltip={
                        <div>
                            Digital product passport (DPP) is a document carrying environmental performance data in
                            order to help consumers and other decision makers assess the sustainability impact of
                            products.
                        </div>
                    }
                >
                    Digital product passport
                </InfoIcon>
            </div>
        ),
        [],
    )

    const productImage = useMemo(
        () => (
            <div hidden={props.dppOptions?.showImage === false} className='mt-3 text-center'>
                <Img
                    src={props.product?.productImageUrl}
                    alt={props.product?.name}
                    width='100%'
                    style={{ maxHeight: '250px' }}
                />
            </div>
        ),
        [props.dppOptions?.showImage, props.product?.productImageUrl, props.product?.name],
    )

    if (!props.product) {
        return <Spinner />
    }

    if (!isLive) {
        return (
            <div style={{ width: '650px', maxWidth: '100%' }} className='mx-auto'>
                <Passport product={props.product} isModal={props.isModal} />
            </div>
        )
    }

    return (
        <div ref={ref} style={{ width: '650px', maxWidth: '100%' }} className={props.className || 'mx-auto text-start'}>
            {props.showTaxonomyPath !== false && (
                <div className={[props.isModal ? 'd-none' : 'd-flex', 'align-items-center gap-2 pe-2 mb-2'].join(' ')}>
                    <TaxonomyLabel taxonomy={props.product.taxonomy} followLink={!HttpService.getSubdomain()} />
                    <span className='ms-auto'>
                        <InventoryToggle product={props.product} showOnProduct={false} onCreated={props.onUpdate} />
                    </span>
                </div>
            )}
            <div className='d-sm-none pt-2 pb-3 px-3'>
                {dppLabel}
                {productImage}
            </div>
            <Card className={props.cardClassName || 'shadow'} innerClassName='p-4 bg-light bg-sm-inherit rounded-3'>
                <div className='d-none d-sm-block mb-4'>
                    {dppLabel}
                    {productImage}
                </div>
                <div className='d-flex flex-column gap-4 gap-md-5'>
                    <div className='d-flex align-items-center gap-1'>
                        <div className='d-flex flex-column align-items-start justify-content-between gap-2'>
                            <div className='d-flex flex-column d-sm-block fs-5 text-height-1'>
                                <h1 className='d-inline fs-inherit fw-bold m-0'>{props.product?.name}</h1>
                                <h6 className='d-inline fs-inherit m-0' hidden={!props.product.productOf?.uuid}>
                                    {' '}
                                    by {props.product.productOf?.name}
                                </h6>
                            </div>
                            <div className='text-height-1'>{props.product?.sku}</div>
                        </div>
                        <Img
                            src={props.product?.productOf?.logoUrl}
                            alt={props.product?.productOf?.name}
                            size='55px'
                            className='ms-auto'
                        />
                    </div>
                    <div className='d-flex align-items-center'>
                        <BarChart
                            id={`dpp-bar-chart-${props.product?.uuid || props.productId}`}
                            data={barChartData}
                            direction={props.dppOptions?.direction}
                            style={{
                                width: props.dppOptions?.direction === 'horizontal' ? '100%' : '480px',
                                height: props.dppOptions?.direction === 'horizontal' ? '150px' : '230px',
                                maxHeight: props.dppOptions?.direction === 'horizontal' ? '150px' : '230px',
                            }}
                            className={[props.dppOptions?.direction === 'horizontal' ? '' : 'mb-3', 'mx-auto'].join(
                                ' ',
                            )}
                            {...props.barChartProps}
                        />
                    </div>
                    <div className='d-flex align-items-center gap-2 text-primary'>
                        <div className='small'>Total carbon footprint</div>
                        <CO2e
                            co2e={
                                props.co2eDisplay === 'Upstream'
                                    ? productService.getEmbeddedCO2e(props.product)
                                    : props.product?.co2e
                            }
                            precision={maxPrecision}
                            showZeroesToPrecision={true}
                            product={props.product}
                            className='d-block text-nowrap text-end text-height-1_2 ms-auto'
                            numberClassName='fs-4 fw-bold'
                            unitsClassName={[
                                'd-block small',
                                props.dppOptions?.direction === 'vertical' ? 'd-md-inline' : '',
                            ].join(' ')}
                            functionalUnit={props.product.unit?.code}
                            functionalUnitClassName='ms-1'
                            unitSize='small'
                        />
                    </div>
                    <ProductCO2eDebug product={props.product} />
                    <div
                        className={[
                            'd-flex align-items-end gap-2',
                            props.dppOptions?.direction === 'horizontal' ? 'mt-4' : '',
                        ].join(' ')}
                    >
                        <div className='d-flex align-items-end gap-2'>
                            {passportUrl && (
                                <QrCode data={passportUrl} target={props.isEmbed ? '_blank' : '_top'} size={38} />
                            )}
                            <div className='very-small text-muted text-height-1'>
                                Updated: <DateString date={props.product?.created} />
                                {passportUrl && (
                                    <Link
                                        to={passportUrl}
                                        className='d-block text-inherit very-small mt-1'
                                        target={props.isEmbed ? '_blank' : '_top'}
                                    >
                                        {passportId}
                                    </Link>
                                )}
                            </div>
                        </div>
                        <Link
                            to={process.env.REACT_APP_WEBSITE_URL!}
                            target={props.isEmbed ? '_blank' : '_top'}
                            className='d-block position-relative ms-auto overflow-hidden text-primary'
                            style={{ width: '80px', height: '20px' }}
                        >
                            <Logo
                                className='position-absolute end-0 bottom-0'
                                logoStyle='text'
                                style={{ maxWidth: '100px', height: 'auto' }}
                            />
                        </Link>
                    </div>
                </div>
                {(documentList !== null || links !== null) && <hr />}
                {documentList}
                {links}
                <div className='small mt-3'>
                    <TagList product={props.product} />
                </div>
            </Card>
            <div
                hidden={props.showLinks === false || !context.stores.user?.uuid}
                className='small bg-body p-2 rounded-2 mt-3'
            >
                <ProductUsage product={props.product} />
            </div>
            <DebugData node={props.product} extraValues={[props.product?.syncId]} />
        </div>
    )
}
