import { useContext, useEffect, useMemo } from 'react'
import { SubscriptionService } from '../../services/subscription'
import { Modal } from '../Modal'
import { PercentBar } from '../PercentBar'
import Button from '../Input/Button'
import { Link } from 'react-router-dom'
import { ArrowCircleUp } from '@phosphor-icons/react'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'
import { PrettyNumber } from '../PrettyNumber'

export const UpgradeModal = () => {
    const context = useContext(ApplicationContext)
    const { uiService, analyticsService, companyService } = useContext(VariableServicesContext)

    useEffect(() => {
        if (context.stores.ui?.showUpgradeModal) analyticsService.track('Showing Upgrade Modal')
    }, [context.stores.ui?.showUpgradeModal])

    const sub = useMemo(() => context.stores.company?.subscription, [context.stores.company?.subscription])

    const userCount = useMemo(() => companyService.getMemberCount(), [context.stores.ui?.membersUpdated])

    return (
        <Modal
            size='lg'
            hidden={!context.stores.ui?.showUpgradeModal}
            content={
                <>
                    <div className='row'>
                        <div className='col py-3'>
                            <div className='pe-3'>
                                <h6>
                                    <ArrowCircleUp size={18} className='nt--1 me-1' />
                                    You are currently on the {sub?.stripeName || sub?.name} plan
                                </h6>
                                <span className='small'>
                                    Upgrade to increase limits and get access to premium features.
                                </span>
                                <div className='mt-3'>
                                    <span className='small'>
                                        <PrettyNumber num={userCount} /> of <PrettyNumber num={sub?.users} /> Users
                                    </span>
                                    <PercentBar
                                        total={sub?.users!}
                                        extraClassName='bg-primary bg-opacity-25'
                                        segments={[{ name: 'Users', amount: userCount, className: 'bg-info' }]}
                                        height='8px'
                                        showLabels={false}
                                    />
                                </div>
                                <div className='mt-3'>
                                    <span className='small'>
                                        <PrettyNumber num={companyService.getConnectedSupplierCount()} /> of{' '}
                                        <PrettyNumber num={sub?.suppliers} /> Suppliers
                                    </span>
                                    <PercentBar
                                        total={sub?.suppliers!}
                                        extraClassName='bg-primary bg-opacity-25'
                                        segments={[
                                            {
                                                name: 'Suppliers',
                                                amount: companyService.getConnectedSupplierCount(),
                                                className: 'bg-info',
                                            },
                                        ]}
                                        height='8px'
                                        showLabels={false}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col bg-light py-3 shadow-sm'>
                            <h6>Paid features</h6>
                            <ul className='list-unstyled small list'>
                                <li className='mb-1'>Audit-ready data & workflow</li>
                                <li className='mb-1'>Chat & community support</li>
                                <li className='mb-1'>Premium databases (incl. ecoinvent)</li>
                                <li className='mb-1'>Authentication controls</li>
                                <li className='mb-1'>Priority Support</li>
                                <li className='mb-1'>Custom LCA Models</li>
                            </ul>
                        </div>
                    </div>
                    <div className='mt-3 text-end'>
                        <Button
                            onClick={() => uiService.showUpgradeModal(false)}
                            className='btn btn-sm btn-plain underline-on-hover small me-2 text-muted'
                        >
                            Dismiss
                        </Button>
                        <Link
                            onClick={() => uiService.showUpgradeModal(false)}
                            className='btn btn-sm btn-secondary'
                            to={SubscriptionService.appPricingPath}
                        >
                            Choose a plan
                        </Link>
                    </div>
                </>
            }
            onVisibilityChange={(v) => uiService.showUpgradeModal(v)}
        />
    )
}
