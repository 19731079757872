import { ActionMap } from '../context'
import { Company } from './company'
import { VariableBaseNode } from '../types'

export interface Workspace extends VariableBaseNode {
    name: string
}

export interface IWorkspaceContext {
    newWorkspace?: Company
}

export enum WorkspaceActionType {
    Create = 'CreateWorkspace',
}

type WorkspaceActionPayload = {
    [WorkspaceActionType.Create]: Company | undefined
}

export type WorkspaceActions = ActionMap<WorkspaceActionPayload>[keyof ActionMap<WorkspaceActionPayload>]

export const WorkspaceReducer = (state: IWorkspaceContext, action: WorkspaceActions): IWorkspaceContext => {
    switch (action.type) {
        case WorkspaceActionType.Create:
            return {
                ...state,
                newWorkspace: action.payload,
            }
        default:
            return state
    }
}
