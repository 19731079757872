import { useCallback, useContext } from 'react'
import { ActivityActionType, ActivityContext } from '../services/activityContext'
import { VariableServicesContext } from '../services'

export const useActivityContext = () => {
    const activityContext = useContext(ActivityContext)
    const { activityService } = useContext(VariableServicesContext)

    const getAllActivityIds = useCallback(async () => {
        const activityQueryString = new URLSearchParams(activityContext.queryString)
        activityQueryString.set('orderDir', 'DESC')
        activityQueryString.set('return', 'uuid')
        activityQueryString.set('limit', 'null')
        // console.log(activityQueryString.toString())
        const ai = await activityService.get(activityQueryString.toString())
        return new Set(ai.data?.filter((item) => item.uuid)?.map((item) => item.uuid!))
    }, [activityContext.queryString])

    const resetActivityContext = useCallback(() => {
        activityService.resetBulkIds()
        activityContext.dispatch({ type: ActivityActionType.Reset })
    }, [])

    return { activityContext, getAllActivityIds, resetActivityContext }
}
